import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { addMonthReducer, listEmployeeLeaveReducer } from "../../../reduxData/leaveSlice";
import { Link, json } from "react-router-dom";

export default function ManageLeaveReport() {
  const _ = require("lodash")

  const { data, saloonLeaveDetails } = useSelector((state: any) => state.leaveInfo.value)
  const localStorageLeaveInfo = JSON.parse(localStorage.getItem("leaveReportInfo"))
  // const leaveInfo = useSelector((state:any)=>state.leaveInfo.value)
  const authInfo = useSelector((state: any) => state.authUserInfo.value);
  const employeeinfo = useSelector((state: any) => state.employeeInfo.value);
  const selectedMonth = useSelector((state:any)=>state.leaveInfo.leaveMonth)
  const dispatch = useDispatch();

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentDate = new Date().toISOString().substring(0, 10);

  const [month, setMonth] = useState(localStorageLeaveInfo?.month??selectedMonth?.month??`${currentYear}-0${currentMonth}`);
  const [days, setdays] = useState([]);
  const [employees, setemployees] = useState([]);

  const handleReport = () => {
    const [y, m]: any = month.split('-');
    const newdays = new Date(y, m, 0).getDate();
    var daysarr: any = [];
    for (let i = 1; i <= newdays; i++) {
      let day = new Date(y, m - 1, i);
      const dayname = day.toLocaleDateString('default', { weekday: 'short' });
      let nd = i.toString().padStart(2, '0');
      daysarr.push(`${y}-${m}-${nd}-${dayname}`);
    }
    setdays(daysarr);
    var final: any = [];
    for (let e of employeeinfo) {
      var list = [];
      var pcount = 0;
      var acount = 0;
      list.push(e.emp_id + " " + e.first_name)
      for (let days of daysarr) {

        if (days.includes(saloonLeaveDetails?.weekoff_day)) {
          list.push("WO");
          continue
        }
        var found = false
        for (let d of data) {
          if (d.leave_date.substring(0, 10) === days.substring(0, 10) && e.emp_id.includes(d.employeeinfo.emp_id) && days.substring(0, 10) >= e.date_of_joining.substring(0, 10)) {
            if (d.leave_type === 'Full Day') {
              acount = acount + 1;
              list.push("FL")
              found = true
              break;
            }
            else if (d.leave_type === 'Half Day')
              acount = acount + 0.5; {
              list.push("HL")
              found = true
              break;
            }
          }
        }
        if (!found && (days.substring(0, 10) >= e.date_of_joining.substring(0, 10)) && currentDate > days.substring(0, 10)) {
          pcount = pcount + 1;
          list.push("P");
        }
        else if (!found) {
          list.push("-");
        }
      }
      list.push(pcount)
      list.push(acount)
      final.push(list);
    }
    setemployees(final);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    localStorage.setItem("leaveReportInfo",JSON.stringify({month:month}))
    dispatch(addMonthReducer({month:month}))
   handleReport();
  }
  useEffect(()=>{
    if(selectedMonth!=null||localStorageLeaveInfo!=null){
      handleReport();
    }
  },[]);
  // const handleCall = async() => {
  //   const url = `${urls.LEAVE_LIST}/1/100`;
  //   const resp = await AuthApiService.GetApiCall(url,authInfo.token);
  //   dispatch(listEmployeeLeaveReducer(resp.data.data));
  // }

  const handlePrint = () => {
    window.print();
  };
  return (
    <>
      <div className="container-fluid p-0">
        <div className="container">

          <div className="row m-0 d-print-none">
            {/* <div className="col-lg-12 col-md-12 col-sm-12 "> */}
            <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
              <Link to="/branchManager/leavelist">
                <span>
                  <button className=" btn btn-sm btn-purple">Back</button>
                </span>
              </Link>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-6 col-6">

            </div>
            <div className="col-lg-2 col-md-2 col-sm-3 col-4 mt-2 d-flex justify-content-end">
              <Link to="">
                <span>
                  <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
                </span>
              </Link>
            </div>
            {/* </div> */}
          </div>
        </div>
        <h4 className="text-center">Employee's Attendence Report</h4>
        <div className="row m-0 pb-xl-4 pb-4">
          <div className="col-lg-3 col-md-3 col-sm-12 d-print-none ">
            <form onSubmit={handleSubmit}>
              <div className="input-group input-group-sm shadow-sm ">
                <input
                  type="month"
                  className="form-control shadow-sm"
                  placeholder="Search Employee Name..."
                  value={month}
                  onChange={(e) => { setMonth(e.target.value) }}
                />
                <button className="btn btn-sm btn-purple" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="table-responsive">
          <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
            <thead>
              <tr className="text-nowrap">
                {days.length == 0 ? "" : <th>Emp Name</th>}
                {days.map((data: any) => { return <th style={{ backgroundColor: data.includes(saloonLeaveDetails?.weekoff_day) ? "RGBA(0,0,0,0.1)" : "" }}>{data.substring(8, 10)}<br/>{data.substring(11,12)}</th> })}
                {days.length != 0 && <th>P</th>}
                {days.length != 0 && <th>A</th>}
              </tr>
            </thead>
            <tbody>
              {employees.map((data: any) => {
                return <tr>
                  {data.map((d: any) => { return <td style={{ backgroundColor: d == "WO" ? 'rgba(0,0,0,0.1)' : '', color: d == "WO" ? "red" : "", fontWeight: d == "WO" ? "bold" : "" }}>{d}</td> })}
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
