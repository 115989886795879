
import React, { useRef, useState, FormEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthApiService, { urls } from '../apiServices/AuthApiService';
import { useDispatch, useSelector } from 'react-redux';
import { authDetailReducer } from '../reduxData/authSlice';
import { LoginSchema } from '../validations/Validations';
import { Alert, Snackbar, RadioGroup, FormControlLabel, Radio } from "@mui/material"; // Import Modal from Material UI

import { Button, Modal } from "react-bootstrap";

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [msg, setMsg] = useState('');
    const [stoken, setToken] = useState('');
    const [userid, setUserId] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const usernameBox = useRef<HTMLInputElement>(null);
    const passwordBox = useRef<HTMLInputElement>(null);
    const authUser = useSelector((state: any) => state.authUserInfo.value);

    const [status, setStatus]: any = useState();
    const [open, setOpen] = useState(false);
    const [roles, setRoles] = useState([]); // State to hold user roles
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectRoleModal, setSelectRoleModal] = useState(false);
    // console.log(roles)
    // useEffect(() => {
    //     const token = localStorage.getItem('token');
    //     if (token && authUser.isLogin && authUser.roleValue == "SCM") {
    //         navigate('/saloonChain');
    //     } else if (token && authUser.isLogin && authUser.roleValue == "BRM") {
    //         navigate('/branchManager');
    //     }

    //     const auth = localStorage.getItem("authInfo")
    //     if (auth == "" || auth == null) {
    //         navigate('/');
    //     }
    // }, []);


    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            setMsg('');
            setIsLoading(true);

            try {
                const response = await AuthApiService.PostApiCall(urls.LOGIN, values);

                if (response.data.status) {
                    const userRoles = response.data.roles;
                    localStorage.setItem('token', response.data.token);
                    setToken(response.data.token)
                    setUserId(response.data.user_info.id)

                    const scmRole = userRoles.find((role: any) => role.role_value === "SCM");

                    // Check if the user has the BRM role
                    const brmRole = userRoles.find((role: any) => role.role_value === "BRM");
                    const stfRole = userRoles.find((role: any) => role.role_value === "STF");
                    // console.log("staff id", stfRole)
                    if (userRoles.length === 1) {
                        if (scmRole) {

                            dispatch(
                                authDetailReducer({
                                    uid: response.data.user_info.id,
                                    token: response.data.token,
                                    isLogin: true,
                                    firstname: response.data.user_info.first_name,
                                    lastname: response.data.user_info.last_name,
                                    role: scmRole.role_id,
                                    roleValue: scmRole.role_value,
                                    roleName: scmRole.role_name,
                                    saloonName: scmRole.additional_info.name,
                                    accessType: scmRole.additional_info?.access_type ?? '',
                                    saloon_id: scmRole.additional_info.id,
                                    saloon_logo: scmRole.settingInfo ? scmRole.settingInfo?.logo : "",
                                    currency: scmRole?.currencyInfo == null ? "" : scmRole?.currencyInfo?.symbol
                                })
                            );
                            navigate('/saloonChain');
                        } else if (brmRole) {

                            dispatch(
                                authDetailReducer({
                                    uid: response.data.user_info.id,
                                    token: response.data.token,
                                    isLogin: true,
                                    firstname: response.data.user_info.first_name,
                                    lastname: response.data.user_info.last_name,
                                    role: brmRole.role_id,
                                    roleValue: brmRole.role_value,
                                    roleName: brmRole.role_name,
                                    saloonName: brmRole.additional_info.branch_name,
                                    // accessType: brmRole.additional_info?.access_type ?? '',
                                    saloon_id: brmRole.additional_info.id,
                                    saloon_logo: brmRole.settingInfo ? brmRole.settingInfo.logo : "",
                                    currency: brmRole?.currencyInfo == null ? "" : brmRole?.currencyInfo?.symbol
                                })
                            );
                            navigate('/branchManager');
                        } else if (stfRole) {
                            // console.log("stfRole", stfRole)
                            const d = dispatch(
                                authDetailReducer({
                                    uid: response.data.user_info.id,
                                    token: response.data.token,
                                    isLogin: true,
                                    firstname: response.data.user_info.first_name,
                                    lastname: response.data.user_info.last_name,
                                    role: stfRole.role_id,
                                    roleValue: stfRole.role_value,
                                    roleName: stfRole.role_name,
                                    // saloonName: null,
                                    // saloon_id: null,
                                    // saloon_logo: null,
                                    // currency: null
                                    saloonName: stfRole?.additional_info == null ? null : stfRole?.additional_info?.branch_name,
                                    saloon_id: stfRole?.additional_info?.id == null ? null : stfRole?.additional_info?.id,
                                    saloon_logo: stfRole?.settingInfo ? stfRole?.settingInfo?.logo : "",
                                    currency: stfRole?.currencyInfo == null ? "" : stfRole?.currencyInfo?.symbol
                                })
                            );
                            navigate('/staff');
                        }
                        else {
                            setMsg("You are not authorized to access this account.");
                            setStatus(false);
                            handleClickOpen();
                        }
                    } else if (userRoles.length === 2) {
                        setRoles(userRoles);
                        // setOpen(true);
                        localStorage.setItem('loginData', JSON.stringify(response.data))
                        handleSelectRoleModalShow()
                    }
                } else {
                    setMsg(response.data.msg);
                    setStatus(response.data.status);
                    handleClickOpen();
                }
            } catch (error: any) {
                // console.error("Login error:", error);
                if (error.response.data.code == 500) {
                    setMsg("! Login Failed . You Are not Assigned to any Branch")
                } else {
                    setMsg(error.response.data.msg)
                }
                // setMsg("An error occurred while logging in. Please try again later.");
                // setMsg(error.response.data.msg)
                setStatus(false);
                handleClickOpen();
            } finally {
                setIsLoading(false);
            }
        },
    });



    const handleLogin = async () => {
        handleClose();
        // Perform login action
        // console.log("Selected Role:", selectedRole);
        const ob = {
            user_id: userid,
            role_id: selectedRole
        }
        try {
            const response = await AuthApiService.PostApiCallWithUrl(urls.REGENERATE_TOKEN, ob, stoken);

            if (response.data.status == true) {
                localStorage.setItem('token', response.data.token);
                const userRoles = response.data.role_id;
                // console.log("Roles:", roles);
                // console.log("User Roles:", userRoles);


                const logininfo = JSON.parse(localStorage.getItem('loginData'));
                const brmRole = logininfo.roles.find((role: any) => role.role_id === 4);
                const brmRoleValue = brmRole ? brmRole.role_value : undefined;

                const stfRole = logininfo.roles.find((role: any) => role.role_id === 5);
                const stfRoleValue = stfRole ? stfRole.role_value : undefined;

                const scmRole = logininfo.roles.find((role: any) => role.role_id === 3);
                const scmRoleValue = scmRole ? scmRole.role_value : undefined;

                // console.log("BRM Role Value:", brmRoleValue);
                // console.log("STF Role Value:", stfRoleValue);


                if (selectedRole == 4) {
                    // If the user is a BRM, extract relevant information and dispatch actions
                    const d = dispatch(
                        authDetailReducer({
                            uid: response.data.user_id,
                            token: response.data.token,
                            isLogin: true,
                            firstname: logininfo.user_info.first_name,
                            lastname: logininfo.user_info.last_name,
                            role: userRoles,
                            roleValue: brmRoleValue,
                            roleName: brmRole.role_name,
                            saloonName: brmRole.additional_info ? brmRole.additional_info.branch_name : "",
                            saloon_id: brmRole.additional_info ? brmRole.additional_info.id : "",
                            saloon_logo: brmRole.settingInfo ? brmRole.settingInfo.logo : "",
                            currency: brmRole.currencyInfo ? brmRole.currencyInfo.symbol : ""
                        })
                    );
                    navigate('/branchManager');
                } else if (selectedRole == 5) {
                    const d = dispatch(
                        authDetailReducer({
                            uid: response.data.user_id,
                            token: response.data.token,
                            isLogin: true,
                            firstname: logininfo.user_info.first_name,
                            lastname: logininfo.user_info.last_name,
                            role: userRoles,
                            roleValue: "STF",
                            roleName: "Staff",
                            saloonName: brmRole.additional_info ? brmRole.additional_info.branch_name : "",
                            saloon_id: brmRole.additional_info ? brmRole.additional_info.id : "",
                            saloon_logo: brmRole.settingInfo ? brmRole.settingInfo.logo : "",
                            currency: brmRole.currencyInfo ? brmRole.currencyInfo.symbol : ""
                        })
                    );
                    navigate('/staff');

                } else if (selectedRole == 3) {
                    // If the user is a BRM, extract relevant information and dispatch actions
                    const d = dispatch(
                        authDetailReducer({
                            uid: response.data.user_id,
                            token: response.data.token,
                            isLogin: true,
                            firstname: logininfo.user_info.first_name,
                            lastname: logininfo.user_info.last_name,
                            role: userRoles,
                            roleValue: scmRoleValue,
                            roleName: scmRole.role_name,
                            saloonName: scmRole?.additional_info ? scmRole?.additional_info?.name : "",
                            saloon_id: scmRole.additional_info ? scmRole.additional_info.id : "",
                            saloon_logo: scmRole.settingInfo ? scmRole.settingInfo.logo : "",
                            currency: scmRole.currencyInfo ? scmRole.currencyInfo.symbol : ""
                        })
                    );
                    navigate('/saloonChain');
                }
                else {
                    // If the user does not have either SCM or BRM role, show an error message

                    setMsg("You are not authorized to access this account.");
                    setStatus(false);
                    handleClickOpen();
                }
            }
        }
        catch {

        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectRoleModalClose = () => {
        setSelectRoleModal(false);
        localStorage.removeItem('loginData')
        localStorage.removeItem('token')

    };

    const handleSelectRoleModalShow = () => {
        setSelectRoleModal(true);
    };

    const handleRoleSelection = () => {
        handleClose();
        handleSelectRoleModalShow();
    };

    return (
        <>

            <div className="login-page">
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
                </Snackbar>
                <div className="container-fluid">
                    <div className="row w-100 h-100 mx-0">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xl-4 col-12 mx-auto">
                            <div className="card border-0 pt-5 pb-3 px-4 px-sm-5 mt-5">
                                <div className="brand-logo text-center">
                                    {/* <h2>HAIRSALON</h2> */}
                                    <a className="navbar-brand brand-logo " href="https://saloonia.com" target='blank'><img src="images/Saloonist_Logo/newLogo.png" className="img-fluid" alt="logo" /></a>

                                </div>
                                {/* <p className="font-weight-light text-center">Sign in to continue</p> */}
                                <form onSubmit={formik.handleSubmit} className="pt-3 mt-4 ">
                                    <div className="form-group mb-3">
                                        <input
                                            type="text"
                                            className={`form-control ${formik.touched.username && formik.errors.username ? 'is-invalid' : ''}`}
                                            id="username"
                                            name='username'
                                            placeholder="Username"
                                            ref={usernameBox}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.username}
                                        />
                                        {formik.touched.username && formik.errors.username && (
                                            <div className="invalid-feedback">{formik.errors.username}</div>
                                        )}
                                    </div>
                                    <div className="form-group mb-3">
                                        <input
                                            type="password"
                                            className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                            placeholder="Password"
                                            id='password'
                                            name='password'
                                            ref={passwordBox}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        {formik.touched.password && formik.errors.password && (
                                            <div className="invalid-feedback">{formik.errors.password}</div>
                                        )}
                                    </div>
                                    <div className="mt-3 text-center">
                                        <button className="btn btn-purple form-control" type="submit" disabled={isLoading}>
                                            {isLoading ? 'Signing In...' : 'SIGN IN'}
                                        </button>
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                        <Link to="/changePassword" className="text-primary">
                                            {/* Change password? */}
                                        </Link>
                                    </div>
                                    <div className="font-weight-light">

                                        <Link to="/register" className="text-primary">
                                            Register
                                        </Link> your Saloon
                                    </div>
                                    <div className="font-weight-light mb-4 ">
                                        <Link to="/resetyourpassword" className="text-primary">
                                            Forgot Password ?
                                        </Link>

                                    </div>

                                    <span className="float-none  text-muted  float-sm-right d-block text-center fs-6 mb-0 pb-0"> © Copyright <a target='blank' href='https://saloonia.com'> Saloonia</a> 2024. All rights reserved.</span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={selectRoleModal} onHide={handleSelectRoleModalClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RadioGroup value={selectedRole} onChange={(event) => setSelectedRole(event.target.value)}>
                        {roles.map(role => (
                            <FormControlLabel
                                key={role.role_id}
                                value={role.role_id} // Set value to role_id
                                control={<Radio />}
                                // label={role.role_value === "BRM" ? "Login as Manager" : "Login as Staff"}
                                label={role.role_name}
                            />
                        ))}
                    </RadioGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSelectRoleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleLogin}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// import React, { useRef, useState, FormEvent } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import AuthApiService, { urls } from '../apiServices/AuthApiService';
// import { useDispatch } from 'react-redux';
// import { authDetailReducer } from '../reduxData/authSlice';

// export default function Login() {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [msg, setMsg] = useState('');
//     const [isLoading, setIsLoading] = useState(false);
//     const usernameBox = useRef<HTMLInputElement>(null);
//     const passwordBox = useRef<HTMLInputElement>(null);

//     const handleLogin = async (event: FormEvent) => {
//         event.preventDefault();
//         setMsg('');
//         setIsLoading(true);
//         const ob = {
//             username: usernameBox.current?.value || '',
//             password: passwordBox.current?.value || '',
//         };
//         console.log(ob)
//         try {
//             const response = await AuthApiService.PostApiCall(urls.LOGIN, ob);
//             console.log('api response', response);

//             if (response.data.status) {
//                 console.log(response);
//                 setMsg(response.data.msg);
//                 if (response.data.role_id == 3) {
//                     dispatch(
//                         authDetailReducer({
//                             uid: response.data.id,
//                             token: response.data.token,
//                             isLogin: true,
//                             firstname: response.data.first_name,
//                             lastname: response.data.last_name,
//                             role: response.data.role_id,
//                             saloonName: response.data.saloonInfo.name,
//                             saloon_id: response.data.saloonInfo.id,

//                         })
//                     );
//                     navigate('/saloonChain');
//                 }
//                 else if (response.data.role_id == 4) {
//                     dispatch(
//                         authDetailReducer({
//                             uid: response.data.id,
//                             token: response.data.token,
//                             isLogin: true,
//                             firstname: response.data.first_name,
//                             lastname: response.data.last_name,
//                             role: response.data.role_id,

//                             // saloonName: response.data.saloonInfo.name


//                         })
//                     );
//                     navigate('/branchManager');
//                 }
//             } else {
//                 console.log(response);
//                 setMsg(response.data.msg);
//             }
//         } catch (error) {
//             const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
//             console.log(errorMessage)
//             console.log(error)

//             setMsg(errorMessage);
//         } finally {
//             setIsLoading(false); // Set loading to false after API call is complete
//         }
//     };

//     return (
//         <>
//             <div className="login-page">
//                 <div className="container-fluid ">
//                     <div className="row w-100 h-100 mx-0">
//                         <div className="col-lg-4 mx-auto">
//                             <div className="card border-0 py-5 px-4 px-sm-5 mt-5">
//                                 <div className="brand-logo text-center">
//                                     <h2>HAIRSALOON</h2>
//                                 </div>
//                                 <p className="font-weight-light text-center">Sign in to continue...</p>
//                                 <form onSubmit={handleLogin} className="pt-3">
//                                     <div className="form-group mb-3">
//                                         <input type="text" className="form-control" placeholder="Username" ref={usernameBox} />
//                                     </div>
//                                     <div className="form-group mb-3">
//                                         <input type="password" className="form-control" placeholder="Password" ref={passwordBox} />
//                                     </div>
//                                     {/* <div className="mt-3 text-center">
//                                         <button className="btn btn-purple form-control" type="submit">
//                                             SIGN IN
//                                         </button>
//                                     </div> */}
//                                     <div className="mt-3 text-center">
//                                         <button className="btn btn-purple form-control" type="submit" disabled={isLoading}>
//                                             {isLoading ? 'Signing In...' : 'SIGN IN'}
//                                         </button>
//                                     </div>
//                                     <div className="my-2 d-flex justify-content-between align-items-center">
//                                         <Link to="#" className="text-primary">
//                                             Forgot password?
//                                         </Link>
//                                     </div>
//                                     <div className="font-weight-light">
//                                         Don't have an account?
//                                         <Link to="/register" className="text-primary">
//                                             Create
//                                         </Link>
//                                     </div>
//                                     <b className="text-danger">{msg}</b>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }



