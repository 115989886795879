// import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { replace, useFormik } from "formik";
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
// import { GenSalaryReducer, listPreviewSalaryReducer, listSalaryReducer, } from "../../../reduxData/SalarySlice";
// import Select from "react-select";
// import { Alert, Snackbar } from "@mui/material";
// import { branch } from "../../../validations/Validations";
// interface FormValues {
//     created_by: number;
//     updated_by: number;
// }

// export default function GenerateSalary() {
//     const [msg, setMsg] = useState("");
//     const [open, setOpen] = useState(false);
//     const [checkMsg, setCheckMsg] = useState("");
//     const [showSave, setShowSave] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [status, setStatus] = useState();
//     const dispatch = useDispatch();
//     const navigate = useNavigate()
//     const admin = useSelector((state: any) => state.authUserInfo.value);
//     const salary = useSelector((state: any) => state.salaryInfo.value);
//     const branchInfo = useSelector((state: any) => state.branchInfo.value);
//     const employeeInfo = useSelector((state: any) => state.employeeInfo.value);
//     const currentDate = new Date().toISOString().substring(0, 7);
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 20;
//     const location = useLocation();

//     const branchOptions = branchInfo.map((item: any) => ({
//         value: item.id,
//         label: item.branch_name,
//     }));


//     const totalRecords = salary?.pagination?.totalRecords


//     const totalPages = Math.ceil(totalRecords / itemsPerPage);
//     const generatePageNumbers = () => {
//         const pageNumbers = [];
//         const maxPageButtons = 3; // Adjust the number of buttons you want to show

//         if (totalPages <= maxPageButtons) {
//             for (let i = 1; i <= totalPages; i++) {
//                 pageNumbers.push(i);
//             }
//         } else {
//             const leftEllipsis = currentPage > 2;
//             const rightEllipsis = currentPage < totalPages - 1;

//             if (leftEllipsis) {
//                 pageNumbers.push(1, '...');
//             }

//             const start = Math.max(1, currentPage - 1);
//             const end = Math.min(totalPages, currentPage + 1);

//             for (let i = start; i <= end; i++) {
//                 pageNumbers.push(i);
//             }

//             if (rightEllipsis) {
//                 pageNumbers.push('...', totalPages);
//             }
//         }

//         return pageNumbers;
//     };

//     const handleChange = async (page: number) => {
//         setCurrentPage(page)
//     };

//     const recalculate = async () => {
//         let ob = {
//             branch_id: formik.values.branch_id,
//             year_month: formik.values.month
//         }
//         let url = urls.GENERATE_SALARY + "/" + formik.values.branch_id + "/" + formik.values.month
//         let res = await AuthApiService.PostApiCallWithUrl(url, ob, admin.token);
//         if (res.status) {
//             if (res.data.data.length <= 0) {
//                 setMsg("No Records Found");
//                 setCheckMsg("No Records Found")
//                 setStatus(400)
//                 handleClickOpen();
//                 dispatch(GenSalaryReducer(res.data))
//             }
//             else {
//                 dispatch(GenSalaryReducer(res.data))
//                 setMsg(res?.data?.msg);
//                 handleClickOpen();
//                 setCheckMsg(res?.data?.msg)
//                 setShowSave(true);
//                 setStatus(res?.data?.status)
//             }
//         }
//     }

//     const formik = useFormik({
//         initialValues: {
//             branch_id: "",
//             month: currentDate
//         },
//         validationSchema: branch,
//         onSubmit: async (values) => {
//             if (!values.branch_id || !values.month) {
//                 handleClickOpen();
//                 setMsg('Please select branch and month to generate salary.')
//                 return;
//             }


//             try {
//                 setShowSave(false)
//                 setLoading(true);
//                 let ob = { branch_id: formik.values.branch_id };
//                 let url = urls.SALARY_LIST + "/" + formik.values.month;
//                 let res = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token)
//                 if (res.status && res.data.data.length > 0) {
//                     dispatch(GenSalaryReducer(res.data))
//                     setCheckMsg("Salary already created for the specified branch and month.")
//                     setMsg("Salary already created for the specified branch and month.");
//                     setStatus(false);
//                     handleClickOpen();
//                 }
//                 else {
//                     recalculate()
//                     // setLoading(false);
//                 }
//             }
//             catch (error) {
//                 setMsg(error?.response?.data?.message);
//                 setCheckMsg(error?.response?.data?.message)
//                 setStatus(error?.response?.status);
//                 handleClickOpen();
//             }
//             finally {
//                 setLoading(false)
//             }
//         }
//     })

//     const loadingSpinner = () => {
//         return <div className="d-flex justify-content-center">
//             <div className="spinner-border" >
//                 <span className="visually-hidden">Loading...</span>
//             </div>
//         </div>
//     }

//     const handleSave = async () => {
//         try {
//             setLoading(true)
//             const url = urls.CONFIRM_SALARY
//             const ob = { resCode: salary?.resCode, branch_id: formik.values.branch_id }
//             const res = await AuthApiService.PostApiCallWithUrl(url, ob, admin.token)
//             if (res.status == 200) {
//                 handleClickOpen();
//                 setMsg(res?.data?.msg);
//                 setCheckMsg(res?.data?.msg)
//                 setShowSave(false);
//                 setStatus(res?.data?.status);
//                 const url = urls.SALARY_LIST + "/" + formik.values.month;
//                 const ob = { branch_id: formik.values.branch_id };
//                 const res2 = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token)
//                 dispatch(GenSalaryReducer(res2.data))
//             }
//         }
//         catch (error) {
//         }
//         finally {
//             setLoading(false);
//             const t = setTimeout(() => {
//                 // setMsg("");
//                 clearTimeout(t);
//             }, 5000)
//         }
//     }

//     const slip = async (empid: any) => {
//         setLoading(true)
//         const url = urls.SALARY_LIST + "/" + formik.values.month;
//         const ob = { branch_id: formik.values.branch_id, employee_id: empid }
//         const res = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token);
//         if (res.status) {
//             dispatch(listSalaryReducer(res.data.data[0]))
//             navigate("/saloonChain/salarySlip")
//         }
//         setLoading(false)
//     }

//     const slipPreview = async (empid: any) => {
//         setLoading(true)
//         const url = urls.SALARY_PREVIEW + "/" + formik.values.month;
//         const ob = { branch_id: formik.values.branch_id, employee_id: empid }
//         const res = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token);
//         if (res.status) {
//             dispatch(listPreviewSalaryReducer({ ...res?.data?.data[0], date: formik.values.month }))
//             // console.log({...res.data.data[0],date:formik.values.month});
//             navigate("/saloonChain/previewSalarySlip")
//         }
//         setLoading(false)
//     }

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     useEffect(() => {
//         if (location.state != null && location.state.message == "saved") {
//             formik.setValues({ branch_id: location.state.branchid, month: location.state.date })
//             formik.handleSubmit()
//         }
//     }, [])

//     useEffect(() => {
//         if (branchInfo.length === 1) {
//             const singleBranch = branchInfo[0];
//             formik.setFieldValue("branch_id", singleBranch.id);
//         }
//     }, [branchInfo]);
//     return (
//         <div className="container-fluid p-0">
//             <Snackbar
//                 anchorOrigin={{ vertical: "top", horizontal: "center" }}
//                 open={open}
//                 autoHideDuration={5000}
//                 onClose={handleClose}
//             >
//                 <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : checkMsg == "Salary already created for the specified branch and month." ? "warning" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
//             </Snackbar>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-10 col-md-10 col-sm-10">
//                         <div className="text-center">
//                             <h4>Generate Salary</h4>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="row mt-2 mt-xl-4 pb-0 p-1 d-flex flex-row">
//                 <div className="col-lg-6 col-md-6 justify-content-center">
//                     <form onSubmit={formik.handleSubmit} onClick={() => { setCheckMsg(""); setMsg(""); setShowSave(false) }}>
//                         <div className="row d-flex flex-row align-self-stretch">
//                             <div className="col-lg-6 col-md-6 col-sm-6">
//                                 <label>Branch</label>

//                                 {/* <Select
//                                     styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
//                                     menuPortalTarget={document.body}
//                                     menuPosition="fixed"
//                                     className="basic-single"
//                                     classNamePrefix="select"
//                                     // defaultValue={"All"}
//                                     placeholder="Select Branch"
//                                     // isDisabled={isDisabled}
//                                     isLoading={branchInfo.length <= 0 ? true : false}
//                                     // isClearable={true}
//                                     // isRtl={isRtl}
//                                     isSearchable={true}
//                                     options={branchOptions}
//                                     onChange={(selectedOption) => formik.setFieldValue("branch_id", selectedOption.value)}
//                                     onBlur={formik.handleBlur}
//                                     value={
//                                         branchOptions.find((option: any) => option.value === formik.values.branch_id) ||
//                                         null
//                                     }
//                                 /> */}
//                                 <Select
//                                     id="branch_id"
//                                     name="branch_id"
//                                     className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? "is-invalid" : ""}`}
//                                     options={branchOptions}
//                                     isSearchable
//                                     placeholder="Select Branch"
//                                     onChange={(selectedOption) => formik.setFieldValue("branch_id", selectedOption.value)}
//                                     onBlur={formik.handleBlur}
//                                     value={branchOptions.find((option: any) => option.isSelected || option.value === formik.values.branch_id)}
//                                 />
//                                 {formik.touched.branch_id && formik.errors.branch_id ? (
//                                     <div className="text-danger">{formik.errors.branch_id}</div>
//                                 ) : null}
//                             </div>

//                             <div className="col-lg-4 col-md-4 col-sm-4">
//                                 <label>Month</label>

//                                 <input
//                                     type="month"
//                                     className="form-control shadow-sm"
//                                     name="month"
//                                     onChange={formik.handleChange}
//                                     max={currentDate}
//                                     value={formik.values.month}
//                                 />
//                             </div>
//                             <div className="col-lg-2 col-md-2 col-sm-2">

//                                 <label></label>
//                                 <br />
//                                 {checkMsg == "Salary record Saved successfully" || checkMsg == "Salary generated successfully" || checkMsg == "Salary already created for the specified branch and month." ? <button type="button" onClick={() => { recalculate() }} className="btn btn-md btn-purple" >Regenerate</button> : <button type="submit" className="btn btn-md btn-purple" >View</button>}
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//             <div className="row d-flex flex-row">
//                 {/* <b className={salary?.data?.length > 0 ? "text-success text-center" : "text-danger text-center"}>{msg}</b> */}
//             </div>
//             <br />
//             {salary?.data?.length > 0 && showSave && status >= 200 && status < 400 && <div className="row d-flex row-flex align-items-center justify-content-center">
//                 <div className="d-flex mb-2 col-flex align-items-center justify-content-center">


//                     <label>Do you want to save this?&nbsp;</label>
//                     <br />
//                     <button className="btn btn-md btn-purple" onClick={() => handleSave()}>Save</button>
//                 </div>
//             </div>}
//             {/* <br /> */}
//             {loading ? loadingSpinner() : salary?.data?.length > 0 && checkMsg == "Salary generated successfully" ?
//                 <div className="mt-0 mt-xl-2 justify-content-center">
//                     <div className="table-responsive">
//                         <table id="report-table" className="table table-striped  table-hover border-light-subtle">
//                             <thead>
//                                 <tr className="text-nowrap">
//                                     {/* <th></th> */}
//                                     {/* <th>Branch</th> */}

//                                     {/* <th>Branch</th> */}
//                                     <th>Employee</th>
//                                     <th>Basic<br /> Salary</th>

//                                     <th>Curr. Month <br />Advance</th>
//                                     <th>Prev. Month <br /> Advance</th>
//                                     <th>Incentive</th>
//                                     <th>Adv. Carry <br /> Forward</th>
//                                     <th>Date</th>
//                                     <th>Gross<br />Salary</th>
//                                     <th>Preview</th>
//                                     {/* <th>Created By</th> */}
//                                 </tr>
//                             </thead>
//                             <tbody className="text-nowrap">
//                                 {salary.data?.map((ob: any) => <tr className="text-center" >
//                                     <td>{ob.first_name}&nbsp;{ob.last_name}

//                                     </td>
//                                     <td>{ob.basic_salary}</td>

//                                     <td>{ob.advance}</td>
//                                     <td>{ob.previous_month_advance}</td>
//                                     <td>{ob.incentive}</td>
//                                     <td>{ob.advance_carry}</td>
//                                     <td>{ob.salary_date.substring(0, 10).split("-").reverse().join("-")}</td>
//                                     <td>{ob.calculated_salary}</td>
//                                     <td onClick={() => { slipPreview(ob.employee_id) }}><button className="btn-purple">View Slip</button></td>
//                                     {/* <td>{ob.created_by}</td> */}
//                                 </tr>)}


//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//                 : salary?.data?.length > 0 && (checkMsg == "Salary record Saved successfully" || checkMsg == "Salary already created for the specified branch and month.") && <div className="mt-0 mt-xl-2 justify-content-center">
//                     <div className="table-responsive">
//                         <table id="report-table" className="table table-striped  table-hover border-light-subtle">
//                             <thead>
//                                 <tr className="text-nowrap">
//                                     {/* <th></th> */}
//                                     {/* <th>Branch</th> */}
//                                     <th>Employee</th>
//                                     <th>Basic<br /> Salary</th>
//                                     <th>Advance</th>
//                                     <th>Previous Month Advance</th>
//                                     <th>Advance Carry Forward</th>
//                                     <th>Incentive</th>
//                                     <th>Date</th>
//                                     <th>Gross <br />Salary</th>
//                                     <th>Pay Slip</th>
//                                     {/* <th>Created By</th> */}
//                                 </tr>
//                             </thead>
//                             <tbody className="text-nowrap">
//                                 {salary.data?.map((ob: any) => <tr className="text-center" >
//                                     {/* <td>{ob.employeeinfo.branch_info.branch_name}</td> */}
//                                     <td style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => { slip(ob.employee_id) }}>{ob.employeeinfo.first_name + " " + ob.employeeinfo.last_name}

//                                     </td><td>{ob?.employeeinfo?.salary}</td>
//                                     <td>{ob.advance}</td>
//                                     <td>{ob.previous_month_advance}</td>
//                                     <td>{ob.advance_carry}</td>
//                                     <td>{ob.incentive}</td>
//                                     <td>{ob.salary_date.substring(0, 10).split("-").reverse().join("-")}</td>
//                                     <td>{ob.calculated_salary}</td>
//                                     <td onClick={() => { slip(ob.employee_id) }}><button className="btn-purple">Pay Slip</button>
//                                     </td>
//                                     {/* <td>{ob.created_by}</td> */}
//                                 </tr>)}


//                             </tbody>
//                         </table>

//                     </div>
//                 </div>}

//         </div>
//     );
// }


import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { replace, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { GenSalaryReducer, listPreviewSalaryReducer, listSalaryReducer } from "../../../reduxData/SalarySlice";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";
import { branch } from "../../../validations/Validations";

interface FormValues {
    created_by: number;
    updated_by: number;
}

export default function GenerateSalary() {
    const [msg, setMsg] = useState("");
    const [open, setOpen] = useState(false);
    const [checkMsg, setCheckMsg] = useState("");
    const [showSave, setShowSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const salary = useSelector((state: any) => state.salaryInfo.value);
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const employeeInfo = useSelector((state: any) => state.employeeInfo.value);
    const currentDate = new Date().toISOString().substring(0, 7);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const location = useLocation();

    const branchOptions = branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }));

    const totalRecords = salary?.pagination?.totalRecords;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 3; // Adjust the number of buttons you want to show

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1, '...');
            }

            const start = Math.max(1, currentPage - 1);
            const end = Math.min(totalPages, currentPage + 1);

            for (let i = start; i <= end; i++) {
                pageNumbers.push(i);
            }

            if (rightEllipsis) {
                pageNumbers.push('...', totalPages);
            }
        }

        return pageNumbers;
    };

    const handleChange = async (page: number) => {
        setCurrentPage(page);
    };

    const recalculate = async () => {
        let ob = {
            branch_id: formik.values.branch_id,
            year_month: formik.values.month
        };
        let url = urls.GENERATE_SALARY + "/" + formik.values.branch_id + "/" + formik.values.month;
        let res = await AuthApiService.PostApiCallWithUrl(url, ob, admin.token);
        if (res.status) {
            if (res.data.data.length <= 0) {
                setMsg("No Records Found");
                setCheckMsg("No Records Found");
                setStatus(400);
                handleClickOpen();
                dispatch(GenSalaryReducer(res.data));
            } else {
                dispatch(GenSalaryReducer(res.data));
                setMsg(res?.data?.msg);
                handleClickOpen();
                setCheckMsg(res?.data?.msg);
                setShowSave(true);
                setStatus(res?.data?.status);
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            branch_id: "",
            month: currentDate
        },
        validationSchema: branch,
        onSubmit: async (values) => {
            const selectedMonthDate = new Date(values.month);
            const currentMonthDate = new Date();

            // Check if selected month is the current month
            if (selectedMonthDate.getFullYear() === currentMonthDate.getFullYear() &&
                selectedMonthDate.getMonth() === currentMonthDate.getMonth()) {
                // Validate if the current date is after the 30th of the current month
                if (currentMonthDate.getDate() <= 30) {
                    setMsg('You can only generate the salary after the 30th of the current month.');
                    setCheckMsg('You can only generate the salary after the 30th of the current month.');
                    setStatus(400);
                    handleClickOpen();
                    return;
                }
            }

            if (!values.branch_id || !values.month) {
                handleClickOpen();
                setMsg('Please select branch and month to generate salary.');
                return;
            }

            try {
                setShowSave(false);
                setLoading(true);
                let ob = { branch_id: formik.values.branch_id };
                let url = urls.SALARY_LIST + "/" + formik.values.month;
                let res = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token);
                if (res.status && res.data.data.length > 0) {
                    dispatch(GenSalaryReducer(res.data));
                    setCheckMsg("Salary already created for the specified branch and month.");
                    setMsg("Salary already created for the specified branch and month.");
                    setStatus(false);
                    handleClickOpen();
                } else {
                    recalculate();
                }
            } catch (error) {
                setMsg(error?.response?.data?.message);
                setCheckMsg(error?.response?.data?.message);
                setStatus(error?.response?.status);
                handleClickOpen();
            } finally {
                setLoading(false);
            }
        }
    });

    const loadingSpinner = () => {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const url = urls.CONFIRM_SALARY;
            const ob = { resCode: salary?.resCode, branch_id: formik.values.branch_id };
            const res = await AuthApiService.PostApiCallWithUrl(url, ob, admin.token);
            if (res.status === 200) {
                handleClickOpen();
                setMsg(res?.data?.msg);
                setCheckMsg(res?.data?.msg);
                setShowSave(false);
                setStatus(res?.data?.status);
                const url = urls.SALARY_LIST + "/" + formik.values.month;
                const ob = { branch_id: formik.values.branch_id };
                const res2 = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token);
                dispatch(GenSalaryReducer(res2.data));
            }
        } catch (error) {
            // Handle error if needed
        } finally {
            setLoading(false);
            const t = setTimeout(() => {
                clearTimeout(t);
            }, 5000);
        }
    };

    const slip = async (empid: any) => {
        setLoading(true);
        const url = urls.SALARY_LIST + "/" + formik.values.month;
        const ob = { branch_id: formik.values.branch_id, employee_id: empid };
        const res = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token);
        if (res.status) {
            dispatch(listSalaryReducer(res.data.data[0]));
            navigate("/saloonChain/salarySlip");
        }
        setLoading(false);
    };

    const slipPreview = async (empid: any) => {
        setLoading(true);
        const url = urls.SALARY_PREVIEW + "/" + formik.values.month;
        const ob = { branch_id: formik.values.branch_id, employee_id: empid };
        const res = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token);
        if (res.status) {
            dispatch(listPreviewSalaryReducer({ ...res?.data?.data[0], date: formik.values.month }));
            navigate("/saloonChain/previewSalarySlip");
        }
        setLoading(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (location.state != null && location.state.message === "saved") {
            formik.setValues({ branch_id: location.state.branchid, month: location.state.date });
            formik.handleSubmit();
        }
    }, []);

    useEffect(() => {
        if (branchInfo.length === 1) {
            const singleBranch = branchInfo[0];
            formik.setFieldValue("branch_id", singleBranch.id);
        }
    }, [branchInfo]);

    return (
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status === true) ? "success" : checkMsg === "Salary already created for the specified branch and month." ? "warning" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="text-center">
                            <h4>Generate Salary</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-2 mt-xl-4 pb-0 p-1 d-flex flex-row">
                <div className="col-lg-6 col-md-6 justify-content-center">
                    <form onSubmit={formik.handleSubmit} onClick={() => { setCheckMsg(""); setMsg(""); setShowSave(false) }}>
                        <div className="row d-flex flex-row align-self-stretch">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <label>Branch</label>
                                <Select
                                    id="branch_id"
                                    name="branch_id"
                                    className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? "is-invalid" : ""}`}
                                    options={branchOptions}
                                    isSearchable
                                    placeholder="Select Branch"
                                    onChange={(selectedOption) => formik.setFieldValue("branch_id", selectedOption.value)}
                                    onBlur={formik.handleBlur}
                                    value={branchOptions.find((option: any) => option.isSelected || option.value === formik.values.branch_id)}
                                  required
                                />
                                {formik.touched.branch_id && formik.errors.branch_id ? (
                                    <div className="text-danger">{formik.errors.branch_id}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <label>Month</label>
                                <input
                                    type="month"
                                    className="form-control shadow-sm"
                                    name="month"
                                    onChange={formik.handleChange}
                                    max={currentDate}
                                    value={formik.values.month}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2">
                                <label></label>
                                <br />
                                {checkMsg === "Salary record Saved successfully" || checkMsg === "Salary generated successfully" || checkMsg === "Salary already created for the specified branch and month." ? (
                                    <button type="button" onClick={() => { recalculate() }} className="btn btn-md btn-purple">Regenerate</button>
                                ) : (
                                    <button type="submit" className="btn btn-md btn-purple">View</button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row d-flex flex-row"></div>
            <br />
            {salary?.data?.length > 0 && showSave && status >= 200 && status < 400 && (
                <div className="row d-flex row-flex align-items-center justify-content-center">
                    <div className="d-flex mb-2 col-flex align-items-center justify-content-center">
                        <label>Do you want to save this?&nbsp;</label>
                        <br />
                        <button className="btn btn-md btn-purple" onClick={() => handleSave()}>Save</button>
                    </div>
                </div>
            )}
            {loading ? loadingSpinner() : salary?.data?.length > 0 && checkMsg === "Salary generated successfully" ? (
                <div className="mt-0 mt-xl-2 justify-content-center">
                    <div className="table-responsive">
                        <table id="report-table" className="table table-striped table-hover border-light-subtle">
                            <thead>
                                <tr className="text-nowrap">
                                    <th>Employee</th>
                                    <th>Basic<br /> Salary</th>
                                    <th>Curr. Month <br />Advance</th>
                                    <th>Prev. Month <br /> Advance</th>
                                    <th>Incentive</th>
                                    <th>Adv. Carry <br /> Forward</th>
                                    <th>Date</th>
                                    <th>Gross<br />Salary</th>
                                    <th>Preview</th>
                                </tr>
                            </thead>
                            <tbody className="text-nowrap">
                                {salary.data?.map((ob: any) => (
                                    <tr className="text-center">
                                        <td>{ob.first_name}&nbsp;{ob.last_name}</td>
                                        <td>{ob.basic_salary}</td>
                                        <td>{ob.advance}</td>
                                        <td>{ob.previous_month_advance}</td>
                                        <td>{ob.incentive}</td>
                                        <td>{ob.advance_carry}</td>
                                        <td>{ob.salary_date.substring(0, 10).split("-").reverse().join("-")}</td>
                                        <td>{ob.calculated_salary}</td>
                                        <td onClick={() => { slipPreview(ob.employee_id) }}><button className="btn-purple">View Slip</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : salary?.data?.length > 0 && (checkMsg === "Salary record Saved successfully" || checkMsg === "Salary already created for the specified branch and month.") && (
                <div className="mt-0 mt-xl-2 justify-content-center">
                    <div className="table-responsive">
                        <table id="report-table" className="table table-striped table-hover border-light-subtle">
                            <thead>
                                <tr className="text-nowrap">
                                    <th>Employee</th>
                                    <th>Basic<br /> Salary</th>
                                    <th>Advance</th>
                                    <th>Previous Month Advance</th>
                                    <th>Advance Carry Forward</th>
                                    <th>Incentive</th>
                                    <th>Date</th>
                                    <th>Gross <br />Salary</th>
                                    <th>Pay Slip</th>
                                </tr>
                            </thead>
                            <tbody className="text-nowrap">
                                {salary.data?.map((ob: any) => (
                                    <tr className="text-center">
                                        <td style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => { slip(ob.employee_id) }}>{ob.employeeinfo.first_name + " " + ob.employeeinfo.last_name}</td>
                                        <td>{ob?.employeeinfo?.salary}</td>
                                        <td>{ob.advance}</td>
                                        <td>{ob.previous_month_advance}</td>
                                        <td>{ob.advance_carry}</td>
                                        <td>{ob.incentive}</td>
                                        <td>{ob.salary_date.substring(0, 10).split("-").reverse().join("-")}</td>
                                        <td>{ob.calculated_salary}</td>
                                        <td onClick={() => { slip(ob.employee_id) }}><button className="btn-purple">Pay Slip</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}

