import { Alert, Snackbar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { ExpenseHeadSchema } from "../../../validations/Validations";

export default function ManagerAddExpenseHead(){
   
    const authInfo = useSelector((state: any) => state.authUserInfo.value);
    const dispatch = useDispatch();
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const [msgs, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
   

    const formik = useFormik({
        initialValues:{
            branch_id:branchInfo[0]?.id,
            category:"",
            description:"",
        },
        validationSchema:ExpenseHeadSchema,
        onSubmit:async (values) => {
           
            try {
                setLoading(true);
                const ob = {
                    branch_id:values.branch_id,
                    category:values.category,
                    description:values.description,
                    isActive:true,
                    created_by:authInfo.uid,
                    updated_by:authInfo.uid,
                }
                const resp = await AuthApiService.SaveApiCall(urls.SAVE_EXPENSE_HEAD, ob, authInfo.token);
                if (resp.status === 201) {
                    setMsg(resp.data.msg);
                    handleClickOpen();
                    formik.resetForm()
                    // setTimeout(() => {
                    //     navigate('/branchManager/managerExpenseHeadList')
                    // }, 2000)
                    setStatus(resp.data.status)

                } else {
                    setMsg(resp.data.msg);

                    // setTimeout(() => {
                    //     navigate('/branchManager/managerExpenseHeadList')
                    // }, 2000)
                }
            } catch (error: any) {
                setMsg(error.response.data.error == "Internal server error" ? "All Fileds Are Required" : error.response.data.error);
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setTimeout(() => {
                    setLoading(false);
                }, 2000)
            }
        },
    })

    return <>
    <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
    >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
    </Snackbar>
    <div className="container p-0">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-10">
                    <div className="text-center">
                        <h4>New Expense Head</h4>
                    </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
                    <Link
                        to="/branchManager/managerExpenseHeadList">
                        <button className="btn btn-sm btn-purple" >All Expense Head</button>
                    </Link>
                </div>
            </div>
        </div>

        <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
            <div className="container">
                <div className="row justify-content-center mt-3">
                    <div className="col-lg-12">
                        <form
                            onFocus={() => setMsg("")}
                            onSubmit={formik.handleSubmit}>
                            <div className="mb-1 mb-xl-3 row">
                                <label htmlFor="category" className="col-sm-3 col-form-label">Expense Head<span style={{ color: "red" }}>*</span>:</label>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        placeholder="Enter Expense Head"
                                        id="category"
                                        name="category"
                                        className={`form-control shadow-sm ${formik.touched.category && formik.errors.category ? 'is-invalid' : ''}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.category}
                                    />
                                    <div className="invalid-feedback">{formik.touched.category && typeof formik.errors.category === 'string' ? (formik.errors.category) : null}</div>

                                </div>
                            </div>
                            <div className="mb-1 mb-xl-3 row">
                                <label htmlFor="desc" className="col-sm-3 col-form-label">Description:</label>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        placeholder="Enter Description"
                                        id="description"
                                        name="description"
                                        className={`form-control shadow-sm ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                    />
                                    <div className="invalid-feedback">{formik.touched.description && typeof formik.errors.description === 'string' ? (formik.errors.description) : null}</div>

                                </div>
                            </div>
                            <div className="mt-3 mb-xl-3 row text-center">
                                <div className="col-lg-5"></div>
                                <div className="col-lg-2">
                                    <button type="submit" className="btn btn-md form-control btn-purple" disabled={loading ? true : false}>
                                        {loading ? "Saving..." : "Add"}
                                    </button>
                                </div>
                                <div className="col-lg-5"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
}