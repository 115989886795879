import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { listExpenseHeadReducer } from "../../../reduxData/expenseHeadSlice";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { ExpenseHeadSchema } from "../../../validations/Validations";
import { Alert, Snackbar } from "@mui/material";

export default function ManagerExpenseHeadList() {
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const expenseHeadInfo = useSelector(
    (state: any) => state.expenseHeadInfo.value
  );
  const [statusLoading, setStatusLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(false);

  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const [msgs, setMsg] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      branch_id: branchInfo[0]?.id,
      category: "",
      description: "",
    },
    validationSchema: ExpenseHeadSchema,
    onSubmit: async (values) => {

      try {
        setStatusLoading(true);
        const ob = {
          branch_id: values.branch_id,
          category: values.category,
          description: values.description,
          isActive: true,
          created_by: admin.uid,
          updated_by: admin.uid,
        }
        const resp = await AuthApiService.SaveApiCall(urls.SAVE_EXPENSE_HEAD, ob, admin.token);
        if (resp.status === 200 || resp.data.status === 201) {
          if (resp.data.status == false) {
            setMsg(resp.data.msg);
            handleClickOpen();
            setStatus(resp.data.status)
          }
          else {
            setMsg(resp.data.msg);
            handleClickOpen();
            formik.resetForm()
            setStatus(resp.data.status)
            handleAddModelClose();
            await ExpenseHeadList(currentPage, itemsPerPage)
          }
        } else {
          setMsg(resp.data.msg);
          handleClickOpen();
          formik.resetForm()
          setStatus(resp.data.status)
          handleAddModelClose();
        }
      } catch (error: any) {
        setMsg(error.response.data.error == "Internal server error" ? "All Fileds Are Required" : error.response.data.error);
        setStatus(error.response.status);
        handleClickOpen()
      }
      finally {
        setStatusLoading(false);
      }
    },
  })

  const ExpenseHeadList = async (page: number, itemsPerPage: number) => {
    const url = `${urls.EXPENSE_HEAD_LIST}/${page}/${itemsPerPage}`;
    const res = await AuthApiService.GetApiCallWithPagination(
      url,
      admin.token,
      page,
      itemsPerPage
    );
    dispatch(listExpenseHeadReducer(res.data));
  };

  //--------------------------Pagination-Start------------------------------
  const totalRecords = expenseHeadInfo?.pagination?.totalRecords;


  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust the number of buttons you want to show

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, "...");
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push("...", totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    // if (searchInput != '') {
    //     await onSearch(searchInput, page, itemsPerPage)
    // }
    // else
    // if (isDateSearching) {
    //     await handleDateSubmit(val, searchInput, page, itemsPerPage)
    // }
    // else if (val != undefined) {
    //     await onBranchSearch(val, page, itemsPerPage);
    // }
    // else {
    await ExpenseHeadList(page, itemsPerPage);
    // }
  };
  //------------------------Pagination-End-----------------------------

  // -------------------------UseEffect-Start--------------------------------
  useEffect(() => {
    ExpenseHeadList(currentPage, itemsPerPage);
  }, []);
  // -------------------------UseEffect-End--------------------------------

  // -------------------------LoadingSpinner-Start--------------------------------
  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };
  // -------------------------LoadingSpinner-End--------------------------------

  //----------------------------AddModel-Start----------------------------------

  const [showAddModal, setShowAddModal] = useState(false);
  const handleAddModelClose = () => { setShowAddModal(false); formik.resetForm() }
  const handleAddModelShow = () => {
    // setrulesval([]);
    setShowAddModal(true);
  }

  const addModal = () => {
    return <>
      <Modal show={showAddModal} onHide={handleAddModelClose} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expense Head</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onFocus={() => setMsg("")}
            onSubmit={formik.handleSubmit}>
            <div className="mb-1 mb-xl-3 row">
              <label htmlFor="category" className="col-sm-4 col-form-label">Expense Head<span style={{ color: "red" }}>*</span>:</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  placeholder="Enter Expense Head"
                  id="category"
                  name="category"
                  className={`form-control shadow-sm ${formik.touched.category && formik.errors.category ? 'is-invalid' : ''}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.category}
                />
                <div className="invalid-feedback">{formik.touched.category && typeof formik.errors.category === 'string' ? (formik.errors.category) : null}</div>

              </div>
            </div>
            <div className="mb-1 mb-xl-3 row">
              <label htmlFor="desc" className="col-sm-4 col-form-label">Description:</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  placeholder="Enter Description"
                  id="description"
                  name="description"
                  className={`form-control shadow-sm ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                />
                <div className="invalid-feedback">{formik.touched.description && typeof formik.errors.description === 'string' ? (formik.errors.description) : null}</div>

              </div>
            </div>
            <div className="mt-3 mb-xl-3 row text-center">
              <div className="col-lg-4"></div>
              <div className="col-lg-4">
                <button type="submit" className="btn btn-md form-control btn-purple" disabled={statusLoading ? true : false}>
                  {statusLoading ? "Saving..." : "Add"}
                </button>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" disabled={statusLoading ? true : false} onClick={handleAddModelClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  }

  //-----------------------------------AddModel-End----------------------------------------

  return (
    <>
      {addModal()}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
      </Snackbar>
      <div className="container-fluid p-0">
        <div className="row align-items-center">
          <div className="col-lg-10 col-md-10 col-sm-10">
            <div className="text-center">
              <h4>Expense Head</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 my-2 d-flex justify-content-end">

            <button className="btn btn-sm btn-purple" onClick={handleAddModelShow}>
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;New Expense Head
            </button>

          </div>
        </div>

        {loading || expenseHeadInfo?.data?.length === 0 ? (
          <div className="mt-4">
            {loading ? (
              // Display loading spinner
              <div className="d-flex justify-content-center align-items-center">
                {loadingSpinner()}
              </div>
            ) : (
              // Display custom message for no records
              <h5>No Transaction Details found.</h5>
            )}
          </div>
        ) : (
          <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
            <div className={`table-container table-responsive`}>
              <table className="table table-striped table-hover border-light-subtle">
                <thead>
                  <tr>
                    {/* <th></th> */}
                    <th>S.no</th>

                    <th>Expense Head</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {expenseHeadInfo?.data?.map((item: any, index: any) =>
                    <tr key={item.id}>
                      <td >{index + 1}</td>
                      {/* <td><FontAwesomeIcon icon={faTrash} cursor="pointer" className="fontIcon"/></td> */}
                      <td>{item?.category}</td>
                      <td>{item?.description == "" || item?.description == null ? "-" : item?.description}</td>
                      <td>{item?.isActive ? <button className="btn btn-sm btn-warning">Active</button> : <button className="btn btn-sm btn-danger">Deactive</button>}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""
                      }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1 || loading}
                    >
                      Previous
                    </button>
                  </li>
                  {generatePageNumbers()?.map((page, index) => (
                    <li
                      key={index}
                      className={`page-item ${page === currentPage ? "active" : ""
                        }`}
                    >
                      {page === "..." ? (
                        <span className="page-link">...</span>
                      ) : (
                        <button
                          className="page-link btn-sm"
                          onClick={() => handlePageChange(page)}
                          disabled={loading}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                      }`}
                  >
                    <button
                      className="page-link btn-sm"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={loading || currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    </>
  );

}