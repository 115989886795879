import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import { empCategoryReducer, listEmployeeReducer } from '../reduxData/employeeSlice';
import AuthApiService, { urls } from '../apiServices/AuthApiService';
import { useDispatch, useSelector } from 'react-redux';
import { ListBranchReducer } from '../reduxData/branchSlice';
import { roleListReducer } from '../reduxData/authSlice';
import { listIncentiveRuleReducer } from '../reduxData/incentiveRuleSlice';
import { listIncentiveEmployeeReducer } from '../reduxData/incentiveEmployeeSlice';
import { listEmployeeLeaveReducer } from '../reduxData/leaveSlice';
import { listTransactionByBranchReducer, listTransactionReducer } from '../reduxData/transactionSlice';
import { listBranchServicesReducer } from '../reduxData/branchServicesSlice';
import { listservicemasterReducer } from '../reduxData/serviceMasterSlice';
import { listAdvancePaymentReducer } from '../reduxData/advancePaymentSlice';
import { listsettingReducer } from '../reduxData/settingSlice';
import { listCurrencyReducer } from '../reduxData/currencySlice';
import { listservicecategoryReducer } from '../reduxData/serviceCategorySlice';
import { listProductReducer } from '../reduxData/productSlice';
import { listUnitReducer } from '../reduxData/unitSlice';
import { listCustomerReducer } from '../reduxData/customerSlice';

export default function Dashboard() {
    const _ = require("lodash");
    const admin = useSelector((state: any) => state.authUserInfo.value);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [msg, setMsg] = useState("");
    const params = useParams();


    const rolesList = async () => {
        try {
            const resp = await AuthApiService.GetApiCall(urls.ROLE_LIST, admin.token);
            // console.log("role list", resp)
            if (resp.status) {
                const r = dispatch(roleListReducer(resp?.data));
            }
        } catch (error) {
            setMsg("Network Error !");
        }

    };

    const branchlist = async () => {
        try {
            const resp = await AuthApiService.GetApiCall(urls.BRANCH_LIST, admin.token);
            // console.log("branch list", resp)
            if (resp.status) {
                dispatch(ListBranchReducer(resp?.data?.data));
            }
        } catch (error) {
            setMsg("Network Error !");
        }

    };
    const empList = async () => {
        try {
            const response = await AuthApiService.GetApiCall(urls.EMPLOYEE_LIST, admin.token);
            // console.log("employee list", response)
            if (response.status) {
                dispatch(listEmployeeReducer(response?.data?.data))
            } else {
                setMsg(response?.data?.msg);
            }
        } catch (error) {
            const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
            setMsg(errorMessage);
        }
    }

    const empCategoryList = async () => {
        try {
            const response = await AuthApiService.GetApiCall(urls.EMPLOYEE_CATEGORY, admin.token);
            // console.log("employee category", response)
            if (response.status) {
                dispatch(empCategoryReducer(response?.data))
            } else {
                setMsg(response?.data?.msg);
            }
        } catch (error) {
            const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
            setMsg(errorMessage);
        }
    }

    const incentiveRuleList = async () => {
        try {
            const response = await AuthApiService.GetApiCall(urls.INCENTIVE_RULE_LIST, admin.token);
            // console.log("incentive rule", response)
            if (response.status) {
                dispatch(listIncentiveRuleReducer(response?.data?.data))
            } else {
                setMsg(response?.data?.msg);
            }
        } catch (error) {
            const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
            setMsg(errorMessage);
        }
    }

    const incentiveEmployeeList = async () => {
        try {
            const response = await AuthApiService.GetApiCall(urls.INCENTIVE_EMPLOYEE_LIST, admin.token);
            // console.log("incentive employee", response)
            if (response.status) {
                dispatch(listIncentiveEmployeeReducer(_.orderBy(response?.data?.data, (item: any) => item.employeeInfo?.first_name?.toLowerCase(), ['asc'])))
            } else {
                setMsg(response?.data?.msg);
            }
        } catch (error) {
            const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
            setMsg(errorMessage);
        }
    }

    const branchservicelist = async () => {
        try {
            const res = await AuthApiService.GetApiCall(urls.BRANCH_SERVICES_LIST, admin.token);
            // console.log("branch service", res)
            if (res.status) {
                dispatch(listBranchServicesReducer(res?.data?.data));
                // console.log(res)
            }
        } catch (error) {
            setMsg("Network Error !");
        }
    };

    const currencyList = async () => {
        try {
            const res = await AuthApiService.GetApiCall(urls.CURRENCY_LIST, admin.token);
            if (res.status) {
                dispatch(listCurrencyReducer(res?.data));
                // console.log(res)
            }
        } catch (error) {
            setMsg("Network Error !");
        }
    };

    const serviceCategoryList = async () => {
        try {
            const res = await AuthApiService.GetApiCall(urls.SERVICE_CATEGORY_LIST, admin.token);
            if (res.status) {
                dispatch(listservicecategoryReducer(res?.data));
                // console.log(res)
            }
        } catch (error) {
            setMsg("Network Error !");
        }
    };

    const productList = async () => {
        try {
            const res = await AuthApiService.GetApiCall(urls.PRODUCT_LIST, admin.token);
            if (res.status) {
                dispatch(listProductReducer(res?.data?.data));
                // console.log(res)
            }
        } catch (error) {
            setMsg("Network Error !");
        }
    };

    const unitList = async () => {
        try {
            const res = await AuthApiService.GetApiCall(urls.UNIT_LIST, admin.token);
            if (res.status) {
                dispatch(listUnitReducer(res?.data));
                // console.log("unit", res)
            }
        } catch (error) {
            setMsg("Network Error !");
        }
    };

    const servicemasterlist = async () => {
        try {
            const res = await AuthApiService.GetApiCall(urls.SERVICE_MASTER_LIST, admin.token);
            if (res.status) {
                dispatch(listservicemasterReducer(res?.data?.data));
                // console.log("servicemaster", res)
            }
        } catch (error) {
            setMsg("Network Error !");
        }
    };

    const settings = async () => {
        try {
            const resp = await AuthApiService.GetApiCall(urls.SETTING_LIST, admin.token);
            // console.log("setting list", resp)
            if (resp.status) {
                dispatch(listsettingReducer(resp?.data?.data));
            }
        } catch (error) {
            setMsg("Network Error !");
        }
    }

    // const customerList = async () => {
    //     const url = `${urls.CUSTOMER_LIST}/1/10`;
    //     const res = await AuthApiService.GetApiCallWithPagination(url, admin.token, 1, 10);
    //     // console.log("ress", res)
    //     dispatch(listCustomerReducer(res.data))
    // }

    // const AllCustomerList = async () => {
    //     const url = `${urls.ALL_CUSTOMER_LIST}`;
    //     const res = await AuthApiService.GetApiCall(url, admin.token);
    //     console.log("ress", res)
    //     dispatch(listCustomerReducer(res.data.data))
    // }

    useEffect(() => {
        empList();
        branchlist();
        rolesList();
        currencyList()
        empCategoryList();
        incentiveRuleList();
        incentiveEmployeeList();
        branchservicelist();
        servicemasterlist();
        settings();
        serviceCategoryList();
        productList();
        unitList()
        // AllCustomerList()
    }, [branchlist, empList, empCategoryList, incentiveRuleList,
        incentiveEmployeeList,
        rolesList,
        branchservicelist, servicemasterlist,
        settings, serviceCategoryList, productList, unitList
    ]);
    return (
        <div className="container-scroller">
            <Header />
            <br />
            <div className="container-fluid page-body-wrapper" style={{ overflowY: "auto" }} >
                <Sidebar />
                <div className="main-panel ">
                    <div className="content-wrapper p-0" style={{ "minHeight": "100vh" }}>
                        <div className="container-fluid p-0 p-xl-1 pr-xl-1 pl-xl-1 p-sm-3 mt-sm-3 mt-2 mt-md-3  mt-xl-4">
                            <Outlet />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}


// import React, { useEffect, useState, useCallback } from 'react';
// import Footer from './Footer';
// import Sidebar from './Sidebar';
// import { Outlet, useNavigate, useParams } from 'react-router-dom';
// import Header from './Header';
// import AuthApiService, { urls } from '../apiServices/AuthApiService';
// import { useDispatch, useSelector } from 'react-redux';
// import { empCategoryReducer, listEmployeeReducer } from '../reduxData/employeeSlice';
// import { ListBranchReducer } from '../reduxData/branchSlice';
// import { roleListReducer } from '../reduxData/authSlice';
// import { listIncentiveRuleReducer } from '../reduxData/incentiveRuleSlice';
// import { listIncentiveEmployeeReducer } from '../reduxData/incentiveEmployeeSlice';
// import { listEmployeeLeaveReducer } from '../reduxData/leaveSlice';
// import { listTransactionByBranchReducer, listTransactionReducer } from '../reduxData/transactionSlice';
// import { listBranchServicesReducer } from '../reduxData/branchServicesSlice';
// import { listservicemasterReducer } from '../reduxData/serviceMasterSlice';
// import { listAdvancePaymentReducer } from '../reduxData/advancePaymentSlice';
// import { listsettingReducer } from '../reduxData/settingSlice';
// // import _ from 'lodash'; // Import lodash

// export default function Dashboard() {
//     const _ = require("lodash");
//     const admin = useSelector((state: any) => state.authUserInfo.value);
//     const dispatch = useDispatch();
//     const [msg, setMsg] = useState("");

//     const fetchData = useCallback(async () => {
//         try {
//             const [
//                 roleResp,
//                 branchResp,
//                 empResp,
//                 empCategoryResp,
//                 incentiveRuleResp,
//                 incentiveEmployeeResp,
//                 branchServiceResp,
//                 serviceMasterResp,
//                 settingResp
//             ] = await Promise.all([
//                 AuthApiService.GetApiCall(urls.ROLE_LIST, admin.token),
//                 AuthApiService.GetApiCall(urls.BRANCH_LIST, admin.token),
//                 AuthApiService.GetApiCall(urls.EMPLOYEE_LIST, admin.token),
//                 AuthApiService.GetApiCall(urls.EMPLOYEE_CATEGORY, admin.token),
//                 AuthApiService.GetApiCall(urls.INCENTIVE_RULE_LIST, admin.token),
//                 AuthApiService.GetApiCall(urls.INCENTIVE_EMPLOYEE_LIST, admin.token),
//                 AuthApiService.GetApiCall(urls.BRANCH_SERVICES_LIST, admin.token),
//                 AuthApiService.GetApiCall(urls.SERVICE_MASTER_LIST, admin.token),
//                 AuthApiService.GetApiCall(urls.SETTING_LIST, admin.token)
//             ]);

//             if (roleResp.status) {
//                 dispatch(roleListReducer(roleResp.data));
//             }

//             if (branchResp.status) {
//                 dispatch(ListBranchReducer(branchResp.data.data));
//             }

//             if (empResp.status) {
//                 dispatch(listEmployeeReducer(_.orderBy(empResp.data.data, ['first_name'], ['asc']))); // Using lodash orderBy
//             }

//             if (empCategoryResp.status) {
//                 dispatch(empCategoryReducer(empCategoryResp.data));
//             }

//             if (incentiveRuleResp.status) {
//                 dispatch(listIncentiveRuleReducer(incentiveRuleResp.data.data));
//             }

//             if (incentiveEmployeeResp.status) {
//                 dispatch(listIncentiveEmployeeReducer(_.orderBy(incentiveEmployeeResp.data.data, (item) => item.employeeInfo?.first_name?.toLowerCase(), ['asc']))); // Using lodash orderBy
//             }

//             if (branchServiceResp.status) {
//                 dispatch(listBranchServicesReducer(branchServiceResp.data.data));
//             }

//             if (serviceMasterResp.status) {
//                 dispatch(listservicemasterReducer(serviceMasterResp.data.data));
//             }

//             if (settingResp.status) {
//                 dispatch(listsettingReducer(settingResp.data.data));
//             }
//         } catch (error) {
//             const errorMessage = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
//             setMsg(errorMessage);
//         }
//     }, [admin.token, dispatch]);

//     useEffect(() => {
//         fetchData();
//     }, [fetchData]);

//     return (
//         <div className="container-scroller">
//             <Header />
//             <br />
//             <div className="container-fluid page-body-wrapper">
//                 <Sidebar />
//                 <div className="main-panel ">
//                     <div className="content-wrapper p-0">
//                         <div className="container-fluid p-0 p-xl-1 pr-xl-1 pl-xl-1  mt-sm-1 mt-2  mt-xl-3">
//                             <Outlet />
//                         </div>
//                     </div>
//                     <Footer />
//                 </div>
//             </div>
//         </div>
//     );
// }




