import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IncentiveRuleState {
    value: Rule[];
    upData?: any;
}

interface Rule {
    branch_id: number;
    rule_title: string;
    from_amount: number;
    to_amount: number;
    incentive_percent: string;
    description: string;
    created_by: number;
    updated_by: number;

}
const initialState: IncentiveRuleState = {
    value: [],
    upData: null,
};

const IncentiveRuleSlice = createSlice({
    name: "incentiveRule",
    initialState,
    reducers: {
        addIncentiveRuleReducer: (state, action: PayloadAction<Rule>) => {
            state.value.push(action.payload);
        },
        listIncentiveRuleReducer: (state, action: PayloadAction<Rule[]>) => {
            state.value = action.payload;
        },
        updateIncentiveRuleReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
            state.value = state.value.map(rul => (rul.id === action.payload.id ? action.payload : rul))
        },
        updateIncentiveRules: (state, action: PayloadAction<Rule[]>) => {
            state.value = action.payload;
        },
    },
});

export const { listIncentiveRuleReducer, addIncentiveRuleReducer, updateIncentiveRuleReducer, updateIncentiveRules } = IncentiveRuleSlice.actions;
export default IncentiveRuleSlice.reducer;
