import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faArrowDown, faArrowUp, faEdit, faPlus, faSort } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { listAllProductReducer, listProductReducer, updateProductReducer } from "../../../reduxData/productSlice";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { Alert, Snackbar } from "@mui/material";

export default function ManageProductList() {
  const productInfo = useSelector((state: any) => state.productInfo.value2)
  const _ = require("lodash")
  const [isSearching, setSearching] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [branchSearchValue, setBranchSearchValue] = useState();
  const [productNameSearchValue, setProductNameSearchValue] = useState();
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const [sortedlist, setsortedlist] = useState(productInfo);
  const [sort, setsort] = useState(false);
  const [val, setval] = useState(null);
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [msgs, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [statusLoading, setStatusLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (data: any) => {
    return (<><th></th>
      {<tr><td className="text-wrap">
        <strong>Purchase Price({admin.currency}):</strong> <span>{data?.purchase_price != "" ? data?.purchase_price : '-'}</span>
      </td></tr>}
      {<tr><td className="text-wrap">
        <strong>Description:</strong> <span>{data?.description != "" ? data?.description : '-'}</span>
      </td></tr>}
      {<tr><td className="text-wrap">
        <strong>Unit:</strong> <span>{data.unitInfo?.abbreviation != "" ? data.unitInfo?.abbreviation : '-'}</span>
      </td></tr>}</>
    );
  };

  const ProductList = async (page, itemsPerPage, branch_id, product_name) => {
    try {
      setLoading(true)
      setSearching(true)
      const url = `${urls.PRODUCT_LIST_WITH_SEARCH}/${page}/${itemsPerPage}`;
      const obj = {
        branch_id: admin?.saloon_id ?? null,
        product_name: product_name
      }
      const resp = await AuthApiService.SearchApiCallWithQuery(url, obj, admin.token)
      dispatch(listAllProductReducer(resp.data));
    }
    catch (error) {
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally {
      setLoading(false)
    }
  }

  //--------------------------Pagination-Start------------------------------
  const totalRecords = productInfo?.pagination?.totalRecords;


  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust the number of buttons you want to show

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, "...");
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push("...", totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    // if (isSearching) {
    await ProductList(page, itemsPerPage, branchSearchValue, productNameSearchValue)

  };


  function Edit(data: any) {
    localStorage.setItem("productinfo", JSON.stringify({
      id: data?.id, product_name: data?.product_name, description: data?.description, sale_price: data?.sale_price,
      purchase_price: data?.purchase_price, stock_count: data?.stock_count, unit: data?.unitInfo?.id, branch_id: data?.branch_id
    }))
    dispatch(updateProductReducer(data));
    navigate("/branchManager/editProduct")
  }
  //--------------------------Pagination-End-----------------------
  const sortByProductName = () => {
    setsort(!sort);
    const sortedProducts = _.orderBy(productInfo.data, (item: any) => item?.product_name.toLowerCase(), sort ? 'desc' : 'asc');
    const newList = { ...productInfo, data: sortedProducts }
    dispatch(listAllProductReducer(newList));
  }


  // edit
  const handleStatus = async (data: any) => {
    let confirmation = confirm(`Are you sure you want to ${data.isActive ? "Deactivate" : "Activate"}?`);
    if (confirmation) {
      try {
        setStatusLoading(data);
        const url = `${urls.PRODUCT_UPDATE}/${data.id}`;
        const ob = {};
        if (data.isActive) {
          ob.updated_by = admin.uid;
          ob.isActive = false;
        }
        else {
          ob.updated_by = admin.uid;
          ob.isActive = true;
        }
        const resp = await AuthApiService.PutApiCall(url, ob, admin.token)
        if (resp.data.status === 201 && resp.status === 200) {
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.data.status)
          await ProductList(currentPage, itemsPerPage, branchSearchValue, productNameSearchValue)
        } else {
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.data.status)
        }
      }
      catch (error) {
        handleClickOpen()
        setMsg('Network Error !');
        setStatus(false);
      } finally {
        setStatusLoading(false)
      }
    } else {
      return
    }
  }


  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }



  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => { ProductList(currentPage, itemsPerPage, branchSearchValue, productNameSearchValue) }, [])

  return <>
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
    </Snackbar>
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-lg-10 col-md-10 col-sm-10">
          <div className="text-center">
            <h4>Products</h4>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
          <Link
            to="/branchManager/addProduct">
            <button className="btn btn-sm btn-purple" ><FontAwesomeIcon icon={faPlus} />&nbsp;New Product</button>
          </Link>
        </div>
      </div>
      <div className="row mt-2 mt-xl-4 pb-4 p-1">
        <div className="col-lg-3 col-md-3 col-sm-12 ">
          <label>Search</label>
          <div className="input-group input-group-md">
            <input
              type="text"
              className="form-control shadow-sm"
              placeholder="Enter Product Name"
              value={productNameSearchValue}
              onChange={(e) => {
                if (e.target.value == "") {
                  setCurrentPage(1);
                  setProductNameSearchValue(null)
                  ProductList(1, itemsPerPage, branchSearchValue, null);
                }
                else {
                  setCurrentPage(1);
                  setProductNameSearchValue(e.target.value)
                  ProductList(1, itemsPerPage, branchSearchValue, e.target.value);
                }

              }}
            />
          </div>
        </div>
      </div>

      {loading || productInfo.length <= 0 ? (
        <div className="mt-4">
          {loading ? (
            // Display loading spinner
            <div className="d-flex justify-content-center align-items-center">
              {loadingSpinner()}
            </div>
          ) : (
            // Display custom message for no records
            <h5>No Products found. You can add New Products.</h5>
          )}
        </div>
      ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
        <div className={`table-container table-responsive`}>
          <table className="table table-striped table-hover border-light-subtle">
            <thead>
              <tr>
                {!isMobile && <th>S.no</th>}
                <th></th>
                <th className="text-center" style={{ cursor: "pointer" }} onClick={() => { sortByProductName() }}>{!isMobile ? "Product Name" : <>Product <br /> Name</>}
                  &nbsp;<FontAwesomeIcon icon={faSort} cursor="pointer" onClick={() => { sortByProductName() }} /></th>
                {!isMobile && <th className="text-center">{isMobile ? <>Purchase<br />Price(INR)</> : <>Purchase Price({admin.currency})</>}</th>}
                <th className="text-center">{isMobile ? <>Sale<br />Price({admin.currency})</> : <>Sale Price({admin.currency})</>}</th>
                {!isMobile && <th>Unit</th>}
                {!isMobile && <th className="text-center">Description</th>}
                {<th className="text-center">Status</th>}
                {isMobile && <th></th>}
              </tr>
            </thead>
            <tbody>
              {productInfo?.data?.map((data: any, index: any) => {
                const isExpanded = expandedRowIndex === index;
                return (
                  <React.Fragment key={index}>
                    <tr>
                      {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}
                      <td> <FontAwesomeIcon cursor="pointer" icon={faEdit} className="fontIcon" onClick={() => { Edit(data) }} />

                      </td>
                      <td className="text-center">{data.product_name}</td>
                      {!isMobile && <td className="text-center">{data.purchase_price ? data.purchase_price : "-"}</td>}
                      <td className="text-center">{data?.sale_price}</td>
                      {!isMobile && <td>{data?.unitInfo?.abbreviation}</td>}
                      {!isMobile && <td className="text-center text-wrap">{data.description ? data.description : '-'}</td>}
                      <td className="text-center">{data?.isActive ? <button className="btn btn-sm btn-warning text-center" onClick={() => handleStatus(data)}>{statusLoading.id == data.id ? "Deactivating..." : "Active"}</button> : <button onClick={() => handleStatus(data)} className="btn btn-sm btn-danger text-center">{statusLoading.id == data.id ? "Activating..." : "Deactive"}</button>}</td>

                      {isMobile && (
                        <td>
                          {!isExpanded ? (
                            <button
                              className="btn btn-link"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="fontIcon"
                                size="sm"
                              />
                            </button>
                          ) : (
                            <button
                              className="btn btn-link"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUp}
                                className="fontIcon"
                                size="sm"
                              />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                    {isExpanded && isMobile && (
                      <tr>
                        <td colSpan="6">{renderRowExpansionContent(data)}</td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""
                  }`}
              >
                <button
                  className="page-link "
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || loading}
                >
                  Previous
                </button>
              </li>
              {generatePageNumbers()?.map((page, index) => (
                <li
                  key={index}
                  className={`page-item ${page === currentPage ? "active" : ""
                    }`}
                >
                  {page === "..." ? (
                    <span className="page-link">...</span>
                  ) : (
                    <button
                      className="page-link btn-sm"
                      onClick={() => handlePageChange(page)}
                      disabled={loading}
                    >
                      {page}
                    </button>
                  )}
                </li>
              ))}
              <li
                className={`page-item ${currentPage === totalPages ? "disabled" : ""
                  }`}
              >
                <button
                  className="page-link btn-sm"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={loading || currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      }
    </div>
  </>
}