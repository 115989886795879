import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface BranchServicesState {
    value: BranchServices[];
    upData?: any; // Update this type based on your use case
}

interface BranchServices {
    id: number; // Assuming you have an ID field, update this based on your actual structure
    branch_id: number;
    service_id: number;
    service_charge: string;
    description: string;
    branch_name?: string; // Add branch_name property
    service_title?: string;
}

const initialState: BranchServicesState = {
    value: [],
    upData: null,
};

const branchServicesSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        addBranchServicesReducer: (state, action: PayloadAction<BranchServices>) => {
            state.value.push(action.payload);
        },
        listBranchServicesReducer: (state, action: PayloadAction<BranchServices[]>) => {
            state.value = action.payload;
        },
        updateBranchServicesReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
            state.value = state.value.map(service => (service.id === action.payload.id ? action.payload : service));
        },
    },
});

export const { addBranchServicesReducer, listBranchServicesReducer, updateBranchServicesReducer } = branchServicesSlice.actions;
export default branchServicesSlice.reducer;
