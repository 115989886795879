import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ServiceMasterState {
    value: Service[];
    upData?: any; // Update this type based on your use case
}

interface Service {

    // Add other properties as needed
}

const initialState: ServiceMasterState = {
    value: [],
    upData: null,
};

const serviceMasterSlice = createSlice({
    name: "servicemaster",
    initialState,
    reducers: {
        // addEmployeeLeaveReducer: (state, action: PayloadAction<Service>) => {
        //     state.value.push(action.payload);
        // },
        listservicemasterReducer: (state, action: PayloadAction<Service[]>) => {
            state.value = action.payload;
        },
        // updateEmployeeLeaveReducer: (state, action: PayloadAction<any>) => {
        //     console.log("Updating upData:", action.payload);
        //     state.upData = action.payload;
        // },
    },
});

export const {listservicemasterReducer} = serviceMasterSlice.actions;
export default serviceMasterSlice.reducer;
