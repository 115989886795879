import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPlus, faSearch, faSort, faTrash, } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listAdvancePaymentReducer,
  updateAdvancePaymentReducer,
} from "../../../reduxData/advancePaymentSlice";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";

export default function AdvancePayment() {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const _ = require("lodash");
  const advancedpaymentinfo = useSelector(
    (state: any) => state.AdvancePaymentInfo.value
  );

  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [sort, setsort] = useState(false);
  const auth = useSelector((state: any) => state.authUserInfo.value);
  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const employeeInfo = useSelector((state: any) => state.employeeInfo.value);
  const [searchInput, setSearchInput] = useState();
  const [val, setval] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const currentDate = new Date().toISOString().substring(0, 10)
  const [toDate, setToDate] = useState(currentDate);
  const [fromDate, setFromDate] = useState(currentDate);
  const [isDateSearching, setDateSearching] = useState(false);
  const [grandTotal, setGrandTotal] = useState();
  const [showReset, setShowReset] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const itemsPerPage = 50;

  const branchOptions =  branchInfo && Array.isArray(branchInfo) ? [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
    value: item.id,
    label: item.branch_name,
  }))] :[];

  useEffect(() => {
    if (branchInfo.length === 1) {
      const singleBranch = branchInfo[0];
      const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
      setSelectedBranch(singleBranchOption);
    //   onBranchSearch(singleBranchOption);
    }
  }, [branchInfo]);

  const employeeOptions = val != undefined && val?.label != "All" ? [{ value: "All", label: "All" }, ...employeeInfo.filter((item: any) => {
    return item.branch_info.id === val?.value;
  }).map((item: any) => ({
    value: item.id,
    label: item.first_name + " " + item.last_name,
  }))] : [{ value: "All", label: "All" }, ...employeeInfo?.map((item: any) => ({
    value: item.id,
    label: item.first_name + " " + item.last_name,
  }))];

  const totalRecords = advancedpaymentinfo?.pagination?.totalRecords

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust the number of buttons you want to show

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, '...');
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push('...', totalPages);
      }
    }

    return pageNumbers;
  };
  const advancepaymentlist = async (page: number, itemsPerPage: number) => {
    try {
      setLoading(true)
      const URL = `${urls.ADVANCED_PAYMENT_LIST}/${page}/${itemsPerPage}`;
      const res = await AuthApiService.GetApiCallWithPagination(URL, auth.token, page, itemsPerPage);
      if (res.status) {
        dispatch(listAdvancePaymentReducer(res.data));
      }
    } catch (error) {
      setMsg("Network Error !");
    } finally {
      setLoading(false)
    }
  };



  const handleChange = async (page: number) => {
    setCurrentPage(page);
    // if (searchInput != '') {
    //   await onSearch(searchInput, page, itemsPerPage)
    // }
    // else 
    if (isDateSearching) {
      await handleDateSubmit(val, searchInput, page, itemsPerPage);
    }
    // else if (val != undefined) {
    //   await onBranchSearch(val, page, itemsPerPage);
    // }
    else {
      await advancepaymentlist(page, itemsPerPage)
    }
  };

  const handleDateSubmit = async (val: any, searchInput: any, page: any, itemsPerPage: any) => {
    setDateSearching(true);
    setLoading(true)
    const url = `${urls.ADVANCED_PAYMENT_BY_DATE}/${fromDate}/${toDate}/${page}/${itemsPerPage}`
    const obj = {
      branch_id: val?.label == "All" ? "" : val?.value,
      employee_id: searchInput?.label == "All" ? "" : searchInput?.value
    }

    const res = await AuthApiService.SearchApiCallWithQuery(url, obj, auth.token);
    if (res.status == 200) {
      dispatch(listAdvancePaymentReducer(res.data));
      var gt = res.data.grand_total;
      setGrandTotal(gt);
      setLoading(false)
    }
  }

  function Edit(data: any) {
    localStorage.setItem("advancepaymentinfo", JSON.stringify({
      id: data?.id, branch_id: data?.branch_id, employee_id: data?.employee_id,
      payment_date: data?.payment_date.substring(0, 10), amount: data?.amount, reason: data?.reason,
      payment_mode: data?.payment_mode
    }))
    dispatch(updateAdvancePaymentReducer(data));
    navigate("/saloonChain/editAdvancePayment");
  }

  const Delete = async (data: any) => {
    const confirmation = window.confirm("Are you sure you want to delete?");

    if (confirmation) {
      try {
        const url = urls.ADVANCED_PAYMENT_DEL + data.id;
        const resp = await AuthApiService.DelApiCall(url, auth.token);
        if (resp.status) {
          setStatus(resp.data.status)
          handleClickOpen();
          const updatedList = {
            data: advancedpaymentinfo?.data?.filter((item: any) => item.id !== data.id),
            pagination: advancedpaymentinfo?.pagination,
          };
          dispatch(listAdvancePaymentReducer(updatedList));
          setMsg(resp.data.msg);
          await advancepaymentlist(currentPage, itemsPerPage);
        } else {
          setMsg(resp.data.msg);
          setStatus(resp.data.status)
        }
      } catch (error: any) {
        setMsg("Network Error !");
        setStatus(error.response.status);
        handleClickOpen()
      }
    }
  };

  const loadingSpinner = () => {
    return <div className="d-flex justify-content-center">
      <div className="spinner-border" >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  }

  useEffect(() => {
    // if (searchInput != '') {
    //   onSearch(searchInput, currentPage, itemsPerPage)
    // }
    // else
    if (isDateSearching) {
      handleDateSubmit(val, searchInput, currentPage, itemsPerPage);
    }
    // else if (val != undefined) {
    //   onBranchSearch(val, currentPage, itemsPerPage);
    // }
    else {
      advancepaymentlist(currentPage, itemsPerPage)
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sorting = (d: any) => {
    setsort(!sort)
    if (d == 'branch') {
      const data = sort ? _.orderBy(advancedpaymentinfo.data, (d: any) => d.branchinfo.branch_name, ['asc']) : _.orderBy(advancedpaymentinfo.data, (d: any) => d.branchinfo.branch_name, ['desc']);
      const advancedpaymentInfo = { ...advancedpaymentinfo, data };
      dispatch(listAdvancePaymentReducer(advancedpaymentInfo));
    }
    else if (d == 'employee') {
      const data = sort ? _.orderBy(advancedpaymentinfo.data, (d: any) => d.employeeinfo.first_name, ['asc']) : _.orderBy(advancedpaymentinfo.data, (d: any) => d.employeeinfo.first_name, ['desc']);
      const advancedpaymentInfo = { ...advancedpaymentinfo, data };
      dispatch(listAdvancePaymentReducer(advancedpaymentInfo));
    }
    else if (d == 'pmode') {
      const data = sort ? _.orderBy(advancedpaymentinfo.data, (d: any) => d.payment_mode, ['asc']) : _.orderBy(advancedpaymentinfo.data, (d: any) => d.payment_mode, ['desc']);
      const advancedpaymentInfo = { ...advancedpaymentinfo, data };
      dispatch(listAdvancePaymentReducer(advancedpaymentInfo));
    }
    else if (d == 'pdate') {
      const data = sort ? _.orderBy(advancedpaymentinfo.data, (d: any) => d.payment_date, ['asc']) : _.orderBy(advancedpaymentinfo.data, (d: any) => d.payment_date, ['desc']);
      const advancedpaymentInfo = { ...advancedpaymentinfo, data };
      dispatch(listAdvancePaymentReducer(advancedpaymentInfo));
    }
  }


  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (data: any) => {
    return (<><th></th>

      <tr> <td className="text-wrap">  <b>Payment Date:&nbsp;</b><span>{data.payment_date.substring(0, 10)}</span></td></tr>
      <tr> <td className="text-wrap">   <b>Payment Mode:&nbsp;</b><span>{data.payment_mode}</span></td></tr>
      <tr> <td className="text-wrap"> <b>Reason:&nbsp;</b><span>{data.reason != '' && data.reason != null ? data.reason : '-'}</span></td></tr>
      <tr> <td className="text-wrap"><b>Created By:&nbsp;</b><span>{data.createAdvanceBy.first_name +
        " " +
        data.createAdvanceBy.last_name}</span></td></tr>
    </>


    );
  };


  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
        </Snackbar>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-10 col-sm-10">
              <div className="text-center">
                <h4>Employee Advance Payments</h4>
              </div>
            </div>
            {/* <div className="col-lg-1 col-md-1 col-sm-1 mt-2 d-flex justify-content-end">
              <Link to="/saloonChain/advancePaymentReport">
                <button className="btn btn-sm btn-purple">Reports</button>
              </Link>
            </div> */}
            <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
              <Link to="/saloonChain/newadvancePayment">
                <button className="btn btn-sm btn-purple"><FontAwesomeIcon icon={faPlus} /> New Employee Advance</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-xl-4 pb-2 pb-xl-4 p-1">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <label>Select Branch</label>
            <Select
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              className="basic-single"
              classNamePrefix="select"
              defaultValue={"All"}
              placeholder="All"
              // isDisabled={isDisabled}
              isLoading={branchInfo.length <= 0 ? true : false}
              isClearable={true}
              // isRtl={isRtl}
              isSearchable={true}
              options={branchOptions}
              onChange={(e:any) => { setShowReset(true); setval(e); setCurrentPage(1); handleDateSubmit(e, searchInput, 1, itemsPerPage); setSelectedBranch(e);}}
              // value={val}
              value={selectedBranch}

            /></div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <label htmlFor="">Select Employee</label>
            <Select
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              className="basic-single"
              classNamePrefix="select"
              placeholder={val?.label || "All"}
              isLoading={employeeInfo.length <= 0 ? true : false}
              isClearable={true}
              isSearchable={true}
              options={employeeOptions}
              value={searchInput}
              onChange={(e: string) => { setShowReset(true); setSearchInput(e); setCurrentPage(1); handleDateSubmit(val, e, 1, itemsPerPage); }}
              escapeClearsValue={true}
              backspaceRemovesValue={false}
              filterOption={(option, inputValue) =>
                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
              }
            />
          </div>

          <div className="col-lg-5 col-md-5 p-0 m-0">
            <form onSubmit={(e) => { e.preventDefault(); setCurrentPage(1); handleDateSubmit(val, searchInput, currentPage, itemsPerPage); }}>
              <div className="row m-0 p-0">
                <div className="col-lg-4 col-md-4 col-sm-6 col-4 m-0 ">
                  <label>From Date:</label>
                  <div className="input-group input-group-md ">
                    <input
                      type="Date"
                      className="form-control shadow-sm"
                      placeholder="Search..."
                      value={fromDate}
                      onChange={(e) => { setFromDate(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-4 m-0 p-0 mr-xl-2 ">

                  <label>To Date:</label>
                  <div className="input-group input-group-md">
                    <input
                      type="Date"
                      className="form-control shadow-sm"
                      placeholder="Search..."
                      value={toDate}
                      min={fromDate}
                      onChange={(e) => { setToDate(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-6 col-4 m-0 ml-1 ml-xl-2">

                  <label></label>
                  <br />
                  <button type="submit" className="btn btn-md btn-purple" onClick={() => { setShowReset(true) }}>Submit</button>
                </div>
              </div>
            </form>
          </div>
          {showReset && <div className="col-lg-1">
            <label></label>
            <br />
            <button className="btn btn-danger" onClick={() => { setShowReset(false); setCurrentPage(1); setFromDate(currentDate); setToDate(currentDate); setDateSearching(false), setSearchInput(""), setval(""), advancepaymentlist(1, itemsPerPage) }}>Reset</button>
          </div>}

        </div>


        {loading || advancedpaymentinfo?.data?.length === 0 ? (
          <div className="mt-4">
            {loading ? (
              // Display loading spinner
              <div className="d-flex justify-content-center align-items-center">
                {loadingSpinner()}
              </div>
            ) : (
              // Display custom message for no records
              <h5>No Advance Details found.</h5>
            )}
          </div>
        ) : (
          <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
            {grandTotal != undefined && <div className="table-responsive">
              <table id="report-table" className="table table-striped table-hover border-light-subtle justify-content-center">
                <thead>
                  <tr>
                    <th className="btn-cash">
                      Total Cash
                    </th>
                    <th className="btn-online">
                      Total Online
                    </th>
                    <th className="btn-Total">
                      Grand Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{grandTotal.total_cash}</td>
                    <td>{grandTotal.total_online}</td>
                    <td>{grandTotal.total}</td>
                  </tr>
                </tbody>
              </table>
            </div>}
            <div className={`table-container ${isMobile ? 'table-fixed' : 'table-responsive'}`}>
              <table className="table table-striped table-hover border-light-subtle ">
                <thead>
                  <tr className="text-nowrap">
                    <th></th>
                    {/* <th style={isMobile ? { width: 'auto' } : {}}>S.No</th> */}
                    <th style={{ cursor: "pointer" }} onClick={() => { sorting('branch') }} >
                      Branch &nbsp;
                      <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th style={{ cursor: "pointer" }} onClick={() => { sorting('employee') }} >
                      Employee &nbsp;
                      <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th className="text-wrap">
                      Amount (INR)
                    </th>
                    {!isMobile && <th style={{ cursor: "pointer" }} onClick={() => { sorting('pdate') }} >
                      Payment Date &nbsp;
                      <FontAwesomeIcon icon={faSort} />
                    </th>}
                    {!isMobile && <th style={{ cursor: "pointer" }} onClick={() => { sorting('pmode') }} >
                      Payment Mode &nbsp;
                      <FontAwesomeIcon icon={faSort} />
                    </th>}
                    {/* {!isMobile && <th>Reason</th>} */}
                    {!isMobile && <th> Created By</th>}
                    {isMobile && <th></th>}
                  </tr>
                </thead>
                <tbody className="text-nowrap">
                  {advancedpaymentinfo?.data?.map((data: any, index: number) => {
                    const isExpanded = expandedRowIndex === index; return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{isMobile ? <><FontAwesomeIcon
                            onClick={() => Edit(data)}
                            cursor="pointer"
                            icon={faEdit}
                            className="fontIcon"
                          />{" "}
                            <br></br>
                            <FontAwesomeIcon
                              onClick={() => {
                                Delete(data);
                              }}
                              cursor="pointer"
                              icon={faTrash}
                              className="fontIcon"
                            /></> : <><FontAwesomeIcon
                              onClick={() => Edit(data)}
                              cursor="pointer"
                              icon={faEdit}
                              className="fontIcon"
                            />{" "}
                            &nbsp;
                            <FontAwesomeIcon
                              onClick={() => {
                                Delete(data);
                              }}
                              cursor="pointer"
                              icon={faTrash}
                              className="fontIcon"
                            /></>}

                          </td>
                          <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{data.branchinfo.branch_name}</td>
                          <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{isMobile ? <>{data.employeeinfo.first_name}<br></br>{data.employeeinfo.last_name}</> : <>{data.employeeinfo.first_name}&nbsp; {data.employeeinfo.last_name}</>}

                          </td>
                          <td>{data.amount}</td>
                          {!isMobile && <td>{data.payment_date.substring(0, 10)}</td>}
                          {!isMobile && <td>{data.payment_mode}</td>}
                          {/* {!isMobile && <td>{data.reason != '' && data.reason != null ? data.reason : '-'}</td>} */}
                          {!isMobile && <td>
                            {data.createAdvanceBy.first_name +
                              " " +
                              data.createAdvanceBy.last_name}
                          </td>}{isMobile && (
                            <td>
                              {!isExpanded ? (
                                <button
                                  className="btn btn-link"
                                  onClick={() => toggleRowExpansion(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowDown}
                                    className="fontIcon"
                                    size="sm"
                                  />
                                </button>
                              ) : (
                                <button
                                  className="btn btn-link"
                                  onClick={() => toggleRowExpansion(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowUp}
                                    className="fontIcon"
                                    size="sm"
                                  />
                                </button>
                              )}</td>
                          )}
                        </tr>
                        {isExpanded && isMobile && (
                          <tr>
                            <td colSpan="5">{renderRowExpansionContent(data)}</td>
                          </tr>
                        )}
                      </React.Fragment>)
                  }
                  )}
                </tbody>


              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link "
                      onClick={() => handleChange(currentPage - 1)}
                      disabled={currentPage === 1 || loading}
                    >
                      Previous
                    </button>
                  </li>
                  {generatePageNumbers().map((page, index) => (
                    <li
                      key={index}
                      className={`page-item ${page === currentPage ? 'active' : ''}`}
                    >
                      {page === '...' ? (
                        <span className="page-link">...</span>
                      ) : (
                        <button
                          className="page-link btn-sm"
                          onClick={() => handleChange(page)}
                          disabled={loading}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                  >
                    <button
                      className="page-link btn-sm"
                      onClick={() => handleChange(currentPage + 1)}
                      disabled={loading || currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
