import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";
import { ProductValidationSchema } from "../../../validations/Validations";


export default function NewProduct() {
    const authInfo = useSelector((state: any) => state.authUserInfo.value);
    const unitInfo = useSelector((state: any) => state.unitInfo.value);
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const dispatch = useDispatch();

    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const [msgs, setMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const branchOptions = branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }));

    const formik = useFormik({
        initialValues: {
            product_name: "",
            description: "",
            sale_price: "",
            purchase_price: "",
            stock_count: "",
            unit: "",
            branch_id: "",
            isActive: true,
            created_by: authInfo.uid,
            updated_by: authInfo.uid,


        },
        validationSchema: ProductValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob = {
                    product_name: values.product_name,
                    description: values?.description ? values?.description : "",
                    sale_price: values.sale_price,
                    purchase_price: values.purchase_price ? values.purchase_price : 0,
                    stock_count: values.stock_count ? values.stock_count : 0,
                    unit: values?.unit,
                    branch_id: values.branch_id,
                    isActive: true,
                    created_by: authInfo.uid,
                    updated_by: authInfo.uid,
                };
                const resp = await AuthApiService.SaveApiCall(urls.PRODUCT_SAVE, ob, authInfo.token);
                if (resp.status === 201) {
                    setMsg(resp.data.msg);
                    // setStatus(true)
                    handleClickOpen();
                    formik.resetForm()
                    setTimeout(() => {
                        navigate('/saloonChain/productDetails')
                    }, 2000)
                    setStatus(resp.data.status)



                } else {
                    setMsg(resp.data.msg);

                    setTimeout(() => {
                        navigate('/saloonChain/newProduct')
                    }, 2000)
                }
            } catch (error: any) {
                setMsg(error.response.data.error == "Internal server error" ? "All Fileds Are Required" : error.response.data.error);
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setTimeout(() => {
                    setLoading(false);
                }, 2000)
            }
        },
    });

    const unitOptions = unitInfo.map((item: any) => ({
        value: item.id,
        label: item.unit_name,
    }));

    useEffect(() => {
        if (branchInfo.length === 1) {
            const singleBranch = branchInfo[0];
            formik.setFieldValue("branch_id", singleBranch.id);
        }
    }, [branchInfo]);

    return <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
        </Snackbar>
        <div className="container p-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="text-center">
                            <h4>New Product</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
                        <Link
                            to="/saloonChain/productDetails">
                            <button className="btn btn-sm btn-purple" >All Products</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                <div className="container">
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form
                                onFocus={() => setMsg("")}
                                onSubmit={formik.handleSubmit}>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="branch_id" className="col-sm-3 col-form-label">Branch Name<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        {/* <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? 'is-invalid' : ''}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch Name"
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue("branch_id", selectedOption.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            value={
                                                branchOptions.find((option: any) => option.value === formik.values.branch_id) ||
                                                null
                                            }
                                        /> */}
                                        <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? "is-invalid" : ""}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch"
                                            onChange={(selectedOption) => formik.setFieldValue("branch_id", selectedOption.value)}
                                            onBlur={formik.handleBlur}
                                            value={branchOptions.find((option: any) => option.isSelected || option.value === formik.values.branch_id)}
                                        />
                                        <div className="invalid-feedback">{formik.touched.branch_id && typeof formik.errors.branch_id === 'string' ? (formik.errors.branch_id) : null}</div>

                                    </div>
                                </div>

                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="service_charge" className="col-sm-3 col-form-label">Product Name<span style={{ color: "red" }}>*</span>:</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Product Name"
                                            id="product_name"
                                            name="product_name"
                                            className={`form-control shadow-sm ${formik.touched.product_name && formik.errors.product_name ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.product_name}
                                        />
                                        <div className="invalid-feedback">{formik.touched.product_name && typeof formik.errors.product_name === 'string' ? (formik.errors.product_name) : null}</div>

                                    </div>
                                </div>




                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="desc" className="col-sm-3 col-form-label">Sell Price<span style={{ color: "red" }}>*</span>:</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Sell Price"
                                            id="sale_price"
                                            name="sale_price"
                                            className={`form-control shadow-sm ${formik.touched.sale_price && formik.errors.sale_price ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.sale_price}
                                        />
                                        <div className="invalid-feedback">{formik.touched.sale_price && typeof formik.errors.sale_price === 'string' ? (formik.errors.sale_price) : null}</div>

                                    </div>
                                </div>

                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="branch_id" className="col-sm-3 col-form-label">Unit<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <Select
                                            id="unit"
                                            name="unit"
                                            className={`react-select-container ${formik.touched.unit && formik.errors.unit ? 'is-invalid' : ''}`}
                                            options={unitOptions}
                                            isSearchable
                                            placeholder="Unit of Measurement"
                                            onChange={(selectOptions) =>
                                                formik.setFieldValue("unit", selectOptions.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            value={
                                                unitOptions.find((option: any) => option.value === formik.values.unit) ||
                                                null
                                            }
                                        />
                                        <div className="invalid-feedback">{formik.touched.unit && typeof formik.errors.unit === 'string' ? (formik.errors.unit) : null}</div>

                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="desc" className="col-sm-3 col-form-label">Purchase Price:</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Purchase Price"
                                            id="purchase_price"
                                            name="purchase_price"
                                            className={`form-control shadow-sm ${formik.touched.purchase_price && formik.errors.purchase_price ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.purchase_price}
                                        />
                                        <div className="invalid-feedback">{formik.touched.purchase_price && typeof formik.errors.purchase_price === 'string' ? (formik.errors.purchase_price) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="desc" className="col-sm-3 col-form-label">Stock Count:</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Stock Count"
                                            id="stock_count"
                                            name="stock_count"
                                            className={`form-control shadow-sm ${formik.touched.stock_count && formik.errors.stock_count ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.stock_count}
                                        />
                                        <div className="invalid-feedback">{formik.touched.stock_count && typeof formik.errors.stock_count === 'string' ? (formik.errors.stock_count) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="desc" className="col-sm-3 col-form-label">Description:</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Description"
                                            id="description"
                                            name="description"
                                            className={`form-control shadow-sm ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                        />
                                        <div className="invalid-feedback">{formik.touched.description && typeof formik.errors.description === 'string' ? (formik.errors.description) : null}</div>

                                    </div>
                                </div>
                                <div className="mt-3 mb-xl-3 row text-center">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn btn-md form-control btn-purple" disabled={loading ? true : false}>
                                            {loading ? "Saving..." : "Add"}
                                        </button>
                                    </div>
                                    <div className="col-lg-5"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}