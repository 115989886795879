
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BranchState {
    value: Branch[];
    upData?: Branch | null; 
}

interface Branch {
    id?: string;
    branch_name: string;
    branch_address: string;
    phone_number: string;
    saloon_type: string;
    // Add other properties as needed
}

const initialState: BranchState = {
    value: [],
    upData: null,
};

const branchSlice = createSlice({
    name: "branch",
    initialState,
    reducers: {
        addBranchReducer: (state, action: PayloadAction<Branch>) => {
            state.value.push(action.payload);
        },
        ListBranchReducer: (state, action: PayloadAction<Branch[]>) => {
            state.value = (action.payload??[]);
        },
        UpdateBranchReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
        },
        // UpdateBranchReducer: (state, action: PayloadAction<Branch>) => {
        //     console.log("Updating upData:", action.payload);
        //     state.upData = action.payload;
        //     state.value = state.value.map(branch => (branch.id === action.payload.id ? action.payload : branch));
        // },

    },
});

export const { addBranchReducer, ListBranchReducer, UpdateBranchReducer } = branchSlice.actions;
export default branchSlice.reducer;




