import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthApiService, { urls } from '../apiServices/AuthApiService';
import { Alert, Snackbar } from '@mui/material';

const RegisterSchema = Yup.object().shape({

    password: Yup.string().required("New Password is required").min(6, "new Password must be at least 6 characters"),
    cpassword: Yup.string()
        .required("Confirm New Password is required")
        .oneOf([Yup.ref("password"), ""], "Confirm Passwords must match"),
});

export default function ResetPassword() {
    const [msg, setMsg] = useState('');
    const otpBox = useRef<HTMLInputElement>(null);
    const SaloonNameBox = useRef<HTMLInputElement>(null);
    const mobileBox = useRef<HTMLInputElement>(null);
    const passwordBox = useRef<HTMLInputElement>(null);
    const emailBox = useRef<HTMLInputElement>(null);
    const cpasswordBox = useRef<HTMLInputElement>(null);

    const navigate = useNavigate()
    const [status, setStatus] = useState<number | boolean>(false); // Update state type
    const [loading, setLoading] = useState<number | boolean>(false);
    const [otpSent, setOtpSent] = useState<number | boolean>(false);
    const [verifyOtp, setVerifyOtp] = useState<number | boolean>(false);
    const [forgotPass, setForgotPass] = useState<number | boolean>(true);
    const [token, setToken] = useState("")

    const [open, setOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
            otp: "",
            password: "",
            cpassword: ""
        },
        onSubmit: async (values) => {
            setLoading(true)
            setMsg('');
            if (otpSent == false && forgotPass == true) {
                try {
                    const ob = {
                        email: values.email
                    };
                    const response = await AuthApiService.PostApiCall(urls.FORGOT_PASSWORD, ob);
                    if (response.status === 200) {
                        if (response?.data?.status === false) {
                            setStatus(false);
                            setMsg(response?.data?.message);
                            setLoading(false)
                        } else {
                            setStatus(true);
                            setMsg(response?.data?.message);
                            setLoading(false)
                            setOtpSent(true)
                        }
                    } else {
                        setStatus(false);
                        setMsg(response.data.message);
                        setLoading(false)
                    }
                    handleClickOpen();
                } catch (error) {
                    const errorMessage: string =
                        (error as any)?.response?.data?.message || 'email not found. Please try different.';
                    setStatus(false);
                    setMsg(errorMessage);
                    setLoading(false)
                    handleClickOpen();
                    // console.error(errorMessage);
                }
            } else if (verifyOtp == false) {
                try {
                    const ob = {
                        email: values.email,
                        otp: values.otp
                    };
                    const response = await AuthApiService.PostApiCall(urls.OTP_VERIFY, ob);
                    if (response.status === 200) {
                        if (response?.data?.status === false) {
                            setStatus(false);
                            setMsg(response?.data?.message);
                            setLoading(false)
                        } else {
                            setStatus(true);
                            setMsg(response?.data?.message);
                            setToken(response?.data?.token)
                            setLoading(false)
                            setVerifyOtp(true)
                            setOtpSent(false)
                            setForgotPass(false)
                        }
                    } else {
                        setStatus(false);
                        setMsg(response.data.message);
                        setLoading(false)
                    }
                    handleClickOpen();
                } catch (error) {
                    const errorMessage: string =
                        (error as any)?.response?.data?.message || 'email not found. Please try different.';
                    setStatus(false);
                    setMsg(errorMessage);
                    setLoading(false)
                    handleClickOpen();
                    // console.error(errorMessage);
                }
            }
            else if (verifyOtp == true) {
                try {
                    const ob = {
                        password: values.password,
                        password_confirmation: values.cpassword
                    };
                    // console.log("ob", ob)
                    const response = await AuthApiService.PostApiCallWithUrl(urls.RESET_PASSWORD, ob, token);
                    if (response.status === 200) {
                        if (response?.data?.status === false) {
                            setStatus(false);
                            setMsg(response?.data?.msg);
                            setLoading(false)
                        } else {
                            // console.log(response)
                            setStatus(true);
                            setMsg(response?.data?.msg);
                            setLoading(false)
                            setVerifyOtp(true)
                            setOtpSent(false)
                            setForgotPass(false)
                            setTimeout(() => {
                                navigate('/login')
                            }, 5000)

                        }
                    } else {
                        setStatus(false);
                        setMsg(response.data.msg);
                        setLoading(false)
                    }
                    handleClickOpen();
                } catch (error) {
                    const errorMessage: string =
                        (error as any)?.response?.data?.message || 'email not found. Please try different.';
                    setStatus(false);
                    setMsg(errorMessage);
                    setLoading(false)
                    handleClickOpen();
                    // console.error(errorMessage);
                }
            }
        },
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="login-page">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={status ? 'success' : 'error'} // Simplified condition
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {msg}
                </Alert>
            </Snackbar>
            <div className="container-fluid ">
                <div className="row w-100  mx-0">
                    <div className="col-lg-4 mx-auto">
                        <div className="card border-0 py-5 px-4 px-sm-5 mt-5">
                            <div className="brand-logo text-center">
                                <Link className="navbar-brand brand-logo " to="">
                                    <img
                                        src="images/Saloonist_Logo/newLogo.png"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </Link>
                            </div>
                            <p className="font-weight-light text-center">Reset Password</p>

                            <form onSubmit={formik.handleSubmit} className="pt-2">

                                {forgotPass && <div className="form-group mb-3">
                                    <input
                                        type="email"
                                        ref={emailBox}
                                        className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        placeholder="Please enter your email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                    )}
                                </div>}

                                {otpSent && <div className="form-group mb-3">
                                    <input
                                        type="number"
                                        ref={otpBox}
                                        className="form-control"
                                        id="otp"
                                        placeholder="Enter OTP"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.otp}
                                    />

                                </div>}

                                {verifyOtp && <>
                                    <p>Create New Password</p> <div className="form-group mb-3">
                                        <input
                                            type="password"
                                            ref={passwordBox}
                                            className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''
                                                }`}
                                            id="password"
                                            placeholder="Password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        {formik.touched.password && formik.errors.password && (
                                            <div className="invalid-feedback">{formik.errors.password}</div>
                                        )}
                                    </div>
                                    <div className="form-group mb-3">
                                        <input
                                            type="password"
                                            ref={cpasswordBox}
                                            className={`form-control ${formik.touched.cpassword && formik.errors.cpassword ? 'is-invalid' : ''
                                                }`}
                                            id="cpassword"
                                            placeholder="Confirm Password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.cpassword}
                                        />
                                        {formik.touched.cpassword && formik.errors.cpassword && (
                                            <div className="invalid-feedback">{formik.errors.cpassword}</div>
                                        )}
                                    </div></>
                                }

                                <div className="mt-3">
                                    {forgotPass == true ? <>{otpSent == false ? <button type="submit" className="btn btn-purple form-control">
                                        {loading ? "Sending..." : "Send OTP"}
                                    </button> : ""}
                                        {otpSent == true && verifyOtp == false ? <button type="submit" className="btn btn-purple form-control">
                                            {loading ? "verifying..." : "Verify OTP"}
                                        </button> : ""}

                                    </> : <>{verifyOtp == true ? <button type="submit" className="btn btn-purple form-control">
                                        {loading ? "creating..." : "create password"}
                                    </button> : ""}</>}
                                </div>
                                <div className="text-center mt-2 font-weight-light">
                                    Already have an account? <Link to="/login" className="text-primary">Login</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

