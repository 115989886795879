import axios from "axios";

class AuthApiService {

    PostApiCall(url: string, data: Record<string, any>) {
        return axios.post(url, data
        )
    }

    PostApi(url: string, data: Record<string, any>,) {
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    PostApiFormData(url: string, data: Record<string, any>, token: string) {
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token
            },
        })
    }

    PutApiFormData(url: string, data: Record<string, any>, token: string) {
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token
            },
        })
    }

    PostApiCallWithUrl(url: string, data: any, token: string) {
        console.log("api data", url, data, token);
        return axios.post(url, data, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json' // Add Content-Type header
            }
        });
    }
    SaveApiCall(url: string, data: Record<string, any>, token: string) {
        return axios.post(url, data, { headers: { Authorization: 'Bearer ' + token } })
    }

    GetApiCall(url: string, token: string) {
        return axios.get(url, { headers: { Authorization: 'Bearer ' + token } })
    }
    PutApiCall(url: string, data: Record<string, any>, token: string) {
        return axios.put(url, data, { headers: { Authorization: 'Bearer ' + token } })
    }
    DelApiCall(url: string, token: string) {
        return axios.delete(url, { headers: { Authorization: 'Bearer ' + token } });
    }

    GetApiCallWithPagination(url: string, token: string, page: number, recordsPerPage: number) {
        const params = {
            page: page,
            recordsPerPage: recordsPerPage,
        };

        return axios.get(url, {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },

            params: params,
        });
    }

    GetApi(url: string, token: string) {
        return axios.get(url, {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },

        });
    }
    SearchApiCallWithPagination(url: string, data: Record<string, any>, token: string, page: number, recordsPerPage: number) {
        const params = {
            page: page,
            recordsPerPage: recordsPerPage,
        };

        const config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json', },
            params: params,
        };

        return axios.post(url, data, config);
    }


    SearchApi(url: string, token: string) {
        const config = {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        };

        return axios.get(url, config);
    }
    SearchApiCall(url: string, data: Record<string, any>, token: string) {
        const config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json', },
        };

        return axios.post(url, data, config);
    }

    SearchApiCallWithQuery(url: string, data: Record<string, any>, token: string) {

        const config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json', },
            params: data
        };

        return axios.get(url, config);
    }

    SearchApiCallForEmployeeAndBranch(url: string, data: Record<string, any>, token: string) {
        const params = {
            branch_id: data
        }

        const config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json', },
            params: params
        };

        return axios.get(url, config);
    }
    GenSalApiCall(url: string, data: Record<string, any>, token: string) {
        // const params = {
        //     date: date
        // };

        const config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json', },
            // params: params,
        };
        return axios.post(url, data, config);
    }

}

const SERVER = process.env.REACT_APP_SERVER;

export const urls = {
    SELF_SALOON_SIGNUP: `${SERVER}/saloonsignup`,

    LOGIN: `${SERVER}/login`,
    REGENERATE_TOKEN: `${SERVER}/regeneratetoken`,
    REGISTER: `${SERVER}/register`,
    UPDATE_PASSWORD: `${SERVER}/updateuser`,
    SAVE_USER: `${SERVER}/saveuser`,
    MAKE_USER: `${SERVER}/makeuser`,
    UPDATE_USER: `${SERVER}/updateuser/`,
    MAKE_MANAGER: `${SERVER}/makemanager/`,

    FORGOT_PASSWORD: `${SERVER}/forgotpassword`,
    OTP_VERIFY: `${SERVER}/otpverification`,
    RESET_PASSWORD: `${SERVER}/resetpassword`,

    CURRENCY_LIST: `${SERVER}/currenylist`,
    UNIT_LIST: `${SERVER}/unitlist`,

    ROLE_LIST: `${SERVER}/rolelist`,
    ROLE_ASSIGN: `${SERVER}/assignrole`,
    UPDATE_ROLE: `${SERVER}/updateuserrole`,

    BRANCH_SAVE: `${SERVER}/savebranch`,
    BRANCH_LIST: `${SERVER}/branchlist`,
    BRANCH_UPDATE: `${SERVER}/updatebranch/`,
    TOTAL_BRANCHES: `${SERVER}/totalbranches`,

    EMPLOYEE_LIST: `${SERVER}/employeelist`,
    EMPLOYEE_UPDATE: `${SERVER}/updateemployee/`,
    EMPLOYEE_SAVE: `${SERVER}/saveemployee/`,
    EMPLOYEE_SEARCH: `${SERVER}/employeelistbysearch`,
    EMPLOYEE_BY_BRANCH: `${SERVER}/employeelistbybranchid`,

    EMPLOYEE_CATEGORY: `${SERVER}/empcategorylist`,

    // transaction CRUD
    TRANSACTION_LIST: `${SERVER}/transactionlist`,
    TRANSACTION_SAVE: `${SERVER}/savetransaction`,
    TRANSACTION_UPDATE: `${SERVER}/updatetransaction/`,
    TRANSACTION_GET: `${SERVER}/transaction/`,
    TRANSACTION_DELETE: `${SERVER}/transaction_del/`,
    TOTAL_TRANSACTION: `${SERVER}/totaltransactions`,
    PRODUCT_TRANSACTION_LIST: `${SERVER}/sellingproducts`,

    // transaction report
    TRANSACTION_LIST_BY_BRANCH: `${SERVER}/transactionlistbybranch/`,
    TRANSACTION_LIST_BY_EMPLOYEE: `${SERVER}/transactionlistbydaterange/`,
    TRANSACTION_LIST_BY_DATE: `${SERVER}/transactionlistbydate/`,
    // search
    TRANSACTION_LIST_BY_SEARCH: `${SERVER}/transactionlistbysearch`,
    TRANSACTION_LIST_BY_FTDATE: `${SERVER}/alltransaction`,

    TRANSACTION_LIST_BY_BRANCHID: `${SERVER}/transactionlistbybranchid`,
    TRANSACTION_LIST_BY_MONTH: `${SERVER}/transactionlistbymonth/`,


    ADVANCED_PAYMENT_LIST: `${SERVER}/advancelist`,
    ADVANCED_PAYMENT_SAVE: `${SERVER}/saveadvance`,
    ADVANCED_PAYMENT_UPDATE: `${SERVER}/updateadvance/`,
    ADVANCED_PAYMENT_GET: `${SERVER}/advance/`,
    ADVANCED_PAYMENT_DEL: `${SERVER}/advance_del/`,
    ADVANCED_PAYMENT_BY_SEARCH: `${SERVER}/advancelistbysearch`,
    // ADVANCED_PAYMENT_BY_BRANCHSEARCH: `${SERVER}/advancelistbybranchid`,
    ADVANCED_PAYMENT_BY_DATE: `${SERVER}/advancelistbydaterange`,
    TOTAL_ADVANCE: `${SERVER}/totaladvance`,

    BRANCH_SERVICES_LIST: `${SERVER}/branchservicelist`,
    BRANCH_SERVICES_LIST_PAGINATION: `${SERVER}/branchservicelists`,
    BRANCH_SERVICES_UPDATE: `${SERVER}/updatebranchservice/`,
    BRANCH_SERVICES_SAVE: `${SERVER}/savebranchservice`,
    BRANCH_SERVICES_BY_ID: `${SERVER}/branchservicelistbybranchid`,
    BRANCH_SERVICES_BY_CATEGORY: `${SERVER}/branchservicelistbycategoryid/`,
    TOTAL_SERVICES: `${SERVER}/totalservices`,
    ALL_BRANCHSERVICE_BY_BRANCHID: `${SERVER}/allbranchservices`,
    BRANCH_SERVICEBYID: `${SERVER}/branchservice`,

    INCENTIVE_RULE_LIST: `${SERVER}/incentivelist`,
    INCENTIVE_RULE_SAVE: `${SERVER}/saveincentiverule`,
    INCENTIVE_RULE_UPDATE: `${SERVER}/updateincentive/`,
    INCENTIVE_RULE_DELETE: `${SERVER}/incentive_del/`,
    INCENTIVE_RULE_BY_ID: `${SERVER}/incentivelistbybranchid/`,

    INCENTIVE_EMPLOYEE_SAVE: `${SERVER}/saveincentiveemployee`,
    INCENTIVE_EMPLOYEE_LIST: `${SERVER}/incentiveemployeelist`,
    INCENTIVE_EMPLOYEE_UPDATE: `${SERVER}/updateincentiveemployee/`,
    INCENTIVE_EMPLOYEE_DELETE: `${SERVER}/incentiveemployee_del/`,
    INCENTIVE_EMPLOYEE_SEARCH: `${SERVER}/incentiveemployeelistbysearch`,
    INCENTIVE_EMPLOYEE_SEARCH_BY_BRANCH_OR_EMPLOYEE: `${SERVER}/incentiveemployeebysearchbybranchoremployee`,

    SERVICE_MASTER_LIST: `${SERVER}/servicemasterlist`,
    SERVICE_CATEGORY_LIST: `${SERVER}/servicecategorylist`,

    PRODUCT_LIST: `${SERVER}/productlist`,
    PRODUCT_SAVE: `${SERVER}/saveproduct`,
    PRODUCT_LIST_WITH_SEARCH: `${SERVER}/allproductlist`,
    PRODUCT_UPDATE: `${SERVER}/updateproductmaster`,
    PRODUCT_DELETE: `${SERVER}/productmaster_del`,
    TOTAL_PRODUCT: `${SERVER}/totalproducts`,

    LEAVE_LIST: `${SERVER}/leavelist`,
    LEAVE_SAVE: `${SERVER}/saveleave`,
    GET_LEAVE: `${SERVER}/leave/`,
    UPDATE_LEAVE: `${SERVER}/updateleave/`,
    DELETE_LEAVE: `${SERVER}/leave_del/`,
    LEAVE_BY_BRANCH_AND_MONTH: `${SERVER}/leavelistbybranchandmonth/`,
    LEAVE_BY_SEARCH: `${SERVER}/leavelistbysearch`,
    LEAVE_BY_BRANCH_ID: `${SERVER}/leavelistbybranchid`,
    LEAVE_BY_DATE: `${SERVER}/leaveslistbydaterange`,
    TOTAL_LEAVES: `${SERVER}/totalleaves`,

    GENERATE_SALARY: `${SERVER}/salary`,
    CONFIRM_SALARY: `${SERVER}/confirmsalary`,
    SALARY_LIST: `${SERVER}/salarylist`,
    SALARY_PREVIEW: `${SERVER}/salarypreview`,

    SETTING_SAVE: `${SERVER}/savesetting`,
    SETTING_LIST: `${SERVER}/settinglist`,
    SETTING_UPDATE: `${SERVER}/updatesetting`,

    ALL_CUSTOMER_LIST: `${SERVER}/allcustomers`,
    CUSTOMER_LIST: `${SERVER}/customerlist`,
    CUSTOMER_LIST_BY_SEARCH: `${SERVER}/customerlistbysearch`,
    CUSTOMER_SAVE: `${SERVER}/savecustomer`,
    CUSTOMER_TRANSACTIONS: `${SERVER}/customertransaction`,
    CUSTOMER_DUES: `${SERVER}/customerlistbydueamount`,
    TOTAL_CUSTOMER: `${SERVER}/totalcustomer`,
    CUSTOMER_UPDATE: `${SERVER}/updatecustomer/`,

    SAVE_EXPENSE_HEAD: `${SERVER}/saveexpensehead`,
    ALL_EXPENSE_HEAD_LIST: `${SERVER}/allexpenseheadlist`,
    EXPENSE_HEAD_LIST: `${SERVER}/expenseheadlist`,
    EXPENSE_HEAD_BY_BRANCH: `${SERVER}/expenseheadbybranchid`,
    EXPENSE_HEAD_UPDATE: `${SERVER}/updateexpensehead`,
    EXPENSE_HEAD_DELETE: `${SERVER}/expensehead_del`,

    SAVE_EXPENSE: `${SERVER}/saveexpenses`,
    ALL_EXPENSE_LIST: `${SERVER}/allexpenseslist`,
    EXPENSE_LIST: `${SERVER}/expenseslist`,
    UPDATE_EXPENSE: `${SERVER}/updateexpense`,
    DELETE_EXPENSE: `${SERVER}/expense_del`,
    EXPENSE_LIST_BY_DATE: `${SERVER}/expenselistbydaterange`,
    TOTAL_EXPENSE: `${SERVER}/totalexpenses`,

    BALANCE_SHEET: `${SERVER}/balancesheet`
}

export default new AuthApiService
