import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listTransactionReducer } from "../../../reduxData/transactionSlice";
import { Alert, Snackbar } from "@mui/material";
import Select, { components } from "react-select"
import CreatableSelect from 'react-select/creatable'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { listBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
import { listCustomerReducer } from "../../../reduxData/customerSlice";

export default function AddDailyTransaction() {

  const _ = require("lodash")
  const [myId, setMyId] = useState(0);
  const [prevItemType, setPrevItemType] = useState("Service");
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDateTime = currentDate.getFullYear() + '-' +
    ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
    ('0' + currentDate.getDate()).slice(-2) + 'T' +
    ('0' + currentDate.getHours()).slice(-2) + ':' +
    ('0' + currentDate.getMinutes()).slice(-2) + ':' +
    ('0' + currentDate.getSeconds()).slice(-2);

  // Format the date string
  const formattedDate = currentDate.getFullYear() + '-' +
    ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
    ('0' + currentDate.getDate()).slice(-2);


  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const branchServiceInfo = useSelector(
    (state: any) => state.branchServicesInfo.value
  );
  const productInfo = useSelector((state: any) => state.productInfo.value)
  const employeeInfo = useSelector((state: any) => state.employeeInfo.value.filter((data: any) => { return (data.is_active == true && data.date_of_joining <= formattedDate && (data.date_of_relieving >= formattedDate || data.date_of_relieving == null)) }));
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [msgs, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const serviceCategoryInfo = useSelector((state: any) => state.serviceCategoryInfo.value)
  const customerInfo = useSelector((state: any) => state.customerInfo.value);
  const [overallDiscountAmount, setOverallDiscountAmount] = useState(0);
  const [hasNonZeroDiscount, setHasNonZeroDiscount] = useState(false);
  const [selectedDiscountType, setSelectedDiscountType] = useState({ value: 'full_amount', label: 'On full amount' });
  const discountOptions = [
    { value: 'itemised', label: 'Item wise' },
    { value: 'full_amount', label: 'On full amount' }
  ];
  const defaultDiscountOption = { value: 'full_amount', label: 'On full amount' };

  const options = [
    { value: 'Service', label: 'Service' },
    { value: 'Product', label: 'Product' }
  ];
  const handleDiscountTypeChange = (selectedOption: any) => {
    setSelectedDiscountType(selectedOption);
  };

  const AddDailyTransactionValidationSchema = Yup.object().shape({
    employee_id: Yup.string().required("Employee Name is required"),

    // amount_paid: Yup.number().min(0, "Enter Positive Number").required("Amount is required"),
    transaction_date: Yup.string().required("Transaction date is required"),
    // payment_mode: Yup.string().required("Payment Mode is required"),
    item_type: Yup.string().required("Service Type is required"),
    // customer_name: Yup.string().when('amount_paid', (amount_paid, schema) => {
    //   return amount_paid < serviceTotal ? schema.required("Customer name is required when the amount is due") : schema;
    // }),
    // customer_mobile: Yup.string().when('amount_paid', (amount_paid, schema) => {
    //   return amount_paid < serviceTotal ? schema.required("Customer mobile is required when the amount is due") : schema;
    // }).matches(/^[0-9]{10}$/, 'Customer Mobile Number must be 10 digits'),

    online_payment: Yup.string().required("Payment Mode is required"),
    cash_payment: Yup.string().required("Payment Mode is required"),
  });


  const customerList = async (id: any) => {
    let query = { branchId: id };
    try {
      const url = `${urls.ALL_CUSTOMER_LIST}`;
      const res = await AuthApiService.SearchApiCallWithQuery(url, query, admin.token);
      if (res.status) {
        dispatch(listCustomerReducer(res.data));
      }
    } catch (error) {
      setMsg("Network Error !");
    }
  }
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceTotal, setServiceTotal] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState([{}]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tempService, setTempService] = useState(null); // To store the currently selected service/product
  const [tempEmployee, setTempEmployee] = useState(null);
  const [due, setDueAmount] = useState(0);
  const [net, setNet] = useState(0);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState({});
  const [showTable, setShowTable] = useState(false);
  useEffect(() => {
    customerList(admin.saloon_id)
  }, [])




  const handleInputDelete = (d: any) => {
    const oldArray = selectedServices;
    const ind = oldArray.findIndex((data: any) => data.id === d.id);
    const newArray = oldArray.toSpliced(ind, 1);
    setSelectedServices(newArray);

    if (newArray.length === 0) {
      setShowTable(false);
    }

    // Calculate the new total and format it
    const total = newArray.map((d: any) => d.charge * d.qty).reduce((a, data) => data + a, 0)
    const nettotal = total - overallDiscountAmount;

    const ftotal = total.toFixed(2);
    const ntotal = nettotal.toFixed(2);

    // Update state and formik values
    setServiceTotal(newArray.map((d: any) => d.mrp * d.qty).reduce((a, data) => data + a, 0));
    setTotalAmount(newArray.map((d: any) => d.mrp * d.qty).reduce((a, data) => data + a, 0))
    formik.setFieldValue('cash_payment', ntotal);
    formik.setFieldValue("online_payment", 0);
    // formik.setFieldValue("amount_paid", 0);
    setNet(ntotal);
  };



  const addServiceToCart = () => {
    console.log("Item type:", formik.values.item_type);
    console.log("Temp service:", tempService);

    if (formik.values.item_type === "Service") {
      // Check if the service is selected
      if (!tempService) {
        alert("Please select a service");
        return;
      }
      // If the service is selected, check if the employee is selected
      if (!tempEmployee) {
        alert("Please select a Staff");
        return;
      }
    }
    // Check if item type is "Product"
    else if (formik.values.item_type === "Product") {
      // Check if the product is selected
      if (!tempService) {
        alert("Please select a product");
        return;
      }
    }
    // If the item type is neither "Service" nor "Product"
    else {
      alert("Invalid item type");
      return;
    }



    // Ensure tempService.charge and tempService.qty are numbers
    const charge = parseFloat(tempService.charge);
    const qty = parseFloat(tempService.qty);

    if (isNaN(charge) || isNaN(qty)) {
      alert("Invalid charge or quantity");
      return;
    }

    const newItem = formik.values.item_type === "Service" ?
      { ...tempService, employee: tempEmployee } :
      tempService;

    if (formik.values.item_type === "Service" || formik.values.item_type === "Product") {
      console.log(`Adding ${formik.values.item_type.toLowerCase()} to cart`);
      setSelectedServices([...selectedServices, newItem]);
    } else {
      alert("Invalid item type");
      return;
    }

    // Calculate the new total and format it
    const total = ((parseFloat(serviceTotal) || 0) + (tempService?.mrp * qty));
    const nettotal = ((parseFloat(serviceTotal) || 0) + (charge * qty)) - overallDiscountAmount;

    const ftotal = total.toFixed(2);
    const ntotal = nettotal.toFixed(2)
    // Update state and formik values
    setServiceTotal(ftotal);
    setTotalAmount(ftotal)
    formik.setFieldValue('cash_payment', ntotal);
    setTempService(null);
    setNet(ntotal);
    setShowTable(true);
  };

  const handleMrpChange = (d: any, event: any) => {
    let mrp = event.target.value;
    // If the input is cleared, set the value to an empty string
    if (mrp === "") {
      mrp = "";
    } else {
      // Parse the value to a number and ensure it's not negative
      mrp = parseFloat(mrp) || 0;
      if (mrp < 0) mrp = 0;
    }

    const discountPercent = d.discount || 0;
    const discountedPrice = (mrp * ((100 - discountPercent) / 100)).toFixed(2);

    const newArray = selectedServices.map(service => service.id === d.id ? { ...service, mrp, charge: discountedPrice } : service);
    setSelectedServices(newArray);

    // Directly recalculate totals
    const totalMRP = newArray.reduce((total, service) => total + service.mrp * service.qty, 0);
    const totalDiscountAmount = newArray.reduce((total, service) => total + (service.mrp - service.charge) * service.qty, 0);
    // setOverallDiscountAmount(totalDiscountAmount);

    const total = newArray.reduce((total, service) => total + service.mrp * service.qty, 0);
    const ftotal = total.toFixed(2)

    const netAmount = (totalMRP - overallDiscountAmount).toFixed(2);
    setNet(netAmount);
    setServiceTotal(total);
    setTotalAmount(netAmount)
    formik.setFieldValue('cash_payment', netAmount)
    formik.setFieldValue('amount_paid', netAmount)
    const totalPaid = parseFloat(formik.values.cash_payment || 0) + parseFloat(formik.values.online_payment || 0);
    // setDueAmount(netAmount - totalPaid);
  };
  const handleMrpBlur = (d: any) => {
    const newArray = selectedServices.map(service =>
      service.id === d.id ? { ...service, mrp: service.mrp || 0 } : service
    );
    setSelectedServices(newArray);
  };

  const handleDiscountChange = (d: any, event: any) => {
    let discountPercent = event.target.value;

    // If the input is cleared, set the value to an empty string
    if (discountPercent === "") {
      discountPercent = "";
    } else {
      // Parse the value to a number and ensure it's within valid range
      discountPercent = parseFloat(discountPercent) || 0;
      if (discountPercent < 0 || discountPercent > 100) discountPercent = 0;
    }
    const discountedPrice = (d.mrp * ((100 - discountPercent) / 100)).toFixed(2);

    const newArray = selectedServices.map(service => service.id === d.id ? { ...service, discount: discountPercent, charge: discountedPrice } : service);
    setSelectedServices(newArray);

    // Directly recalculate totals
    const totalMRP = newArray.reduce((total, service) => total + service.mrp * service.qty, 0);
    const totalDiscountAmount = newArray.reduce((total, service) => total + (service.mrp - service.charge) * service.qty, 0);
    setOverallDiscountAmount(totalDiscountAmount);

    const total = newArray.reduce((total, service) => total + service.mrp * service.qty, 0);
    const ftotal = total.toFixed(2)

    const netAmount = (totalMRP - totalDiscountAmount).toFixed(2);
    setNet(netAmount);
    setServiceTotal(ftotal);
    setTotalAmount(netAmount)

    formik.setFieldValue('cash_payment', netAmount)
    formik.setFieldValue('amount_paid', netAmount)

    const totalPaid = parseFloat(formik.values.cash_payment || 0) + parseFloat(formik.values.online_payment || 0);
    // setDueAmount((netAmount - totalPaid).toFixed(2));
  };

  const handleDiscountBlur = (d: any) => {
    const newArray = selectedServices.map(service =>
      service.id === d.id ? { ...service, discount: service.discount || 0 } : service
    );
    setSelectedServices(newArray);
  };

  const handleDiscountedAmountChange = (d: any, event: any) => {
    let discountedAmount = event.target.value;

    // If the input is cleared, set the value to an empty string
    if (discountedAmount === "") {
      discountedAmount = "";
    } else {
      // Parse the value to a number and ensure it's not negative or greater than MRP
      discountedAmount = parseFloat(discountedAmount) || 0;
      if (discountedAmount < 0 || discountedAmount > d.mrp) discountedAmount = 0;
    }
    const discountPercent = (((d.mrp - discountedAmount) / d.mrp) * 100).toFixed(2);

    const newArray = selectedServices.map(service => service.id === d.id ? { ...service, discount: discountPercent, charge: discountedAmount } : service);
    setSelectedServices(newArray);

    // Directly recalculate totals
    const totalMRP = newArray.reduce((total, service) => total + service.mrp * service.qty, 0);
    const totalDiscountAmount = newArray.reduce((total, service) => total + (service.mrp - service.charge) * service.qty, 0);
    setOverallDiscountAmount(totalDiscountAmount);

    const total = newArray.reduce((total, service) => total + service.charge * service.qty, 0);
    const ftotal = total.toFixed(2)

    const netAmount = (totalMRP - totalDiscountAmount).toFixed(2);
    setNet(netAmount);
    setServiceTotal(ftotal);
    setTotalAmount(netAmount)

    formik.setFieldValue('cash_payment', netAmount)
    const totalPaid = parseFloat(formik.values.cash_payment || 0) + parseFloat(formik.values.online_payment || 0);
    formik.setFieldValue('amount_paid', netAmount)

    // setDueAmount((netAmount - totalPaid).toFixed(2));
  };

  const handleDiscountedAmountBlur = (d: any) => {
    const newArray = selectedServices.map(service =>
      service.id === d.id ? { ...service, charge: service.charge || 0 } : service
    );
    setSelectedServices(newArray);
  };



  //   const handleOverallDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     let overallDiscount = event.target.value;

  //     // If the input is cleared, set the value to an empty string and return early
  //     if (overallDiscount === "") {
  //       setOverallDiscountAmount(""); // Keep the input box empty
  //       return; // Exit the function to prevent further processing
  //     }

  //     // Parse the value to a number and ensure it's not negative
  //     const parsedValue = parseFloat(overallDiscount) || 0;
  //     const validValue = Math.max(parsedValue, 0);
  //     setOverallDiscountAmount(validValue);

  //     // Calculate total MRP and apply the discount proportionally
  //     const totalMRP = selectedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
  //     const cappedDiscount = Math.min(validValue, totalMRP);
  //     const proportionFactor = cappedDiscount / totalMRP;

  //     const updatedServices = selectedServices.map(service => {
  //       const discountedAmount = service.mrp - (service.mrp * proportionFactor);
  //       const discountPercent = (proportionFactor * 100).toFixed(2);

  //       return {
  //         ...service,
  //         discount: discountPercent,
  //         charge: discountedAmount.toFixed(2),
  //       };
  //     });

  //     setSelectedServices(updatedServices);

  //     // Recalculate totals
  //     const totalDiscountAmount = updatedServices.reduce((total, service) => total + (service.mrp - parseFloat(service.charge)) * service.qty, 0);
  //     setOverallDiscountAmount(Number(totalDiscountAmount.toFixed(2)));

  //     const total = updatedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
  //     const netAmount = (total - totalDiscountAmount).toFixed(2);

  //     setNet(Number(netAmount));
  //     setServiceTotal(total);
  //     setTotalAmount(Number(netAmount));

  //     const totalPaid = parseFloat(formik.values.cash_payment || "0") + parseFloat(formik.values.online_payment || "0");
  //     const dueAmount = Number((Number(netAmount) - totalPaid).toFixed(2));

  //     setDueAmount(Math.max(dueAmount, 0));

  //     formik.setFieldValue('cash_payment', netAmount);
  //     formik.setFieldValue('amount_paid', netAmount);
  // };


  const handleOverallDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let overallDiscount = event.target.value;

    // Allow the input to be an empty string to handle backspace
    if (overallDiscount === "") {
      setOverallDiscountAmount(""); // Clear the overall discount amount input

      // Reset discounts for all services to 0 when the discount box is cleared
      const updatedServices = selectedServices.map(service => ({
        ...service,
        discount: 0, // Set discount to 0
        charge: service.mrp, // Reset charge to MRP
      }));
      formik.setFieldValue('online_payment', 0);
      formik.setFieldValue('dues', 0);



      setSelectedServices(updatedServices);

      // Recalculate totals after resetting the discounts
      const total = updatedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
      setNet(total); // Set net amount to the total amount
      setServiceTotal(total);
      setTotalAmount(total);

      // Recalculate payments and due amount
      const cashPayment = Number(formik.values.cash_payment || "0");
      const onlinePayment = Number(formik.values.online_payment || "0");
      const totalPaid = cashPayment + onlinePayment;
      const dueAmount = Number((total - totalPaid).toFixed(2));

      setDueAmount(Math.max(dueAmount, 0));

      // Update Formik values
      formik.setFieldValue('cash_payment', total);
      formik.setFieldValue('amount_paid', total);
      return;
    }

    // Parse and handle the overall discount as a number
    let overallDiscountValue = parseFloat(overallDiscount) || 0;

    overallDiscountValue = Math.max(overallDiscountValue, 0);

    const totalMRP = selectedServices.reduce((total, service) => total + service.mrp * service.qty, 0);

    overallDiscountValue = Math.min(overallDiscountValue, totalMRP);
    const proportionFactor = overallDiscountValue / totalMRP;

    const updatedServices = selectedServices.map(service => {
      const discountedAmount = service.mrp - (service.mrp * proportionFactor);
      const discountPercent = (proportionFactor * 100).toFixed(2);

      return {
        ...service,
        discount: discountPercent,
        charge: discountedAmount.toFixed(2),
      };
    });

    setSelectedServices(updatedServices);

    // Recalculate totals
    const totalDiscountAmount = updatedServices.reduce((total, service) => total + (service.mrp - parseFloat(service.charge)) * service.qty, 0);
    setOverallDiscountAmount(overallDiscountValue);

    const total = updatedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
    const netAmount = (total - totalDiscountAmount).toFixed(2);

    setNet(Number(netAmount));
    setServiceTotal(total);
    setTotalAmount(Number(netAmount));

    // Recalculate payments and due amount
    const cashPayment = Number(formik.values.cash_payment || "0");
    const onlinePayment = Number(formik.values.online_payment || "0");
    const totalPaid = cashPayment + onlinePayment;
    const dueAmount = Number((Number(netAmount) - totalPaid).toFixed(2));

    setDueAmount(Math.max(dueAmount, 0));

    // Update Formik values
    formik.setFieldValue('cash_payment', Number(netAmount));
    formik.setFieldValue('amount_paid', Number(netAmount));
  };

  const handleOverallDiscountBlur = () => {
    if (overallDiscountAmount === "") {
      setOverallDiscountAmount(0);
      setDueAmount(0)
    }
  };


  // const handleCashPaymentChange = (e: any) => {
  //   let cashAmount = e.target.value;

  //   // If the input is cleared, set the value to an empty string
  //   if (cashAmount === "") {
  //     formik.setFieldValue('cash_payment', "");
  //   } else {
  //     const parsedValue = parseFloat(cashAmount) || 0;
  //     formik.setFieldValue('cash_payment', parsedValue);

  //     const onlineAmount = parseFloat(formik.values.online_payment || 0);
  //     const totalPaid = parsedValue + onlineAmount;
  //     formik.setFieldValue('amount_paid', totalPaid);

  //     const totalMRP = selectedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
  //     const totalDiscountAmount = selectedServices.reduce((total, service) => total + (service.mrp - service.charge) * service.qty, 0);
  //     const netAmount = totalMRP - totalDiscountAmount;

  //     setServiceTotal(totalMRP);
  //     setNet(netAmount);
  //     setDueAmount((netAmount - totalPaid).toFixed(2));
  //   }
  // };

  const handleCashPaymentChange = (e: any) => {
    let cashAmount = e.target.value;

    // If the input is cleared, set the value to an empty string and update due amount
    if (cashAmount === "") {
      formik.setFieldValue('cash_payment', "");
      const onlineAmount = parseFloat(formik.values.online_payment || 0);
      const totalPaid = onlineAmount;

      const totalMRP = selectedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
      const totalDiscountAmount = selectedServices.reduce((total, service) => total + (service.mrp - service.charge) * service.qty, 0);
      const netAmount = totalMRP - totalDiscountAmount;

      setServiceTotal(totalMRP);
      setNet(netAmount);
      setDueAmount((netAmount - totalPaid).toFixed(2));
    } else {
      const parsedValue = parseFloat(cashAmount) || 0;
      formik.setFieldValue('cash_payment', parsedValue);

      const onlineAmount = parseFloat(formik.values.online_payment || 0);
      const totalPaid = parsedValue + onlineAmount;

      const totalMRP = selectedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
      const totalDiscountAmount = selectedServices.reduce((total, service) => total + (service.mrp - service.charge) * service.qty, 0);
      const netAmount = totalMRP - totalDiscountAmount;

      setServiceTotal(totalMRP);
      setNet(netAmount);
      setDueAmount((netAmount - totalPaid).toFixed(2));
    }
  };

  const handleOnlinePaymentChange = (e: any) => {
    let onlineAmount = e.target.value;

    // If the input is cleared, set the value to an empty string
    if (onlineAmount === "") {
      formik.setFieldValue('online_payment', "");
    } else {
      const parsedValue = parseFloat(onlineAmount) || 0;
      formik.setFieldValue('online_payment', parsedValue);

      const cashAmount = parseFloat(formik.values.cash_payment || 0);
      const totalPaid = parsedValue + cashAmount;
      formik.setFieldValue('amount_paid', totalPaid);

      const totalMRP = selectedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
      const totalDiscountAmount = selectedServices.reduce((total, service) => total + (service.mrp - service.charge) * service.qty, 0);
      const netAmount = totalMRP - totalDiscountAmount;

      setServiceTotal(totalMRP);
      setNet(netAmount);
      setDueAmount((netAmount - totalPaid).toFixed(2));
    }
  };

  const handleCashPaymentBlur = () => {
    if (formik.values.cash_payment === "") {
      formik.setFieldValue('cash_payment', 0);
    }
  };

  const handleOnlinePaymentBlur = () => {
    if (formik.values.online_payment === "") {
      formik.setFieldValue('online_payment', 0);
    }
  };


  const handleQtyInputChange = (d, event) => {
    const value = event.target.value === "" ? 0 : parseInt(event.target.value, 10);
    const updatedQty = value >= 0 ? value : 1;

    const updatedServices = selectedServices.map((service) =>
      service.id === d.id ? { ...service, qty: updatedQty } : service
    );

    setSelectedServices(updatedServices);

    // Recalculate totals
    const totalMRP = updatedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
    const netAmount = totalMRP - overallDiscountAmount;

    setNet(netAmount);
    setServiceTotal(totalMRP);
    setTotalAmount(netAmount);
    formik.setFieldValue('cash_payment', netAmount);
    formik.setFieldValue('online_payment', 0);
    formik.setFieldValue('amount_paid', netAmount);
  };

  const handleQtyInputBlur = (d, event) => {
    if (event.target.value === "" || parseInt(event.target.value, 10) === 0) {
      const updatedServices = selectedServices.map((service) =>
        service.id === d.id ? { ...service, qty: 1 } : service
      );

      setSelectedServices(updatedServices);

      // Recalculate totals
      const totalMRP = updatedServices.reduce((total, service) => total + service.mrp * service.qty, 0);
      const netAmount = totalMRP - overallDiscountAmount;

      setNet(netAmount);
      setServiceTotal(totalMRP);
      setTotalAmount(netAmount);
      formik.setFieldValue('cash_payment', netAmount);
      formik.setFieldValue('amount_paid', netAmount);
    }
  };



  const employeeOptions = employeeInfo.map((item: any) => ({
    value: item.id,
    label: item.first_name + " " + item.last_name,
  }));

  // Service and product options with "Others" at the top
  const serviceOptions = branchServiceInfo?.map((item: any) => ({
    value: item.serviceMasterInfo.id,
    label: item.serviceMasterInfo.service_title,
  }));

  const productOptions = productInfo?.map((item: any) => ({
    value: item.id,
    label: item.product_name,
  }));

  const othersServiceOption = serviceOptions.find((option: any) => option.label === "Others");
  const othersProductOption = productOptions.find((option: any) => option.label === "Others");

  if (othersServiceOption) {
    serviceOptions.splice(serviceOptions.indexOf(othersServiceOption), 1);
    serviceOptions.unshift(othersServiceOption);
  }

  if (othersProductOption) {
    productOptions.splice(productOptions.indexOf(othersProductOption), 1);
    productOptions.unshift(othersProductOption);
  }

  const defaultServiceOption = serviceOptions.find((option: any) => option.label === "Others");
  const defaultProductOption = productOptions.find((option: any) => option.label === "Others");


  const customerOptions = customerInfo?.data?.map((item: any) => ({
    value: item.id,
    label: item.name
  }));

  const formik = useFormik({

    initialValues: {
      employee_id: "",
      transaction_date: formattedDateTime,
      date_only: formattedDate,
      item_type: "Service",
      amount_paid: 0,
      customer_name: "",
      customer_mobile: "",
      customer_id: "",
      cash_payment: 0,
      online_payment: 0,
    },
    // validationSchema: (values: any) => values?.item_type === "Service" ? AddDailyTransactionValidationSchema : AddDailyTransactionValidationSchemaProduct,
    validationSchema: () => {
      if (formik.values.item_type === "Service") {
        return AddDailyTransactionValidationSchema;
      } else {
        return Yup.object().shape({
          transaction_date: Yup.string().required("Transaction date is required"),
          item_type: Yup.string().required("Service Type is required"),
          // customer_name: Yup.string().when('amount_paid', (amount_paid, schema) => {
          //   return amount_paid < serviceTotal ? schema.required("Customer name is required when the amount is due") : schema;
          // }),
          // customer_mobile: Yup.string().when('amount_paid', (amount_paid, schema) => {
          //   return amount_paid < serviceTotal ? schema.required("Customer mobile is required when the amount is due") : schema;
          // }).matches(/^[0-9]{10}$/, 'Customer Mobile Number must be 10 digits'),

          online_payment: Yup.string().required("Payment Mode is required"),
          cash_payment: Yup.string().required("Payment Mode is required"),
        });
      }
    },
    onSubmit: async (values) => {
      if (due > 0 && (!values.customer_name || !values.customer_mobile)) {
        handleClickOpen();
        setMsg('Your amount is due , Please fill the customer details.')
        return;
      }

      try {
        // alert(values)
        setLoading(true);
        let ob = {}
        if (values.item_type === "Service") {
          ob = {
            branch_id: branchInfo[0].id,
            // employee_id: values.employee_id,
            // amount: serviceTotal,
            amount: totalAmount,
            transaction_date: values.transaction_date,
            customer_id: values.customer_id,
            services_id: selectedServices?.filter(d => d?.service_id).map(d => ({ id: d?.service_id, mrp: d?.mrp, quantity: d?.qty, discount_percent: d?.discount || 0, employee_id: d.employee?.employee_id, })),
            products_id: [],
            payment_mode: {
              Cash: values.cash_payment,
              Online: values.online_payment
            },
            amount_paid: values.amount_paid,
            remark: "",
            customer_name: values.customer_name,
            customer_mobile: values.customer_mobile,
            created_by: admin.uid,
            updated_by: admin.uid,
          };
        } else {
          ob = {
            branch_id: branchInfo[0].id,
            employee_id: null,
            // amount: serviceTotal,
            amount: totalAmount,
            transaction_date: values.transaction_date,
            customer_id: values.customer_id,
            services_id: [],
            products_id: selectedServices?.filter(d => d?.product_id).map(d => ({ id: d?.product_id, mrp: d?.mrp, quantity: d?.qty, discount_percent: d?.discount, employee_id: d.employee?.employee_id })),
            payment_mode: {
              Cash: values.cash_payment,
              Online: values.online_payment
            },
            amount_paid: values.amount_paid,
            remark: "",
            customer_name: values.customer_name,
            customer_mobile: values.customer_mobile,
            created_by: admin.uid,
            updated_by: admin.uid,
          };
        }
        const resp = await AuthApiService.SaveApiCall(
          urls.TRANSACTION_SAVE,
          ob,
          admin.token
        );
        if (resp.status === 200 || resp.status === 201) {
          setLoading(false);
          formik.resetForm();
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.data.status)
          // dispatch(addTransactionReducer(ob));
          dispatch(listTransactionReducer(ob));
          setTimeout(() => {
            navigate("/branchManager/dailytransactionslist")
          }, 1000);
        } else {
          if (resp.data && resp.data.err) {
            setMsg(resp.data.err);
            setStatus(resp.data.status)
          } else {
            setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
            setStatus(resp.data.status)
          }
        }
      }
      catch (error: any) {
        setLoading(false);
        setMsg(error.response.data.msg);
        setStatus(error.response.status);
        handleClickOpen()
      }
    },
  });



  const handleDateChange = (e: any) => {
    const dateValue = e.target.value;
    formik.setFieldValue("date_only", dateValue);
    const dateTimeValue = dateValue + "T" + ('0' + currentDate.getHours()).slice(-2) + ':' + ('0' + currentDate.getMinutes()).slice(-2) + ':' + ('0' + currentDate.getSeconds()).slice(-2);
    formik.setFieldValue("transaction_date", dateTimeValue);
  };
  console.log(formik.values.item_type)

  const handleItemTypeChange = () => {
    console.log(formik.values.item_type)

    setSelectedServices([]);
    setServiceTotal(0);
    setTempService(null);
    setTempEmployee(null);
    setShowTable(false)
    formik.setFieldValue("cash_payment", 0);
    formik.setFieldValue("online_payment", 0);
    formik.setFieldValue("amount_paid", 0);
    formik.setFieldValue('employee_id', '');
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
      </Snackbar>
      <div className="container-fluid p-0">
        <div className="container">

          <div className="row pr-xl-5 pl-xl-5 offset-md-2 offset-sm-0 offset-lg-0">
            <div className="col-lg-10 col-md-8 col-sm-10 d-flex justify-content-center">
              <div className="text-center">
                <h4>Add Daily Transaction</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-2 mt-2 d-flex pr-2 justify-content-end">
              <Link to="/branchManager/dailytransactionslist">
                <span>
                  <button className=" btn btn-purple btn-sm">
                    All Daily Transaction
                  </button>
                </span>
              </Link>
            </div>

          </div>
        </div>
        <div className=" px-xl-2 py-xl-2  mt-0 m-md-2 m-2 m-xl-2 m-lg-2">
          <div className="container">
            <div className="row mt-0  offset-lg-1 offset-md-1">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <form onSubmit={formik.handleSubmit}>
                  {/* staff and date */}
                  <div className="mb-2 mb-xl-3 mb-md-3 mb-lg-3 mb-sm-3 row">
                    <div className="col-sm-5 col-5 col-lg-4 col-md-5 col-xs-12 mt-3 mr-md-1">
                      <input
                        type="date"
                        className="form-control"
                        name="date_only"
                        onChange={handleDateChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.date_only}
                        readOnly
                      />
                      <input
                        type="hidden"

                        placeholder="Enter Transaction Date"
                        id="transaction_date"
                        name="transaction_date"
                        className={`form-control shadow-sm  ${formik.touched.transaction_date &&
                          formik.errors.transaction_date
                          ? "is-invalid"
                          : ""
                          }`}
                        min={employeeInfo.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_joining.substring(0, 10))}
                        max={employeeInfo.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_relieving?.substring(0, 10) == null ? undefined : data.date_of_relieving?.substring(0, 10))}

                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.transaction_date}
                        readOnly
                      />
                      <div className="invalid-feedback">
                        {formik.touched.transaction_date &&
                          typeof formik.errors.transaction_date === "string"
                          ? formik.errors.transaction_date
                          : null}
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-2 col-1"></div>
                    <div className="col-sm-5 col-md-5 col-sm-4 col-lg-4 col-5 py-0 mt-3">
                      <Select
                        id="item_type"
                        name="item_type"
                        options={options}
                        value={options.find(option => option.value === formik.values.item_type)}
                        onChange={(selectedOption) => {
                          formik.setFieldValue('item_type', selectedOption.value);
                          handleItemTypeChange();
                        }}
                        onBlur={formik.handleBlur}
                        className="react-select-container"
                      />
                    </div>
                    {/* Service and product select */}
                    {/* <div className="pe-5 form-check-reverse m-0 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
                      <input
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleItemTypeChange();
                        }}
                        onBlur={formik.handleBlur}
                        className="form-check-input"
                        type="radio"
                        name="item_type"
                        id="flexRadioDefault1"
                        value="Service"
                        checked={formik.values.item_type === "Service"}
                      />
                      <label
                        className="form-check-label pe-1 "
                        htmlFor="flexRadioDefault1"
                      >
                        Service
                      </label>
                    </div> */}
                    {/* <div className="pe-5 pe-sm-0 form-check-reverse col-xl-2 col-lg-2 col-md-3 col-sm-2 col-4">

                      <input
                        onChange={(e) => { formik.handleChange(e); handleItemTypeChange(); }}
                        onBlur={formik.handleBlur}
                        className="form-check-input"
                        type="radio"
                        name="item_type"
                        id="flexRadioDefault2"
                        value="Product"
                        checked={formik.values.item_type === "Product"}
                      />
                      <label
                        className="pe-1 mr-0 form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Product
                      </label>
                     
                    </div> */}

                  </div>

                  {/* service and product select add to cart */}
                  <div className="mb-2 mb-xl-3 mb-md-3 mb-lg-3 mb-sm-3 row">
                    {/* <label htmlFor="item_type" className="col-sm-2 col-3 col-lg-1 col-md-2 col-form-label m-xl-0 m-lg-0">{formik.values.item_type == "Service" ? "Service" : "Product"}<span style={{ color: "red" }}>*</span></label> */}
                    <div className="col-sm-5 col-lg-4 col-md-5 col-5 align-items-center justify-content-between">
                      {formik.values.item_type == "Service" ? <><Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        // menuIsOpen={true}
                        // maxMenuHeight="15rem"
                        className={`react-select-container ${formik.touched.item_type && formik.errors.item_type ? "is-invalid" : ""}`}
                        options={serviceOptions}
                        isSearchable
                        placeholder="Select Service Name"
                        defaultValue={defaultServiceOption}

                        onChange={(selectedOption) => {
                          let ser = branchServiceInfo.filter((d: any) => d.serviceMasterInfo.id == selectedOption.value).map((d: any) => ({
                            id: myId,
                            charge: d.service_charge,
                            service_id: d.id,
                            item: d.serviceMasterInfo.service_title,
                            qty: 1,
                            mrp: d.service_charge
                          }));
                          setMyId(myId + 1);
                          setTempService(ser[0]);
                        }}
                        onBlur={formik.handleBlur}
                      /> <div className="invalid-feedback">
                          {formik.touched.item_type && formik.errors.item_type ? (
                            <div className="invalid-feedback">{formik.errors.item_type}</div>
                          ) : null}
                        </div></> : <>
                        <Select
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          id="service"
                          name="service"
                          className={`react-select-container   ${formik.touched.item_type &&
                            formik.errors.item_type
                            ? "is-invalid"
                            : ""
                            }`}
                          options={productOptions}
                          defaultValue={defaultProductOption}
                          isSearchable
                          placeholder="Select Product"

                          onChange={(selectedOption) => {
                            let pro = productInfo.filter((d: any) => d.id == selectedOption.value).map((d: any) => ({
                              id: myId,
                              item: d.product_name,
                              qty: d.qty,
                              charge: d.sale_price,
                              product_id: d.id,
                              mrp: d.sale_price
                            }));
                            setMyId(myId + 1);
                            setTempService(pro[0]);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <div className="invalid-feedback">
                          {formik.touched.item_type && formik.errors.item_type ? (<div className="invalid-feedback">{formik.errors.item_type}</div>) : null}
                        </div></>
                      }

                    </div>
                    <div className={formik.values.item_type == "Service" ? "col-lg-1 col-md-1 col-sm-2 col-1" : "col-lg-1 col-sm-2 col-md-2 col-1"}></div>

                    {formik.values.item_type == "Service" ? <>
                      {/* <label
                        htmlFor="employee_id"
                        className="col-sm-2 col-2 col-lg-1 col-md-2 m-xl-0 m-lg-0  col-form-label"
                      >
                        Staff<span style={{ color: "red" }}>*</span>
                      </label> */}
                      <div className="col-sm-5 col-lg-4 col-md-5 col-5 align-items-center justify-content-between">
                        <Select
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          name="employee_id"
                          id="employee_id"
                          className={`react-select-container ${formik.touched.employee_id && formik.errors.employee_id ? "is-invalid" : ""}`}
                          classNamePrefix="select"
                          placeholder="Staff"
                          isLoading={employeeInfo.length <= 0 ? true : false}
                          // isClearable={true}
                          isSearchable={true}
                          options={employeeOptions}
                          value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || undefined}
                          onChange={(selectedOption) => { formik.setFieldValue("employee_id", selectedOption.value); setTempEmployee({ employee_id: selectedOption.value, employee_name: selectedOption.label }) }}
                          onBlur={formik.handleBlur}
                          filterOption={(option, inputValue) =>
                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                          }
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? '#80bdff' : state.menuIsOpen ? '#80bdff' : (formik.touched.employee_id && formik.errors.employee_id) ? '#dc3545' : '#ced4da',
                              boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.menuIsOpen ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.isValid ? null : 'none',
                            }),
                            menuPortal: base => ({ ...base, zIndex: 9999 })
                          }}
                          backspaceRemovesValue={false}
                        />
                        {formik.touched.employee_id && formik.errors.employee_id ? (
                          <div className="invalid-feedback">{formik.errors.employee_id}</div>
                        ) : null}
                      </div></> : <div></div>}

                  </div>
                  {/* service and product */}

                  {/* Add to cart button */}

                  <div className="mb-2 mb-xl-3 mb-md-3 mb-lg-3 mb-sm-3 row col-lg-9 col-md-12 col-sm-12 col-12 justify-content-center">
                    <div className="col-auto">
                      <span className="btn btn-purple btn-sm" onClick={addServiceToCart}>
                        <FontAwesomeIcon icon={faPlus} /> Add to bill
                      </span>
                    </div>
                  </div>

                  {/* selected service and product */}

                  {showTable && (<div className="mb-2 mb-xl-3 mb-md-3 mb-lg-3 mb-sm-3 row">

                    {/* <label htmlFor="" className="col-sm-3 col-form-label"></label> */}
                    <div className="col-sm-12 col-lg-9 col-md-11 col-12 rounded-1 ">
                      {/* <div className="row col-12 col-lg-12 col-md-12 col-sm-12 p-0">
                        <div className="col-6 col-lg-5 col-md-5 col-1 p-0 m-0 text-wrap">
                          <label className="col-form-label ps-2" > Selected {formik.values.item_type == "Service" ? "Service" : "Product"}:  </label>
                        </div> */}
                      <div className="col-12 col-lg-12 col-md-12 col-12 m-0 p-0 d-flex align-items-center justify-content-end d-none" >
                        <span className="p-0 m-0">Discount Type:</span>
                        <Select
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999, border: 0 }),
                            control: (base) => ({
                              ...base,
                              minHeight: '10px',
                              // width: '200px',
                              margin: '0'
                            }),
                            input: (base) => ({
                              ...base,
                              // height: '30px',
                              minHeight: '10px',

                            }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          className=" form-control-sm react-selcet-container"
                          classNamePrefix="select"
                          placeholder="select..."
                          isClearable={true}
                          isSearchable={true}
                          options={discountOptions}
                          defaultValue={defaultDiscountOption}
                          onChange={handleDiscountTypeChange}
                          escapeClearsValue={true}
                          backspaceRemovesValue={false}
                        />
                      </div>

                      {/* </div> */}
                      <div className="table-responsive" style={{ height: "" }}>
                        <table className="table table-bordered" id="selected-service-table">
                          <thead>
                            <tr>
                              <th>
                                {formik.values.item_type == "Service" ? "Service" : "Product"}
                              </th>
                              {formik.values.item_type == "Service" ? <th>
                                Staff
                              </th> : ""}
                              <th>
                                MRP
                              </th>
                              {formik.values.item_type == "Service" ?
                                "" : <th>Qty</th>}
                              {selectedDiscountType?.value == 'itemised' ? <><th>Discount(%)</th>
                                <th>
                                  Amount({admin.currency})
                                </th></> : <></>}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>

                            {(selectedServices.map((d: any) => (
                              <tr key={d.id}>
                                <td>{d.item}</td>
                                {formik.values.item_type === "Service" && <td>{d?.employee?.employee_name}</td>}
                                <td>
                                  <input style={{ "width": "100px" }}
                                    type="number"

                                    className="form-control shadow-sm d-inline text-center "
                                    name=""
                                    id=""
                                    // min={0}
                                    value={d?.mrp != null ? d?.mrp : 0}
                                    onChange={(event) => handleMrpChange(d, event)}
                                    onBlur={() => handleMrpBlur(d)}
                                  /></td>
                                {formik.values.item_type == "Service" ? (
                                  ""
                                ) : (
                                  <td>
                                    <input
                                      style={{ "width": "70px" }}
                                      type="number"
                                      className="form-control shadow-sm  d-inline "
                                      disabled={d.service_id}
                                      name=""
                                      id=""
                                      // min={1}
                                      value={d.qty === 0 ? "" : d.qty}
                                      onChange={(event) => handleQtyInputChange(d, event)}
                                      onBlur={(event) => handleQtyInputBlur(d, event)}
                                    />
                                  </td>
                                )}
                                {selectedDiscountType.value === 'itemised' ? <><td width={20}>
                                  <input style={{ "width": "70px" }}
                                    type="number"
                                    step="0.01"
                                    className="form-control shadow-sm d-inline "
                                    name={`discount_${d.id}`}
                                    id={`discount_${d.id}`}
                                    // min={0}
                                    max={100}
                                    value={d?.discount != null ? d?.discount : ''}
                                    onChange={(event) => handleDiscountChange(d, event)}
                                    disabled={selectedDiscountType?.value === 'full_amount'}
                                    onBlur={() => handleDiscountBlur(d)}
                                  />&nbsp;%
                                </td>
                                  <td width="100px">
                                    <input
                                      type="number"
                                      // step="0.01"
                                      className="form-control shadow-sm"
                                      name=""
                                      id=""
                                      // min={0}
                                      max={d?.mrp}
                                      value={d?.charge}
                                      onChange={(event) => handleDiscountedAmountChange(d, event)}
                                      onBlur={() => handleDiscountedAmountBlur(d)}

                                    />
                                  </td></> : ""}
                                <td>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => handleInputDelete(d)}
                                  />
                                </td>
                              </tr>
                            )))}

                          </tbody>
                          <tfoot className="align-self-end">
                            <tr>
                              <td colSpan={6} >
                                <div className="row col-lg-12">
                                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                                    <b>Total ({admin.currency || '₹'})</b>  - <input
                                      style={{ "width": "80px" }} type="text"
                                      className="form-control-sm"
                                      name=""
                                      id=""
                                      value={serviceTotal || 0}
                                      min={0}
                                      disabled
                                    /></div>
                                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                                    <b>Discount(&#8377;)</b> <input
                                      style={{ "width": "80px" }} type="number"
                                      // step="0.00"
                                      className="form-control-sm"

                                      value={overallDiscountAmount !== "" ? overallDiscountAmount : ""}
                                      onChange={handleOverallDiscountChange}
                                      // min={0}
                                      disabled={selectedDiscountType?.value === 'itemised'}
                                      onBlur={handleOverallDiscountBlur}
                                    /></div>
                                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                                    <b> Net(&#8377;)</b><span style={{ color: "red" }}>*</span> :&nbsp;
                                    <input
                                      style={{ "width": "80px" }} type="text"
                                      className="form-control-sm"
                                      name=""
                                      id=""
                                      value={net || 0}
                                      min={0}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </td>                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    {/* </div> */}

                  </div>)}

                  {/* Payment Mode */}
                  {showTable && (<div className="mb-2 mb-xl-3 mb-md-3 mb-lg-3 mb-sm-3 row">

                    <div className="col-sm-8 col-lg-9 col-md-10 col-12 d-flex">
                      {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6"> */}
                      <label className="col-form-label" htmlFor="cash_payment">
                        Cash(&#8377;):
                      </label>
                      <div className="col-sm-4 col-lg-3 col-md-3 col-3 p-1">
                        <input
                          type="number"
                          // step="0.01"
                          id="cash_payment"
                          name="cash_payment"
                          className={`form-control shadow-sm ${formik.touched.cash_payment && formik.errors.cash_payment ? "is-invalid" : ""}`}
                          onChange={handleCashPaymentChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.cash_payment}
                          onBlur={handleCashPaymentBlur}
                        // min="0"
                        />
                        <div className="invalid-feedback">
                          {formik.touched.cash_payment && typeof formik.errors.cash_payment === 'string' ? (formik.errors.cash_payment) : null}
                        </div>
                      </div>
                      &nbsp;

                      <label className="col-form-label" htmlFor="online_payment">
                        Online(&#8377;):
                      </label>
                      <div className="col-sm-4 col-lg-3 col-md-3 col-2 p-1">
                        <input
                          type="number"
                          // step="0.01"
                          id="online_payment"
                          name="online_payment"
                          className={`form-control shadow-sm ${formik.touched.online_payment && formik.errors.online_payment ? "is-invalid" : ""}`}
                          // onBlur={formik.handleBlur}
                          value={formik.values.online_payment}
                          // min="0"
                          onChange={handleOnlinePaymentChange}
                          onBlur={handleOnlinePaymentBlur}
                        />
                        <div className="invalid-feedback">
                          {formik.touched.online_payment && typeof formik.errors.online_payment === 'string' ? (formik.errors.online_payment) : null}
                        </div>
                      </div>
                      &nbsp;
                      <label htmlFor="due_amount" className="col-form-label">
                        Due(&#8377;)
                      </label>
                      <div className="col-sm-4 col-lg-3 col-md-3 col-3 p-1"><input
                        type="number"
                        step="0.01"
                        disabled
                        className={`form-control shadow-sm`}
                        value={due || 0}
                        min="0"
                      />
                        <div className="invalid-feedback"></div></div>
                    </div>
                  </div>)}

                  {/* Customer */}
                  {showTable && (<div className="mb-2 mb-xl-3 mb-md-3 mb-lg-3 mb-sm-3 row">
                    {/* <label htmlFor="customer_name" className="col-sm-3 col-form-label">Customer Details:</label> */}
                    <div className="col-sm-9 col-lg-9 col-md-8 col-12 d-flex ">
                      <label htmlFor="customer_name" className="col-form-label col-sm-3 col-md-2 col-xl-2 col-1" >
                        Name:
                      </label>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-5 col-xs-4 col-4">

                        <CreatableSelect
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          name="customer_name"
                          id="customer_name"
                          className="react-select-container"
                          classNamePrefix="select"
                          placeholder="Customer"
                          isClearable={true}
                          isSearchable={true}
                          options={customerOptions}
                          formatCreateLabel={(input) => <div><span>Add Customer: </span><span>{input}</span></div>}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? '#80bdff' : state.menuIsOpen ? '#80bdff' : (formik.touched.customer_name && formik.errors.customer_name) ? '#dc3545' : '#ced4da',
                              boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.menuIsOpen ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.isValid ? null : 'none',
                            }),
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={async (selectedOption, ActionTypes) => {
                            if (ActionTypes.action == "select-option") {
                              const url = `${urls.CUSTOMER_LIST_BY_SEARCH}/1/10`;
                              const data = {
                                nameChars: selectedOption.label
                              }
                              const res = await AuthApiService.SearchApiCallWithQuery(url, data, admin.token);
                              if (res.status == 200 || res.data.status == 200) {
                                const customer = res.data.data.find((e: any) => e.id == selectedOption.value)
                                formik.setFieldValue("customer_mobile", customer.mobile)
                                formik.setFieldValue("customer_id", customer.id);
                                formik.setFieldValue("customer_name", customer.name)
                              }
                            }
                            else if (ActionTypes.action == "clear") {
                              formik.setFieldValue("customer_mobile", "")
                              formik.setFieldValue("customer_id", "");
                              formik.setFieldValue("customer_name", "")
                            }
                            else {
                              formik.setFieldValue("customer_name", selectedOption.label)
                            }
                          }}
                          onBlur={formik.handleBlur}
                          backspaceRemovesValue={false}
                        />

                      </div>
                      <label
                        htmlFor="customer_mobile"
                        className="form-check col-form-label col-sm-2 col-md-4 col-xl-2 col-lg-2 col-2"
                      >
                        Mobile:
                      </label>
                      <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-xs-4 col-4">

                        <input
                          type="text"
                          placeholder="Eg. +91-XXXXX-XXXXX"
                          id="customer_mobile"
                          name="customer_mobile"
                          // className={`form-control shadow-sm  ${formik.touched.customer_mobile &&
                          //   formik.errors.customer_mobile
                          //   ? "is-invalid"
                          //   : ""
                          //   }`}
                          className={`form-control shadow-sm `}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.customer_mobile ?? null}
                        />
                        {/* <div className="invalid-feedback">
                          {formik.touched.customer_mobile &&
                            typeof formik.errors.customer_mobile === "string"
                            ? formik.errors.customer_mobile
                            : null}
                        </div> */}
                      </div>
                    </div>
                  </div>)}
                  {showTable && (<div className="row justify-content-start">
                    <div className="bottom-0 col-lg-2 col-md-2 col-sm-3">
                      <button type="submit" className="btn form-control btn-purple btn-md" disabled={loading}>
                        {loading ? 'Saving...' : 'Add'}
                      </button>
                    </div>
                  </div>)}
                </form>
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  );
}
