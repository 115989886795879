import { faArrowDown, faArrowUp, faEdit, faPlus, faSearch, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { listBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { Alert, Snackbar } from "@mui/material";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { CustomerValidationSchema } from "../../../validations/Validations";
import { getCustomerTransactionReducer, listCustomerReducer, updateCustomerReducer } from "../../../reduxData/customerSlice";

export default function ManagerCustomerList() {
  const _ = require("lodash")
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const branchServicesInfo = useSelector((state: any) => state.branchServicesInfo.value);
  const customerInfo = useSelector((state: any) => state.customerInfo.value);
  const auth = useSelector((state: any) => state.authUserInfo.value);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sort, setsort] = useState(false);
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [upLoading, setUpLoading] = useState(false);
  const navigate = useNavigate();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentDate = new Date().toISOString().substring(0, 10);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [branch, setBranch] = useState(auth.saloon_id);
  const [cid, setCid] = useState('');
  const sortByName = () => {
    setsort(!sort);
    const sortedCustomers = _.orderBy(customerInfo.data, [(item: any) => item.name.toLowerCase()], sort ? 'desc' : 'asc');
    dispatch(listCustomerReducer({ ...customerInfo, data: sortedCustomers }));
  };

  const totalRecords = customerInfo?.pagination?.totalRecords

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust the number of buttons you want to show

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, '...');
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push('...', totalPages);
      }
    }

    return pageNumbers;
  };

  const customerList = async (page: number, itemsPerPage: number) => {
    try {
      setLoading(true)
      const URL = `${urls.CUSTOMER_LIST}/${page}/${itemsPerPage}`;
      const res = await AuthApiService.GetApiCallWithPagination(URL, auth.token, page, itemsPerPage);
      if (res.status) {
        dispatch(listCustomerReducer(res.data));
      }
    } catch (error) {
      setMsg("Network Error !");
    } finally {
      setLoading(false)
    }

  };




  // const onSearch = async (searchInput: any, page: any, itemsPerPage: any) => {
  //   let url = `${urls.CUSTOMER_LIST_BY_SEARCH}/${page}/${itemsPerPage}`;
  //   let queryParams = {};

  //   if (queryParams) {

  //     if (searchInput) {

  //       if (/^\d+$/.test(searchInput)) {
  //         queryParams.mobileDigits = searchInput;
  //       } else {
  //         queryParams.nameChars = searchInput;
  //       }
  //     }

  //     try {
  //       setLoading(true);

  //       const queryString = new URLSearchParams(queryParams).toString();

  //       if (queryString) {
  //         url += `?${queryString}`;
  //       }
  //       const res = await AuthApiService.SearchApiCallWithQuery(url, {}, auth.token);
  //       if (res.status) {
  //         setLoading(false);
  //         dispatch(listCustomerReducer(res.data));
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       setMsg("Network Error !");
  //     }
  //   } else {
  //     await customerList(currentPage, itemsPerPage)
  //   }
  // };


  const onSearch = async (searchInput: string, page: number, itemsPerPage: number) => {
    try {
      setLoading(true);

      let queryParams: any = {};

      if (queryParams) {
        if (branch && branch !== "All") {
          queryParams.branch_id = branch;
        }
        if (searchInput.trim() !== '') {

          if (/^\d+$/.test(searchInput)) {
            queryParams.mobileDigits = searchInput;
          } else {

            queryParams.nameChars = searchInput;
          }
        }


        const queryString = new URLSearchParams(queryParams).toString();
        let url = `${urls.CUSTOMER_LIST_BY_SEARCH}/${page}/${itemsPerPage}`;
        if (queryString) {
          url += `?${queryString}`;
        }

        const res = await AuthApiService.SearchApiCallWithQuery(url, {}, auth.token);
        if (res.status) {
          setLoading(false);
          dispatch(listCustomerReducer(res.data));
        }
      } else {
        await customerList(currentPage, itemsPerPage)
      }
    } catch (error) {
      // console.error("Error occurred during search:", error);
      setLoading(false);
      setMsg("Network Error !");
    }

  };

  useEffect(() => {
    customerList(currentPage, itemsPerPage);
  }, [currentPage]);



  const handleChange = async (page: number) => {
    setCurrentPage(page);
    if (searchInput != '') {
      await onSearch(searchInput, page, itemsPerPage)
    }
    else {
      await customerList(page, itemsPerPage);
    }
  };



  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (data: any) => {
    return (<><th></th>
      {<tr><td className="text-wrap">
        <strong>Email:</strong> <span>{(data.email != '' && data.email != null) ? data.email : '-'}</span>
      </td></tr>}
    </>
    );
  };

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      branch_id: auth.saloon_id,
      isActive: true,
      created_by: auth.uid,
      updated_by: auth.uid
    },
    validationSchema: CustomerValidationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const email = values.email.trim();
        const ob: any = {
          name: values.name,
          email: email !== "" ? email : null,
          mobile: values.mobile,
          branch_id: auth.saloon_id,
          isActive: true,
          created_by: auth.uid,
          updated_by: auth.uid
        };

        const resp = await AuthApiService.SaveApiCall(urls.CUSTOMER_SAVE, ob, auth.token)
        if (resp.status) {
          handleClickOpen();
          setLoading(false);
          setMsg(resp.data.msg);
          setStatus(resp.data.status)
          if (resp.data.status == 201) {
            setTimeout(() => {
              handleAddModelClose()
              formik.resetForm()
              navigate("/branchManager/managerCustomerList")
              handleClose()

            }, 1000)
          } else {
            setTimeout(() => {
              navigate('/branchManager/managerCustomerList')
            }, 1000)
          }

        } else {
          if (resp.data && resp.data.err) {
            setMsg(resp.data.err);
            setStatus(resp.data.status)

          } else {
            setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
            setStatus(resp.data.status)
          }
        }
      } catch (error: any) {

        setMsg("Network Error !");
        setStatus(error.response.status);
        handleClickOpen()
      }
      finally {
        setLoading(false);
      }
    }
  },)

  const [showAddModal, setShowAddModal] = useState(false);
  const handleAddModelClose = () => { setShowAddModal(false); formik.resetForm() }
  const handleAddModelShow = () => { setShowAddModal(true); }

  const addModal = () => {
    return <>
      <Modal show={showAddModal} onHide={handleAddModelClose} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onFocus={() => setMsg("")}
            onSubmit={formik.handleSubmit}
          >
            <div className="mb-1 mb-xl-3 row">
              <label className="col-sm-3 col-form-label" htmlFor="name">
                Name<span style={{ color: "red" }}>*</span> :
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  placeholder="Eg. Amit Patel"
                  id="name"
                  name="name"
                  className={`form-control shadow-sm ${formik.errors.name ? "is-invalid" : ""}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <div className="invalid-feedback">{formik.touched.name && typeof formik.errors.name === 'string' ? (formik.errors.name) : null}</div>
              </div>
            </div>

            <div className="mb-1 mb-xl-3 row">
              <label className="col-sm-3 col-form-label" htmlFor="mobile">
                Mobile<span style={{ color: "red" }}>*</span> :
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  placeholder="Eg. +91-XXXXX-XXXXX"
                  id="mobile"
                  name="mobile"
                  className={`form-control shadow-sm ${formik.errors.mobile ? "is-invalid" : ""}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                />
                <div className="invalid-feedback">{formik.touched.mobile && typeof formik.errors.mobile === 'string' ? (formik.errors.mobile) : null}</div>
              </div>
            </div>
            <div className="mb-1 mb-xl-3 row">
              <label className="col-sm-3 col-form-label" htmlFor="email">
                Email:
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  placeholder="Eg. amitpatel@gmail.com"
                  id="email"
                  name="email"
                  className={`form-control shadow-sm ${formik.errors.email ? "is-invalid" : ""}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <div className="invalid-feedback">{formik.touched.email && typeof formik.errors.email === 'string' ? (formik.errors.email) : null}</div>
              </div>
            </div>
            <div className="row mt-4 justify-content-center">
              <div className="col-lg-2 col-md-2 col-sm-6">
                <button type="submit" className="btn  btn-purple btn-md text-center" disabled={loading}>
                  {loading ? 'Saving...' : 'Add'}
                </button>
              </div>
            </div>
            {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" disabled={loading ? true : false} onClick={handleAddModelClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewHistory = (data: any) => {
    localStorage.setItem("customerhistoryinfo", JSON.stringify(data));
    navigate("/branchManager/managerCustomerHistory");
    dispatch(getCustomerTransactionReducer(data));
  }


  const handleEdit = (data: any) => {
    dispatch(updateCustomerReducer(data));
    setCid(data.id)
    handleUpdateModalShow();

    formik.setValues({
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      branch_id: auth.saloon_id,
      isActive: data.isActive.toString(),
      created_by: data.created_by,
      updated_by: auth.updated_by
    });
    // const ra = incentiveEmployee.filter((data2) => data2.employee_id == emp.employee_id).map((el) => el.incentive_rule_id)
    // const rb = rulesOptions.filter((data) => !ra.includes(data.value));
    // setro(rb);
  };

  const handleEditSubmit = async (event: any) => {
    event.preventDefault()
    try {
      setUpLoading(true);

      const updatedData = {
        name: formik.values.name,
        email: formik.values.email,
        mobile: formik.values.mobile,
        isActive: formik.values.isActive === "true", // Convert string to boolean
        created_by: formik.values.created_by,
        updated_by: auth.uid,
      };

      // const up = {
      //     ...UpincentiveEmployee,
      //     ...updatedData
      // }
      const upURL = urls.CUSTOMER_UPDATE + cid;
      const resp = await AuthApiService.PutApiCall(upURL, updatedData, auth.token);

      if (resp.status) {
        setMsg(resp.data.msg);
        handleClickOpen();
        setStatus(resp.data.status)
        customerList(currentPage, itemsPerPage)
        handleUpdateModalClose();
        formik.resetForm()

      } else {

        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
        setStatus(resp.data.status)
      }
    } catch (error: any) {

      setMsg("Network Error !");
      setStatus(error.response.status);
      handleClickOpen()
    } finally {
      setUpLoading(false);
      setTimeout(() => {
        //setUpMsg('');
        // window.location.reload()

      }, 1000);
    }
  };
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const handleUpdateModalShow = () => setShowUpdateModal(true);

  const UpdateModal = () => {
    return <>
      <Modal show={showUpdateModal} onHide={handleUpdateModalClose} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleEditSubmit}
          >
            <div className="mb-1 mb-xl-3 row">
              <label className="col-sm-3 col-form-label" htmlFor="name">
                Name<span style={{ color: "red" }}>*</span> :
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  placeholder="Eg. Amit Patel"
                  id="name"
                  name="name"
                  className={`form-control shadow-sm ${formik.errors.name ? "is-invalid" : ""}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <div className="invalid-feedback">{formik.touched.name && typeof formik.errors.name === 'string' ? (formik.errors.name) : null}</div>
              </div>
            </div>

            <div className="mb-1 mb-xl-3 row">
              <label className="col-sm-3 col-form-label" htmlFor="mobile">
                Mobile<span style={{ color: "red" }}>*</span> :
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  placeholder="Eg. +91-XXXXX-XXXXX"
                  id="mobile"
                  name="mobile"
                  className={`form-control shadow-sm ${formik.errors.mobile ? "is-invalid" : ""}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                />
                <div className="invalid-feedback">{formik.touched.mobile && typeof formik.errors.mobile === 'string' ? (formik.errors.mobile) : null}</div>
              </div>
            </div>
            <div className="mb-1 mb-xl-3 row">
              <label className="col-sm-3 col-form-label" htmlFor="email">
                Email:
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  placeholder="Eg. amitpatel@gmail.com"
                  id="email"
                  name="email"
                  className={`form-control shadow-sm`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {/* <div className="invalid-feedback">{formik.touched.email && typeof formik.errors.email === 'string' ? (formik.errors.email) : null}</div> */}
              </div>
            </div>
            <div className="row mt-4 justify-content-center">
              <div className="col-lg-2 col-md-2 col-sm-6">
                <button type="submit" className="btn  btn-purple btn-md text-center" disabled={loading}>
                  {loading ? 'Updating...' : 'Update'}
                </button>
              </div>
            </div>
            {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" disabled={loading ? true : false} onClick={handleUpdateModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  }



  return <>
    {UpdateModal()}
    {addModal()}
    <div className="container-fluid pt-2">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
      </Snackbar>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-10">
            <div className="text-center">
              <h4>Customers</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
            {/* <Link to="/branchManager/addCustomer"> */}
            <button className="btn btn-sm btn-purple" onClick={handleAddModelShow}><FontAwesomeIcon icon={faPlus} />&nbsp;New Customer</button>
            {/* </Link> */}
          </div>
        </div>
        <div className="row mt-2 mt-xl-4 pb-4 p-1 align-items-end">
          <div className="col-lg-3 col-md-3 col-sm-3 ">
            <label>Search:</label>

            <div className="input-group input-group-sm">
              <input
                type="text"
                className="form-control shadow-sm"
                placeholder="Enter Name or Mobile"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value); // Update search input
                  onSearch(e.target.value, currentPage, itemsPerPage); // Trigger search on input change
                }}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-3">
            <div className="input-group input-group-sm">
              <button className="btn btn-sm btn-purple my-2 my-sm-0" onClick={() => navigate("/branchManager/managerCustomerDues")}>Due Report</button>
            </div>
          </div>

        </div>
      </div>
      {
        loading || customerInfo?.length <= 0 ? (
          <div className="mt-4">
            {loading ? (
              // Display loading spinner
              <div className="d-flex justify-content-center align-items-center">
                {loadingSpinner()}
              </div>
            ) : (
              // Display custom message for no records
              <h5>No Customer found.</h5>
            )}
          </div>
        ) :
          <div className="mt-2 mt-xl-4 mt-sm-2 justify-content-center">
            <div className={`table-container table-responsive`}>
              <table className="table table-striped table-hover border-light-subtle" >
                <thead>
                  <tr>
                    {!isMobile && <th>S.no</th>}
                    <th style={{ width: "5px" }}></th>
                    <th style={{ cursor: "pointer" }}
                      onClick={() => { sortByName() }}
                      className="text-center"
                    >
                      Name &nbsp;
                      <FontAwesomeIcon icon={faSort} cursor="pointer"
                        onClick={() => { sortByName() }}
                      />
                    </th>
                    <th className="text-center">Mobile</th>

                    {!isMobile && <th className="text-center">Email</th>}
                    <th>History</th>
                    {isMobile && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    customerInfo?.data?.map((data: any, index: any) => {
                      const isExpanded = expandedRowIndex === index;
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}

                            <td style={{ width: "5px" }}>
                              <FontAwesomeIcon cursor="pointer" icon={faEdit} className="fontIcon" onClick={() => { handleEdit(data) }} />

                            </td>
                            <td className="text-center">{data.name}</td>
                            {<td className="text-center">{data.mobile ? data.mobile : '-'}</td>}

                            {!isMobile && <td className="text-center">{data?.email ? data?.email : "-"}</td>}
                            <td><button className="btn btn-sm btn-purple" onClick={() => handleViewHistory(data)}>View</button></td>
                            {isMobile && (
                              <td className="text-center">
                                {!isExpanded ? (
                                  <button
                                    className="btn btn-link"
                                    onClick={() => toggleRowExpansion(index)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowDown}
                                      className="fontIcon"
                                      size="sm"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-link"
                                    onClick={() => toggleRowExpansion(index)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowUp}
                                      className="fontIcon"
                                      size="sm"
                                    />
                                  </button>
                                )}
                              </td>
                            )}
                          </tr>
                          {isExpanded && isMobile && (
                            <tr>
                              <td colSpan="5">{renderRowExpansionContent(data)}</td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link "
                      onClick={() => handleChange(currentPage - 1)}
                      disabled={currentPage === 1 || loading}
                    >
                      Previous
                    </button>
                  </li>
                  {generatePageNumbers().map((page, index) => (
                    <li
                      key={index}
                      className={`page-item ${page === currentPage ? 'active' : ''}`}
                    >
                      {page === '...' ? (
                        <span className="page-link">...</span>
                      ) : (
                        <button
                          className="page-link btn-sm"
                          onClick={() => handleChange(page)}
                          disabled={loading}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                  >
                    <button
                      className="page-link btn-sm"
                      onClick={() => handleChange(currentPage + 1)}
                      disabled={loading || currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
      }
    </div>
  </>
}