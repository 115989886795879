import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { incentiveRuleValidationSchema } from "../../../validations/Validations";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { addIncentiveRuleReducer, updateIncentiveRules } from "../../../reduxData/incentiveRuleSlice";
import { Alert, Snackbar } from "@mui/material";
interface FormValues {
    branch_id: number;
    rule_title: string;
    from_amount: number;
    to_amount: number;
    incentive_percent: number;
    description: string;
    created_by: number;
    updated_by: number;

}

export default function NewIncentiveRule() {

    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const branchList = useSelector((state: any) => state.branchInfo.value);
    const incentiveRules = useSelector((state: any) => state.incentiveRuleInfo.value)


    const formik = useFormik({
        initialValues: {
            from_amount: "",
            to_amount: "",
            incentive_percent: "",
            description: "",
            created_by: "",
            updated_by: "",
        },
        validationSchema: incentiveRuleValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob = {
                    saloon_id: admin.saloon_id,
                    from_amount: values.from_amount,
                    to_amount: values.to_amount,
                    incentive_percent: values.incentive_percent,
                    description: values.description,
                    created_by: admin.uid,
                    updated_by: admin.uid,
                };
                const resp = await AuthApiService.SaveApiCall(urls.INCENTIVE_RULE_SAVE, ob, admin.token);
                if (resp.status === 200) {
                    setMsg(resp.data.msg);
                    handleClickOpen();
                    setStatus(resp.data.status)
                    dispatch(addIncentiveRuleReducer(ob))

                    if (resp.data.status == 201) {
                        setTimeout(() => {
                            navigate('/saloonChain/incentiveRuleDetails')
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/NewincentiveRule')
                        }, 2000)
                    }

                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)

                    }
                }
            } catch (error: any) {
                setMsg(error.response.data);
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setLoading(false);
                // setTimeout(() => {
                //     formik.resetForm();

                // }, 5000);
            }
        },
    });
    const branchOptions = branchList.map((item: any) => ({
        value: item.id,
        label: item.branch_name
    }))

    const handleAutoPopulate = () => {
        let from = formik.values.from_amount;
        let to = formik.values.to_amount;
        let per = formik.values.incentive_percent;
        let autoGeneratedString = `Rule - From ${from} To ${to}, per - ${per}%`
        // console.log(typeof autoGeneratedString);

        formik.setFieldValue('description', autoGeneratedString);
    }

    useEffect(() => {

    }, [incentiveRules]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <>
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">
                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                        <div className="text-center">
                            <h4>New Incentive Rule</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/saloonChain/incentiveRuleDetails"><span><button className=" btn btn-purple btn-sm">All Rules</button></span></Link>
                    </div>
                </div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                <div className="container">
                    {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form
                                onFocus={() => setMsg("")}
                                onSubmit={formik.handleSubmit}>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="from_amount" className="col-sm-3 col-form-label">From Amount<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="number"
                                            className={`form-control shadow-sm ${formik.touched.from_amount && formik.errors.from_amount
                                                ? 'is-invalid'
                                                : ''
                                                }`}
                                            id="from_amount"
                                            placeholder="From Amount"
                                            name="from_amount"
                                            onChange={formik.handleChange}
                                            onBlur={(e) => { handleAutoPopulate(); formik.handleBlur(e) }}
                                            value={formik.values.from_amount}
                                        />
                                        {formik.touched.from_amount && formik.errors.from_amount ? (
                                            <div className="invalid-feedback">{formik.errors.from_amount}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="to_amount" className="col-sm-3 col-form-label">To Amount<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="number"
                                            className={`form-control shadow-sm ${formik.touched.to_amount && formik.errors.to_amount
                                                ? 'is-invalid'
                                                : ''
                                                }`}
                                            id="to_amount"
                                            placeholder="To Amount"
                                            name="to_amount"
                                            onChange={formik.handleChange}
                                            onBlur={(e) => { handleAutoPopulate(); formik.handleBlur(e) }}
                                            value={formik.values.to_amount}
                                        />
                                        {formik.touched.to_amount && formik.errors.to_amount ? (
                                            <div className="invalid-feedback">{formik.errors.to_amount}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="incentive_percent" className="col-sm-3 col-form-label">Percentage<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="number"
                                            className={`form-control shadow-sm ${formik.touched.incentive_percent && formik.errors.incentive_percent
                                                ? 'is-invalid'
                                                : ''
                                                }`}
                                            id="incentive_percent"
                                            placeholder="Enter Percentage"
                                            name="incentive_percent"
                                            onChange={formik.handleChange}
                                            onBlur={(e) => { handleAutoPopulate(); formik.handleBlur(e) }}
                                            value={formik.values.incentive_percent}
                                        // onMouseOut={handleAutoPopulate}
                                        />
                                        {formik.touched.incentive_percent && formik.errors.incentive_percent ? (
                                            <div className="invalid-feedback">{formik.errors.incentive_percent}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="description" className="col-sm-3 col-form-label">Description<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.description && formik.errors.description
                                                ? 'is-invalid'
                                                : ''
                                                }`}
                                            id="description"
                                            placeholder="Enter Description"
                                            name="description"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                            onFocus={handleAutoPopulate}
                                        />
                                        {formik.touched.description && formik.errors.description ? (
                                            <div className="invalid-feedback">{formik.errors.description}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mt-3 mb-xl-3 row text-center">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn form-control btn-md btn-purple" disabled={loading}>
                                            {loading ? 'Saving...' : 'Add'}
                                        </button>
                                    </div>
                                    <div className="col-lg-5"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    </>
}


