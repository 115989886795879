import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPlus, faSort } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listEmployeeLeaveReducer, updateEmployeeLeaveReducer } from "../../../reduxData/leaveSlice";
import Select from "react-select"

export default function Leaves() {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const _ = require("lodash");
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const leavelist = useSelector((state: any) => state.leaveInfo.value);
  const employeeInfo = useSelector((state: any) => state.employeeInfo.value);
  const [sort, setsort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const employeeOptions = [{ value: "All", label: "All" }, ...employeeInfo.map((item: any) => ({
    value: item.first_name,
    label: item.first_name + " " + item.last_name,
  }))];

  const [currentPage, setCurrentPage] = useState(1);
  const leavesPerPage = 100;
  const totalRecords = leavelist?.pagination?.totalRecords
  const totalPages = Math.ceil(totalRecords / leavesPerPage);

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust the number of buttons you want to show

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, '...');
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push('...', totalPages);
      }
    }

    return pageNumbers;
  };

  const leavelists = async (page: number, leavesPerPage: number) => {
    try {
      setLoading(true);
      const URL = `${urls.LEAVE_LIST}/${page}/${leavesPerPage}`;
      const res = await AuthApiService.GetApiCallWithPagination(URL, admin.token, page, leavesPerPage);
      if (res.status) {
        dispatch(listEmployeeLeaveReducer(res.data));
      }
    } catch (error) {
      setMsg("Network Error !");
    } finally {
      setLoading(false);
    }
  };


  const editLeave = (data: any) => {
    localStorage.setItem("leaveinfo", JSON.stringify({
      id: data?.id, employee_id: data?.employee_id, leave_type: data?.leave_type,
      leave_date: data?.leave_date?.substring(0, 10), reason: data?.reason
    }))
    dispatch(updateEmployeeLeaveReducer(data));
    navigate("/branchManager/editleave");
  }

  const handleChange = async (page: number) => {
    setCurrentPage(page);
    if (searchInput != '') {
      await onSearch(searchInput, page, leavesPerPage)
    }
    else {
      await leavelists(page, leavesPerPage);
    }
  };

  // const onSearch = async (data: any, page: number, leavesPerPage: number) => {
  //   if (data !== null && data.value != "All") {
  //     setLoading(true)
  //     const url = `${urls.LEAVE_BY_SEARCH}/${page}/${leavesPerPage}`;
  //     const ob = { nameChars: data.value }
  //     const res = await AuthApiService.SearchApiCallWithPagination(url, ob, admin.token, page, leavesPerPage);
  //     if (res.status) {
  //       setLoading(false)
  //       dispatch(listEmployeeLeaveReducer(res.data));
  //     }
  //   }
  //   else {
  //     await leavelists(page, leavesPerPage);
  //   }
  // };
  const onSearch = async (data: any, page: number, leavesPerPage: number) => {
    if (data !== null && data.value != "All") {
      setLoading(true);
      const url = `${urls.LEAVE_BY_SEARCH}/${page}/${leavesPerPage}?nameChars=${data.value}`;
      const res = await AuthApiService.SearchApi(url, admin.token);
      if (res.status) {
        setLoading(false);
        dispatch(listEmployeeLeaveReducer(res.data));
      }
    } else {
      await leavelists(page, leavesPerPage);
    }
  };

  useEffect(() => {
    leavelists(currentPage, leavesPerPage);
  }, [currentPage]);

  const sorting = (d) => {
    setsort(!sort)
    if (d == 'employee') {
      const data = sort ? _.orderBy(leavelist.data, (d) => d.employeeinfo.first_name, ['asc']) : _.orderBy(leavelist.data, (d) => d.employeeinfo.first_name, ['desc']);
      const leavelistInfo = { ...leavelist, data };
      dispatch(listEmployeeLeaveReducer(leavelistInfo));
    }
    else if (d == 'ltype') {
      const data = sort ? _.orderBy(leavelist.data, (d) => d.leave_type, ['asc']) : _.orderBy(leavelist.data, (d) => d.leave_type, ['desc']);
      const leavelistInfo = { ...leavelist, data };
      dispatch(listEmployeeLeaveReducer(leavelistInfo));
    }
    else if (d == 'ldate') {
      const data = sort ? _.orderBy(leavelist.data, (d) => d.leave_date, ['asc']) : _.orderBy(leavelist.data, (d) => d.leave_date, ['desc']);
      const leavelistInfo = { ...leavelist, data };
      dispatch(listEmployeeLeaveReducer(leavelistInfo));
    }
  }

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (data: any) => {
    return (<><th></th>

      {/* <tr> <td className="text-wrap">  <b>Branch&nbsp;</b><span>{data.employeeinfo?.branch_info?.branch_name}</span></td></tr> */}
      <tr> <td className="text-wrap">   <b>Leave Type:&nbsp;</b><span>{data.leave_type}</span></td></tr>

      <tr> <td className="text-wrap"><b>Reason:&nbsp;</b><span>{data.reason ? data.reason : '-'}</span></td></tr>

    </>


    );
  }


  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return <>
    <div className="container-fluid p-0">

      <div className="container">

        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-9 col-md-8 ">
            <div className="text-center">
              <h4>Employee's Leaves</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
            <Link to="/branchManager/newleave">
              <button className="btn btn-sm btn-purple">
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;New Leave
              </button>
            </Link>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-2 d-flex justify-content-end ">
            <Link to="/branchManager/managerLeaveReport">
              <button className="btn btn-sm btn-purple">Report</button>
            </Link>
          </div>
        </div>

      </div>
      <div className="row mt-2 mt-xl-4 pb-4 p-1">
        <div className="col-lg-3 col-md-3 col-sm-12 ">
          <label>Select Employee</label>

          <Select
            // type="text"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            className="basic-single"
            classNamePrefix="select"
            placeholder="All"
            isLoading={employeeInfo.length <= 0 ? true : false}
            isClearable={true}
            isSearchable={true}
            options={employeeOptions}
            // value={searchInput}
            onChange={(e: string) => { setSearchInput(e); setCurrentPage(1); onSearch(e, 1, leavesPerPage) }}
            escapeClearsValue={true}
            backspaceRemovesValue={false}
            filterOption={(option, inputValue) =>
              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
            }
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-1"></div>
      </div>

      {loading || leavelist?.data?.length === 0 ? (
        <div className="mt-4">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>) : (
            // Display custom message for no records
            <h5>No Leaves found.</h5>
          )}
        </div>
      ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
        <div className={`table-container ${isMobile ? 'table-fixed' : 'table-responsive'}`}>
          <table className="table table-striped  table-hover border-light-subtle ">
            <thead>
              <tr className="text-nowrap">
                {!isMobile && <th style={isMobile ? { width: 'auto' } : {}}>S.No</th>}
                <th> </th>
                <th style={{ cursor: "pointer" }} onClick={() => { sorting('employee') }}>Employee &nbsp;<FontAwesomeIcon icon={faSort} /></th>

                {!isMobile && <th style={{ cursor: "pointer" }} onClick={() => { sorting('ltype') }}>Leave Type &nbsp;<FontAwesomeIcon icon={faSort} /></th>}

                <th className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`} style={{ cursor: "pointer" }} onClick={() => { sorting('ldate') }}>Leave Date &nbsp;<FontAwesomeIcon icon={faSort} /></th>

                {!isMobile && <th>Reason</th>}
                {isMobile && <th></th>}
              </tr>


            </thead>
            <tbody className="text-nowrap">
              {leavelist?.data?.map((data: any, index: any) => {
                const isExpanded = expandedRowIndex === index; return (
                  <React.Fragment key={index}> <tr key={index}>
                    {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}

                    <td><FontAwesomeIcon onClick={() => { editLeave(data) }} cursor="pointer" icon={faEdit} className="fontIcon" />
                    </td>
                    <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>
                      {data?.employeeinfo?.first_name +
                        " " +
                        data?.employeeinfo?.last_name + " - " + data?.employeeinfo?.emp_id}
                    </td>
                    {!isMobile && <td>{data.leave_type}</td>}
                    <td>{data.leave_date.substring(0, 10).split('-').reverse().join('-')}</td>
                    {/* <td>{data.end_date.substring(0,10)}</td> */}
                    {!isMobile && <td>{data.reason ? data.reason : '-'}</td>}

                    {isMobile && (
                      <td>
                        {!isExpanded ? (
                          <button
                            className="btn btn-link "
                            onClick={() => toggleRowExpansion(index)}
                          >
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              className="fontIcon"
                              size="sm"
                            />
                          </button>
                        ) : (
                          <button
                            className="btn btn-link"
                            onClick={() => toggleRowExpansion(index)}
                          >
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              className="fontIcon"
                              size="sm"
                            />
                          </button>
                        )}</td>
                    )}
                  </tr>
                    {
                      isExpanded && isMobile && (
                        <tr>
                          <td colSpan="5">{renderRowExpansionContent(data)}</td>
                        </tr>
                      )
                    }
                  </React.Fragment>)
              }
              )}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link "
                  onClick={() => handleChange(currentPage - 1)}
                  disabled={currentPage === 1 || loading}
                >
                  Previous
                </button>
              </li>
              {generatePageNumbers().map((page, index) => (
                <li
                  key={index}
                  className={`page-item ${page === currentPage ? 'active' : ''}`}
                >
                  {page === '...' ? (
                    <span className="page-link">...</span>
                  ) : (
                    <button
                      className="page-link btn-sm"
                      onClick={() => handleChange(page)}
                      disabled={loading}
                    >
                      {page}
                    </button>
                  )}
                </li>
              ))}
              <li
                className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
              >
                <button
                  className="page-link btn-sm"
                  onClick={() => handleChange(currentPage + 1)}
                  disabled={loading || currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>}
    </div >
  </>
}



