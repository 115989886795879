import { faArrowDown, faArrowUp, faPlus, faSearch, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listTransactionReducer } from "../../../reduxData/transactionSlice";
import Select from "react-select"
import { listCustomerBillReducer } from "../../../reduxData/customerSlice";
export default function DailyTransactionsListForStaff() {
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const _ = require("lodash");
    const [sort, setsort] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showDetails, setShowDetails] = useState([]);
    const itemsPerPage = 100;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const employeeInfo = useSelector((state: any) => state.employeeInfo.value);
    let count = 0;
    const [selectedCustomer, setSelectedCustomer] = useState(false);


    const transactioninfo = useSelector((state: any) => state?.transactionInfo?.value);
    console.log("transs", transactioninfo)

    const employeeOptions = [{ value: "All", label: "All" }, ...employeeInfo.map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }))];


    const totalRecords = transactioninfo?.pagination?.totalRecords
    console.log("total records", totalRecords)


    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 3; // Adjust the number of buttons you want to show

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1, '...');
            }

            const start = Math.max(1, currentPage - 1);
            const end = Math.min(totalPages, currentPage + 1);

            for (let i = start; i <= end; i++) {
                pageNumbers.push(i);
            }

            if (rightEllipsis) {
                pageNumbers.push('...', totalPages);
            }
        }

        return pageNumbers;
    };

    const transactionlist = async (page: number, itemsPerPage: number) => {
        console.log(page, itemsPerPage, admin.token)
        try {
            setLoading(true)
            const URL = `${urls.TRANSACTION_LIST}/${page}/${itemsPerPage}`;
            const res = await AuthApiService.GetApiCallWithPagination(URL, admin.token, page, itemsPerPage);
            console.log("for staff response", res)

            if (res.status) {
                const d = dispatch(listTransactionReducer(res.data));
                // setLoading(false)
                console.log(d)
            }
        } catch (error) {
            setMsg("Network Error !");
        } finally {
            setLoading(false)
        }

    };

    useEffect(() => {
        transactionlist(currentPage, itemsPerPage);
    }, [currentPage]);


    const sorting = (d: any) => {
        setsort(!sort)
        if (d == 'employee') {
            const data = sort ? _.orderBy(transactioninfo.data, (d: any) => d.employee_info.first_name, ['asc']) : _.orderBy(transactioninfo.data, (d: any) => d.employee_info.first_name, ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'service') {
            const data = sort ? _.orderBy(transactioninfo.data, (d: any) => d.transactionItems.map((d: any) => d.item_type), ['asc']) : _.orderBy(transactioninfo.data, (d: any) => d.transactionItems.map((d: any) => d.item_type), ['desc']);
            console.log(data)
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'payment') {
            const data = sort ? _.orderBy(transactioninfo.data, (d: any) => d.payment_info.map((d: any) => d.payment_mode), ['asc']) : _.orderBy(transactioninfo.data, (d: any) => d.payment_info.map((d: any) => d.payment_mode), ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'tdate') {
            const data = sort ? _.orderBy(transactioninfo.data, (d: any) => d.createdAt, ['asc']) : _.orderBy(transactioninfo.data, (d: any) => d.createdAt, ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
    }


    const handleChange = async (page: number) => {
        setCurrentPage(page);
        await transactionlist(page, itemsPerPage);
    };



    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (data: any) => {
        const customerName = data?.customerinfo?.name || "-";
        return (<><th></th>

            {/* <tr> <td className="text-wrap">  <b>Qty:&nbsp;</b><span>{data?.transactionItems?.map((d: any) => <><span>{d.quantity}</span>,</>)}</span></td></tr>
            <tr><td><b>Amount({admin.currency}):&nbsp;</b>{data?.transactionItems?.map((d: any) => <><span>{d.mrp},</span></>)}</td></tr> */}

            {/* <tr> <td className="text-wrap"> <b>Remark:&nbsp;</b><span>{data.remark == "" ? "-" : data.remark}</span></td></tr> */}
            {/* <tr> <td className="text-wrap"><b>Cust.Name:&nbsp;</b><span>{isMobileNumber(customerName) ? "-" : data.customerName}</span></td></tr> */}
            {/* <tr> <td className="text-wrap"> <b>Cust.Email:&nbsp;</b><span>{data.cutomer_email == null ? "-" : data.cutomer_email}</span></td></tr> */}
            {/* <tr> <td className="text-wrap"> <b>Cust.Phone:&nbsp;</b><span>{data.customer_mobile == null ? "-" : data.customer_mobile}</span></td></tr> */}

        </>

        )
    }

    const handleShowBill = async (data: any) => {
        console.log("data", data)
        // const url = `${urls.TRANSACTION_GET}${data}`
        // const res = await AuthApiService.GetApiCall(url, admin.token);
        // console.log("handlbill", res, url)
        // if (res.status == 200 && res.data.status == 200) {
        // const ob = res.data.data;
        dispatch(listCustomerBillReducer(data));
        localStorage.setItem("billinfo", JSON.stringify(data))
        navigate("/staff/bill")

    }


    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const calculateDiscount = (amount: any, discountPercent: any) => {
        return (amount * discountPercent / 100).toFixed(2);
    };

    const calculateRate = (amount: any, discountPercent: any) => {
        return (amount - calculateDiscount(amount, discountPercent)).toFixed(2);
    };
    const isMobileNumber = (name: any) => /\d/.test(name);
    return <>
        <div className="container-fluid P-0 ml-0 ml-xl-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="text-center">
                            <h4>Daily Transactions</h4>
                        </div>
                    </div>

                </div>
            </div>


            {transactioninfo?.data?.length === 0 ? (
                <div className="mt-4">
                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>) : (
                        // Display custom message for no records
                        <h5>No Transactions found.</h5>
                    )}
                </div>
            ) :
                <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                    <div className={`table-container table-responsive`}>
                        <table className="table table-striped  table-hover border-light-subtle " id={isMobile ? 're-table' : 'report-table'}>
                            <thead>
                                <tr className="text-nowrap">
                                    {!isMobile && <th style={isMobile ? { width: 'auto' } : {}}>S.No</th>}
                                    <th className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`} style={{ cursor: "pointer" }} onClick={() => { sorting('tdate') }}>{!isMobile ? "Trans. Date " : "Date"}&nbsp;<FontAwesomeIcon icon={faSort} /></th>
                                    {/* {!isMobile && <th>Customer</th>} */}
                                    <th style={{ cursor: "pointer" }} onClick={() => { sorting('service') }}>Service &nbsp;<FontAwesomeIcon icon={faSort} /></th>
                                    {!isMobile && <th>Qty.</th>}
                                    <th className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>Amount ({admin.currency})</th>
                                    {/* {<th style={{ cursor: "pointer" }} onClick={() => { sorting('payment') }}>{!isMobile ? "Payment" : "Pmt."} &nbsp;<FontAwesomeIcon icon={faSort} /></th>} */}
                                    {/* <th style={{ cursor: "pointer" }} onClick={() => { sorting('employee') }}>{!isMobile ? "Employee" : "Emp."} &nbsp;<FontAwesomeIcon icon={faSort} /></th> */}
                                    {/* {!isMobile && <th>Remark</th>} */}
                                    {/* {!isMobile && <th>Customer Email</th>}
                                {!isMobile && <th>Customer Mobile</th>} */}
                                    {isMobile && <th></th>}
                                </tr>
                            </thead>
                            <tbody className="text-nowrap">
                                {transactioninfo?.data?.map((data: any, index: any) => {
                                    const customerName = data?.customerinfo?.name || "-";
                                    const isExpanded = expandedRowIndex === index; return (
                                        <React.Fragment key={index}><tr className="text-nowrap">
                                            {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}
                                            <td className="text-wrap">{new Date(data.transaction_date).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                timeZone: 'UTC'
                                            })}&nbsp;<br />
                                                {new Date(data.transaction_date).toLocaleTimeString('en-GB', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: false,
                                                    timeZone: 'UTC'
                                                })}<br />
                                                {/* <button className="btn btn-link" onClick={() => { handleShowBill(data) }}>Bill</button> */}
                                            </td>
                                            <td className="text-wrap">{data?.transactionItems?.map((d: any) => <><span>{d.service_type ?? d.product_name}</span>{isMobile && (-d.quantity)}<br /></>)}</td>
                                            {!isMobile && <td>{data?.transactionItems?.map((d: any) => <><span>{d.quantity}</span><br /></>)}</td>}
                                            <td>{data?.transactionItems?.map((d: any) => <><span>{calculateRate(d?.mrp, d?.discount_percent) * d?.quantity}</span><br /></>)}</td>

                                            {isMobile && (
                                                <td>
                                                    {!isExpanded ? (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowDown}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowUp}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    )}</td>
                                            )}
                                        </tr>
                                            {isExpanded && isMobile && (
                                                <tr>
                                                    <td colSpan={5}>{renderRowExpansionContent(data)}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                        // data?.transactionItems?.map((data2: any, index2: any) => {
                                        //     count++;
                                        //     return (

                                        //     )
                                        // })
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link "
                                        onClick={() => handleChange(currentPage - 1)}
                                        disabled={currentPage === 1 || loading}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {generatePageNumbers().map((page, index) => (
                                    <li
                                        key={index}
                                        className={`page-item ${page === currentPage ? 'active' : ''}`}
                                    >
                                        {page === '...' ? (
                                            <span className="page-link">...</span>
                                        ) : (
                                            <button
                                                className="page-link btn-sm"
                                                onClick={() => handleChange(Number(page))}
                                                disabled={loading}
                                            >
                                                {page}
                                            </button>
                                        )}
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                >
                                    <button
                                        className="page-link btn-sm"
                                        onClick={() => handleChange(currentPage + 1)}
                                        disabled={loading || currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            }
        </div >
    </>
}
