import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import Header from "../../Header";
import { faCodeBranch, faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { addDateReducer, updateTransactionReducer } from "../../../reduxData/transactionSlice";
import { listEmployeeReducer } from "../../../reduxData/employeeSlice";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useReactToPrint } from 'react-to-print';
export default function TransactionReport() {
  const empinfo = useSelector((state: any) => state.employeeInfo.value);
  const admin = useSelector((state: any) => state.authUserInfo.value);

  const _ = require("lodash")

  // const [b, setb] = useState([]);
  const [active, setactive] = useState(false);
  const [empName, setEmpName] = useState([]);
  const [flist, setflist] = useState([]);
  const [emp, setemp] = useState([]);
  const [empcash, setempcash] = useState([]);
  const [emponline, setemponline] = useState([]);
  const [emptotal, setemptotal] = useState([]);
  const [total, settotal] = useState(0);
  const [finalarr, setfinalarr]: any = useState([]);
  const [isLoading, setLoading] = useState(false);
  const currentDate = new Date().toISOString().substring(0, 10);
  const selectedDate = useSelector((state: any) => state.transactionInfo.transactionDate)
  const [branchinfo, setbranchinfo] = useState(selectedDate?.branchinfo ?? 1);
  const [toDate, setToDate] = useState(selectedDate?.toDate ?? currentDate);
  const [fromDate, setFromDate] = useState(selectedDate?.fromDate ?? currentDate);
  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const [bname, setbName] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(empcash, emponline, emptotal)
  const componentRef = useRef<any>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Transaction Report',
    onAfterPrint: () => console.log('Printed PDF successfully!'),
  });

  var filteredList = [];


  const getEmpData = async () => {
    const response = await AuthApiService.GetApiCall(urls.EMPLOYEE_LIST, admin.token);
    dispatch(listEmployeeReducer(response.data.data))
  }

  const loadingSpinner = () => {
    return <div className="container-fluid mt-5"><div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    </div>
  }

  var getDaysArray = function (s, e) {
    for (
      var a = [], d = new Date(s);
      d <= new Date(e);
      d.setDate(d.getDate() + 1)
    ) {
      a.push(new Date(d).toISOString().substring(0, 10));
    }
    return a;
  };

  var getData = (res: any, empInfoo: any, fD: any, tD: any) => {
    const tmp = _.orderBy(res.data?.employee_total_sum, (e: any) => e.emp_name.toLowerCase());
    setemp(tmp);
    const empInfo = _.orderBy(empInfoo, (e: any) => e.first_name.toLowerCase())
    settotal(res.data?.total_sum);
    var daysarr = [];
    daysarr = getDaysArray(fD, tD);
    var final = [];
    for (let days of daysarr) {
      var arr = [];
      arr.push(days.split('-').reverse().join('-'));
      for (let e of empInfo) {
        // console.log("tdeeeeeeeee", e)

        var found = false;
        for (let d of res.data.data) {
          for (let td of d.transaction_info) {
            // console.log()
            // console.log("td", td)
            if (td.emp_id == e.id && days == d.date) {
              found = true;
              arr.push(e.id + "/" + td.total_amount.toFixed(2));
              console.log(e.id + "/" + td.total_amount)
            }
          }
        }
        if (!found) {
          arr.push("-");
        }
      }
      console.log("arr", arr)
      final.push(arr);
    }
    console.log("finalarr", finalarr)
    setfinalarr(final);
    getEmpCashInfo(tmp, empInfo);
    getEmpOnlineInfo(tmp, empInfo);
    getEmpTotalInfo(tmp, empInfo);
  }

  function getEmpCashInfo(tmp: any, empInfo: any) {
    let p = [];
    for (let en of empInfo) {
      // console.log("getEmpCashInfo", en, empInfo)
      let found = false;
      for (let ep of tmp) {
        // console.log("ep", ep, tmp)

        if (ep.emp_id == en.id) {
          found = true;
          p.push(`${ep.cash_amount.toFixed(2)}`)
          break
        }
      }
      if (!found) {
        p.push('0')
      }
    }
    setempcash(p);
  }

  function getEmpOnlineInfo(tmp: any, empInfo: any) {
    let p = [];
    for (let en of empInfo) {
      let found = false;
      for (let ep of tmp) {
        if (ep.emp_id == en.id) {
          found = true;
          p.push(`${ep.online_amount.toFixed(2)}`)
          break
        }
      }
      if (!found) {
        p.push('0')
      }
    }
    setemponline(p);
  }

  function getEmpTotalInfo(tmp: any, empInfo: any) {
    let p = [];
    for (let en of empInfo) {
      let found = false;
      for (let ep of tmp) {
        if (ep.emp_id == en.id) {
          found = true;
          p.push(`${ep?.total_sum.toFixed(2)}`)
          break
        }
      }
      if (!found) {
        p.push('0')
      }
    }
    setemptotal(p);
  }

  var getInitialData = async (id = '') => {
    if (id != null) {
      setLoading(true);
      const url = urls.TRANSACTION_LIST_BY_BRANCH + id + "/" + currentDate + "/" + currentDate;
      const res = await AuthApiService.GetApiCall(url, admin.token);
      if (res.status === 200) {
        const empInfo = branchinfo !== "All" ? empinfo.filter((e: any) => e.branch_info.id === branchinfo) : empinfo;
        // const empInfo = empinfo.filter((e) => { return e.branch_info.id === id }).filter((data) => { return ((data.date_of_joining.substring(0, 10) >= fromDate || data.date_of_joining.substring(0, 10) <= fromDate) && (data.date_of_joining.substring(0, 10) <= toDate)) && ((data.date_of_relieving >= fromDate || data.date_of_relieving >= toDate) || (data.date_of_relieving == null)) })
        const filteredEmpInfo = empInfo.filter((employee: any) => {
          const joiningDate = new Date(employee.date_of_joining).getTime();
          const relievingDate = employee.date_of_relieving ? new Date(employee.date_of_relieving).getTime() : null;
          const selectedFromDate = new Date(fromDate).getTime();
          const selectedToDate = new Date(toDate).getTime();
          return (joiningDate <= selectedToDate && (!relievingDate || relievingDate >= selectedFromDate));
        });
        setEmpName(_.orderBy(filteredEmpInfo, (e) => e.first_name.toLowerCase(), ['asc']));
        getData(res, filteredEmpInfo, currentDate, currentDate)
      }
    }
    setLoading(false)
  }

  const handleInitialData = async () => {
    setLoading(true); // Set loading state to true
    const response = await AuthApiService.GetApiCall(urls.EMPLOYEE_LIST, admin.token);
    dispatch(listEmployeeReducer(response.data.data))
    if (response.status) {
      const url =
        branchinfo !== 1
          ? urls.TRANSACTION_LIST_BY_BRANCH + branchinfo + "/" + fromDate + "/" + toDate
          : urls.TRANSACTION_LIST_BY_BRANCH + 102 + "/" + fromDate + "/" + toDate;

      const res = await AuthApiService.GetApiCall(url, admin.token);
      if (res?.status === 200) {
        setbName(res?.data?.branchInfo?.branch_name)
        // const empInfo = branchinfo !== "All" ? empinfo.filter((e) => e.branch_info.id === branchinfo).filter((data: any) => { return ((data.date_of_joining.substring(0, 10) >= fromDate) && (data.date_of_joining.substring(0, 10) <= toDate)) && ((data.date_of_relieving >= fromDate || data.date_of_relieving >= toDate) || (data.date_of_relieving == null)) }) : empinfo;
        const empInfo = response.data.data.filter((e: any) => e.branch_info.id === branchinfo);
        const filteredEmpInfo = empInfo.filter((employee: any) => {
          const joiningDate = new Date(employee.date_of_joining).getTime();
          const relievingDate = employee.date_of_relieving ? new Date(employee.date_of_relieving).getTime() : null;
          const selectedFromDate = new Date(fromDate).getTime();
          const selectedToDate = new Date(toDate).getTime();
          return (joiningDate <= selectedToDate && (!relievingDate || relievingDate >= selectedFromDate));
        });
        // const empInfo = branchinfo !== "All" ? empinfo.filter((e) => { return e.branch_info.id === branchinfo.id }).filter((data) => { return ((data.date_of_joining.substring(0, 10) >= fromDate || data.date_of_joining.substring(0, 10) <= fromDate) && (data.date_of_joining.substring(0, 10) <= toDate)) && ((data.date_of_relieving >= fromDate || data.date_of_relieving >= toDate) || (data.date_of_relieving == null)) }) : empinfo;
        setEmpName(_.orderBy(filteredEmpInfo, (e) => e.first_name.toLowerCase(), ['asc']));
        getData(res, filteredEmpInfo, fromDate, toDate);
      }

    }
    setLoading(false);
  }

  useEffect(() => { handleInitialData() }, []);
  useEffect(() => {
    // Fetch the branch info
    const fetchBranchInfo = async () => {
      const response = await AuthApiService.GetApiCall(urls.BRANCH_LIST, admin.token);
      if (response.status === 200) {
        const branches = response.data.data;
        // Set the first branch as default
        setbranchinfo(branches.length > 0 ? branches[0].id : "All");
        dispatch(listEmployeeReducer(branches));
      }
    };
  
    fetchBranchInfo();
  }, []);
  

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();
  //   dispatch(addDateReducer({ fromDate: fromDate, toDate: toDate, branchinfo: branchinfo }))
  //   setLoading(true); // Set loading state to true
  //   const url =
  //     branchinfo !== 1
  //       ? urls.TRANSACTION_LIST_BY_BRANCH + branchinfo + "/" + fromDate + "/" + toDate
  //       : urls.TRANSACTION_LIST_BY_BRANCH + 101 + "/" + fromDate + "/" + toDate;

  //   const res = await AuthApiService.GetApiCall(url, admin.token);
  //   if (res?.status === 200) {
  //     setbName(res?.data?.branchInfo?.branch_name)
  //     // const empInfo = branchinfo !== "All" ? empinfo.filter((e) => e.branch_info.id === branchinfo).filter((data: any) => { return ((data.date_of_joining.substring(0, 10) >= fromDate) && (data.date_of_joining.substring(0, 10) <= toDate)) && ((data.date_of_relieving >= fromDate || data.date_of_relieving >= toDate) || (data.date_of_relieving == null)) }) : empinfo;
  //     const empInfo = empinfo.filter((e: any) => e.branch_info.id === branchinfo);
  //     const filteredEmpInfo = empInfo.filter((employee: any) => {
  //       const joiningDate = new Date(employee.date_of_joining).getTime();
  //       const relievingDate = employee.date_of_relieving ? new Date(employee.date_of_relieving).getTime() : null;
  //       const selectedFromDate = new Date(fromDate).getTime();
  //       const selectedToDate = new Date(toDate).getTime();
  //       return (joiningDate <= selectedToDate && (!relievingDate || relievingDate >= selectedFromDate));
  //     });
  //     // const empInfo = branchinfo !== "All" ? empinfo.filter((e) => { return e.branch_info.id === branchinfo.id }).filter((data) => { return ((data.date_of_joining.substring(0, 10) >= fromDate || data.date_of_joining.substring(0, 10) <= fromDate) && (data.date_of_joining.substring(0, 10) <= toDate)) && ((data.date_of_relieving >= fromDate || data.date_of_relieving >= toDate) || (data.date_of_relieving == null)) }) : empinfo;
  //     setEmpName(_.orderBy(filteredEmpInfo, (e) => e.first_name.toLowerCase(), ['asc']));
  //     getData(res, filteredEmpInfo, fromDate, toDate);
  //   }

  //   setLoading(false);
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
  
    // Validate branch selection
    if (branchinfo === "All" || branchinfo === "") {
      alert("Please select a branch.");
      return;
    }
  
    dispatch(addDateReducer({ fromDate: fromDate, toDate: toDate, branchinfo: branchinfo }));
    setLoading(true);
  
    const url =
      branchinfo !== 1
        ? urls.TRANSACTION_LIST_BY_BRANCH + branchinfo + "/" + fromDate + "/" + toDate
        : urls.TRANSACTION_LIST_BY_BRANCH + 101 + "/" + fromDate + "/" + toDate;
  
    const res = await AuthApiService.GetApiCall(url, admin.token);
    if (res?.status === 200) {
      setbName(res?.data?.branchInfo?.branch_name);
      const empInfo = empinfo.filter((e: any) => e.branch_info.id === branchinfo);
      const filteredEmpInfo = empInfo.filter((employee: any) => {
        const joiningDate = new Date(employee.date_of_joining).getTime();
        const relievingDate = employee.date_of_relieving ? new Date(employee.date_of_relieving).getTime() : null;
        const selectedFromDate = new Date(fromDate).getTime();
        const selectedToDate = new Date(toDate).getTime();
        return (joiningDate <= selectedToDate && (!relievingDate || relievingDate >= selectedFromDate));
      });
      setEmpName(_.orderBy(filteredEmpInfo, (e) => e.first_name.toLowerCase(), ['asc']));
      getData(res, filteredEmpInfo, fromDate, toDate);
    }
  
    setLoading(false);
  };
  


  const handleReportByDate = (newdata: any) => {
    const data = newdata.split('-').reverse().join('-')
    console.log(dispatch(updateTransactionReducer({ date: data, branchinfo })));
    navigate("/saloonChain/transactionReportByDate");
  }

  const handleEmployeeReport = (id: any, name: any, empid: any) => {
    dispatch(updateTransactionReducer({ id, fromDate, toDate, name, empid }));
    navigate("/saloonChain/transactionReportByEmployee")
  }

  const handleEmployeeAmountReport = (d: any, employeeId: any) => {
    // const dat = d.replaceAll("-", "-").split("/").reverse().join("/")
    dispatch(updateTransactionReducer({ d, fromDate, toDate, employeeId }));
    navigate("/saloonChain/transactionReportByAmount")
  }


  // const handlePrint = () => {
  //   window.print();
  // };



  const branchOptions = branchInfo.map((item: any) => ({
    value: item.id,
    label: item.branch_name,
  }))



  return (
    <>


      <div className="container-fluid p-0 p-xl-0 pr-xl-0 pl-xl-0  mt-sm-0 mt-0  mt-xl-0">

        <div className="container">

          <div className="row m-0 d-print-none">

            <div className="col-lg-10 col-md-10 col-sm-8 col-8 mt-2 d-flex justify-content-start">
              <Link to="/saloonChain/dailyTransaction">
                <span>
                  <button className=" btn btn-sm btn-purple">Back</button>
                </span>
              </Link>
            </div>
           
            <div className="col-lg-2 col-md-2 col-sm-4 col-4 mt-2 d-flex justify-content-end">
              <Link to="">
                <span>
                  <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
                </span>
              </Link>
            </div>

          </div>
          <div className="row m-0 mb-xl-3 d-print-none text-center">
            <h4>Transaction Report</h4>
          </div>
        </div>

        <div className="container m-0 m-xl-2 justify-content-center">
          <form
            className=""
            onSubmit={handleSubmit}
          ><div className="row m-0 p-0 d-print-none">
              <div className="col-lg-3 col-md-3 col-sm-3 col-6 m-0 ">
                <label>From Date:</label>

                <div className="input-group input-group-md">
                  <input
                    type="Date"
                    className="form-control shadow-sm"
                    value={fromDate}
                    onChange={(e) => { setFromDate(e.target.value) }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-6 m-0 p-0 mr-xl-2  ">
                <label>To Date:</label>

                <div className="input-group input-group-md">
                  <input
                    type="Date"
                    className="form-control shadow-sm"
                    min={fromDate}
                    value={toDate}
                    onChange={(e) => { setToDate(e.target.value) }}
                  />
                </div>
              </div>&nbsp;&nbsp;
              <div className="col-lg-3 col-md-3 col-sm-3 col-6 m-0 m-0 p-0 mr-xl-2  ">
                <label>Select Branch</label>

                {/* <Select
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  className="basic-single"
                  classNamePrefix="select"
                  value={{ value: branchinfo, label: branchInfo.find(item => item.id === branchinfo)?.branch_name || "Select Branch" }}
                  placeholder="Select Branch"
                  isLoading={branchInfo.length <= 0 ? true : false}
                  isClearable={true}
                  isSearchable={true}
                  options={branchOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setbranchinfo(selectedOption.value);
                    } else {
                      setbranchinfo("All");
                      setfinalarr([]);
                      setEmpName([]);
                      setempcash([]);
                      setemponline([]);
                      setemptotal([]);
                      settotal([]);
                    }
                  }}
                  required
                /> */}
<Select
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              className="basic-single"
              classNamePrefix="select"
              value={branchOptions.find(option => option.value === branchinfo) || null}
              placeholder="Select Branch"
              isLoading={branchInfo.length <= 0}
              isClearable={true}
              isSearchable={true}
              options={branchOptions}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setbranchinfo(selectedOption.value);
                } else {
                  // Reset branchinfo and related states when selection is cleared
                  setbranchinfo("All");
                  setfinalarr([]);
                  setEmpName([]);
                  setempcash([]);
                  setemponline([]);
                  setemptotal([]);
                  settotal([]);
                }
              }}
              required
            />
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2 col-2 m-0 ml-1 ml-xl-2">
                <label></label>
                <br />
                <button type="submit" className="btn btn-md btn-purple">
                  Submit
                </button>
              </div></div>
          </form>
        </div>
        <br />
        {isLoading ? loadingSpinner() :
          <div className="mt-1 justify-content-center" ref={componentRef}>
            <h4 className="text-center mb-3">{bname}</h4>
            <div className="table-responsive" style={{ maxHeight: "500px", overflowY: "auto" }}>
              <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
                <thead className="thead-light sticky-top">
                  <tr style={{ position: "sticky" }}>
                    {finalarr.length == 0 ? null : <th style={{ width: "200px" }}>Date</th>}
                    {empName?.map((data: any) => { return <th className="btn-Text" onClick={() => { handleEmployeeReport(data.id, data.first_name, data.emp_id) }} style={{ borderCollapse: "collapse", borderLeft: "0.5px solid rgba(0,0,0,0.1)", borderRight: "0.5px solid rgba(0,0,0,0.1)", cursor: "pointer" }}>{data.first_name}<br></br>{data.emp_id}</th> })}
                  </tr>
                </thead>

                <tbody>
                  {finalarr.map((da: any, rowIndex: any) => (
                    <tr key={rowIndex}>
                      {da.map((d: any, colIndex: any) => (
                        <td key={colIndex} style={{
                          cursor: d !== "-" ? "pointer" : "default",
                          textDecoration: d !== "-" ? "underline" : "none", // Remove underline

                        }} onClick={() => {
                          if (d !== "-") {
                            if (colIndex === 0) {
                              handleReportByDate(d);
                            } else {
                              const employeeId = d.split('/')[0];
                              handleEmployeeAmountReport(da[0], employeeId);
                            }
                          }

                        }
                        }
                          onMouseEnter={(e) => {
                            e.target.style.color = "blue"; // Change color to navy on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "black"; // Restore default color when not hovering
                          }}>
                          {colIndex === 0 ? d : (d !== "-" ? d.split('/')[1] : "-")}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                {emp.length !== 0 && <tfoot className="sticky-bottom">
                  
                  <tr>
                    {emp.length !== 0 && (
                      <td>
                        <b>Total</b>
                      </td>
                    )}
                    {emptotal.length != 0 && emptotal.map((data) => {
                      return <td className="btn-Total">
                        <b>{data}</b>
                      </td>
                    })}
                  </tr>
                </tfoot>
                }


              </table>
            </div>

            {total?.total_sum > 0 && <div className="table-responsive mb-3">
              <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
                <thead>
                  <tr><th>Cash Amount (INR)</th><th>Online Amount (INR)</th><th>Total Sum (INR)</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="btn-cash">{(total?.cash_amount).toFixed(2) ? (total?.cash_amount).toFixed(2) : '0'}</td>
                    <td className="btn-online"> {(total?.online_amount).toFixed(2) ? (total?.online_amount.toFixed(2)) : '0'}</td>
                    <td className="btn-Total">{(total?.total_sum).toFixed(2) ? (total?.total_sum).toFixed(2) : '0'}</td>
                  </tr>
                </tbody>
                <tfoot>
                </tfoot>
              </table>
            </div>}
          </div>}
      </div>

     
    </>
  );
}
