import { faArrowDown, faArrowUp, faEdit, faPlus, faSort, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { listExpenseReducer, updateExpenseReducer } from "../../../reduxData/expenseSlice";
import { Alert, Snackbar } from "@mui/material";
import Select from "react-select"
import { listExpenseHeadReducer } from "../../../reduxData/expenseHeadSlice";

export default function ExpensesList() {
  const _ = require("lodash")
  const authInfo = useSelector((state: any) => state.authUserInfo.value);
  const expenseInfo = useSelector(
    (state: any) => state.expenseInfo.value
  );
  const expenseHeadInfo = useSelector(
    (state: any) => state.expenseHeadInfo.value
  );
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const [statusLoading, setStatusLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const [msgs, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const currentDate = new Date().toISOString().substring(0, 10)
  const [fromDate, setFromDate] = useState(currentDate)
  const [toDate, setToDate] = useState(currentDate)
  const [isSearching, setSearching] = useState(false);
  const [branchSearchValue, setBranchSearchValue] = useState();
  const [expHeadSearchValue, setExpHeadSearchValue] = useState();
  const [sort, setsort] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const expensesHeadOptions = expenseHeadInfo?.data?.length > 0 ? [{ value: "All", label: "All" }, ...expenseHeadInfo?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.category
  }))] : [];

  const branchOptions = branchInfo && Array.isArray(branchInfo) ? [{ value: "All", label: "All" }, ...branchInfo?.map((item: any) => ({
    value: item?.id,
    label: item?.branch_name,
  }))] : [];

  useEffect(() => {
    if (branchInfo.length === 1) {
      const singleBranch = branchInfo[0];
      const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
      setSelectedBranch(singleBranchOption);
      //   onBranchSearch(singleBranchOption);
    }
  }, [branchInfo]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ExpenseHeadList = async () => {
    try {
      setLoading(true)
      const url = `${urls.ALL_EXPENSE_HEAD_LIST}`;
      const res = await AuthApiService.GetApiCall(
        url,
        authInfo.token,
      );
      dispatch(listExpenseHeadReducer(res.data));
    }
    catch (error) {
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally {
      setLoading(false)
    }
  };

  const GetExpenseHeadByBranch = async (data: any) => {
    try {
      setLoading(true)
      const url = `${urls.EXPENSE_HEAD_BY_BRANCH}/${data}`;
      const resp = await AuthApiService.GetApiCall(url, authInfo.token);
      dispatch(listExpenseHeadReducer(resp.data));
    }
    catch (error) {
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally {
      setLoading(false)
    }
  }

  const ExpensesList = async (page: number, itemsPerPage: number) => {
    try {
      setLoading(true)
      const url = `${urls.EXPENSE_LIST}/${page}/${itemsPerPage}`;
      const res = await AuthApiService.GetApiCallWithPagination(
        url,
        authInfo.token,
        page,
        itemsPerPage
      );
      dispatch(listExpenseReducer(res.data));
    }
    catch (error) {
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally {
      setLoading(false)
    }
  };

  const onSearch = async (page, itemsPerPage, fDate, tDate, branch_id, expense_head_id) => {
    try {
      setLoading(true)
      setSearching(true)
      const url = `${urls.EXPENSE_LIST_BY_DATE}/${fDate}/${tDate}/${page}/${itemsPerPage}`;
      const obj = {
        branch_id: branch_id,
        expense_head_id: expense_head_id
      }
      const resp = await AuthApiService.SearchApiCallWithQuery(url, obj, authInfo.token)
      dispatch(listExpenseReducer(resp.data));
    }
    catch (error) {
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally {
      setLoading(false)
    }
  }

  //--------------------------Pagination-Start------------------------------
  const totalRecords = expenseInfo?.pagination?.totalRecords;


  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust the number of buttons you want to show

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, "...");
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push("...", totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    if (isSearching) {
      await onSearch(page, itemsPerPage, fromDate, toDate, branchSearchValue, expHeadSearchValue)
    }
    // else
    // if (isDateSearching) {
    //     await handleDateSubmit(val, searchInput, page, itemsPerPage)
    // }
    // else if (val != undefined) {
    //     await onBranchSearch(val, page, itemsPerPage);
    // }
    else {
      await ExpensesList(page, itemsPerPage);
    }
  };
  //------------------------Pagination-End-----------------------------

  const Sort = (val) => {
    setsort(!sort);
    if (val == 'expenseHead') {
      const sortedList = _.orderBy(expenseInfo?.data, [(item: any) => item?.expenseHeadInfo?.category?.toLowerCase()], sort ? 'desc' : 'asc');
      dispatch(listExpenseReducer({ ...expenseInfo, data: sortedList }));
    }
    else if (val == 'expenseName') {
      const sortedList = _.orderBy(expenseInfo?.data, [(item: any) => item?.item_name?.toLowerCase()], sort ? 'desc' : 'asc');
      dispatch(listExpenseReducer({ ...expenseInfo, data: sortedList }));
    }
    else if (val == 'expenseDate') {
      const sortedList = _.orderBy(expenseInfo?.data, [(item: any) => item?.date], sort ? 'desc' : 'asc');
      dispatch(listExpenseReducer({ ...expenseInfo, data: sortedList }));
    }
    else if (val == 'branch') {
      const sortedList = _.orderBy(expenseInfo?.data, [(item: any) => item?.expenseHeadInfo?.branchinfo?.branch_name?.toLowerCase()], sort ? 'desc' : 'asc');
      dispatch(listExpenseReducer({ ...expenseInfo, data: sortedList }));
    }
  }

  const handleUpdateExpense = (data: any) => {
    localStorage.setItem("expenseinfo", JSON.stringify({
      id: data?.id, item_name: data?.item_name, expense_head_id: data?.expense_head_id,
      branch_id: data?.branch_id, invoice_no: data?.invoice_no, date: data?.date?.substring(0, 10), amount: data?.amount,
      description: data?.description
    }))
    dispatch(updateExpenseReducer(data))
    navigate("/saloonChain/editExpense");
  }

  const handleDeleteExpense = async (data: any) => {
    let confirmation = confirm(`Are you sure you want to delete?`);
    if (confirmation) {
      try {
        setStatusLoading(true);
        const url = `${urls.DELETE_EXPENSE}/${data.id}`;
        const resp = await AuthApiService.DelApiCall(url, authInfo.token)
        if (resp.data.status === 201 && resp.status === 200) {
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.data.status)
          await ExpensesList(currentPage, itemsPerPage)
        } else {
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.status)
        }
      }
      catch (error) {
        handleClickOpen()
        setMsg('Network Error !');
        setStatus(false);
      } finally {
        setStatusLoading(false)
      }
    } else {
      return
    }
  }


  // -------------------------LoadingSpinner-Start--------------------------------
  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };
  // -------------------------LoadingSpinner-End--------------------------------

  useEffect(() => {
    ExpensesList(currentPage, itemsPerPage)
    ExpenseHeadList()
  }, [])

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (item: any) => {
    return (<><th></th>
      {<tr><th className="text-wrap">
        Expense Head: <span style={{ fontWeight: "normal" }}>{item?.expenseHeadInfo?.category ? item?.expenseHeadInfo?.category : '-'}</span>
      </th></tr>}
      {<tr><th className="text-wrap">
        Branch: <span style={{ fontWeight: "normal" }}>{item?.expenseHeadInfo?.branchinfo?.branch_name ? item?.expenseHeadInfo?.branchinfo?.branch_name : '-'}</span>
      </th></tr>}
      {<tr><th className="text-wrap">
        Invoice No.: <span style={{ fontWeight: "normal" }}>{item?.invoice_no ? item?.invoice_no : '-'}</span>
      </th></tr>}
      {<tr><th className="text-wrap">
        Description: <span style={{ fontWeight: "normal" }}>{item?.description ? item?.description : '-'}</span>
      </th></tr>}</>
    );
  };

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return <>
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
    </Snackbar>
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-lg-10 col-md-10 col-sm-10">
          <div className="text-center">
            <h4>Expenses</h4>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
          <Link
            to="/saloonChain/newExpense">
            <button className="btn btn-sm btn-purple" ><FontAwesomeIcon icon={faPlus} />&nbsp;New Expense</button>
          </Link>
        </div>
      </div>
      <div className="row mt-2 mt-xl-4 pb-2 pb-xl-4 p-1 align-items-baseline">
        <div className="col-lg-2 col-md-2 col-sm-12">
          <label>Select Branch</label>
          <Select
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            className="basic-single"
            classNamePrefix="select"
            // defaultValue={"All"}
            placeholder="All"
            isLoading={branchInfo.length <= 0 ? true : false}
            // isClearable={true}
            isSearchable={true}
            options={branchOptions}
            onChange={(selectedOption: any) => {
              if (selectedOption == null || selectedOption.value == "All") {
                ExpenseHeadList();
                setCurrentPage(1);
                setBranchSearchValue(null)
                onSearch(1, itemsPerPage, fromDate, toDate, null, expHeadSearchValue);
                setSelectedBranch(selectedOption);
              }
              else {
                setCurrentPage(1);
                GetExpenseHeadByBranch(selectedOption.value)
                setBranchSearchValue(selectedOption.value)
                onSearch(1, itemsPerPage, fromDate, toDate, selectedOption.value, expHeadSearchValue);
                setSelectedBranch(selectedOption);
              }
            }}
            value={selectedBranch}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12">
          <label>Select Expense Head</label>
          <Select
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            id="expense_head_id"
            name="expense_head_id"
            className={`react-select-container`}
            options={expensesHeadOptions}
            isSearchable
            // defaultValue={"All"}
            placeholder="All"
            // isClearable={true}
            onChange={(selectedOption) => {
              if (selectedOption == null || selectedOption.value == "All") {
                setCurrentPage(1);
                setExpHeadSearchValue(null)
                onSearch(1, itemsPerPage, fromDate, toDate, branchSearchValue, null)
              }
              else {
                setCurrentPage(1);
                setExpHeadSearchValue(selectedOption.value)
                onSearch(1, itemsPerPage, fromDate, toDate, branchSearchValue, selectedOption.value)
              }
            }}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 col-sm-6 col-4 m-0 ">
          <label>From Date:</label>

          <div className="input-group input-group-md">
            <input
              type="date"
              className="form-control shadow-sm"
              placeholder="Search..."
              value={fromDate}
              max={currentDate}
              onChange={(event) => {
                if (new Date(event.target.value) > new Date(toDate)) {
                  setToDate(event.target.value); setFromDate(event.target.value);
                } else {
                  setFromDate(event.target.value)
                }
              }}
            /></div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-6 col-4 m-0 p-0 mr-xl-2 ">
          <label>To Date:</label>

          <div className="input-group input-group-md">
            <input
              type="date"
              className="form-control shadow-sm"
              placeholder="Search..."
              max={currentDate}
              min={fromDate}
              value={toDate}
              onChange={(event) => {
                setToDate(event.target.value);
              }}
            /></div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-6 col-4 m-0 ml-1 ml-xl-2 align-self-end">
          <button className="btn btn-purple" onClick={() => {
            onSearch(currentPage, itemsPerPage, fromDate, toDate, branchSearchValue, expHeadSearchValue); setSearching(true)
          }}>Submit</button>
        </div>
        {isSearching && <div className="col-lg-2 col-md-2 col-sm-12 align-self-end pt-2">
          <button className="btn btn-danger" onClick={() => { ExpensesList(1, itemsPerPage); setCurrentPage(1); setSearching(false); setFromDate(currentDate); setToDate(currentDate) }}>Reset</button>
        </div>}
      </div>

      {loading || expenseInfo?.data?.length <= 0 ? (
        <div className="mt-4">
          {loading ? (
            // Display loading spinner
            <div className="d-flex justify-content-center align-items-center">
              {loadingSpinner()}
            </div>
          ) : (isSearching ? <h5>No Expenses Found.</h5> :
            // Display custom message for no records
            <h5>No Expenses Found. You can add New Expense.</h5>
          )}
        </div>
      ) :
        <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
          <div className={`table-container table-responsive`}>
            <table className="table table-striped table-hover border-light-subtle">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-wrap">{isMobile ? "Exp. Date" : "Expense Date"}</th>
                  <th >Amount</th>
                  <th>Expense Name&nbsp;</th>
                  {!isMobile && <th >Expense Head</th>}
                  {!isMobile && <th >Branch</th>}
                  {!isMobile && <th >Invoice Number</th>}
                  {!isMobile && <th >Description</th>}
                  {isMobile && <th></th>}
                </tr>
              </thead>
              <tbody>
                {expenseInfo?.data?.map((data: any, index: any) => {
                  const isExpanded = expandedRowIndex === index;
                  return (<React.Fragment key={index}>
                    <tr>
                      <td><FontAwesomeIcon icon={faEdit} cursor="pointer" className="fontIcon" onClick={() => { handleUpdateExpense(data) }} />&nbsp;<FontAwesomeIcon icon={faTrash} cursor="pointer" className="fontIcon" onClick={() => handleDeleteExpense(data)} /></td>
                      <td>{new Date(data?.date).toLocaleString("en-GB", { dateStyle: "short" })}</td>
                      <td>{data?.amount}</td>
                      <td>{data?.item_name}</td>
                      {!isMobile && <td>{data?.expenseHeadInfo?.category}</td>}
                      {!isMobile && <td>{data?.expenseHeadInfo?.branchinfo?.branch_name}</td>}
                      {!isMobile && <td>{data?.invoice_no == "" || data?.invoice_no == null ? "-" : data?.invoice_no}</td>}
                      {!isMobile && <td>{data?.description == "" || data?.description == null ? "-" : data?.description}</td>}
                      {isMobile && (
                        <td>
                          {!isExpanded ? (
                            <button
                              className="btn btn-link"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="fontIcon"
                                size="sm"
                              />
                            </button>
                          ) : (
                            <button
                              className="btn btn-link"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUp}
                                className="fontIcon"
                                size="sm"
                              />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                    {isExpanded && isMobile && (
                      <tr>
                        <td colSpan="6">{renderRowExpansionContent(data)}</td>
                      </tr>
                    )}
                  </React.Fragment>)
                }
                )}
              </tbody>
            </table>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link "
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || loading}
                  >
                    Previous
                  </button>
                </li>
                {generatePageNumbers()?.map((page, index) => (
                  <li
                    key={index}
                    className={`page-item ${page === currentPage ? "active" : ""
                      }`}
                  >
                    {page === "..." ? (
                      <span className="page-link">...</span>
                    ) : (
                      <button
                        className="page-link btn-sm"
                        onClick={() => handlePageChange(page)}
                        disabled={loading}
                      >
                        {page}
                      </button>
                    )}
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link btn-sm"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={loading || currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      }
    </div>
  </>
}
