
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AdvancePaymentValidationSchema, editAdvancePaymentValidationSchema } from "../../../validations/Validations";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { addAdvancePaymentReducer, listAdvancePaymentReducer } from "../../../reduxData/advancePaymentSlice";
import { Alert, Snackbar } from "@mui/material";
import Select from "react-select";


export default function NewAdvancePaymentForAdmin() {
    const navigate = useNavigate();
    const currentDate = new Date().toISOString();
    const employee = useSelector((state: any) => state.employeeInfo.value.filter((data: any) => { return (data.is_active == true && data.date_of_joining <= currentDate && (data.date_of_relieving >= currentDate || data.date_of_relieving == null)) }));
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const auth = useSelector((state: any) => state.authUserInfo.value);
    const [msg, setMsg] = useState("");
    const [val, setval] = useState(null);

    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const [emp, setemp] = useState();

    // const employeeOptions = employee.map((item: any) => ({
    //     value: item.id,
    //     label: item.first_name + " " + item.last_name,
    // }));

    // const branchOptions = branchInfo.map((item: any) => ({
    //     value: item.id,
    //     label: item.branch_name,
    // }));

    const branchOptions = [{ value: "All", label: "All" }, ...branchInfo?.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }))];

    const employeeOptions = val != undefined && val?.label != "All" ? [{ value: "All", label: "All" }, ...employee.filter((item: any) => {
        return item.branch_info.id === val?.value;
    }).map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }))] : [{ value: "All", label: "All" }, ...employee?.map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }))];

    useEffect(() => {
        if (branchInfo.length === 1) {
            const singleBranch = branchInfo[0];
            formik.setFieldValue("branch_id", singleBranch.id);
        }
    }, [branchInfo]);


    const formik = useFormik({
        initialValues: {
            branch_id: "",
            employee_id: "",
            payment_date: new Date().toISOString().substring(0, 10),
            amount: "",
            reason: "",
            payment_mode: "Cash",
        },
        validationSchema: editAdvancePaymentValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob: any = {
                    employee_id: values.employee_id,
                    branch_id: values.branch_id,
                    payment_date: values.payment_date,
                    amount: values.amount,
                    reason: values.reason,
                    payment_mode: values.payment_mode,
                    created_by: auth.uid,
                    updated_by: auth.uid
                };

                const resp = await AuthApiService.SaveApiCall(urls.ADVANCED_PAYMENT_SAVE, ob, auth.token)
                if (resp.data.status === 201) {
                    setLoading(false);
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    dispatch(listAdvancePaymentReducer(ob))
                    formik.resetForm();
                    handleClickOpen();
                    setTimeout(() => {
                        navigate("/saloonChain/advancePayment")
                    }, 1000)
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)
                    }
                }
            } catch (error: any) {
                setLoading(false);
                setMsg("Network Error !");
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
            }
        }
    },)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">

                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                        <div className="text-center">
                            <h4>New Employee Advance Payment</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/saloonChain/advancePayment">
                            <span>
                                <button className=" btn btn-sm btn-purple">All Payment List</button>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 mt-xl-4 mt-0 justify-content-center">
                <div className="container">
                    {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form onFocus={() => setMsg("")} onSubmit={formik.handleSubmit}>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="branch_id" className="col-sm-3 col-form-label">Branch Name<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        {/* <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? 'is-invalid' : ''}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch Name"
                                            // onChange={(selectedOption) =>
                                            //     formik.setFieldValue("branch_id", selectedOption.value)
                                            // }
                                            onChange={(e: any) => { setval(e); formik.setFieldValue("branch_id", e.value) }}
                                             value={val}
                                            onBlur={formik.handleBlur}
                                            // value={
                                            //     branchOptions.find((option: any) => option.value === formik.values.branch_id) ||
                                            //     null
                                            // }
                                        /> */}
                                        <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? "is-invalid" : ""}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch"
                                            onChange={(selectedOption) => formik.setFieldValue("branch_id", selectedOption.value)}
                                            onBlur={formik.handleBlur}
                                            value={branchOptions.find((option: any) => option.isSelected || option.value === formik.values.branch_id)}
                                        />
                                        <div className="invalid-feedback">{formik.touched.branch_id && typeof formik.errors.branch_id === 'string' ? (formik.errors.branch_id) : null}</div>

                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="employee_id">
                                        Employee Name<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <Select
                                            name="employee_id"
                                            id="employee_id"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select Employee"
                                            isLoading={employee.length <= 0 ? true : false}
                                            // isClearable={true}
                                            isSearchable={true}
                                            options={employeeOptions}
                                            value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
                                            onChange={(selectedOption) => formik.setFieldValue("employee_id", selectedOption.value)}
                                            filterOption={(option, inputValue) =>
                                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                            }
                                            onBlur={formik.handleBlur}
                                            // escapeClearsValue={true}
                                            backspaceRemovesValue={false}
                                        />
                                        <div className="invalid-feedback">{formik.touched.employee_id && typeof formik.errors.employee_id === 'string' ? (formik.errors.employee_id) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="payment_date">
                                        Payment Date<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="date"
                                            placeholder="Enter Start Date"
                                            id="payment_date"
                                            name="payment_date"
                                            className={`form-control shadow-sm ${formik.touched.payment_date && formik.errors.payment_date ? 'is-invalid' : ''}`}
                                            min={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_joining.substring(0, 10))}
                                            max={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_relieving?.substring(0, 10) == null ? undefined : data.date_of_relieving?.substring(0, 10))}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.payment_date}
                                        // defaultValue={}
                                        />
                                        <div className="invalid-feedback">{formik.touched.payment_date && typeof formik.errors.payment_date === 'string' ? (formik.errors.payment_date) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="amount">
                                        Amount({auth.currency || '₹'})<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="number"
                                            placeholder="Enter Amount"
                                            id="amount"
                                            name="amount"
                                            min="0"
                                            className={`form-control shadow-sm ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.amount}
                                        />
                                        <div className="invalid-feedback">{formik.touched.amount && typeof formik.errors.amount === 'string' ? (formik.errors.amount) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="reason">
                                        Reason:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Reason"
                                            id="reason"
                                            name="reason"
                                            className={`form-control shadow-sm`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.reason}
                                        />
                                        {/* <div className="invalid-feedback">{formik.touched.reason && typeof formik.errors.reason === 'string' ? (formik.errors.reason) : null}</div> */}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-4 col-form-label">
                                        Payment Mode<span style={{ color: "red" }}>*</span> :
                                    </label>

                                    <div className="form-check col-sm-3 col-3">
                                        <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // value={formik.values.payment_mode}
                                            className="form-check-input"
                                            type="radio"
                                            name="payment_mode"
                                            id="flexRadioDefault1"
                                            value="Cash"
                                            checked={formik.values.payment_mode === "Cash"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            Cash
                                        </label>
                                    </div>
                                    <div className="form-check col-sm-3 col-3">
                                        <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // value={formik.values.payment_mode}
                                            className="form-check-input"
                                            type="radio"
                                            name="payment_mode"
                                            id="flexRadioDefault2"
                                            value="Online"
                                            checked={formik.values.payment_mode === "Online"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault2"
                                        >
                                            Online
                                        </label>
                                    </div>

                                </div>
                                <div className="mt-3 mb-xl-3 row text-center">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn btn-purple btn-md form-control" disabled={loading ? true : false}>
                                            {loading ? "Saving..." : "Add"}
                                        </button>
                                    </div>
                                    <div className="col-lg-5"></div>
                                </div>
                                {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
