import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';

export default function Profile() {
    const authUser = useSelector((state: any) => state.authUserInfo.value);
    const localInfo = JSON.parse(localStorage.getItem("authInfo"));
    const [formData, setFormData] = useState({
        firstName: 'Faheem',
        lastName: 'Mansuri',
        designation: 'Staff',
        saloonName: 'HAIRPORT MALE BARWANI',
        mobile: ''
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Process the form data here, e.g., send it to a server
        console.log('Form submitted:', formData);
    };

    return (
        <div className="login-page mt-3">
            <div className="container-fluid ">
                <div className="row w-100 h-100 mx-0">
                    <div className="col-lg-6 mx-auto">
                        <div className="card card-sm" style={{ width: '100%', maxWidth: '600px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-center mb-4">Profile</h5>
                                <form onSubmit={handleSubmit}>
                                    <div className="row mb-3 align-items-center">
                                        <div className="col-sm-3">
                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                name="firstName"
                                                value={authUser.firstname ?? localInfo.firstname}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3 align-items-center">
                                        <div className="col-sm-3">
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastName"
                                                name="lastName"
                                                value={authUser.lastname ?? localInfo.lastname}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3 align-items-center">
                                        <div className="col-sm-3">
                                            <label htmlFor="designation" className="form-label">Designation</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="designation"
                                                name="designation"
                                                value={authUser.roleName ?? localInfo.roleName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3 align-items-center">
                                        <div className="col-sm-3">
                                            <label htmlFor="saloonName" className="form-label">Saloon Name</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="saloonName"
                                                name="saloonName"
                                                value={authUser.saloonName ?? localInfo.firstname.saloonName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="row mb-3 align-items-center">
                            <div className="col-sm-3">
                                <label htmlFor="mobile" className="form-label">Mobile</label>
                            </div>
                            <div className="col-sm-9">
                                <input
                                    type="tel"
                                    className="form-control"
                                    id="mobile"
                                    name="mobile"
                                    value={authUser.mobile}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div> */}
                                    {/* <div className="d-flex justify-content-right">
                                        <button type="submit" className="btn btn-purple">Save</button>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    );
}
