
import { faCog, faCode, faClipboardList, faPowerOff, faHome, faLock, faUser, faCodeBranch, faUsers, faCogs, faGavel, faGift, faChartBar, faChartLine, faIndianRupee, faPeopleGroup, faPeopleLine, faShoppingBag, faMoneyBill, faMoneyBill1, faMoneyCheck, faMoneyBillTrendUp, faWallet, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { authDetailReducer } from "../reduxData/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';

export default function Sidebar() {

    const authUser = useSelector((state: any) => state.authUserInfo.value)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation();

    useEffect(() => {
        const isAuthenticated = localStorage.getItem("authInfo");
        if (!isAuthenticated && authUser.isLogin) {
            alert("Please login to continue.");

            navigate("/");

        }
    }, [authUser, navigate]);

    const logout = () => {
        dispatch(authDetailReducer({
            token: undefined,
            isLogin: false
        }))
        localStorage.removeItem("authInfo")
        localStorage.removeItem("token")
        localStorage.removeItem("loginData")
        localStorage.removeItem("empinfo")
        localStorage.removeItem("leaveReportInfo")
        localStorage.removeItem("transactioninfo")
        localStorage.removeItem("billinfo")
        localStorage.removeItem("bill")
        localStorage.removeItem("leaveinfo")
        localStorage.removeItem("serviceinfo")
        localStorage.removeItem("incentiveruleinfo")
        localStorage.removeItem("customerhistoryinfo")
        localStorage.removeItem("productinfo")

        navigate("/")

    }
    return <>
        <div className="offcanvas-md offcanvas-start d-print-none" style={{ "width": "250px" }} data-bs-scroll="true" tabIndex={-1} id="offcanvasResponsive" aria-labelledby="offcanvasResponsiveLabel" >

            <div className="offcanvas-header align-self m-0 m-xl-2 pt-3 pl-2 pt-xl-0 pt-xl-0 ">

                <div className="nav-link fontIcon">
                    <h6>{authUser.saloonName}</h6>
                </div>

                <div className="nav-link fontIcon">
                    <h6>{authUser.firstname}&nbsp;{authUser.lastname}</h6>
                </div>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close"></button>
            </div>

            {/* <div className="offcanvas-header align-self m-0 m-xl-2 pt-3 pl-2 pt-xl-0 pt-xl-0 d-flex flex-column position-relative">

                <div className="nav-link fontIcon">
                    <h6>{authUser.saloonName}</h6>

                </div>

                <div className="nav-link fontIcon d-flex align-items-start">
                    <h6>{authUser.firstname}&nbsp;{authUser.lastname}</h6>
                </div>
                <button type="button" className="btn-close align-self-end position-absolute top-0 end-0 mt-2 me-2" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close"></button>

            </div> */}
            <div className="offcanvas-body p-0 mt-0 ">

                <nav className="sidebar" >

                    <ul className="nav p-0 mt-4">
                        {authUser.isLogin ? <>
                            {authUser.roleValue == "SCM" ? <>
                                {/* scm menus */}
                                <li className={`nav-item ${pathname == '/saloonChain/dailyTransaction' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/saloonChain/dailyTransaction">
                                        <FontAwesomeIcon icon={faChartBar} className="sideIcon" />
                                        <span className="px-1 menu-title">Daily Transactions</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/advancePayment' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close" >
                                    <Link className="nav-link " to="/saloonChain/advancePayment">
                                        <FontAwesomeIcon icon={faChartLine} className="sideIcon" />
                                        <span className="px-1 menu-title">Employee Advance</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/branchDetails' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/saloonChain/branchDetails" >
                                        <FontAwesomeIcon icon={faCodeBranch} className="sideIcon" />
                                        <span className="px-1 menu-title">Branch</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/employeeDetails' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close" >
                                    <Link className="nav-link" to="/saloonChain/employeeDetails">
                                        <FontAwesomeIcon icon={faUsers} className="sideIcon" />
                                        <span className="px-1 menu-title">Employees</span>
                                    </Link>
                                </li>


                                <li className={`nav-item ${pathname == '/saloonChain/leaves' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link " to="/saloonChain/leaves">
                                        <FontAwesomeIcon icon={faClipboardList} className="sideIcon" />
                                        <span className="px-1 menu-title">Leaves</span>
                                    </Link>
                                </li>

                                <li className={`nav-item ${pathname == '/saloonChain/serviceDetails' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close" >
                                    <Link className="nav-link " to="/saloonChain/serviceDetails">
                                        <FontAwesomeIcon icon={faCogs} className="sideIcon" />
                                        <span className="px-1 menu-title">Services</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/productDetails' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link " to="/saloonChain/productDetails">
                                        <FontAwesomeIcon icon={faShoppingBag} className="sideIcon" />
                                        <span className="px-1 menu-title">Products</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/incentiveRuleDetails' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/saloonChain/incentiveRuleDetails">
                                        <FontAwesomeIcon icon={faGavel} className="sideIcon" />
                                        <span className="px-1 menu-title">Incentive Rules</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/incentiveEmployeeDetails' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link " to="/saloonChain/incentiveEmployeeDetails">
                                        <FontAwesomeIcon icon={faGift} className="sideIcon" />
                                        <span className="px-1 menu-title">Incentive Employees</span>
                                    </Link>
                                </li>


                                <li className={`nav-item ${pathname == '/saloonChain/customerList' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/saloonChain/customerList">
                                        <FontAwesomeIcon icon={faPeopleLine} className="sideIcon" />
                                        <span className="px-1 menu-title">Customers</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/expenseHeadList' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/saloonChain/expenseHeadList">
                                        <FontAwesomeIcon icon={faMoneyCheck} className="sideIcon" />
                                        <span className="px-1 menu-title">Expense Head</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/expenseList' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close" >
                                    <Link className="nav-link" to="/saloonChain/expenseList">
                                        <FontAwesomeIcon icon={faWallet} className="sideIcon" />
                                        <span className="px-1 menu-title">Expenses</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/salary' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/saloonChain/salary">
                                        <FontAwesomeIcon icon={faIndianRupee} className="sideIcon" />
                                        <span className="px-1 menu-title">Salary</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/saloonChain/balanceSheet' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/saloonChain/balanceSheet">
                                        <FontAwesomeIcon icon={faBalanceScale} className="sideIcon" />
                                        <span className="px-1 menu-title">Balance Sheet</span>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link " to="setting">
                                        <FontAwesomeIcon icon={faCog} className="sideIcon" />
                                        <span className="px-1 menu-title">Settings</span>
                                    </Link>
                                </li> */}

                                <hr />

                                {authUser.roleValue === "SCM" ? <>
                                    <li className={`nav-item ${pathname == '/saloonChain/setting' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                        <Link className="nav-link" to="/saloonChain/setting">
                                            <FontAwesomeIcon icon={faCog} className="sideIcon" /><span className="px-1 menu-title">Settings</span>
                                        </Link>
                                    </li>
                                    <li className={`nav-item ${pathname == '/saloonChain/changePassword' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                        <Link className="nav-link" to="/saloonChain/changePassword">
                                            <FontAwesomeIcon icon={faLock} className="sideIcon" /> <span className="px-1 menu-title">Change Password</span>
                                        </Link>
                                    </li>
                                    <li className={`nav-item mb-5`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                        <b className="nav-link " style={{ cursor: "pointer" }} onClick={logout} >
                                            <FontAwesomeIcon icon={faPowerOff} className="sideIcon" /> <span className="px-1 menu-title">Logout</span>
                                        </b>
                                    </li></> : <li className={`nav-item mb-5`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <b className="nav-link " style={{ cursor: "pointer" }} onClick={logout} >
                                        <FontAwesomeIcon icon={faPowerOff} className="sideIcon" /> <span className="px-1 menu-title">Logout</span>
                                    </b>
                                </li>}

                            </> : ""}
                            {/* brm menus */}
                            {authUser.roleValue == "BRM" ? <>
                                <li className={`nav-item ${pathname == '/branchManager/dailytransactionslist' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close" >
                                    <Link className="nav-link " to="/branchManager/dailytransactionslist">
                                        <FontAwesomeIcon icon={faIndianRupee} className="sideIcon" />
                                        <span className="px-1 menu-title">Daily Transactions</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/branchManager/managerAdvancePayment' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link " to="/branchManager/managerAdvancePayment">
                                        <FontAwesomeIcon icon={faChartBar} className="sideIcon" />
                                        <span className="px-1 menu-title">Employee Advance</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/branchManager/employeeslist' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close" >
                                    <Link className="nav-link " to="/branchManager/employeeslist">
                                        <FontAwesomeIcon icon={faPeopleGroup} className="sideIcon" />
                                        <span className="px-1 menu-title">Employees</span>
                                    </Link>
                                </li>


                                <li className={`nav-item ${pathname == '/branchManager/leavelist' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/branchManager/leavelist">
                                        <FontAwesomeIcon icon={faClipboardList} className="sideIcon" />
                                        <span className="px-1 menu-title">Leaves</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/branchManager/serviceslist' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link " to="/branchManager/serviceslist">
                                        <FontAwesomeIcon icon={faCogs} className="sideIcon" />
                                        <span className="px-1 menu-title">Services</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/branchManager/productDetails' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link " to="/branchManager/productDetails">
                                        <FontAwesomeIcon icon={faShoppingBag} className="sideIcon" />
                                        <span className="px-1 menu-title">Products</span>
                                    </Link>
                                </li>


                                <li className={`nav-item ${pathname == '/branchManager/managerCustomerList' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/branchManager/managerCustomerList">
                                        <FontAwesomeIcon icon={faPeopleLine} className="sideIcon" />
                                        <span className="px-1 menu-title">Customers</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/branchManager/managerExpenseHeadList' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/branchManager/managerExpenseHeadList">
                                        <FontAwesomeIcon icon={faMoneyCheck} className="sideIcon" />
                                        <span className="px-1 menu-title">Expense Head</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/branchManager/managerExpenseList' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="/branchManager/managerExpenseList">
                                        <FontAwesomeIcon icon={faWallet} className="sideIcon" />
                                        <span className="px-1 menu-title">Expenses</span>
                                    </Link>
                                </li>
                                <hr />
                                {authUser.roleValue === "SCM" ? <>
                                    <li className={`nav-item ${pathname == '/saloonChain/setting' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                        <Link className="nav-link" to="/branchManager/setting">
                                            <FontAwesomeIcon icon={faCog} className="sideIcon" /><span className="px-1 menu-title">Settings</span>
                                        </Link>
                                    </li>
                                    <li className={`nav-item ${pathname == '/saloonChain/changePassword' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                        <Link className="nav-link" to="/branchManager/changePassword">
                                            <FontAwesomeIcon icon={faLock} className="sideIcon" /> <span className="px-1 menu-title">Change Password</span>
                                        </Link>
                                    </li>
                                    <li className={`nav-item mb-5`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                        <b className="nav-link " style={{ cursor: "pointer" }} onClick={logout} >
                                            <FontAwesomeIcon icon={faPowerOff} className="sideIcon" /> <span className="px-1 menu-title">Logout</span>
                                        </b>
                                    </li></> : <></>
                                }
                                {authUser.roleValue === "BRM" ? <>
                                    <li className={`nav-item ${pathname == '/branchManager/changePassword' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                        <Link className="nav-link" to="/branchManager/changePassword">
                                            <FontAwesomeIcon icon={faLock} className="sideIcon" /> <span className="px-1 menu-title">Change Password</span>
                                        </Link>
                                    </li> <li className={`nav-item mb-5`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                        <b className="nav-link" style={{ cursor: "pointer" }} onClick={logout} >
                                            <FontAwesomeIcon icon={faPowerOff} className="sideIcon" /> <span className="px-1 menu-title">Logout</span>
                                        </b>
                                    </li></> : <></>}

                            </> : <></>}

                            {authUser.roleValue === "STF" ? <>
                                <li className={`nav-item ${pathname == '/staff/mytransactions' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close" >
                                    <Link className="nav-link " to="mytransactions">
                                        <FontAwesomeIcon icon={faIndianRupee} className="sideIcon" />
                                        <span className="px-1 menu-title">My Transactions</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/staff/myleaves' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="myleaves">
                                        <FontAwesomeIcon icon={faClipboardList} className="sideIcon" />
                                        <span className="px-1 menu-title">Leaves</span>
                                    </Link>
                                </li>
                                <li className={`nav-item ${pathname == '/staff/myadvanceamount' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="myadvanceamount">
                                        <FontAwesomeIcon icon={faChartBar} className="sideIcon" />
                                        <span className="px-1 menu-title">Advance Amount</span>
                                    </Link>
                                </li>
                                {/* <li className={`nav-item ${pathname == '/staff/myincentives' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="myincentives">
                                        <FontAwesomeIcon icon={faGift} className="sideIcon" />
                                        <span className="px-1 menu-title">Incentive Rules</span>
                                    </Link>
                                </li> */}
                                {/* <li className={`nav-item ${pathname == '/staff/salary' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="salary">
                                        <FontAwesomeIcon icon={faIndianRupee} className="sideIcon" />
                                        <span className="px-1 menu-title">Salary Slip</span>
                                    </Link>
                                </li> */}

                                <hr />
                                <li className={`nav-item ${pathname == '/staff/changePassword' && 'active'}`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <Link className="nav-link" to="changePassword">
                                        <FontAwesomeIcon icon={faLock} className="sideIcon" /> <span className="px-1 menu-title">Change Password</span>
                                    </Link>
                                </li> <li className={`nav-item mb-5`} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">
                                    <b className="nav-link" style={{ cursor: "pointer" }} onClick={logout} >
                                        <FontAwesomeIcon icon={faPowerOff} className="sideIcon" /> <span className="px-1 menu-title">Logout</span>
                                    </b>
                                </li>
                            </> : <></>


                            }


                        </>
                            :
                            <></>}
                    </ul>
                </nav>
            </div>


        </div>
    </>
}



