import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SalaryState {
    value: Salary[];
    upData: any; // Update this type based on your use case
    upData2:any;
}

interface Salary {

    // Add other properties as needed
}

const initialState: SalaryState = {
    value: [],
    upData: null,
    upData2:null,
};

const salaryInfo = createSlice({
    name: "salary",
    initialState,
    reducers: {

        listPreviewSalaryReducer:(state, action: PayloadAction<Salary[]>) => {
            console.log(action.payload)
            state.upData2 = action.payload;
        },

        listSalaryReducer: (state, action: PayloadAction<Salary[]>) => {
            console.log(action.payload)
            state.upData = action.payload;
        },
        GenSalaryReducer: (state, action: PayloadAction<Salary[]>) => {
            state.value = action.payload;
        },

    },
});

export const {  GenSalaryReducer,listSalaryReducer,listPreviewSalaryReducer } = salaryInfo.actions;
export default salaryInfo.reducer;
