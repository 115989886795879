import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function SalarySlip() {
    const admin = useSelector((state: any) => state.authUserInfo.value)

    const [totalEarnings, setTotalEarnings] = useState(0)
    const listSalaryInfo = useSelector((state: any) => state.salaryInfo.upData);
    const navigate = useNavigate();
    const location = useLocation();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const date = new Date(listSalaryInfo?.salary_date?.substring(5, 7)).getMonth();

    const handlePrint = () => {
        window.print();
    };

    return <>

        <div className="container-fluid p-0">
            <div className="container">

                <div className="row m-0 d-print-none">
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 "> */}
                    <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
                        <Link to="/saloonChain/salary" state={{ message: "saved", date: listSalaryInfo?.salary_date.substring(0, 7), branchid: listSalaryInfo?.branch_id }}>
                            <span>
                                <button className=" btn btn-sm btn-purple">Back</button>
                            </span>
                        </Link>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6 col-6">

                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-4 mt-2 d-flex justify-content-end">
                        <Link to="">
                            <span>
                                <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
                            </span>
                        </Link>
                    </div>
                    {/* </div> */}
                </div>
            </div>
            <div className="row d-print-block">
                <div className="col-lg-12 col-md-12 col-sm-12 m-0">
                    <div className="text-center">
                        <div className="brand-logo text-center">
                            <h4>{admin.saloonName}</h4>
                        </div>
                        <h5>Salary Slip for the month of {listSalaryInfo?.salary_date?.substring(0, 4)}-{months[date]}</h5>
                    </div>
                </div>
                {/* <div className="col-lg-2">
                    <Link to="/saloonChain/salary">
                        <span>
                            <button className=" btn btn-purple">Back</button>
                        </span>
                    </Link>
                </div> */}
            </div>
            <div className="mt-xl-5 mt-3 justify-content-center d-print-block">
                <div className="container "  >


                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12 col-xl-12 col-md-12">
                            <div className={`table-responsive`}>
                                <table className="table table-borderless table-responsive text-wrap">
                                    <tr>
                                        <th>Date:</th><td>{listSalaryInfo?.salary_date.substring(0, 10).split("-").reverse().join("-")}</td>

                                        <th>Joining Date:</th><td>{listSalaryInfo?.employeeinfo?.date_of_joining.substring(0, 10).split("-").reverse().join("-")}</td>
                                        <th>Total Working Days:</th><td>{listSalaryInfo?.otherDetails?.total_working_days ? listSalaryInfo?.otherDetails?.total_working_days : "0"}</td>


                                    </tr>
                                    <tr>
                                        <th>Employee Id/Name:</th>
                                        <td>{listSalaryInfo?.employeeinfo?.emp_id}/{listSalaryInfo?.employeeinfo?.first_name} {listSalaryInfo?.employeeinfo?.last_name}</td>
                                        <th>Branch Name: </th><td>{listSalaryInfo?.employeeinfo?.branch_info?.branch_name}</td>
                                        <th>Total Leaves: </th><td>{listSalaryInfo?.otherDetails?.emp_total_leave ? listSalaryInfo?.otherDetails?.emp_total_leave : "0"}</td>
                                    </tr>
                                    <tr>
                                        <th> Basic Salary :</th><td>{listSalaryInfo?.employeeinfo?.salary}</td>
                                        <th>Net Pay:</th><td> {listSalaryInfo?.otherDetails?.net_payable == null ? "0" : listSalaryInfo?.otherDetails?.net_payable}</td>
                                        <th>One Day Salary:</th><td>{listSalaryInfo?.otherDetails?.per_day_salary ? listSalaryInfo?.otherDetails?.per_day_salary : "0"}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* <div className="container">
                        <div className="row">
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>Date:</strong> {listSalaryInfo?.salary_date.substring(0, 10).split("-").reverse().join("-")}</p>
                            </div>
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>Date Of Joining:</strong> {listSalaryInfo?.employeeinfo?.date_of_joining.substring(0, 10).split("-").reverse().join("-")}</p>
                            </div>
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>Total Working Days:</strong> {listSalaryInfo?.otherDetails?.total_working_days ? listSalaryInfo?.otherDetails?.total_working_days : "0"}</p>
                            </div>
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>Employee Id/Name:</strong> {listSalaryInfo?.employeeinfo?.emp_id}/{listSalaryInfo?.employeeinfo?.first_name} {listSalaryInfo?.employeeinfo?.last_name}</p>
                            </div>
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>Branch Name:</strong> {listSalaryInfo?.employeeinfo?.branch_info?.branch_name}</p>
                            </div>
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>Total Leaves:</strong> {listSalaryInfo?.otherDetails?.emp_total_leave ? listSalaryInfo?.otherDetails?.emp_total_leave : "0"}</p>
                            </div>
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>Basic Salary:</strong> {listSalaryInfo?.employeeinfo?.salary}</p>
                            </div>
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>Net Pay:</strong> {listSalaryInfo?.otherDetails?.net_payable == null ? "0" : listSalaryInfo?.otherDetails?.net_payable}</p>
                            </div>
                            <div className="col-6 col-lg-6 col-sm-6 col-xl-3 col-md-6">
                                <p className="text-wrap"><strong>One Day Salary:</strong> {listSalaryInfo?.otherDetails?.per_day_salary ? listSalaryInfo?.otherDetails?.per_day_salary : "0"}</p>
                            </div>
                        </div>
                    </div> */}



                </div>
                <div className="container" >

                    <div className="row mt-1">
                        <div className="col-12 col-lg-4 col-sm-12 col-xl-4 col-md-4">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan={2} className="text-center">
                                            Deductions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            Previous Month Advance:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.previous_month_advance == null ? "0" : listSalaryInfo?.previous_month_advance}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            This Month Advance:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.advance == null ? "0" : listSalaryInfo?.advance}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Tax:
                                        </th>
                                        <td>
                                            0
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Others:
                                        </th>
                                        <td>
                                            <td>
                                                {listSalaryInfo?.otherDetails?.Others_amount == null ? "0" : listSalaryInfo?.otherDetails?.Others_amount}
                                            </td>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr className="table-secondary">
                                        <th className="table-secondary">
                                            Total:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.otherDetails?.total_deduction == null ? "0" : listSalaryInfo?.otherDetails?.total_deduction}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="col-12 col-xl-4 col-lg-4 col-sm-12 col-md-4">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan={2} className="text-center">
                                            Earnings
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            Basic Salary:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.otherDetails?.monthly_salary == null ? "0" : listSalaryInfo?.otherDetails?.monthly_salary}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Incentive:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.otherDetails?.incentiveAmount == null ? "0" : listSalaryInfo?.otherDetails?.incentiveAmount}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Compensation:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.otherDetails?.Compensation_amount == null ? "0" : listSalaryInfo?.otherDetails?.Compensation_amount}
                                        </td>
                                    </tr>

                                </tbody>
                                <tfoot>
                                    <tr className="table-secondary">
                                        <th>
                                            Total:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.otherDetails?.total_earning == null ? "0" : listSalaryInfo?.otherDetails?.total_earning}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div className="col-12 col-xl-4 col-lg-4 col-sm-12 col-md-4">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan={2} className="text-center">
                                            Net Salary
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            Total Earnings:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.otherDetails?.total_earning == null ? "0" : listSalaryInfo?.otherDetails?.total_earning}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Total Deductions:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.otherDetails?.total_deduction == null ? "0" : listSalaryInfo?.otherDetails?.total_deduction}
                                        </td>
                                    </tr>
                                    {/* <tr>
                                    <th>
                                        Basic:
                                    </th>
                                </tr> */}
                                </tbody>
                                <tfoot>
                                    <tr className="table-secondary">
                                        <th>
                                            Net Payable:
                                        </th>
                                        <td>
                                            {listSalaryInfo?.otherDetails?.net_payable == null ? "0" : listSalaryInfo?.otherDetails?.net_payable
                                            }
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    {/* <div className="row ">
                        <div className="col-12 col-lg-12 col-sm-12 col-md-12">
                            <b>Note:</b> Amount Carry Forwarded to next month <strong>{listSalaryInfo?.advance_carry == null ? "0" : listSalaryInfo?.advance_carry}</strong>
                        </div>
                    </div> */}
                </div>
            </div>
        </div >
    </>
}