import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { updateBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
import { serviceValidationSchema } from "../../../validations/Validations";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";

export default function EditService() {
    const branchServicesInfo = useSelector((state: any) => state.branchServicesInfo.upData);
    const localServicesInfo = JSON.parse(localStorage.getItem("serviceinfo"));
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const authInfo = useSelector((state: any) => state.authUserInfo.value);
    const branchServices = useSelector((state: any) => state.branchServicesInfo.value);
    const serviceMasterInfo = useSelector((state: any) => state.serviceMasterInfo.value);
  const serviceCategoryInfo = useSelector((state: any) => state.serviceCategoryInfo.value)
    // const dispatch = useDispatch();
    const [msgs, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    // console.log(branchServicesInfo, authInfo.token, branchInfo, branchServices, serviceMasterInfo);
    const [status, setStatus] = useState()
    const [open, setOpen] = useState(false);
    const [serviceId, setServiceId] = useState<number | null>(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [services,setServices] = useState();

    useEffect(()=>{
        setServices(serviceMasterInfo)
    },[serviceMasterInfo]);

    useEffect(() => {
        if (branchServicesInfo && branchServicesInfo.id) {
            setServiceId(branchServicesInfo.id);
        }
    }, [branchServicesInfo]);

    const formik = useFormik({
        initialValues: {
            branch_id: (branchServicesInfo?.branch_id??localServicesInfo.branch_id) || undefined,
            service_id: (branchServicesInfo?.service_id??localServicesInfo.service_id) || undefined,
            service_charge: (branchServicesInfo?.service_charge??localServicesInfo.service_charge) || 0,
            description: (branchServicesInfo?.description??localServicesInfo.description) || "",

        },
        validationSchema: serviceValidationSchema,
        onSubmit: async (values) => {
            try {

                setLoading(true);
                const upService = {
                    ...branchServicesInfo, ...values
                }
                const ob = {
                    branch_id: values.branch_id,
                    service_id: values.service_id,
                    service_charge: values.service_charge ? values.service_charge : 0,
                    description: values.description ? values.description : '',
                    created_by: branchServicesInfo?.created_by,
                    updated_by: authInfo.uid,
                };
                const url = `${urls.BRANCH_SERVICES_UPDATE}${serviceId??localServicesInfo?.id}`
                const resp = await AuthApiService.PutApiCall(url, upService, authInfo.token);
                if (resp.status === 200) {
                    handleClickOpen();
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    dispatch(updateBranchServicesReducer(upService))
                    dispatch(updateBranchServicesReducer(ob))
                    // formik.handleReset;
                    if (resp.data.status == 201) {
                        formik.resetForm()
                        setTimeout(() => {
                            navigate('/saloonChain/serviceDetails')
                            localStorage.removeItem("serviceinfo")
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/editService')
                        }, 2000)
                    }
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)
                    }
                }
            } catch (error: any) {
                if (error.response && error.response.status === 400 && error.response.data && error.response.data.msg) {
                    setMsg(error.response.data.msg);
                } else {
                    setMsg("Error: Conflict occurred.");
                }
                setStatus(false);
                handleClickOpen()
                // navigate('/saloonChain/newService')
            }
            finally {
                setTimeout(() => {
                    setLoading(false);
                    // navigate('/saloonChain/serviceDetails')

                }, 2000)
            }
        }
    });

    const branchOptions = branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }));

    const serviceOptions = services?.map((item: any) => ({
        value: item.id,
        label: item.service_title,
    }));

    const categoryOptions = [{value:'All',label:'All'},...serviceCategoryInfo?.map((item: any) => ({
        value: item.id,
        label: item.category_title,
    }))];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
        </Snackbar>
        <div className="container-fluid p-0">
            <div className="container">
                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                        <div className="text-center">
                            <h4>Update Service </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/saloonChain/serviceDetails"><span><button className=" btn btn-purple btn-md" onClick={()=>localStorage.removeItem("serviceinfo")}>All Services</button></span></Link></div>
                </div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                <div className="container">
                    {/* <b className={status ? "text-success" : "text-danger"}>{msgs}</b> */}
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form onSubmit={formik.handleSubmit}>

                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="branch_id" className="col-sm-3 col-form-label">Branch Name<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <Select
                                            isDisabled
                                            id="branch_id"
                                            name="branch_id"
                                            isLoading={branchInfo.length<1}
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? 'is-invalid' : ''}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch Name..."
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue("branch_id", selectedOption.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            value={
                                                branchOptions?.find((option: any) => option.value === formik.values.branch_id) ||
                                                null
                                            }
                                        />
                                        <div className="invalid-feedback">{formik.touched.branch_id && typeof formik.errors.branch_id === 'string' ? (formik.errors.branch_id) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="category_name" className="col-sm-3 col-form-label">Category Name :</label>
                                    <div className="col-sm-7">
                                        <Select
                                            id="category_name"
                                            name="category_name"
                                            className={`react-select-container`}
                                            options={categoryOptions}
                                            isSearchable
                                            placeholder="Select Category"
                                            onChange={(selectedOption) =>{
                                                if(selectedOption.value!='All'){
                                                    const newList = serviceMasterInfo?.filter(d=>d?.service_category==selectedOption.value);
                                                    setServices(newList);
                                                }
                                                else{
                                                    setServices(serviceMasterInfo);
                                                }
                                            }}
                                            onBlur={formik.handleBlur}
                                              />
                                        <div className="invalid-feedback">{formik.touched.service_id && typeof formik.errors.service_id === 'string' ? (formik.errors.service_id) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="service_id" className="col-sm-3 col-form-label">Service Name<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <Select
                                            id="service_id"
                                            name="service_id"
                                            isLoading={serviceMasterInfo.length<1}
                                            className={`react-select-container ${formik.touched.service_id && formik.errors.service_id ? 'is-invalid' : ''}`}
                                            options={serviceOptions}
                                            isSearchable
                                            placeholder="Select Service Name..."
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue("service_id", selectedOption.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            value={
                                                serviceOptions?.find((option: any) => option.value === formik.values.service_id) ||
                                                null
                                            }
                                        />
                                        <div className="invalid-feedback">{formik.touched.service_id && typeof formik.errors.service_id === 'string' ? (formik.errors.service_id) : null}</div>
                                    </div>

                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="service_charge" className="col-sm-3 col-form-label">Service Charge:</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="number"
                                            placeholder="Enter Charges"
                                            id="service_charge"
                                            name="service_charge"
                                            className={`form-control shadow-sm ${formik.touched.service_charge && formik.errors.service_charge ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.service_charge}
                                        />
                                        <div className="invalid-feedback">{formik.touched.service_charge && typeof formik.errors.service_charge === 'string' ? (formik.errors.service_charge) : null}</div>

                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="desc" className="col-sm-3 col-form-label">Description:</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Description"
                                            id="description"
                                            name="description"
                                            className={`form-control shadow-sm  ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                        />
                                        <div className="invalid-feedback">{formik.touched.description && typeof formik.errors.description === 'string' ? (formik.errors.description) : null}</div>

                                    </div>
                                </div>

                                <div className="mt-3 mb-xl-3 row text-center">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn form-control btn-md btn-purple" disabled={loading}>
                                            {loading ? "Updating..." : "Update"}
                                        </button>
                                    </div>
                                    <div className="col-lg-5"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    </>
}



