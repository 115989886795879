import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { addDateReducer, updateTransactionReducer } from "../../../reduxData/transactionSlice";
import { Link, useNavigate } from "react-router-dom";

export default function ManageDailyTransactionReport() {
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const empinfo = useSelector((state: any) => state.employeeInfo.value);
  const selectedDate = useSelector((state: any) => state.transactionInfo.transactionDate)
  const _ = require("lodash");
  const currentDate = new Date().toISOString().substring(0, 10)
  const [toDate, setToDate] = useState(selectedDate?.toDate ?? currentDate);
  const [fromDate, setFromDate] = useState(selectedDate?.fromDate ?? currentDate);


  const [empcash, setempcash] = useState([]);
  const [emponline, setemponline] = useState([]);
  const [emptotal, setemptotal] = useState([]);
  const [empName, setempName] = useState(empinfo);
  const [emp, setemp] = useState([]);
  const [total, settotal] = useState(0);
  const [finalarr, setfinalarr]: any = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [sameDate, setSameDate] = useState(false);


  const transactionReportByBranchlist = async (fd, td) => {
    setLoading(true)
    const url = urls.TRANSACTION_LIST_BY_BRANCH + branchInfo[0]?.id + "/" + fd + "/" + td;
    const res = await AuthApiService.GetApiCall(
      url,
      admin.token
    );
    if (res.status === 200) {
      var empInfo = empinfo.filter((data) => { return ((data.date_of_joining.substring(0, 10) >= fromDate || data.date_of_joining.substring(0, 10) <= fromDate) && (data.date_of_joining.substring(0, 10) <= toDate)) && ((data.date_of_relieving >= fromDate || data.date_of_relieving >= toDate) || (data.date_of_relieving == null)) })
      setempName(_.orderBy(empInfo, (e) => e.first_name.toLowerCase(), ['asc']));
      handleData(res, empInfo, fromDate, toDate)
      setLoading(false);
    }
  };

  const loadingSpinner = () => {
    return <div className="container-fluid mt-5"><div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    </div>
  }

  var getDaysArray = function (s, e) {
    for (
      var a = [], d = new Date(s);
      d <= new Date(e);
      d.setDate(d.getDate() + 1)) { a.push(new Date(d).toISOString().substring(0, 10)); } return a;
  };

  const handleData = (res: any, empInfoo: any, fD: any, tD: any) => {
    const tmp = _.orderBy(res.data.employee_total_sum, (e: any) => e.emp_name.toLowerCase(), ['asc']);
    setemp(tmp);
    const empInfo = _.orderBy(empInfoo, (e: any) => e.first_name.toLowerCase(), ['asc'])
    settotal(res.data.total_sum);
    var daysarr = []
    daysarr = getDaysArray(fD, tD)
    var final = [];
    for (let days of daysarr) {
      var arr = [];
      var newDays = days.split('-').reverse().join('-')
      arr.push(newDays)
      for (let e of empInfo) {
        var found = false;
        for (let d of res.data.data) {
          for (let td of d.transaction_info) {
            if (td.emp_id == e.id && days == d.date) {
              found = true;
              arr.push(e.id + "/" + td?.total_amount.toFixed(2));
            }
          }
        }
        if (!found) {
          arr.push("-");
        }
      }
      final.push(arr);
    }
    setfinalarr(final);
    getEmpCashInfo(tmp, empInfo);
    getEmpOnlineInfo(tmp, empInfo);
    getEmpTotalInfo(tmp, empInfo);
  }

  function getEmpCashInfo(tmp: any, empInfo: any) {
    let p = [];
    for (let en of empInfo) {
      let found = false;
      for (let ep of tmp) {
        if (ep.emp_id == en.id) {
          found = true;
          p.push(`${ep.cash_amount.toFixed(2)}`)
          break
        }
      }
      if (!found) {
        p.push('0')
      }
    }
    setempcash(p);
  }

  function getEmpOnlineInfo(tmp: any, empInfo: any) {
    let p = [];
    for (let en of empInfo) {
      let found = false;
      for (let ep of tmp) {
        if (ep.emp_id == en.id) {
          found = true;
          p.push(`${ep.online_amount.toFixed(2)}`)
          break
        }
      }
      if (!found) {
        p.push('0')
      }
    }
    setemponline(p);
  }

  function getEmpTotalInfo(tmp: any, empInfo: any) {
    let p = [];
    for (let en of empInfo) {
      let found = false;
      for (let ep of tmp) {
        if (ep.emp_id == en.id) {
          found = true;
          p.push(`${ep.total_sum.toFixed(2)}`)
          break
        }
      }
      if (!found) {
        p.push('0')
      }
    }
    setemptotal(p);
  }

  const handleReport = async () => {
    dispatch(addDateReducer({ fromDate, toDate }))
    setLoading(true)
    const fD = fromDate;
    const tD = toDate;
    var empInfo = empinfo.filter((data) => { return ((data.date_of_joining.substring(0, 10) >= fD || data.date_of_joining.substring(0, 10) <= fD) && (data.date_of_joining.substring(0, 10) <= tD)) && ((data.date_of_relieving >= fD || data.date_of_relieving >= tD) || (data.date_of_relieving == null)) })
    setempName(_.orderBy(empInfo, (e) => e.first_name.toLowerCase(), ['asc']));
    const url = urls.TRANSACTION_LIST_BY_BRANCH + branchInfo[0]?.id + "/" + fD + "/" + tD;
    const res = await AuthApiService.GetApiCall(url, admin.token);
    if (res.status === 200) {
      handleData(res, empInfo, fD, tD);
      setLoading(false);
    }
    setSameDate(fD === tD);
  };

  const handleClick = (e) => {
    e.preventDefault();
    handleReport()
  }

  const handleDateReport = (data: any) => {
    const date = data.split("-").reverse().join("-");
    dispatch(updateTransactionReducer(date));
    navigate("/branchManager/managerTransactionByDate");
  }

  const handleEmployeeReport = (id: any, name: any, empid: any) => {
    dispatch(updateTransactionReducer({ id, fromDate, toDate, name, empid }));
    navigate("/branchManager/managerTransactionByEmployee")
  }

  const handleEmployeeAmountReport = (d: any, employeeId: any) => {
    // const dat = d.replaceAll("-", "-").split("/").reverse().join("/")
    dispatch(updateTransactionReducer({ d, fromDate, toDate, employeeId }));
    navigate("/branchManager/managerTransactionByAmount")
  }

  useEffect(() => {
    // if(selectedDate!=null){
    transactionReportByBranchlist(fromDate, toDate);
    // }
    // else{
    //   transactionReportByBranchlist(selectedDate.fromDate,selectedDate.toDate)
    // }
  }, []);

  const handlePrint = () => {
    window.print();
  };


  return (
    <div className="container-fluid p-0">
      <div className="container">

        <div className="row m-0 d-print-none">
          {/* <div className="col-lg-12 col-md-12 col-sm-12 "> */}
          <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
            <Link to="/branchManager/dailytransactionslist">
              <span>
                <button className=" btn btn-sm btn-purple">Back</button>
              </span>
            </Link>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-6 col-6">

          </div>
          <div className="col-lg-2 col-md-2 col-sm-3 col-4 mt-2 d-flex justify-content-end">
            <Link to="">
              <span>
                <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
              </span>
            </Link>
          </div>
          {/* </div> */}
        </div>
      </div>
      <h4 className="text-center">Transaction Report</h4>

      <form className="d-print-none" onSubmit={handleClick}>
        <div className="row m-0 p-0 align-items-end">
          <div className="col-lg-3 col-md-3 col-sm-12 ">
            <label>From Date:</label>

            <div className="input-group input-group-sm">
              <input
                type="Date"
                className="form-control shadow-sm"
                placeholder="Search..."
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 ">
            <label>To Date:</label>

            <div className="input-group input-group-sm">
              <input
                type="Date"
                className="form-control shadow-sm"
                placeholder="Search..."
                value={toDate}
                min={fromDate}
                onChange={(e) => { setToDate(e.target.value) }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 justify-content-end mt-2 mt-xl-4">
            <label></label>

            <button type="submit" className="btn btn-sm btn-purple">
              Submit
            </button>
          </div>
        </div>
      </form>

      <br />

      {isLoading ? loadingSpinner() : <div className="mt-1 mt-xl-2 justify-content-center">
        <div className="table-responsive" style={{ maxHeight: "500px", overflowY: "auto" }}>
          <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
            <thead className="thead-light sticky-top">
              <tr style={{ position: "sticky" }}>
                {finalarr.length == 0 ? <></> : <th style={{ width: "8rem" }}>Date
                </th>}
                {finalarr.length > 0 && empName?.map((data: any) => { return <th className="btn-Text" onClick={() => { handleEmployeeReport(data.id, data.first_name, data.emp_id) }} style={{ borderCollapse: "collapse", borderLeft: "0.5px solid rgba(0,0,0,0.1)", borderRight: "0.5px solid rgba(0,0,0,0.1)" }}><span onClick={() => { }} hidden>{data.id}</span>{data.first_name}<br></br>{data.emp_id}</th> })}
              </tr>
            </thead>
            {/* <tbody>
              {finalarr.map((da) => { return <tr>{da.map((d) => { return <td style={{ cursor: d.toString().includes("-", 4) ? "pointer" : "default" }} onClick={(d.toString().includes("-", 4)) ? (() => { handleDateReport(d) }) : () => { }}>{d}</td> })}</tr> })}
            </tbody> */}

            <tbody>
              {finalarr.map((da: any, rowIndex: any) => (
                <tr key={rowIndex}>
                  {da.map((d: any, colIndex: any) => (
                    <td key={colIndex} style={{
                      cursor: d !== "-" ? "pointer" : "default",
                      textDecoration: d !== "-" ? "underline" : "none",

                    }} onClick={() => {
                      if (d !== "-") {
                        if (colIndex === 0) {
                          handleDateReport(d);
                        } else {
                          const employeeId = d.split('/')[0];
                          handleEmployeeAmountReport(da[0], employeeId);
                        }
                      }

                    }
                    }
                      onMouseEnter={(e) => {
                        e.target.style.color = "blue"; // Change color to navy on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = "black"; // Restore default color when not hovering
                      }}>
                      {colIndex === 0 ? d : (d !== "-" ? d.split('/')[1] : "-")}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            {sameDate && emp.length !== 0 && <tfoot className="sticky-bottom">
              {/* <tr>
                {emp.length !== 0 && (
                  <td >
                    <b >Cash Amount({admin.currency})</b>
                  </td>
                )}
                {empcash.length != 0 && empcash.map((data) => {
                  return <td className="btn-cash">
                    <b >{data}</b>
                  </td>
                })}
              </tr>
              <tr>
                {emp.length !== 0 && (
                  <td >
                    <b >Online Amount({admin.currency})</b>
                  </td>
                )}
                {emponline.length != 0 && emponline.map((data) => {
                  return <td className="btn-online">
                    <b >{data}</b>
                  </td>
                })}
              </tr> */}
              <tr>
                {emp.length !== 0 && (
                  <td >
                    <b>Total({admin.currency})</b>
                  </td>
                )}
                {emptotal.length != 0 && emptotal.map((data) => {
                  return <td className="btn-Total">
                    <b>{data}</b>
                  </td>
                })}
              </tr>
            </tfoot>}
          </table>
        </div>
      </div>}
      {sameDate && total?.total_sum > 0 && <div className="table-responsive mb-1 mb-xl-1">
        <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
          <thead>
            <tr><th >Cash Amount({admin.currency})</th><th>Online Amount({admin.currency})</th><th>Total Sum({admin.currency})</th></tr>
          </thead>
          <tbody>
            <tr>
              <td className="btn-cash">{(total?.cash_amount).toFixed(2) ? (total?.cash_amount).toFixed(2) : '0'}</td>
              <td className="btn-online"> {(total?.online_amount).toFixed(2) ? (total?.online_amount).toFixed(2) : '0'}</td>
              <td className="btn-Total">{(total?.total_sum).toFixed(2) ? (total?.total_sum).toFixed(2) : '0'}</td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>}
    </div>
  );
}
