import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { Link, useNavigate } from "react-router-dom";
import { updateTransactionReducer } from "../../../reduxData/transactionSlice";

export default function ManageDailyTransactionReportByEmployee() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const idInfo = useSelector((state: any) => state.transactionInfo.upData);
    const [isLoading, setLoading] = useState(false);
    const [empdata, setempdata] = useState();
    const currentDate = new Date().toISOString().substring(0, 10);
    const [toDate, setToDate] = useState(idInfo ? idInfo?.toDate : currentDate);
    const [fromDate, setFromDate] = useState(idInfo ? idInfo?.fromDate : currentDate);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [grandTotal, setGrandTotal] = useState("")
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const loadingSpinner = () => {
        return <div className="container-fluid mt-5"><div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
        </div>
    }

    const handleEmployeeReport = async () => {

        // if (idInfo == null) {
        //     navigate("/branchManager/managerTransactionReport")
        // }
        setLoading(true)
        const url = `${urls.TRANSACTION_LIST_BY_EMPLOYEE}${fromDate}/${toDate}/1/100`;
        const data = {
            employee_id: parseInt(idInfo.id, 10)
        }
        const res = await AuthApiService.SearchApiCallWithQuery(url, data, admin.token);

        if (res.status) {
            const total = res?.data?.grand_total
            setGrandTotal(total)
            setLoading(false);
            const finalarr = []
            res.data?.transactions?.forEach((data: any) => {
                const arr = [];
                finalarr.push({ date: data.date, total: data.total_sum.total_sum, total_online: data.total_sum.online_amount, total_cash: data.total_sum.cash_amount });
                // arr.push({data.total_sum.total_sum});
                // finalarr.push(arr);
            })
            setempdata(_.orderBy(finalarr, ['date']))
        }
    }


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await handleEmployeeReport();
    };

    useEffect(() => {
        if (idInfo) {
            handleEmployeeReport();
        }
    }, [idInfo]);

    useEffect(() => {
        handleEmployeeReport();
    }, [])

    const handlePrint = () => {
        window.print();
    };

    const formatDate = (dateString: any) => {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    }
    const handleEmployeeAmountReport = (da: any, employeeId: any) => {
        const d = formatDate(da);
        // const dat = d?.replaceAll("-", "-").split("/").reverse().join("/")
        dispatch(updateTransactionReducer({ d, employeeId }));
        navigate("/branchManager/managerTransactionByAmount")
    }

    return (
        <div className="container-fluid p-0 p-xl-0 pr-xl-0 pl-xl-0  mt-sm-0 mt-0  mt-xl-0">
            <div className="container">

                <div className="row m-0 d-print-none">

                    <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
                        <Link to="/branchManager/managerTransactionReport">
                            <span>
                                <button className=" btn btn-sm btn-purple">Back</button>
                            </span>
                        </Link>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6 col-6">

                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-4 mt-2 d-flex justify-content-end">
                        <Link to="">
                            <span>
                                <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
                            </span>
                        </Link>
                    </div>

                </div>

            </div>

            <div className="container m-0 m-xl-2 justify-content-center">
                <form onSubmit={handleFormSubmit}>
                    <div className="row m-0 p-0 d-print-none">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-6 m-0 ">
                            <label>From Date:</label>

                            <div className="input-group input-group-md">
                                <input
                                    type="date"
                                    className="form-control shadow-sm"
                                    value={fromDate}
                                    max={toDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-6 m-0 p-0 mr-xl-2  ">
                            <label>To Date:</label>

                            <div className="input-group input-group-md">
                                <input
                                    type="date"
                                    className="form-control shadow-sm"
                                    min={fromDate}
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                        </div>&nbsp;&nbsp;
                        {/* <div className="col-lg-3 col-md-3 col-sm-3 col-6 m-0 m-0 p-0 mr-xl-2  ">
              <label>Select Employee</label>

              <Select
                value={selectedEmployee}
                options={empdata.map(emp => ({ value: emp.id, label: emp.name }))}
                onChange={(selectedOption) => setSelectedEmployee(selectedOption)}
              />

            </div> */}

                        <div className="col-lg-2 col-md-2 col-sm-3 col-2 m-0 ml-1 ml-xl-2">
                            <label></label>
                            <br />
                            <button type="submit" className="btn btn-md btn-purple">
                                Submit
                            </button>
                        </div></div>
                </form>
            </div>
            {isLoading ? loadingSpinner() : <div className="container-fluid">
                <h5 className="text-center mb-1 m-xl-2 mt-2 mt-xl-2">Employee Id -  {idInfo?.empid}, Employee Name - {idInfo?.name} </h5>
                <h6 className="text-center mb-3">{idInfo?.fromDate?.replaceAll("-", "/").split("/").reverse().join("/")} - {idInfo?.toDate?.replaceAll("-", "/").split("/").reverse().join("/")}</h6>

                <div className="table-responsive">
                    <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
                        <thead>
                            <tr className="text-center">
                                <th>S.NO</th>

                                <th>Date</th>
                                {/* <th>Cash Amount({admin.currency})</th>
                                <th>Online Amount({admin.currency})</th> */}
                                <th>Total Amount({admin.currency})</th>
                            </tr>
                        </thead>
                        <tbody>
                            {empdata?.map((data: any, index: any) => {
                                return <tr className="text-center">
                                    <td>{index + 1}</td>
                                    <td className=" btn-link" onClick={() => handleEmployeeAmountReport(data.date, idInfo?.id)}>{data.date}</td>
                                    {/* <td>{data.total_cash}</td>
                                    <td>{data.total_online}</td> */}
                                    <td>{data.total?.toFixed(2)}</td>
                                </tr>
                            })}
                        </tbody>
                        <tfoot>
                            {/* <tr>
                                <td className="text-center btn-cash" colSpan="2">
                                    <b>Total Cash({admin.currency})</b>
                                </td>
                                {grandTotal?.length === 0 ? (
                                    <td colSpan="3">Loading...</td>
                                ) : (
                                    <td style={{ color: "blue", fontWeight: "bold" }} className="text-center" colSpan="6">{grandTotal?.cash_amount?.toFixed(2)}</td>
                                )}
                            </tr>
                            <tr>
                                <td className="text-center btn-online" colSpan="2">
                                    <b>Total Online({admin.currency})</b>
                                </td>
                                {grandTotal?.length === 0 ? (
                                    <td colSpan="5">Loading...</td>
                                ) : (
                                    <td style={{ color: "red", fontWeight: "bold" }} className="text-center" colSpan="2">{grandTotal?.online_amount?.toFixed(2)}</td>
                                )}
                            </tr> */}
                            <tr>
                                <td className="text-center btn-Total " colSpan="2">
                                    <b>Total Sum({admin.currency})</b>
                                </td>
                                {grandTotal?.length === 0 ? (
                                    <td colSpan="5">Loading...</td>
                                ) : (
                                    <td style={{ fontWeight: "bold" }} className="text-center" colSpan="2">{grandTotal?.total_sum?.toFixed(2)}</td>
                                )}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            }
        </div>
    )
}