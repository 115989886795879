// import React, { useRef, useState, FormEvent } from 'react';
// import { Link } from 'react-router-dom';
// import AuthApiService, { urls } from '../apiServices/AuthApiService';

// export default function Register() {
//   const [msg, setMsg] = useState('');

//   const nameBox = useRef<HTMLInputElement>(null);
//   const saloonNameBox = useRef<HTMLInputElement>(null);
//   const mobileBox = useRef<HTMLInputElement>(null);
//   const passwordBox = useRef<HTMLInputElement>(null);

//   const handleRegister = async (event: FormEvent) => {
//     event.preventDefault();
//     setMsg('');

//     const ob = {
//       name: nameBox.current?.value,
//       saloon_name: saloonNameBox.current?.value,
//       password: passwordBox.current?.value,
//       mobile_number: mobileBox.current?.value,
//     };

//     try {
//       const response = await AuthApiService.PostApiCall(urls.SELF_SALOON_SIGNUP, ob);

//       if (response.data.status) {
//         console.log(response);
//         setMsg(response.data.msg);
//       } else {
//         console.log(response);
//         setMsg(response.data.msg);
//       }
//     } catch (error) {
//       const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
//       setMsg(errorMessage);
//     }
//   };

//   return (
//     <div className="login-page">
//       <div className="container-fluid ">
//         <div className="row w-100  mx-0">
//           <div className="col-lg-4 mx-auto">
//             <div className="card border-0 py-5 px-4 px-sm-5 mt-5">
//               <div className="brand-logo text-center">
//                 {/* <h2>HAIRSALOON</h2> */}
//                 <Link className="navbar-brand brand-logo " to=""><img src="images/Saloonist_Logo/newLogo.png" className="img-fluid" alt="logo" /></Link>
//               </div>
//               <p className="font-weight-light text-center">Sign up here...</p>
//               <form onSubmit={handleRegister} className="pt-2">
//                 <div className="form-group mb-3">
//                   <input type="text" ref={nameBox} className="form-control" id="userName" placeholder="Your Name" />
//                 </div>
//                 <div className="form-group mb-3">
//                   <input type="text" ref={saloonNameBox} className="form-control" id="lastName" placeholder="Saloon Name" />
//                 </div>
//                 <div className="form-group mb-3">
//                   <input type="text" ref={mobileBox} className="form-control " id="mobile" placeholder="Mobile" />
//                 </div>
//                 {/* <div className="form-group mb-3">
//                   <input type="email" ref={emailBox} className="form-control" id="email" placeholder="Email" />
//                 </div> */}
//                 <div className="form-group mb-3">
//                   <input type="password" ref={passwordBox} className="form-control " id="password" placeholder="Password" />
//                 </div>

//                 <div className="mt-3">
//                   <button type="submit" className="btn btn-purple form-control">
//                     SIGN UP
//                   </button>
//                 </div>
//                 <div className="text-center mt-2 font-weight-light">
//                   Already have an account? <Link to="/login" className="text-primary">Login</Link>
//                 </div>
//                 <b className="text-danger">{msg}</b>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };


// import React, { useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import AuthApiService, { urls } from '../apiServices/AuthApiService';
// import { Alert, Snackbar } from '@mui/material';

// const RegisterSchema = Yup.object().shape({
//   name: Yup.string().required('Your Name is required'),
//   saloon_name: Yup.string().required('Saloon Name is required'),
//   mobile: Yup.string().required('Mobile is required'),
//   password: Yup.string().required('Password is required'),
// });

// export default function Register() {
//   const [msg, setMsg] = useState('');
//   const NameBox = useRef<HTMLInputElement>(null);
//   const SaloonNameBox = useRef<HTMLInputElement>(null);
//   const mobileBox = useRef<HTMLInputElement>(null);
//   const passwordBox = useRef<HTMLInputElement>(null);
//   const [status, setStatus] = useState<number | boolean>(false);
//   const [open, setOpen] = useState(false);

//   const formik = useFormik({
//     initialValues: {
//       name: '',
//       saloon_name: '',
//       mobile: '',
//       password: '',
//     },
//     validationSchema: RegisterSchema,
//     onSubmit: async (values) => {
//       setMsg('');
//       try {
//         const ob = {
//           password: values.password,
//           name: values.name,
//           saloon_name: values.saloon_name,
//           mobile_number: values.mobile,
//         };
//         console.log(ob)
//         const response = await AuthApiService.PostApiCall(urls.SELF_SALOON_SIGNUP, ob);
//         console.log(response)
//         if (response.status == 200) {
//           setMsg(response.data.msg)
//           handleClickOpen();
//           console.log(response);
//           formik.resetForm();
//         } else {
//           console.log(response);
//           setMsg(response.data.msg)
//           handleClickOpen();
//         }
//       } catch (error) {
//         const errorMessage: string =
//           (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
//         console.error(errorMessage);
//       }
//     },
//   });

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };


//   return (
//     <div className="login-page">
//       <Snackbar
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//         open={open}
//         autoHideDuration={5000}
//         onClose={handleClose}
//       >
//         <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
//       </Snackbar>
//       <div className="container-fluid ">
//         <div className="row w-100  mx-0">
//           <div className="col-lg-4 mx-auto">
//             <div className="card border-0 py-5 px-4 px-sm-5 mt-5">
//               <div className="brand-logo text-center">
//                 {/* <h2>HAIRSALOON</h2> */}
//                 <Link className="navbar-brand brand-logo " to=""><img src="images/Saloonist_Logo/newLogo.png" className="img-fluid" alt="logo" /></Link>
//               </div>
//               <p className="font-weight-light text-center">Sign up here..!</p>
//               <form onSubmit={formik.handleSubmit} className="pt-2">
//                 <div className="form-group mb-3">
//                   <input
//                     type="text"
//                     ref={NameBox}
//                     className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
//                     id="name"
//                     placeholder="Your Name"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.name}
//                   />
//                   {formik.touched.name && formik.errors.name && (
//                     <div className="invalid-feedback">{formik.errors.name}</div>
//                   )}
//                 </div>
//                 <div className="form-group mb-3">
//                   <input
//                     type="text"
//                     ref={SaloonNameBox}
//                     className={`form-control ${formik.touched.saloon_name && formik.errors.saloon_name ? 'is-invalid' : ''}`}
//                     id="saloon_name"
//                     placeholder="Saloon Name"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.saloon_name}
//                   />
//                   {formik.touched.saloon_name && formik.errors.saloon_name && (
//                     <div className="invalid-feedback">{formik.errors.saloon_name}</div>
//                   )}
//                 </div>
//                 <div className="form-group mb-3">
//                   <input
//                     type="text"
//                     ref={mobileBox}
//                     className={`form-control ${formik.touched.mobile && formik.errors.mobile ? 'is-invalid' : ''}`}
//                     id="mobile"
//                     placeholder="Mobile"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.mobile}
//                   />
//                   {formik.touched.mobile && formik.errors.mobile && (
//                     <div className="invalid-feedback">{formik.errors.mobile}</div>
//                   )}
//                 </div>
//                 <div className="form-group mb-3">
//                   <input
//                     type="password"
//                     ref={passwordBox}
//                     className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
//                     id="password"
//                     placeholder="Password"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.password}
//                   />
//                   {formik.touched.password && formik.errors.password && (
//                     <div className="invalid-feedback">{formik.errors.password}</div>
//                   )}
//                 </div>

//                 <div className="mt-3">
//                   <button type="submit" className="btn btn-purple form-control">
//                     SIGN UP
//                   </button>
//                 </div>
//                 <div className="text-center mt-2 font-weight-light">
//                   Already have an account? <Link to="/login" className="text-primary">Login</Link>
//                 </div>
//                 {/* <b className="text-danger">{msg}</b> */}
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthApiService, { urls } from '../apiServices/AuthApiService';
import { Alert, Snackbar } from '@mui/material';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Your Name is required'),
  saloon_name: Yup.string().required('Saloon Name is required'),
  mobile: Yup.string().required('Mobile is required'),
  password: Yup.string().required("New Password is required").min(6, "new Password must be at least 6 characters"),
  cpassword: Yup.string()
    .required("Confirm New Password is required")
    .oneOf([Yup.ref("password"), ""], "Confirm Passwords must match"),
});

export default function Register() {
  const [msg, setMsg] = useState('');
  const NameBox = useRef<HTMLInputElement>(null);
  const SaloonNameBox = useRef<HTMLInputElement>(null);
  const mobileBox = useRef<HTMLInputElement>(null);
  const passwordBox = useRef<HTMLInputElement>(null);
  const emailBox = useRef<HTMLInputElement>(null);
  const cpasswordBox = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<number | boolean>(false);

  const [status, setStatus] = useState<number | boolean>(false); // Update state type
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      saloon_name: '',
      cpassword: "",
      mobile: '',
      password: '',
      email: null
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      setLoading(true)
      setMsg('');
      try {
        const ob = {
          password: values.password,
          name: values.name,
          saloon_name: values.saloon_name,
          mobile_number: values.mobile,
          email: values.email
        };
        const response = await AuthApiService.PostApiCall(urls.SELF_SALOON_SIGNUP, ob);
        if (response?.status === 200) {

          if (response?.data?.status === false) {
            setLoading(false)
            setStatus(false);
            setMsg(response?.data?.msg);
          } else {
            setLoading(false)

            setStatus(true);
            setMsg(response?.data?.msg);
            formik.resetForm();

          }
        } else {
          setStatus(false);
          setLoading(false)

          setMsg(response?.data?.msg);
        }

        handleClickOpen();
      } catch (error) {
        const errorMessage: string =
          (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
        setStatus(false);
        setMsg(errorMessage);
        handleClickOpen();
        setLoading(false)

        // console.error(errorMessage);
        // console.error(error);

      }
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="login-page">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={status ? 'success' : 'error'} // Simplified condition
          variant="filled"
          sx={{ width: '100%' }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <div className="container-fluid ">
        <div className="row w-100  mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card border-0 py-5 px-4 px-sm-5 mt-5">
              <div className="brand-logo text-center">
                <Link className="navbar-brand brand-logo " to="">
                  <img
                    src="images/Saloonist_Logo/newLogo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
              </div>
              <p className="font-weight-light text-center">Sign up here..!</p>
              <form onSubmit={formik.handleSubmit} className="pt-2">
                <div className="form-group mb-3">
                  <input
                    type="text"
                    ref={NameBox}
                    className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''
                      }`}
                    id="name"
                    placeholder="Your Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    ref={SaloonNameBox}
                    className={`form-control ${formik.touched.saloon_name && formik.errors.saloon_name ? 'is-invalid' : ''
                      }`}
                    id="saloon_name"
                    placeholder="Saloon Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.saloon_name}
                  />
                  {formik.touched.saloon_name && formik.errors.saloon_name && (
                    <div className="invalid-feedback">{formik.errors.saloon_name}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    ref={mobileBox}
                    className={`form-control ${formik.touched.mobile && formik.errors.mobile ? 'is-invalid' : ''
                      }`}
                    id="mobile"
                    placeholder="Mobile"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <div className="invalid-feedback">{formik.errors.mobile}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="email"
                    ref={emailBox}
                    className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="password"
                    ref={passwordBox}
                    className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''
                      }`}
                    id="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="invalid-feedback">{formik.errors.password}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="password"
                    ref={cpasswordBox}
                    className={`form-control ${formik.touched.cpassword && formik.errors.cpassword ? 'is-invalid' : ''
                      }`}
                    id="cpassword"
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cpassword}
                  />
                  {formik.touched.cpassword && formik.errors.cpassword && (
                    <div className="invalid-feedback">{formik.errors.cpassword}</div>
                  )}
                </div>

                <div className="mt-3">
                  <button type="submit" className="btn btn-purple form-control">
                    {loading ? "Signing..." : "SIGN UP"}
                  </button>
                </div>
                <div className="text-center mt-2 font-weight-light">
                  Already have an account? <Link to="/login" className="text-primary">Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

