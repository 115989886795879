import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPen, faPlus, faSearch, faSort, faTrash, } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { incentiveEmployeeValidationSchema } from "../../../validations/Validations";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { addIncentiveEmployeeReducer, listIncentiveEmployeeReducer, updateIncentiveEmployee, updateIncentiveEmployeeReducer } from "../../../reduxData/incentiveEmployeeSlice";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { listEmployeeLeaveReducer } from "../../../reduxData/leaveSlice";

export default function IncentiveRulesForStaff() {

    return <>

        <div className="container-fluid p-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center ">
                        <div className="text-center">
                            <h4>Incentive Employees</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}