import { faCog, faCode, faClipboardList, faPowerOff, faHome, faLock, faUser, faCodeBranch, faUsers, faCogs, faGavel, faGift, faChartBar, faChartLine, faIndianRupee, faPeopleLine, faShoppingBag, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthApiService, { urls } from '../apiServices/AuthApiService';
import { useLocation, useNavigate } from 'react-router-dom';
export default function Home() {
    const admin = useSelector((state:any)=>state.authUserInfo.value);
    const [loading,setLoading] = useState(false);
    const [totalBranches,setTotalBranches]:any = useState(0);
    const [totalServices,setTotalServices]:any = useState(0);
    const [totalAdvTran,setTotalAdvTran]:any = useState(0);
    const [totalProduct,setTotalProduct]:any = useState(0);
    const [totalCustomer,setTotalCusotmer]:any = useState(0);
    const [totalTransactions,setTotalTransactions]:any = useState(0);
    const [totalExpenses,setTotalExpenses]:any = useState(0);
    const [totalLeaves,setTotalLeaves]:any = useState(0);

// console.log(admin.token)



    const dispatch = useDispatch();
    const navigate = useNavigate()

    const TotalBranches = async () => {
        setLoading(true)
        const URL = `${urls.TOTAL_BRANCHES}`;
        const res = await AuthApiService.GetApiCall(URL, admin.token,);
        if (res?.status==200 && res?.data?.status==200) {
          setLoading(false)
          setTotalBranches(res?.data?.total_branches)
        }
        else{
          setTotalBranches(0);
        }
    };

    const TotalServices = async () => {
        setLoading(true);
        const URL = `${urls.TOTAL_SERVICES}`;
        const res = await AuthApiService.GetApiCall(URL, admin.token);
        if (res?.status==200 && res?.data?.status==200) {
            const ob = {services_sale_this_month:res?.data?.service_sale_this_month,total_services:res?.data?.total_services}
            setLoading(false)
            setTotalServices(ob)
            }
            else{
                setTotalServices(0)
            }

    };

    const TotalAdvancePayment = async () => {
          setLoading(true)
          const URL = `${urls.TOTAL_ADVANCE}`;
          const res = await AuthApiService.GetApiCall(URL, admin.token,);
          if (res?.status==200 && res?.data?.status==200) {
            setLoading(false)
            setTotalAdvTran(res?.data?.total_advance)
          }
          else{
            setTotalAdvTran(0);
          }
      };
      const TotalLeavesStaff = async () => {
        setLoading(true)
        const URL = `${urls.TOTAL_LEAVES}`;
        const res = await AuthApiService.GetApiCall(URL, admin.token,);
        if (res?.status==200 && res?.data?.status==200) {
          setLoading(false)
          setTotalLeaves(res?.data?.total_leaves)
        }
        else{
            setTotalLeaves(0);
        }
    };

      const TotalProducts = async () => {
        setLoading(true)
        const URL = `${urls.TOTAL_PRODUCT}`;
        const res = await AuthApiService.GetApiCall(URL, admin.token,);
        if (res?.status==200 && res?.data?.status==200) {
            const ob = {total_products:res?.data?.total_products,product_sale_this_month:res?.data?.product_sale_this_month}
             setLoading(false)
          setTotalProduct(ob)
        }
        else{
            setTotalProduct(0);
        }
    };
    const TotalCustomers = async () => {
        setLoading(true)
        const URL = `${urls.TOTAL_CUSTOMER}`;
        const res = await AuthApiService.GetApiCall(URL, admin.token,);
        if (res?.status==200 && res?.data?.status==200) {
            const ob = {total_customer:res?.data?.total_customer,new_customer_this_month:res?.data?.new_customer_this_month}
          setLoading(false)
          setTotalCusotmer(ob)
        }
        else{
            setTotalCusotmer(0);
        }
    };

    const TotalTransactions = async () => {
        setLoading(true)
        const URL = `${urls.TOTAL_TRANSACTION}`;
        const res = await AuthApiService.GetApiCall(URL, admin.token,);
        if (res?.status==200 && res?.data?.status==200) {
            const ob = {sum_current_day:res?.data?.sum_current_day,sum_current_week:res?.data?.sum_current_week,sum_current_month:res?.data?.sum_current_month}

          setLoading(false)
          setTotalTransactions(ob)
          console.log("totaltransaction",res)
        }
        else{
            setTotalTransactions(0);
        }
    };

    const TotalExpenses = async () => {
        setLoading(true)
        const URL = `${urls.TOTAL_EXPENSE}`;
        const res = await AuthApiService.GetApiCall(URL, admin.token,);
        if (res?.status==200 && res?.data?.status==200) {
            const ob = {sum_current_month:res?.data?.sum_current_month,total_expenses:res?.data?.total_expenses}

          setLoading(false)
          setTotalExpenses(ob)
        }
        else{
            setTotalExpenses(0);
        }
    };
    
    useEffect(()=>{
        const fetchData = async () => {
         if(admin?.roleValue=='STF')  {
            await TotalTransactions();
            await TotalAdvancePayment();
            await TotalLeavesStaff();
         } else {await TotalServices();
            await TotalAdvancePayment();
            await TotalBranches();
            await TotalProducts();
            await TotalCustomers();
            await TotalTransactions();
            await TotalExpenses();
            await TotalLeavesStaff();
            }setLoading(false); // Set loading to false once data fetching is completed
        };
        fetchData();

        console.log(fetchData)
    },[])

    return <>
        <div className="content-wrapper px-3">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <div className="row">
                        <div className="col-12 col-xl-8 mb-0 mb-xl-0">
                            <h3 className="font-weight-bold">Welcome to {admin.saloonName ?? "Saloonia"}.</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                
                {admin?.roleValue=='SCM'?<div className="col-md-6 grid-margin transparent">
                    <div className="row">
                        
                        
                        <div onClick={()=>{navigate("/saloonChain/dailyTransaction")}} className="col-md-6 mb-4 stretch-card transparent">
                            <div className="card card-dark-tale">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Transactions</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faChartBar}/></div></div>
                                    <p className="fs-25 mb-1">Today's - {loading?"Loading...":totalTransactions?.sum_current_day || 0} </p>
                                    <p className="fs-25 mb-1">This Week - {(loading?"Loading...":totalTransactions?.sum_current_week) ?? 0}</p>
                                    <p className="fs-25 mb-1">This Month - {loading?"Loading...":(totalTransactions?.sum_current_month)}</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/saloonChain/expenseList")}} className="col-md-6 mb-4 stretch-card transparent">
                            <div className="card card-tale">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Expenses</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faWallet}/></div></div>
                                    <p className="fs-25 mb-1">This Month - {loading?"Loading...":totalExpenses?.sum_current_month}</p>
                                    <p className="fs-25 mb-1">Total - {loading?"Loading...":totalExpenses?.total_expenses}</p>
                                   
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/saloonChain/serviceDetails")}} className="col-md-6 mb-4  stretch-card transparent">
                            <div className="card card-light-blue">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Services</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faCogs}/></div></div>
                                    <p className="fs-25 mb-1">Total Services - {loading?"Loading...":totalServices?.total_services}</p>
                                    <p className="fs-25 mb-1 text-wrap">This Month Service Sales - {loading?"Loading...":totalServices?.services_sale_this_month}</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/saloonChain/productDetails")}} className="col-md-6 mb-4 stretch-card transparent">
                            <div className="card card-light-danger">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Products</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faShoppingBag}/></div></div>
                                    <p className="fs-25 mb-1">Total Products - {loading?"Loading...":totalProduct?.total_products}</p>
                                    <p className="fs-25 mb-1">This Month Product Sales - {loading?"Loading...":totalProduct?.product_sale_this_month}</p>
                                   
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/saloonChain/advancePayment")}} className="col-md-6 mb-4 stretch-card transparent">
                            <div className="card card-tale">
                                <div className="card-body">
                                    {/* <FontAwesomeIcon icon={faCodeBranch} className="sideIcon" /> */}
                                    <div className='row d-flex justify-content-between'><p className="col-11 col-md-9 mb-4">Employee Advances</p><div className='col-1 col-md-3'><FontAwesomeIcon icon={faChartLine}/></div></div>
                                    <p className="fs-25 mb-1">This Month - {loading?"Loading...":totalAdvTran}</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/saloonChain/customerList")}} className="col-md-6 mb-4 stretch-card transparent">
                            <div className="card card-dark-blue">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Customers</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faPeopleLine}/></div></div>
                                    <p className="fs-25 mb-1">Total Customers - {loading?"Loading...":totalCustomer?.total_customer}</p>
                                    <p className="fs-25 mb-1">New This Month - {loading?"Loading...":totalCustomer?.new_customer_this_month}</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
                : <></>}
                {admin?.roleValue=='BRM'?<>
                <div className="col-md-6 grid-margin transparent">
                    <div className="row">
                    <div onClick={()=>{navigate("/branchManager/dailytransactionslist")}} className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-dark-tale">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Transactions</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faChartBar}/></div></div>
                                <p className="fs-25 mb-1">Today's - {loading?"Loading...":totalTransactions?.sum_current_day}</p>
                                    <p className="fs-25 mb-1">This Week - {loading?"Loading...":totalTransactions?.sum_current_week}</p>
                                    <p className="fs-25 mb-1">This Month - {loading?"Loading...":totalTransactions?.sum_current_month}</p>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/branchManager/managerExpenseList")}} className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-tale">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Expenses</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faWallet}/></div></div>
                                <p className="fs-25 mb-1">This Month - {loading?"Loading...":totalExpenses?.sum_current_month}</p>
                                    <p className="fs-25 mb-1">Total - {loading?"Loading...":totalExpenses?.total_expenses}</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/branchManager/serviceslist")}} className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-light-blue">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Services</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faCogs}/></div></div>
                                    <p className="fs-25 mb-1">Total Services - {loading?"Loading...":totalServices?.total_services}</p>
                                    <p className="fs-25 mb-1 text-wrap">This Month Service Sales - {loading?"Loading...":totalServices?.services_sale_this_month}</p>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/branchManager/productDetails")}} className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-light-danger">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Products</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faShoppingBag}/></div></div>
                                <p className="fs-25 mb-1">Total Products - {loading?"Loading...":totalProduct?.total_products}</p>
                                    <p className="fs-25 mb-1">This Month Product Sales - {loading?"Loading...":totalProduct?.product_sale_this_month}</p>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/branchManager/managerAdvancePayment")}} className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-tale">
                                <div className="card-body">
                                    {/* <FontAwesomeIcon icon={faCodeBranch} className="sideIcon" /> */}
                                    <div className='row d-flex justify-content-between'><p className="col-11 col-md-9 mb-4">Employee Advances</p><div className='col-1 col-md-3'><FontAwesomeIcon icon={faChartLine}/></div></div>
                                    <p className="fs-25 mb-1">This Month - {loading?"Loading...":totalAdvTran}</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/branchManager/managerCustomerList")}} className="col-md-6 mb-4  stretch-card transparent">
                        <div className="card card-dark-blue">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Customers</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faPeopleLine}/></div></div>
                                <p className="fs-25 mb-1">Total Customers - {loading?"Loading...":totalCustomer?.total_customer}</p>
                                    <p className="fs-25 mb-1">New This Month - {loading?"Loading...":totalCustomer?.new_customer_this_month}</p>
                                    
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="row">
                    </div>
                </div></>:<></>}


                {admin?.roleValue=='STF'?<>
                <div className="col-md-6 grid-margin transparent">
                    <div className="row">
                    <div onClick={()=>{navigate("/staff/mytransactions")}} className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-dark-tale">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">My Transactions</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faChartBar}/></div></div>
                                <p className="fs-25 mb-1">Today's - {loading?"Loading...":totalTransactions?.sum_current_day}</p>
                                    <p className="fs-25 mb-1">This Week - {loading?"Loading...":totalTransactions?.sum_current_week}</p>
                                    <p className="fs-25 mb-1">This Month - {loading?"Loading...":totalTransactions?.sum_current_month}</p>
                                    
                                </div>
                            </div>
                        </div>
                       
                        <div onClick={()=>{navigate("/staff/myadvancepayments")}} className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-tale">
                                <div className="card-body">
                                    
                                    <div className='row d-flex justify-content-between'><p className="col-11 col-md-9 mb-4">My Advance Amount</p><div className='col-1 col-md-3'><FontAwesomeIcon icon={faChartLine}/></div></div>
                                    <p className="fs-25 mb-1">This Month - {loading?"Loading...":totalAdvTran}</p>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{navigate("/staff/myleaves")}} className="col-md-6 mb-4  stretch-card transparent">
                        <div className="card card-dark-blue">
                                <div className="card-body">
                                <div className='row d-flex justify-content-between'><p className="col-10 col-md-9 mb-4">Leaves</p><div className='col-2 col-md-3'><FontAwesomeIcon icon={faPeopleLine}/></div></div>
                                <p className="fs-25 mb-1">Total Leaves - {loading?"Loading...":totalLeaves}</p>
                                   
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="row">
                    </div>
                </div></>:<></>}
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card tale-bg">
                        <div className="card-people mt-0">
                            <img src="images/imghair.png" className='img-fluid' alt="people" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}







// import { faCog, faCode, faClipboardList, faPowerOff, faHome, faLock, faUser, faCodeBranch, faUsers, faCogs, faGavel, faGift, faChartBar, faChartLine, faIndianRupee } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import AuthApiService, { urls } from '../apiServices/AuthApiService';
// import { listTransactionReducer } from '../reduxData/transactionSlice';
// import { useNavigate } from 'react-router-dom';
// export default function Home() {
//     const admin = useSelector(state => state.authUserInfo.value)
//     const branchInfo = useSelector((state) => state.branchInfo.value);
//     const employeeInfo = useSelector((state) => state.employeeInfo.value);
//     const transactionInfo = useSelector((state) => state.transactionInfo.value);
//     const branchServicesInfo = useSelector((state) => state.branchServicesInfo.value);
//     const [loading, setLoading] = useState(false);
//     const [totalTran, setTotalTran] = useState();
//     const dispatch = useDispatch();
//     const navigate = useNavigate()
//     console.log(branchInfo, employeeInfo, transactionInfo, branchServicesInfo)

//     const transactionlist = async (page: number, itemsPerPage: number) => {
//         setLoading(true);
//         const URL = `${urls.TRANSACTION_LIST}/${page}/${itemsPerPage}`;
//         const res = await AuthApiService.GetApiCallWithPagination(URL, admin.token, page, itemsPerPage);

//         if (res.status) {
//             setLoading(false)
//             let totalTransaction = 0;
//             res.data.data.forEach((data: any) => {
//                 totalTransaction = totalTransaction + data.amount;
//             })
//             setTotalTran(totalTransaction);
//             console.log(totalTransaction);
//             dispatch(listTransactionReducer(res.data));
//         }

//     };

//     useEffect(() => {
//         transactionlist(1, 10000000000)
//     }, [])

//     return <>
//         {/* <div className="home-container"> */}
//         {/* <div className="background-image">
//                 <div className="content container text-center">
//                     <h1 className="text-white">Welcome to Hairport Saloon</h1>
//                 </div>
//             </div> */}
//         {/* </div> */}

//         {/* <div className="container text-center mt-5">
//             <h2 className="text-center ">Services</h2>
//             <p>We provide Best Hair Solutions...</p>
//             <div className="row mt-4">

//                 <div className="col-4 col-lg-4 col-md-4 col-sm-12">
//                     <div className="service-box">
//                         <h3>Hair Cutting</h3>
//                         <p>Completes haircutting techniques, including trims, layers, and texturizing. Performs haircoloring methods, such as highlights, ombre, and balayage. Styles hair, including blowouts, curls, braids, ponytails, and updos. Upsells clients on products and services.</p>
//                     </div>
//                 </div>


//                 <div className="col-4 col-lg-4 col-md-4 col-sm-12">
//                     <div className="service-box ">
//                         <h3>Face Massage</h3>
//                         <p>Facial massage is a cosmetic treatment that includes using hands or a tool to massage the face and neck to stimulate blood circulation, promote relaxation, and improve the appearance of the skin. Facial massage represents rejuvenating treatment that can help you achieve a more youthful, glowing complexion.</p>
//                     </div>
//                 </div>


//                 <div className="col-4 col-lg-4 col-md-4 col-sm-12">
//                     <div className="service-box">
//                         <h3>Products</h3>
//                         <p>Hairstylists and cosmetologists offer a wide range of hair services, such as shampooing, cutting, coloring, and styling. They often provide consultation and advise clients on how to care for their hair at home. Some also clean and style wigs and hairpieces.</p>
//                     </div>
//                 </div>
//             </div>
//         </div> */}
//         <div className="content-wrapper px-3">
//             <div className="row">
//                 <div className="col-md-12 mb-2">
//                     <div className="row">
//                         <div className="col-12 col-xl-8 mb-0 mb-xl-0">
//                             <h3 className="font-weight-bold">Welcome to HairPort Salon.</h3>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="row">
//                 <div className="col-md-6 grid-margin stretch-card">
//                     <div className="card tale-bg">
//                         <div className="card-people mt-0">
//                             <img src="images/imghair.png" alt="people" />
//                             {/* <div className="weather-info">
//                                 <div className="card-body">
                                  
//                                     <p className="mb-4">Number of Branches</p>
//                                     <p className="fs-30 mb-2">04</p>
//                                     <p>10.00% (30 days)</p>
//                                 </div>
//                             </div> */}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-md-6 grid-margin transparent">
//                     <div className="row">
//                         <div onClick={() => { navigate("/saloonChain/branchDetails") }} className="col-md-6 mb-4 stretch-card transparent">
//                             <div className="card card-tale">
//                                 <div className="card-body">
//                                     {/* <FontAwesomeIcon icon={faCodeBranch} className="sideIcon" /> */}
//                                     <p className="mb-4">Branches</p>
//                                     <p className="fs-30 mb-2">{branchInfo.length <= 0 ? "Loading.." : branchInfo.length}</p>
//                                     {/* <p>10.00% (30 days)</p> */}
//                                 </div>
//                             </div>
//                         </div>
//                         {/* <div className="col-md-3 mb-4 stretch-card transparent">
//                             <div className="card card-dark-tale">
//                                 <div className="card-body">
//                                     <p className="mb-4">Total Bookings</p>
//                                     <p className="fs-30 mb-2">61344</p>
//                                     <p>22.00% (30 days)</p>
//                                 </div>
//                             </div>
//                         </div> */}
//                         <div onClick={() => { navigate("/saloonChain/employeeDetails") }} className="col-md-6 mb-4 stretch-card transparent">
//                             <div className="card card-dark-blue">
//                                 <div className="card-body">
//                                     <p className="mb-4">Employees</p>
//                                     <p className="fs-30 mb-2">{employeeInfo.length <= 0 ? "Loading..." : employeeInfo.length}</p>
//                                     {/* <p>22.00% (30 days)</p> */}
//                                 </div>
//                             </div>
//                         </div>
//                         <div onClick={() => { navigate("/saloonChain/serviceDetails") }} className="col-md-6 mb-4  stretch-card transparent">
//                             <div className="card card-light-blue">
//                                 <div className="card-body">
//                                     <p className="mb-4">Services</p>
//                                     <p className="fs-30 mb-2">{branchServicesInfo.length <= 0 ? "Loading..." : branchServicesInfo.length}</p>
//                                     {/* <p>2.00% (30 days)</p> */}
//                                 </div>
//                             </div>
//                         </div>
//                         <div onClick={() => { navigate("/saloonChain/dailyTransaction") }} className="col-md-6 mb-4 stretch-card transparent">
//                             <div className="card card-light-danger">
//                                 <div className="card-body">
//                                     <p className="mb-4">Transactions</p>
//                                     <p className="fs-30 mb-2">{loading ? "Loading..." : totalTran}</p>
//                                     {/* <p>0.22% (30 days)</p> */}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="row">
//                         {/* <div className="col-md-3 mb-4 mb-lg-0 stretch-card transparent">
//                             <div className="card card-light-blue">
//                                 <div className="card-body">
//                                     <p className="mb-4">Number of Meetings</p>
//                                     <p className="fs-30 mb-2">34040</p>
//                                     <p>2.00% (30 days)</p>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-md-4 stretch-card transparent">
//                             <div className="card card-light-danger">
//                                 <div className="card-body">
//                                     <p className="mb-4">Number of Clients</p>
//                                     <p className="fs-30 mb-2">47033</p>
//                                     <p>0.22% (30 days)</p>
//                                 </div>
//                             </div>
//                         </div> */}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </>
// }




