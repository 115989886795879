import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPlus, faSearch, faSort, } from "@fortawesome/free-solid-svg-icons";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useDispatch, useSelector } from "react-redux";
import { ListBranchReducer, UpdateBranchReducer, } from "../../../reduxData/branchSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, Snackbar } from "@mui/material";
import { Button, Modal } from "react-bootstrap";


export default function BranchList() {
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [open, setOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false);
    const [assignManagerLoading, setAssignManagerLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [empList, setEmpList] = useState<{ id: string }[]>([]);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [regUserId, setRegUserId] = useState(null)
    const [filteredEmployeeList, setFilteredEmployeeList] = useState<any[]>([]);
    const [branchName, setBranchName] = useState(null);
    const [defaultBMInfo, setDefaultBMInfo] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState({
        email: '',
        first_name: '',
        last_name: '',
        mobile_number: '',
        created_by: '',
        updated_by: '', userinfo: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [isUser, setIsUser] = useState(false);
    const branchesPerPage = 5;

    const admin = useSelector((state: any) => state.authUserInfo.value);
    const branchList = useSelector((state: any) => state.branchInfo.value);
    const branches = Array.isArray(branchList) ? branchList : [];
    const employeeList = useSelector((state: any) => state.employeeInfo.value);
    const roles = useSelector((state: any) => state.authUserInfo.rolesValue);
    const [sortBy, setSortBy] = useState<{ field: string; order: "asc" | "desc" }>({
        field: "branch_name",
        order: "asc",
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userNameBox = useRef<HTMLInputElement>(null);
    const passBox = useRef<HTMLInputElement>(null);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleUpdateModalClose = () => setShowUpdateModal(false);
    const handleUpdateModalShow = () => setShowUpdateModal(true);

    const validationSchema = Yup.object({
        employee: Yup.string().required("Please select an employee"),
        username: Yup.string()
            .required("Username is required")
            .matches(/^[a-z0-9]+$/, "Username must contain only lowercase alphabets and numbers")
            .min(10, "Username must be at least 10 characters long"),
        password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
        cpassword: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), ""], "Passwords must match"),
    });

    // when select employee

    // check employe is user or not
    const handleEmployeeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedEmpId = parseInt(event.target.value);
        const selectedEmpDetails = employeeList.find((emp: any) => emp.id === selectedEmpId);
        setSelectedEmployee(selectedEmpDetails || {
            email: "",
            first_name: "",
            last_name: "",
            mobile_number: "",
            isActive: true,
            created_by: '',
            updated_by: '',
            branch_info: {}
        });

        // const isUser = selectedEmpDetails && selectedEmpDetails.userinfo;
        const isUser = selectedEmpDetails && selectedEmpDetails.userinfo !== null && selectedEmpDetails.userinfo !== undefined;
        setIsUser(isUser)

        // Set a flag indicating whether the selected employee is a user
        setEmpList(isUser ? [{ id: selectedEmpId }] : []);

        const userId = isUser ? selectedEmpDetails.userinfo.id : null;
        // Set the userId in the state
        setRegUserId(userId);

        // Update the Formik value for the "employee" field
        formik.setFieldValue("employee", selectedEmpId);

        // Auto-populate mobile number into the username field
        if (selectedEmpDetails?.mobile_1) {
            formik.setFieldValue("username", selectedEmpDetails.mobile_1);
        }
    };

    // filtered employee list based on branch id
    const handleBranchSelect = (branchId: any) => {
        setSelectedBranch(branchId.id);
        setBranchName(branchId.branch_name)
        setDefaultBMInfo(branchId);
        if (employeeList) {
            const filteredEmployees = employeeList.filter(
                (emp: any) => {
                    return emp.branch_info?.id === branchId.id
                }
            );

            setFilteredEmployeeList([...filteredEmployees]);
            setShowForm(filteredEmployees.length !== 0);
        }
    };


    // assign Manager
    const formik = useFormik(isUser ? {
        initialValues: {

        }, onSubmit: async () => {
            const created_by = admin.uid;
            const updated_by = admin.uid;
            const employee = selectedEmployee.id
            const isUser = selectedEmployee && selectedEmployee.userinfo;
            const isRole = selectedEmployee?.user_role_info?.some((r: any) => r.role_name == "Branch Manager")
            if (isUser && isRole) {
                const userId = selectedEmployee.userinfo.id;
                const uid = {
                    branch_manager_id: userId,
                    created_by: created_by,
                    updated_by: updated_by
                };
                const upURL = urls.BRANCH_UPDATE + selectedBranch;
                try {
                    const bm = await AuthApiService.PutApiCall(upURL, uid, admin.token);
                    if (bm.status === 200) {
                        // setMsg(bm.data.msg);
                        handleUpdateModalClose()
                        setMsg("Branch Manager Updated ");
                        setStatus(true)
                        handleClickOpen();
                        formik.resetForm()
                        const updatedBranchList = branchList.map((b: any) =>
                            b.id === selectedBranch
                                ? {
                                    ...b,
                                    managerinfo: {
                                        id: userId,
                                        first_name: selectedEmployee.first_name,
                                        last_name: selectedEmployee.last_name,
                                    },
                                }
                                : b
                        );
                        //for active user role status
                        const RoleId = selectedEmployee?.user_role_info?.find((r: any) => r.role_name == "Branch Manager")
                        const da = {
                            isActive: true,
                            created_by: created_by,
                            updated_by: updated_by
                        };
                        const URLU_R = urls.UPDATE_ROLE + "/" + RoleId.id;
                        const ur = await AuthApiService.PutApiCall(URLU_R, da, admin.token)
                        // Create a sorted copy of the updated branch list
                        const sortedUpdatedBranches = [...updatedBranchList].sort((a, b) => {
                            const valueA = a[sortBy.field].toUpperCase();
                            const valueB = b[sortBy.field].toUpperCase();

                            if (sortBy.field === "saloon_type") {
                                return sortBy.order === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                            } else {
                                if (valueA < valueB) {
                                    return sortBy.order === "asc" ? -1 : 1;
                                }
                                if (valueA > valueB) {
                                    return sortBy.order === "asc" ? 1 : -1;
                                }
                                return 0;
                            }
                        });
                        // Update the state with the sorted copy
                        dispatch(ListBranchReducer(sortedUpdatedBranches));

                    }
                } catch (error: any) {
                    const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
                    setMsg(errorMessage);
                    setStatus(error.response.status);
                    handleClickOpen()
                }
            } else {
                if (isUser) {

                    // If the employee is already a user, update the branch manager ID
                    const userId = selectedEmployee.userinfo.id;
                    const uid = {
                        isActive: true,
                        role_id: 4,
                        created_by: created_by,
                        updated_by: updated_by
                    };
                    // const upURL = urls.BRANCH_UPDATE + selectedBranch;
                    const upURL = urls.MAKE_MANAGER + employee;

                    try {
                        setAssignManagerLoading(true);

                        const bm = await AuthApiService.SaveApiCall(upURL, uid, admin.token);
                        if (bm.status === 200) {
                            // setMsg(bm.data.msg);
                            handleUpdateModalClose()
                            setMsg("Branch Manager Updated ");
                            setStatus(true)
                            handleClickOpen();
                            // Update the branch manager ID directly in the branch list
                            const updatedBranchList = branchList.map((b: any) =>
                                b.id === selectedBranch
                                    ? {
                                        ...b,
                                        managerinfo: {
                                            id: userId,
                                            first_name: selectedEmployee.first_name,
                                            last_name: selectedEmployee.last_name,
                                        },
                                    }
                                    : b
                            );

                            // Create a sorted copy of the updated branch list
                            const sortedUpdatedBranches = [...updatedBranchList].sort((a, b) => {
                                const valueA = a[sortBy.field].toUpperCase();
                                const valueB = b[sortBy.field].toUpperCase();

                                if (sortBy.field === "saloon_type") {
                                    return sortBy.order === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                                } else {
                                    if (valueA < valueB) {
                                        return sortBy.order === "asc" ? -1 : 1;
                                    }
                                    if (valueA > valueB) {
                                        return sortBy.order === "asc" ? 1 : -1;
                                    }
                                    return 0;
                                }
                            });
                            // Update the state with the sorted copy
                            dispatch(ListBranchReducer(sortedUpdatedBranches));
                            formik.resetForm()
                        } else {
                            if (bm.data && bm.data.err) {
                                setMsg(bm.data.err);
                                // setStatus(false)
                            } else {
                                setMsg(`Error: ${bm.status} - ${bm.data.msg}`);
                                setStatus(false)
                            }
                        }
                    } catch (error: any) {
                        const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
                        setMsg(errorMessage);
                        setStatus(error.response.status);
                        handleClickOpen()
                    } finally {
                        setAssignManagerLoading(false);
                        setTimeout(() => {
                            // setMsg('');
                        }, 5000);
                    }
                }
            }
        }
    } : {
        initialValues: {
            username: "",
            password: "",
            email: selectedEmployee.email || null,
            first_name: selectedEmployee.first_name,
            last_name: selectedEmployee.last_name,
            mobile_number: selectedEmployee.mobile_1,
            isActive: true,
            employee: "",
            created_by: admin.uid,
            updated_by: admin.uid,
            cpassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setAssignManagerLoading(true);
            if (!selectedBranch) {
                setMsg("Please select a branch first.");
                return;
            }
            const created_by = admin.uid;
            const updated_by = admin.uid;
            const brmRoleId = roles.find((role: any) => role.value === "BRM")?.id;
            // Check if the selected employee is already a user
            const isUser = selectedEmployee && selectedEmployee.userinfo;
            // If the employee is not a user, register the user and update the branch
            const ob = {
                email: selectedEmployee.email ? selectedEmployee.email : null,
                first_name: selectedEmployee.first_name,
                last_name: selectedEmployee.last_name,
                mobile_number: selectedEmployee.mobile_1,
                isActive: true,
                username: values.username,
                password: values.password,
                created_by: parseInt(admin.uid),
                updated_by: parseInt(admin.uid)
            };
            try {
                const response = await AuthApiService.PostApiCall(urls.REGISTER, ob);
                if (response.status) {
                    if (response.data.msg == "User registered successfully") {
                        setMsg("Branch Manager Assigned Successfully");
                        setStatus(response.data.status)
                        handleClickOpen()
                        handleUpdateModalClose()
                    } else {
                        setMsg(response.data.msg)
                        setStatus(response.data.status)
                        handleClickOpen()
                    }
                    // setStatus(response.data.status)
                    // handleClickOpen();
                    const userId = response.data.id;
                    // Assign branch manager role to the employee
                    const assignRole = {
                        user_id: userId, role_id: brmRoleId, created_by: created_by,
                        updated_by: updated_by
                    }
                    const aRole = await AuthApiService.SaveApiCall(urls.ROLE_ASSIGN, assignRole, admin.token);
                    // Update the branch with the manager's user ID
                    const uid = {
                        branch_manager_id: userId, created_by: created_by,
                        updated_by: updated_by
                    };
                    const upURL = urls.BRANCH_UPDATE + selectedBranch;
                    const bm = await AuthApiService.PutApiCall(upURL, uid, admin.token);
                    const updatedBranchList = branchList.map((b: any) =>
                        b.id === selectedBranch
                            ? {
                                ...b,
                                managerinfo: {
                                    id: userId,
                                    first_name: selectedEmployee.first_name,
                                    last_name: selectedEmployee.last_name,
                                },
                            }
                            : b
                    );
                    const sortedUpdatedBranches = [...updatedBranchList].sort((a, b) => {
                        const valueA = a[sortBy.field].toUpperCase();
                        const valueB = b[sortBy.field].toUpperCase();
                        if (sortBy.field === "saloon_type") {
                            return sortBy.order === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                        } else {
                            if (valueA < valueB) {
                                return sortBy.order === "asc" ? -1 : 1;
                            }
                            if (valueA > valueB) {
                                return sortBy.order === "asc" ? 1 : -1;
                            }
                            return 0;
                        }
                    });
                    // Update the state with the sorted copy
                    dispatch(ListBranchReducer(sortedUpdatedBranches));
                    // Update the employee with the userid
                    const uEid = {
                        user_id: userId,
                        created_by: created_by,
                        updated_by: updated_by
                    }
                    const upEmURL = urls.EMPLOYEE_UPDATE + selectedEmployee.id
                    const em = await AuthApiService.PutApiCall(upEmURL, uEid, admin.token)
                    formik.resetForm()
                } else {
                    if (response.data && response.data.msg) {
                        setMsg("Branch Manager Assigned. ");
                        handleUpdateModalClose()
                    } else {
                        setMsg("An error occurred. Please try again.");
                    }
                }
            } catch (error: any) {
                const errorMessage: string = (error as any)?.response?.data?.msg
                setMsg(errorMessage);
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setAssignManagerLoading(false);
                setTimeout(() => {
                    setOpen(false)
                }, 1000);
            }
        }
    })

    const resetModalState = () => {
        setMsg('');
        setAssignManagerLoading(false);
        setFilteredEmployeeList([]);
        setSelectedEmployee({
            email: '',
            first_name: '',
            last_name: '',
            mobile_number: '',
            created_by: '',
            updated_by: '',
            userinfo: '',
        });
        setRegUserId(null);
        formik.resetForm();
    };

    // update
    const edit = (ob: any) => {
        localStorage.setItem("branchinfo", JSON.stringify({ id: ob?.id, branch_name: ob?.branch_name, saloon_type: ob?.saloon_type, branch_address: ob?.branch_address, phone_number: ob?.phone_number, email: ob?.email, currency: ob?.currency, manager_id: ob?.managerinfo?.id }));
        dispatch(UpdateBranchReducer(ob));
        navigate("/saloonChain/editBranch");
    };

    const handleSort = (field: string) => {
        setSortBy({
            field,
            order: sortBy.field === field ? (sortBy.order === "asc" ? "desc" : "asc") : "asc",
        });
    };

    // Sorting logic
    const sortedBranches = [...branches].sort((a, b) => {
        const valueA = a[sortBy.field].toUpperCase();
        const valueB = b[sortBy.field].toUpperCase();

        if (sortBy.field === "saloon_type") {
            // For "Saloon Type," compare values as strings
            return sortBy.order === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        } else {
            // For other fields, compare values as before
            if (valueA < valueB) {
                return sortBy.order === "asc" ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortBy.order === "asc" ? 1 : -1;
            }
            return 0;
        }
    });

    // useEffect(() => {
    //     const modalElement = document.getElementById('AddManager');
    //     modalElement.addEventListener('hidden.bs.modal', resetModalState);
    //     return () => {
    //         modalElement.removeEventListener('hidden.bs.modal', resetModalState);
    //     };
    // }, []);

    const loadingSpinner = () => {
        return <div className="d-flex justify-content-center">
            <div className="spinner-border" >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (ob: any) => {
        return (<><th ></th>
            {/* <td colSpan="4" className="text-wrap"> */}
            <tr> <td className="text-wrap">  <b>Address:</b><span>{ob.branch_address}</span></td></tr>
            <tr> <td className="text-wrap">   <b>Phone No.:</b><span>{ob.phone_number}</span></td></tr>
            <tr> <td className="text-wrap"> <b>Saloon Type:</b><span>{ob.saloon_type}</span></td></tr>
            {ob.email == "" ? <></> : <tr> <td className="text-wrap"><b>Email:</b><span>{ob.email ? ob.email : '-'}</span></td></tr>}
            <tr> <td className="text-wrap"> <b>Currency:</b><span>{ob.currencyinfo?.short_code ? ob.currencyinfo?.short_code : '-'}</span></td></tr></>


        );
    };

    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        if (admin.accessType === 'Trail' && sortedBranches.length >= 1) {
            // Show Snackbar with a message
            setMsg('You cannot add more branches during the trial period. Please upgrade to Premium.');

            handleClickOpen()
        } else {
            // Navigate to the new branch page
            navigate('/saloonChain/newBranch'); // or use history.push if using react-router v5
        }
    };

    const updateModal = () => {
        return <>
            <Modal show={showUpdateModal} onHide={handleUpdateModalClose} centered backdrop="static" keyboard={false}>
                <Modal.Header onClick={() => resetModalState()} closeButton>
                    <Modal.Title>Branch - {branchName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showForm ? (
                        <form
                            onFocus={() => setMsg("")}
                            onSubmit={formik.handleSubmit}>
                            <div className="mb-2 mt-0 row">
                                <p>Select Branch Manager</p>
                                <label
                                    htmlFor="employee"
                                    className="col-sm-3 col-form-label"
                                >
                                    Employee<span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-sm-9">
                                    <select
                                        className={`form-control form-select shadow-sm ${formik.touched.employee && formik.errors.employee ? "is-invalid" : ""}`}
                                        id="employee"
                                        name="employee"
                                        onChange={handleEmployeeChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.employee}
                                    >
                                        <option value="">Select Employee</option>
                                        {filteredEmployeeList
                                            ?.filter((e: any) => e.is_active === true)
                                            .map((employee: any) => (
                                                <option key={employee.id} value={employee.id}>
                                                    {employee.first_name}&nbsp;{employee.last_name}
                                                </option>
                                            ))}
                                    </select>
                                    <div className="invalid-feedback">{formik.touched.employee && formik.errors.employee}</div>
                                </div>
                            </div>
                            {(empList.length === 0 && !isUser) && (
                                <>
                                    <div className="mb-2 row">
                                        <label htmlFor="username" className="col-sm-3 col-form-label">
                                            Username<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className={`form-control shadow-sm ${formik.touched.username && formik.errors.username ? "is-invalid" : ""}`}
                                                id="username"
                                                placeholder="Enter username or mobile"
                                                name="username"
                                                value={formik.values.username}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="invalid-feedback">{formik.touched.username && formik.errors.username}</div>
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label htmlFor="password" className="col-sm-3 col-form-label">
                                            Password<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="password"
                                                className={`form-control shadow-sm ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                                                id="password"
                                                placeholder="Enter Password"
                                                name="password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="invalid-feedback">{formik.touched.password && formik.errors.password}</div>
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label htmlFor="cpassword" className="col-sm-3 col-form-label">
                                            Confirm Password<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="password"
                                                className={`form-control shadow-sm ${formik.touched.cpassword && formik.errors.cpassword ? "is-invalid" : ""}`}
                                                id="cpassword"
                                                placeholder="Enter Confirm Password"
                                                name="cpassword"
                                                value={formik.values.cpassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="invalid-feedback">{formik.touched.cpassword && formik.errors.cpassword}</div>
                                        </div>
                                    </div>
                                    <div className="mb-2 row p-2 pb-0">
                                        <button type="submit" className="btn btn-purple btn-sm text-center" disabled={assignManagerLoading}>
                                            {assignManagerLoading ? "Adding..." : "Add Manager"}
                                        </button>
                                    </div>
                                </>
                            )}

                            {empList.length > 0 && (
                                <div className="mb-2 row p-2 pb-0">
                                    <button type="submit" className="btn btn-purple btn-sm text-center" disabled={assignManagerLoading}>
                                        {assignManagerLoading ? "Updating..." : "Update Manager"}
                                    </button>
                                </div>
                            )}
                            {/* <b>{msg}</b> */}
                        </form>) :
                        <div className="mb-2 row">
                            <div className="col-sm-12 text-center">
                                <p> There are no employees associated with the selected branch add New Employee from here.</p>
                                {/* <Link to="/saloonChain/newEmployee">
                                            <button className="btn btn-purple btn-sm" data-bs-dismiss="modal" >
                                                <FontAwesomeIcon icon={faPlus} /> Add New Employee
                                            </button>
                                        </Link> */}
                                <Link to={`/saloonChain/newEmployee?branchId=${selectedBranchId || defaultBMInfo?.id}`}>
                                    <button className="btn btn-purple btn-sm" onClick={handleUpdateModalClose}>
                                        <FontAwesomeIcon icon={faPlus} /> Add New Employee
                                    </button>
                                </Link>
                            </div>
                        </div>

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" disabled={assignManagerLoading ? true : false} onClick={handleUpdateModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    return (
        <>
            {updateModal()}
            <div className="container-fluid p-0">
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
                </Snackbar>
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                            <div className="text-center">
                                <h4>Branches</h4>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex justify-content-end">
                            {/* <Link to="/saloonChain/newBranch">
                                <button className="btn btn-purple btn-sm">
                                    <FontAwesomeIcon icon={faPlus} />&nbsp;New Branch
                                </button>
                            </Link> */}
                            <button
                                className="btn btn-purple btn-sm"
                                onClick={handleClick}
                            >
                                <FontAwesomeIcon icon={faPlus} />&nbsp;New Branch
                            </button>
                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={open}
                                autoHideDuration={5000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
                                    {msg}
                                </Alert>
                            </Snackbar>
                        </div>
                    </div>
                </div>

                {loading || branchList <= 0 ? (
                    <div className="mt-4">
                        {loading ? (
                            // Display loading spinner
                            <div className="d-flex justify-content-center align-items-center">
                                {loadingSpinner()}
                            </div>
                        ) : (
                            // Display custom message for no records
                            <h5>No Branches found. You can add a new Branch.</h5>
                        )}
                    </div>
                ) : <div className="mt-4 mt-xl-5 mt-sm-5 justify-content-center">
                    <div className={`table-container ${isMobile ? 'table-fixed' : 'table-responsive'}`}>
                        <table className="table table-striped table-hover border-light-subtle">
                            <thead>
                                <tr className="text-nowrap">
                                    <th style={isMobile ? { width: 'auto' } : {}}></th>
                                    <th style={{ cursor: "pointer" }} onClick={() => handleSort("branch_name")}>
                                        Branch Name &nbsp;
                                        <FontAwesomeIcon icon={faSort} />
                                    </th>
                                    {!isMobile && <th>
                                        Address
                                    </th>}
                                    {!isMobile && <th>Phone No.</th>}
                                    {!isMobile && <th style={{ cursor: "pointer" }} onClick={() => handleSort("saloon_type")}>
                                        Saloon Type &nbsp;
                                        <FontAwesomeIcon icon={faSort} />
                                    </th>}
                                    {!isMobile && <th>Email </th>}
                                    {!isMobile && <th>Currency </th>}

                                    <th className="text-center">Manager </th>
                                    {isMobile && <th></th>}
                                </tr>
                            </thead>

                            <tbody className="text-nowrap">
                                {sortedBranches?.map((ob: any, index: any) => {
                                    const isExpanded = expandedRowIndex === index; return (
                                        <React.Fragment key={index}>
                                            <tr key={index}>
                                                <td style={isMobile ? { width: 'auto', cursor: "pointer" } : { width: 'auto', cursor: "pointer" }}
                                                    className="fontIcon"
                                                    onClick={() => edit(ob)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} className="fontIcon" />
                                                </td>
                                                <td className="text-wrap">{ob.branch_name}</td>
                                                {!isMobile && <td>{ob.branch_address}</td>}
                                                {!isMobile && <td>{ob.phone_number}</td>}
                                                {!isMobile && <td>{ob.saloon_type}</td>}
                                                {!isMobile && <td>{ob.email ? ob.email : '-'}</td>}
                                                {!isMobile && <td>{ob.currencyinfo?.short_code ? ob.currencyinfo?.short_code : '-'}</td>}

                                                <td >
                                                    {ob.managerinfo == null ? (
                                                        <button style={{
                                                            width: isMobile ? "90px" : "150px"
                                                        }}
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#AddManager"
                                                            className="btn btn-warning btn-sm text-wrap"
                                                            onClick={() => { handleBranchSelect(ob); handleUpdateModalShow() }}
                                                        >
                                                            Assign Manager
                                                        </button>
                                                    ) : (
                                                        <><button

                                                            style={{
                                                                width: isMobile ? "90px" : "150px"
                                                            }}
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#AddManager"
                                                            onClick={() => { handleBranchSelect(ob); handleUpdateModalShow() }}
                                                            className="btn btn-info btn-sm text-wrap"
                                                        >
                                                            {ob.managerinfo.first_name}<br></br>{ob.managerinfo.last_name}
                                                        </button></>
                                                    )}
                                                </td>
                                                {isMobile && (
                                                    <td>
                                                        {!isExpanded ? (
                                                            <button
                                                                className="btn btn-link"
                                                                onClick={() => toggleRowExpansion(index)}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faArrowDown}
                                                                    className="fontIcon"
                                                                    size="sm"
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-link"
                                                                onClick={() => toggleRowExpansion(index)}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faArrowUp}
                                                                    className="fontIcon"
                                                                    size="sm"
                                                                />
                                                            </button>
                                                        )}</td>
                                                )}
                                            </tr>
                                            {isExpanded && isMobile && (
                                                <tr>
                                                    <td colSpan="4">{renderRowExpansionContent(ob)}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>)
                                }
                                )}
                            </tbody>

                        </table>
                    </div>
                </div>}
            </div>
        </>
    )
}














