// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
// import { useDispatch, useSelector } from "react-redux";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { addBranchReducer } from "../../../reduxData/branchSlice";
// import { branchValidationSchema } from '../../../validations/Validations'
// import { Alert, Snackbar } from "@mui/material";
// interface FormValues {
//     branch_name: string;
//     saloon_type: string;
//     branch_address: string;
//     phone_number: string;
//     email: string;
// }

// export default function NewBranch() {
//     const admin = useSelector((state: any) => state.authUserInfo.value);
//     const dispatch = useDispatch()
//     const [msg, setMsg] = useState("");
//     const [status, setStatus] = useState();
//     const [open, setOpen] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate()

//     const formik = useFormik({
//         initialValues: {
//             branch_name: "",
//             saloon_type: "",
//             branch_address: "",
//             phone_number: "",
//             email: "",
//         },
//         validationSchema: branchValidationSchema,
//         onSubmit: async (values) => {
//             try {
//                 setLoading(true);
//                 const ob = {
//                     saloon_id: admin.saloon_id,
//                     branch_name: values.branch_name,
//                     branch_address: values.branch_address,
//                     email: values.email ? values.email : null,
//                     phone_number: values.phone_number,
//                     saloon_type: values.saloon_type,
//                     created_by: admin.uid,
//                     updated_by: admin.uid,
//                 };

//                 const resp = await AuthApiService.SaveApiCall(urls.BRANCH_SAVE, ob, admin.token);
//                 if (resp.status === 200) {
//                     setMsg(resp.data.msg);
//                     handleClickOpen();
//                     setStatus(resp.data.status)
//                     dispatch(addBranchReducer(ob))

//                     if (resp.data.code == 500) {
//                         setStatus(false)
//                         if (resp.data.err === "Validation error") {
//                             setMsg("This Branch name is already in use please use different name...")
//                         }

//                     } else {
//                         setStatus(true)
//                         // formik.resetForm();
//                     }
//                     if (resp.data.status == 201) {
//                         setTimeout(() => {
//                             navigate('/saloonChain/branchDetails')
//                         }, 2000)
//                     } else {
//                         setTimeout(() => {
//                             navigate('/saloonChain/newBranch')
//                         }, 2000)
//                     }

//                     // navigate('/saloonChain/branchDetails')
//                 } else {
//                     if (resp.data && resp.data.err) {
//                         setMsg(resp.data.err);
//                         setStatus(resp.data.status)

//                     } else {
//                         setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
//                         setStatus(resp.data.status)

//                     }
//                 }
//             } catch (error: any) {

//                 setMsg("Network Error !");
//                 setStatus(error.response.status);
//                 handleClickOpen()

//             }
//             finally {
//                 setLoading(false);
//                 setTimeout(() => {
//                     // formik.resetForm();
//                 }, 1000);
//             }
//         },
//     });

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     return (
//         <div className="container-fluid p-0">
//             <Snackbar
//                 anchorOrigin={{ vertical: "top", horizontal: "center" }}
//                 open={open}
//                 autoHideDuration={5000}
//                 onClose={handleClose}
//             >
//                 <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == 500 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
//             </Snackbar>
//             <div className="container">
//                 <div className="row pr-xl-5 pl-xl-5">
//                     <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
//                         <div className="text-center">
//                             <h3>New Branch </h3>
//                         </div>
//                     </div>
//                     <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
//                         <Link to="/saloonChain/branchDetails">
//                             <span>
//                                 <button className=" btn btn-sm btn-purple">All Branches</button>
//                             </span>
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//             <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
//                 <div className="container">

//                     <div className="row justify-content-center mt-3">
//                         <div className="col-lg-12">
//                             <form
//                                 onFocus={() => setMsg("")}
//                                 onSubmit={formik.handleSubmit}>
//                                 <div className="mb-1 mb-xl-3 row">
//                                     <label htmlFor="branch_name" className="col-sm-3 col-form-label">
//                                         Branch Name<span style={{ color: "red" }}>*</span>
//                                     </label>
//                                     <div className="col-sm-7">
//                                         <input
//                                             type="text"
//                                             className={`form-control shadow-sm ${formik.touched.branch_name && formik.errors.branch_name ? 'is-invalid' : ''}`}
//                                             id="branch_name"
//                                             placeholder="Enter Name"
//                                             name="branch_name"
//                                             onChange={formik.handleChange}
//                                             onBlur={formik.handleBlur}
//                                             value={formik.values.branch_name}
//                                         />
//                                         <div className="invalid-feedback">{formik.touched.branch_name && formik.errors.branch_name}</div>
//                                     </div>
//                                 </div>
//                                 <div className="mb-1 mb-xl-3 row">
//                                     <label htmlFor="saloon_type" className="col-sm-3 col-form-label">
//                                         Salon Type<span style={{ color: "red" }}>*</span>
//                                     </label>
//                                     <div className="col-sm-7">
//                                         <select
//                                             className={`form-control form-select shadow-sm ${formik.touched.saloon_type && formik.errors.saloon_type ? 'is-invalid' : ''}`}
//                                             id="saloon_type"
//                                             name="saloon_type"
//                                             onChange={formik.handleChange}
//                                             onBlur={formik.handleBlur}
//                                             value={formik.values.saloon_type}
//                                         >
//                                             <option value="">Select Salon Type</option>
//                                             <option value="Male">Male</option>
//                                             <option value="Female">Female</option>
//                                             <option value="Unisex">Unisex</option>
//                                         </select>
//                                         <div className="invalid-feedback">{formik.touched.saloon_type && formik.errors.saloon_type}</div>
//                                     </div>
//                                 </div>
//                                 <div className="mb-1 mb-xl-3 row">
//                                     <label htmlFor="address" className="col-sm-3 col-form-label">
//                                         Address<span style={{ color: "red" }}>*</span>
//                                     </label>
//                                     <div className="col-sm-7">
//                                         <input
//                                             type="text"
//                                             className={`form-control shadow-sm ${formik.touched.branch_address && formik.errors.branch_address ? 'is-invalid' : ''}`}
//                                             id="address"
//                                             placeholder="Enter Address"
//                                             name="branch_address"
//                                             onChange={formik.handleChange}
//                                             onBlur={formik.handleBlur}
//                                             value={formik.values.branch_address}
//                                         />
//                                         <div className="invalid-feedback">{formik.touched.branch_address && formik.errors.branch_address}</div>
//                                     </div>
//                                 </div>
//                                 <div className="mb-1 mb-xl-3 row">
//                                     <label htmlFor="phone" className="col-sm-3 col-form-label">
//                                         Phone<span style={{ color: "red" }}>*</span>
//                                     </label>
//                                     <div className="col-sm-7">
//                                         <input
//                                             type="text"
//                                             className={`form-control shadow-sm ${formik.touched.phone_number && formik.errors.phone_number ? 'is-invalid' : ''}`}
//                                             id="phone"
//                                             placeholder="Enter Phone"
//                                             name="phone_number"
//                                             onChange={formik.handleChange}
//                                             onBlur={formik.handleBlur}
//                                             value={formik.values.phone_number}
//                                         />
//                                         <div className="invalid-feedback">{formik.touched.phone_number && formik.errors.phone_number}</div>
//                                     </div>
//                                 </div>
//                                 <div className="mb-1 mb-xl-3 row">
//                                     <label htmlFor="email" className="col-sm-3 col-form-label">
//                                         Email
//                                     </label>
//                                     <div className="col-sm-7">
//                                         <input
//                                             type="email"
//                                             className={`form-control shadow-sm ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
//                                             id="email"
//                                             placeholder="Enter email"
//                                             name="email"
//                                             onChange={formik.handleChange}
//                                             onBlur={formik.handleBlur}
//                                             value={formik.values.email}
//                                         />
//                                         <div className="invalid-feedback">{formik.touched.email && formik.errors.email}</div>
//                                     </div>
//                                 </div>

//                                 <div className="mt-3 mb-xl-3 row text-center">
//                                     <div className="col-lg-5"></div>
//                                     <div className="col-lg-2">
//                                         <button type="submit" className="btn form-control btn-purple btn-md" disabled={loading}>
//                                             {loading ? 'Saving...' : 'Add'}
//                                         </button>
//                                     </div>
//                                     <div className="col-lg-3"></div>
//                                 </div>

//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }





import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addBranchReducer } from "../../../reduxData/branchSlice";
import { branchValidationSchema } from '../../../validations/Validations'
import { Alert, Snackbar } from "@mui/material";
import Select from "react-select";
interface FormValues {
    branch_name: string;
    saloon_type: string;
    branch_address: string;
    phone_number: string;
    email: string;
    currency: string;
}

export default function NewBranch() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const currencyList = useSelector((state: any) => state.currencyInfo.value);

    const dispatch = useDispatch()
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            branch_name: "",
            saloon_type: "",
            branch_address: "",
            phone_number: "",
            email: "",
            currency: null
        },
        validationSchema: branchValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob = {
                    saloon_id: admin.saloon_id,
                    branch_name: values.branch_name,
                    branch_address: values.branch_address,
                    email: values.email ? values.email : null,
                    phone_number: values.phone_number,
                    saloon_type: values.saloon_type,
                    currency: values.currency || null,
                    created_by: admin.uid,
                    updated_by: admin.uid,
                };

                const resp = await AuthApiService.SaveApiCall(urls.BRANCH_SAVE, ob, admin.token);
                if (resp.status === 200) {
                    setMsg(resp.data.msg);
                    handleClickOpen();
                    setStatus(resp.data.status)
                    dispatch(addBranchReducer(ob))

                    if (resp.data.code == 500) {
                        setStatus(false)
                        if (resp.data.err === "Validation error") {
                            setMsg("This Branch name is already in use please use different name...")
                        }

                    } else {
                        setStatus(true)
                        // formik.resetForm();
                    }
                    if (resp.data.status == 201) {
                        setTimeout(() => {
                            navigate('/saloonChain/branchDetails')
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/newBranch')
                        }, 2000)
                    }

                    // navigate('/saloonChain/branchDetails')
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)

                    }
                }
            } catch (error: any) {

                setMsg("Network Error !");
                setStatus(error.response.status);
                handleClickOpen()

            }
            finally {
                setLoading(false);
                setTimeout(() => {
                    // formik.resetForm();
                }, 1000);
            }
        },
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const currencyOptions = currencyList.map((item: any) => ({
        value: item.id,
        label: item.currency,
    }));

    return (
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == 500 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">
                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                        <div className="text-center">
                            <h4>New Branch </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/saloonChain/branchDetails">
                            <span>
                                <button className=" btn btn-sm btn-purple">All Branches</button>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                <div className="container">

                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form
                                onFocus={() => setMsg("")}
                                onSubmit={formik.handleSubmit}>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="branch_name" className="col-sm-3 col-form-label">
                                        Branch Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.branch_name && formik.errors.branch_name ? 'is-invalid' : ''}`}
                                            id="branch_name"
                                            placeholder="Enter Name"
                                            name="branch_name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.branch_name}
                                        />
                                        <div className="invalid-feedback">{formik.touched.branch_name && formik.errors.branch_name}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="saloon_type" className="col-sm-3 col-form-label">
                                        Salon Type<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            className={`form-control form-select shadow-sm ${formik.touched.saloon_type && formik.errors.saloon_type ? 'is-invalid' : ''}`}
                                            id="saloon_type"
                                            name="saloon_type"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.saloon_type}
                                        >
                                            <option value="">Select Salon Type</option>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                            <option value="UNISEX">Unisex</option>
                                        </select>
                                        <div className="invalid-feedback">{formik.touched.saloon_type && formik.errors.saloon_type}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="address" className="col-sm-3 col-form-label">
                                        Address<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.branch_address && formik.errors.branch_address ? 'is-invalid' : ''}`}
                                            id="address"
                                            placeholder="Enter Address"
                                            name="branch_address"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.branch_address}
                                        />
                                        <div className="invalid-feedback">{formik.touched.branch_address && formik.errors.branch_address}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="phone" className="col-sm-3 col-form-label">
                                        Phone<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.phone_number && formik.errors.phone_number ? 'is-invalid' : ''}`}
                                            id="phone"
                                            placeholder="Enter Phone"
                                            name="phone_number"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phone_number}
                                        />
                                        <div className="invalid-feedback">{formik.touched.phone_number && formik.errors.phone_number}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="email" className="col-sm-3 col-form-label">
                                        Email
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="email"
                                            className={`form-control shadow-sm ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                            id="email"
                                            placeholder="Enter email"
                                            name="email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        <div className="invalid-feedback">{formik.touched.email && formik.errors.email}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="email" className="col-sm-3 col-form-label">
                                        Currency
                                    </label>
                                    <div className="col-sm-7">
                                        <Select
                                            // menuIsOpen={true}
                                            maxMenuHeight="8em"
                                            id="currency"
                                            name="currency"
                                            className={`react-select-container ${formik.touched.currency && formik.errors.currency ? 'is-invalid' : ''}`}
                                            options={currencyOptions}
                                            isSearchable
                                            placeholder="Select currency"
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue("currency", selectedOption.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            value={currencyOptions.find(option => option.value === formik.values.currency)}

                                        />
                                        <div className="invalid-feedback">{formik.touched.currency && formik.errors.currency}</div>
                                    </div>
                                </div>

                                <div className="mt-3 mb-xl-3 row text-center">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn form-control btn-purple btn-md" disabled={loading}>
                                            {loading ? 'Saving...' : 'Add'}
                                        </button>
                                    </div>
                                    <div className="col-lg-3"></div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


