import { Link } from "react-router-dom";
import Header from "../../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch, faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useSelector } from "react-redux";
import Select from "react-select";

export default function LeaveReport() {
  const _ = require("lodash")

  const admin = useSelector((state: any) => state.authUserInfo.value);

  const [b, setb] = useState([]);
  const [msg, setMsg] = useState([]);
  const [sdata, setData] = useState([]);

  const [branchinfo, setbranchinfo] = useState(null);
  const [empinfo, setempinfo] = useState([]);
  const [flist, setflist] = useState([]);
  const mon = useRef()
  const [days, setdays] = useState([]);
  const [employees, setemployees] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [weekoffDay, setWeekoffDay] = useState();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentDate = new Date().toISOString().substring(0, 10);
  const [bname, setbName] = useState("")




  const loadingSpinner = () => {
    return <div className="container-fluid mt-5"><div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div></div>
  }

  const handleEmployeeInfo = async (data: any) => {
    setLoading(true)
    const res = await AuthApiService.GetApiCall(urls.EMPLOYEE_LIST, admin.token);
    if (res.status) {
      setLoading(false);
      // const newData = res.data.data.filter((d: any) => { return d.branch_info.id === data })
      const newData = res.data.data.filter((d: any) => d.branch_info.id === data && d.is_active);
      setempinfo(newData);
      // setempinfo(newData);
    }
  }

  const branchlist = async () => {
    setLoading(true);
    const resp = await AuthApiService.GetApiCall(urls.BRANCH_LIST, admin.token);
    setb(resp.data.data);
    setLoading(false);

  };

  var filteredList = [];
  const handleBranchFilter = (e: any) => {
    filteredList = b.filter((data: any) => { return data.branch_name.toLowerCase().includes(e.target.value.toLowerCase()) }).map((data) => {
      return <li className="nav-item">
        <button className="nav-link" onClick={() => { window.scrollTo(0, 0); setdays([]); setemployees([]); setbranchinfo(data); handleEmployeeInfo(data) }}>
          <span className="px-1 menu-title" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close">{data?.branch_name}
          </span>
        </button>
      </li>
    })
    setflist(filteredList)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const [y, m] = (mon.current.value).split('-');
    const url = urls.LEAVE_BY_BRANCH_AND_MONTH + branchinfo + "/" + mon.current.value;
    const res = await AuthApiService.GetApiCall(url, admin.token);

    setWeekoffDay(res?.data?.saloonLeaveDetails?.weekoff_day)
    setbName(res?.data?.data[0]?.employeeinfo?.branch_info?.branch_name)
    if (res.status) {
      // if (res.data.data) {
      //   setMsg("No Records Found")
      // } else {
      setLoading(false);
      var data = res.data.data;
      data = data.filter((data: any) => { return data.employeeinfo.branch_id === branchinfo });
      setData(data)
      const newdays = new Date(y, m, 0).getDate();
      var daysarr: any = [];
      for (let i = 1; i <= newdays; i++) {
        let day = new Date(y, m - 1, i);
        const dayname = day.toLocaleDateString('default', { weekday: 'short' });
        let nd = i.toString().padStart(2, '0');
        daysarr.push(`${y}-${m}-${nd}-${dayname}`);
      }
      setdays(daysarr);
      var final = [];
      // const emp = _.sortBy(_.uniq(_.map(data,(d)=>{return (d.employeeinfo.first_name+" "+d.employeeinfo.emp_id)})))
      for (let e of empinfo) {
        var list = [];
        var pcount = 0;
        var acount = 0;
        list.push(e.emp_id + " " + e.first_name)
        for (let days of daysarr) {
          if (days.includes(res?.data?.saloonLeaveDetails?.weekoff_day)) {
            list.push('WO');
            continue
          }
          var found = false
          for (let d of data) {
            if (d.leave_date.substring(0, 10) === days.substring(0, 10) && e.emp_id.includes(d.employeeinfo.emp_id) && days.substring(0, 10) >= e.date_of_joining.substring(0, 10)) {
              if (d.leave_type === 'FullDay') {
                acount = acount + 1;
                list.push("FL")
                found = true
                break;
              }
              else if (d.leave_type === 'HalfDay') {
                acount = acount + 0.5;
                list.push("HL")
                found = true
                break;
              }
            }
          }
          if (!found && (days.substring(0, 10) >= e.date_of_joining.substring(0, 10)) && currentDate > days.substring(0, 10)) {
            pcount = pcount + 1;
            list.push("P");
          }
          else if (!found) {
            list.push("-");
          }
        }
        list.push(pcount)
        list.push(acount)
        final.push(list);
      }
      setemployees(final);
      // console.log(final)
      // }
    }
  }

  useEffect(() => { branchlist(); }, []);

  const handlePrint = () => {
    window.print();
  };

  const branchOptions = flist.length > 0 ? flist : b.map((item: any) => ({
    value: item.id,
    label: item.branch_name,
  }))
  return (
    <><div className="container-fluid p-0 p-xl-0 pr-xl-0 pl-xl-0  mt-sm-0 mt-0  mt-xl-0">
      <div className="container">

        <div className="row m-0 d-print-none">
          {/* <div className="col-lg-12 col-md-12 col-sm-12 "> */}
          <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
            <Link to="/saloonChain/leaves">
              <span>
                <button className=" btn btn-sm btn-purple">Back</button>
              </span>
            </Link>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-6 col-6">

          </div>
          <div className="col-lg-2 col-md-2 col-sm-3 col-4 mt-2 d-flex justify-content-end">
            <Link to="">
              <span>
                <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
              </span>
            </Link>
          </div>
          {/* </div> */}
        </div>
      </div>




      {/* <div className="row justify-content-center"> */}
      <h4 className="text-center">Attendence Report</h4>
      {/* {b.length != 0 && <h5 className="text-center my-3">Branch Name : {b.branch_name}</h5>} */}
      {/* </div> */}
      <div className="row mt-1 pb-4 d-print-none">
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <form
            onSubmit={handleSubmit}
          >
            <div className="row m-0 p-0 d-print-none">
              <div className="col-lg-3 col-md-3 col-sm-3 col-6 m-0 m-0 p-0 mr-xl-2  ">
                <label>Branch</label>

                <Select
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  className="basic-single"
                  classNamePrefix="select"
                  value={branchinfo ? { value: branchinfo, label: b.find(item => item.id === branchinfo)?.branch_name || "Select Branch" } : null}
                  placeholder="Select Branch"
                  isLoading={branchinfo && branchinfo.length <= 0 ? true : false}
                  isClearable={true}
                  isSearchable={true}
                  options={branchOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setbranchinfo(selectedOption.value); // Update selected branch info
                      handleEmployeeInfo(selectedOption.value)
                    } else {
                      setbranchinfo("");
                      setempinfo([]);
                      setflist([]);
                      setdays([]);
                      setemployees([]);
                      setbName("")
                    }
                  }}
                  required
                />

              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-6   ">
                <label></label>

                <div className="input-group input-group-md shadow-sm ">
                  <input
                    type="month"
                    className="form-control shadow-sm"
                    placeholder="Search Employee Name..."
                    defaultValue={`${currentYear}-0${currentMonth}`}
                    ref={mon}
                  />
                  <button className="btn btn-sm" type="submit" >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <b>{msg}</b> */}
      {isLoading ? loadingSpinner() : <div>
        {bname ? <h5 className="text-center mb-3">Branch-{bname}</h5> : <div></div>}
        {sdata ?
          <div className="table-responsive">
            <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
              <thead>
                <tr className="text-nowrap">
                  {days.length != 0 && <th>Emp Name</th>}
                  {days.map((data: any) => { return <th style={{ backgroundColor: data?.includes(weekoffDay) ? 'rgba(0,0,0,0.1)' : '' }}>{data.substring(8, 10)}<br />{data.substring(11, 12)}</th> })}
                  {days.length != 0 && <th className="sticky-right ">P</th>}
                  {days.length != 0 && <th className="sticky-right ">A</th>}
                </tr>
              </thead>
              <tbody>
                {employees.map((data: any) => {
                  return <tr>
                    {data.map((d: any) => { return <td style={{ backgroundColor: d == "WO" ? 'rgba(0,0,0,0.1)' : '', color: d == "WO" ? "red" : "", fontWeight: d == "WO" ? "bold" : "" }}>{d}</td> })}
                  </tr>
                })}
              </tbody>
            </table>
          </div> : <b>No Records found</b>}
      </div>}
    </div>

    </>
  );
}
