import { faArrowDown, faArrowUp, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listEmployeeReducer } from "../../../reduxData/employeeSlice";

export default function ManagerEmployeeList() {
    const bmanager = useSelector((state: any) => state.authUserInfo?.value);

    const _ = require("lodash")
    const employeeList = useSelector((state: any) => state.employeeInfo.value);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [sort, setsort] = useState(false);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const sortByName = () => {
        setsort(!sort);
        if (sort) {
            const nw = _.orderBy(employeeList, ['first_name'], ['asc'])
            dispatch(listEmployeeReducer(nw))
        }
        else {
            const nw = _.orderBy(employeeList, ['first_name'], ['desc'])
            dispatch(listEmployeeReducer(nw))
        }
    }

    const formateDate = (datestr: any) => {
        if (!datestr) {
            return '-';
        }

        const date = new Date(datestr);
        return date.toLocaleDateString();
    }

    const loadingSpinner = () => {
        return <div className="d-flex justify-content-center">
            <div className="spinner-border" >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (employee: any) => {
        return (<><th></th>

            <tr> <td className="text-wrap">  <b>Emp Code:</b><span>{employee.emp_id}</span></td></tr>
            <tr> <td className="text-wrap">  <b>Gender:</b><span>{employee.gender}</span></td></tr>
            {/* <tr> <td className="text-wrap">   <b>PhoneNo:</b><span>{employee.mobile_1}</span></td></tr> */}
            <tr> <td className="text-wrap"> <b>Category:</b><span>{employee?.category_info?.category_title}</span></td></tr>
            {/* <tr> <td className="text-wrap"><b>Salary({bmanager.currency}):</b><span>{employee.salary}</span></td></tr> */}
            <tr> <td className="text-wrap"><b>Joining Date:</b><span>{employee.date_of_joining.split('T')[0]}</span></td></tr>
            {/* <tr> <td className="text-wrap"><b>Branch:</b><span>{employee?.branch_info?.branch_name}</span></td></tr> */}

            {employee.date_of_relieving == "" || null ? <></> : <tr> <td className="text-wrap"><b>Relieving Date:</b><span>{employee.date_of_relieving !== null ? formateDate(employee.date_of_relieving) : '-'}</span></td></tr>}
            {employee.date_of_birth == "" || null ? <></> : <tr> <td className="text-wrap"><b>DOB:</b><span>{formateDate(employee.date_of_birth)}</span></td></tr>}
            {employee.govt_id == "" || null ? <></> : <tr> <td className="text-wrap"><b>Govt.Id:</b><span>{employee.govt_id ? employee.govt_id : "-"}</span></td></tr>}
            {employee.email == "" || null ? <></> : <tr> <td className="text-wrap"><b>Email:</b><span>{employee.email ? employee.email : "-"}</span></td></tr>}
        </>
        );
    };


    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return <>
        <div className="container-fluid p-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center ">
                        <div className="text-center">
                            <h4>Employees</h4>
                        </div>
                    </div>
                </div>
            </div>
            {loading || employeeList.length <= 0 ? (
                <div className="mt-4">
                    {loading ? (
                        // Display loading spinner
                        <div className="d-flex justify-content-center align-items-center">
                            {loadingSpinner()}
                        </div>
                    ) : (
                        // Display custom message for no records
                        <h5>No Employees found.</h5>
                    )}
                </div>
            ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                <div className={`table-container table-responsive`}>
                    <table className="table table-striped  table-hover border-light-subtle ">
                        <thead>
                            <tr className="text-nowrap text-center">
                                {!isMobile && <th style={isMobile ? { width: 'auto' } : {}}>S.No</th>}
                                {!isMobile && <th>EmpCode</th>}
                                <th style={{ "cursor": "pointer" }} onClick={() => { sortByName() }}>Name&nbsp;<FontAwesomeIcon icon={faSort} cursor="pointer" onClick={() => { sortByName() }} /></th>
                                {!isMobile && <th>Gender </th>}
                                <th>PhoneNo.</th>
                                {!isMobile && <th>Category &nbsp;</th>}
                                {/* {!isMobile && <th className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>Salary ({bmanager.currency})</th>} */}
                                {!isMobile && <th>Joining Date</th>}
                                {/* {!isMobile && <th>Branch</th>} */}

                                {!isMobile && <th>Relieving Date  </th>}
                                {!isMobile && <th>DOB </th>}
                                {!isMobile && <th>Govt.Id</th>}
                                {!isMobile && <th>Email </th>}
                                <th>Status</th>

                                {isMobile && <th></th>}
                            </tr>
                        </thead>
                        <tbody className="text-nowrap">
                            {employeeList?.map((employee: any, index: number) => {
                                const isExpanded = expandedRowIndex === index; return (
                                    <React.Fragment key={index}>
                                        <tr key={index} className="text-center">
                                            {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}
                                            {!isMobile && <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{employee.emp_id}</td>}
                                            <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{isMobile ? <>{employee.first_name}<br></br>{employee.last_name}</> : <>{employee.first_name}&nbsp; {employee.last_name}</>}</td>
                                            {!isMobile && <td>{employee.gender}</td>}
                                            <td>{employee.mobile_1}</td>
                                            {!isMobile && <td>{employee?.category_info?.category_title}</td>}
                                            {/* {!isMobile && <td>{employee.salary}</td>} */}
                                            {!isMobile && <td>{employee.date_of_joining.split('T')[0]}</td>}

                                            {/* {!isMobile && <td>{employee?.branch_info?.branch_name}</td>} */}

                                            {!isMobile && <td>{employee.date_of_relieving !== null ? formateDate(employee.date_of_relieving) : '-'}</td>}
                                            {!isMobile && <td>{formateDate(employee.date_of_birth)}</td>}
                                            {!isMobile && <td>{employee.govt_id ? employee.govt_id : "-"}</td>}
                                            {!isMobile && <td>{employee.email ? employee.email : "-"}</td>}

                                            <td>
                                                {employee.is_active ? (
                                                    <button
                                                        className="btn btn-sm btn-warning"

                                                    >
                                                        Active
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-sm btn-danger"
                                                    // style={{ "width": "120px" }}

                                                    >
                                                        Deactive
                                                    </button>
                                                )}
                                            </td>
                                            {isMobile && (
                                                <td>
                                                    {!isExpanded ? (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowDown}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowUp}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    )}</td>
                                            )}
                                        </tr>
                                        {isExpanded && isMobile && (
                                            <tr>
                                                <td colSpan={6}>{renderRowExpansionContent(employee)}</td>
                                            </tr>
                                        )}
                                    </React.Fragment>)
                            }
                            )}
                        </tbody>
                    </table>

                </div>
            </div>}
        </div>
    </>
}