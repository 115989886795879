import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import jsPDF from "jspdf";
export default function ManagerCustomerBill() {
    const authUser = useSelector((state: any) => state.authUserInfo.value);
    const SERVER = process.env.BASEURL;
    const billInfo = useSelector((state: any) => state.customerInfo.bill);
    // const billInfo = billInf.map((data:any)=>data)
    const localBillInfo = JSON.parse(localStorage.getItem("billinfo"));
    console.log("bills", billInfo, localBillInfo)
    const navigate = useNavigate()
    const [pdfUrl, setPdfUrl] = useState("");

    const handlePrint = () => {
        window.print();
    };

    const handleShare = () => {
        // Construct the bill details text
        const billDetails = `
            Bill Invoice
            Date:${new Date(billInfo[0]?.transaction_date).toLocaleDateString('en-GB') ?? localBillInfo[0]?.transaction_date}
            Customer Name: ${billInfo[0]?.customerinfo?.name ?? localBillInfo[0]?.customerinfo?.name ?? 'N/A'}
            Total Amount: ${billInfo[0]?.amount ?? localBillInfo[0]?.amount ?? 'N/A'}
            Amount Paid : ${billInfo[0]?.total_amount_paid ?? localBillInfo[0]?.total_amount_paid}
            Due Amount : ${billInfo[0]?.dues ?? localBillInfo[0]?.dues}

ThankYou
HairPort
        `;

        // Create the shareable message
        const message = `Check out this bill:\n${billDetails}`;

        // Open WhatsApp with the message
        window.open(`https://wa.me/?text=${encodeURIComponent(message)}`, '_blank');
    };

    const calculateDiscount = (amount: any, discountPercent: any) => {
        return (amount * discountPercent / 100).toFixed(2);
    };

    const calculateRate = (amount: any, discountPercent: any) => {
        return (amount - calculateDiscount(amount, discountPercent)).toFixed(2);
    };


    return (
        <>
            <div className="container-fluid p-1">
                <div className="container">

                    {/* <div className="row m-0 d-print-none">

                        <div className="col-lg-9 col-md-9 col-sm-8 mt-2 col-4 justify-content-start  d-print-none">

                            <span>
                                <button className=" btn btn-sm btn-purple" onClick={() => navigate(-1)}>Back</button>
                            </span>

                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-3 col-5 mr-0 mt-2 justify-content-end d-print-none m-0">
                            <Link to="">
                                <span>
                                    <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
                                </span>
                            </Link>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-1 mt-2 justify-content-end d-print-none mr-0 mt-0">

                            <span className="btn btn-success btn-sm" onClick={handleShare}><FontAwesomeIcon icon={faWhatsapp} className="sideIcon fa-lg fa-10x text-white" />
                            </span>

                        </div>

                    </div> */}
                    <div className="row m-0 d-print-none d-flex justify-content-between">

                        <div className="col-lg-9 col-md-9 col-sm-8 mt-2 col-4 d-flex align-items-center">
                            <button className="btn btn-sm btn-purple" onClick={() => navigate(-1)}>Back</button>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-3 col-5 d-flex align-items-center justify-content-end">
                            <Link to="">
                                <button className="btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
                            </Link>
                        </div>

                        <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-flex align-items-center">
                            <span className="btn btn-success btn-sm" onClick={handleShare}>
                                <FontAwesomeIcon icon={faWhatsapp} className=" fa-lg fa-10x" />
                            </span>
                        </div>

                    </div>

                    <div className="row m-0 mb-xl-3 d-print-none text-center">
                        <h4>Bill Invoice</h4>
                    </div>
                </div>
                {/* <div className="container"> */}
                {authUser.roleValue == "STF" ? <><div className="row mt-4 justify-content-around">
                    <div className="col-sm-5 col-5 col-md-5 col-lg-5 border border-dark">
                        <div className="row mt-1 justify-content-between">
                            <div className="col-lg-10 col-md-9 col-sm-11 align-self-center"><p>From</p></div>
                            <div className="col-lg-2 col-md-3 col-sm-1 pe-1 pt-0"><img
                                src={`${SERVER}/${authUser?.saloon_logo}`}
                                className="img-fluid"
                                height="60px"
                                width="80px"
                            /></div>
                        </div>

                        <h5 className="fontIcon">{authUser?.saloonName}</h5>
                        <p className="text-muted m-0">{billInfo?.branch_info?.branch_address ?? localBillInfo?.branch_info?.branch_address}</p>
                        {/* <p className="text-muted m-0">{`${billInfo[0]?.employee_info?.first_name ?? localBillInfo[0]?.employee_info?.first_name} ${billInfo[0]?.employee_info?.last_name ?? localBillInfo[0]?.employee_info?.last_name}`}</p> */}
                        <p className="text-muted mb-1">{billInfo?.branch_info?.email ?? localBillInfo?.branch_info?.email}</p>
                    </div>
                    <div className="col-sm-5 col-5 col-md-5 col-lg-5 border border-dark">
                        <div className="row mt-1 d-flex">
                            <div className="col-12 d-flex align-self-center"><p className="p-0">To</p></div>
                        </div>
                        <h5 className="fontIcon">{billInfo?.customerinfo == null && localBillInfo?.customerinfo == null ? "-" : (billInfo?.customerinfo?.name ?? localBillInfo?.customerinfo?.name)}</h5>
                        <p className="text-muted m-0">{billInfo?.customerinfo == null && localBillInfo?.customerinfo == null ? "-" : (billInfo?.customerinfo?.mobile ?? localBillInfo?.customerinfo?.mobile)}</p>
                        <p className="text-muted mb-1">{billInfo?.customerinfo == null && localBillInfo?.customerinfo == null ? "-" : (billInfo?.customerinfo?.email ?? localBillInfo?.customerinfo?.email ?? "-")}</p>
                    </div>
                </div>
                    <div className="row mt-4 justify-content-around">
                        {/* <div className="col-5"><p><span className="fw-semibold">Invoice No : </span>{billInfo[0]?.id ?? localBillInfo[0].id}</p></div> */}
                        {/* <div className="col-5"><p><span className="fw-semibold">Due Date : </span></p></div> */}
                        <div className="col-5"><p className=""><span className="fw-semibold ">Invoice Date : </span>{new Date(billInfo?.transaction_date).toLocaleDateString('en-GB') ?? localBillInfo?.transaction_date}</p></div>
                        {/* <div className="col-5"></div> */}
                    </div>
                    <div className="row me-lg-3 ms-lg-3">
                        {billInfo != null ?
                            billInfo?.transactionItems?.map((d: any) => d?.item_type).includes('Service') && billInfo?.transactionItems?.map((d: any) => d?.item_type).includes('Product') ?
                                <>
                                    <div className="col-12">
                                        <div className="table table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                        <th>Service</th>
                                                        <th>MRP({authUser.currency || '₹'})</th>
                                                        <th>Qty</th>
                                                        <th>Discount</th>
                                                        <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                        <th>Total({authUser.currency || '₹'})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        billInfo?.transactionItems?.filter(d => d.item_type == 'Service').map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                            <td>{data?.service_type}</td>
                                                            <td>{data?.mrp}</td>
                                                            <td >{data?.quantity}</td>
                                                            <td >{data?.discount_percent}%</td>
                                                            <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                            <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="table table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                        <th>Product</th>
                                                        <th>MRP({authUser.currency || '₹'})</th>
                                                        <th>Qty</th>
                                                        <th>Discount</th>
                                                        <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                        <th>Total({authUser.currency || '₹'})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        billInfo?.transactionItems?.filter(d => d.item_type == 'Product').map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                            <td>{data?.product_name}</td>
                                                            <td >{data?.mrp}</td>

                                                            <td >{data?.quantity}</td>
                                                            <td >{data?.discount_percent}%</td>
                                                            <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                            <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="col-12">
                                    <div className="table table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                    <th>{billInfo?.transactionItems?.map(d => d.item_type).includes('Service') ? 'Service' : 'Product'}</th>
                                                    <th>MRP({authUser.currency || '₹'})</th>
                                                    <th>Qty</th>
                                                    <th>Discount</th>
                                                    <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                    <th>Total({authUser.currency || '₹'})</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    billInfo?.transactionItems?.map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                        <td>{data?.service_type ?? data?.product_name}</td>
                                                        <td >{data?.mrp}</td>
                                                        <td >{data?.quantity}</td>
                                                        <td >{data?.discount_percent}%</td>
                                                        <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                        <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            :
                            localBillInfo?.transactionItems[0]?.map(d => d.item_type).includes('Service') && localBillInfo[0]?.transactionItems?.map(d => d.item_type).includes('Product') ?
                                <>
                                    <div className="col-12">
                                        <div className="table table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                        <th>Service</th>
                                                        <th>MRP({authUser.currency || '₹'})</th>
                                                        <th>Qty</th>
                                                        <th>Discount</th>
                                                        <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                        <th>Total({authUser.currency || '₹'})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        localBillInfo?.transactionItems[0]?.filter(d => d.item_type == 'Service').map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                            <td>{data?.service_type}</td>
                                                            <td >{data?.mrp}</td>
                                                            <td >{data?.quantity}</td>
                                                            <td >{data?.discount_percent}%</td>
                                                            <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                            <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="table table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                        <th>Product</th>
                                                        <th>MRP({authUser.currency || '₹'})</th>
                                                        <th>Qty</th>
                                                        <th>Discount</th>
                                                        <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                        <th>Total({authUser.currency || '₹'})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        localBillInfo?.transactionItems[0]?.filter(d => d.item_type == 'Product').map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                            <td>{data?.product_name}</td>
                                                            <td >{data?.mrp}</td>
                                                            <td >{data?.quantity}</td>
                                                            <td >{data?.discount_percent}%</td>
                                                            <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                            <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="col-12">
                                    <div className="table table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                    <th>{localBillInfo?.transactionItems?.map(d => d.item_type).includes('Service') ? 'Service' : 'Product'}</th>
                                                    <th>MRP({authUser.currency || '₹'})</th>
                                                    <th>Qty</th>
                                                    <th>Discount</th>
                                                    <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                    <th>Total({authUser.currency || '₹'})</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    localBillInfo?.transactionItems[0]?.map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                        <td>{data?.service_type ?? data?.product_name}</td>
                                                        <td >{data?.mrp}</td>
                                                        <td >{data?.quantity}</td>
                                                        <td >{data?.discount_percent}%</td>
                                                        <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                        <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        }

                    </div>
                    <div className="row justify-content-end me-lg-3 ms-lg-3">
                        <div className="col-12 col-lg-12 col-md-12">
                            <div className="table table-responsive">
                                <table className="table table-responsive" id="report-table">
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>Invoice Summary</th>
                                        </tr>
                                        <tr>
                                            <th>Total({authUser.currency || '₹'})</th>
                                            <th>Due({authUser.currency || '₹'})</th>
                                            <th>Paid({authUser.currency || '₹'})</th>
                                            <th>Payment</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {billInfo?.payment_info?.length > 0 ? (
                                            <tr>
                                                <td>{billInfo?.amount ?? localBillInfo?.amount}</td>
                                                <td>{billInfo?.dues ?? localBillInfo?.dues}</td>
                                                <td>{billInfo?.total_amount_paid ?? localBillInfo?.total_amount_paid}</td>
                                                <td colSpan="2">
                                                    {billInfo?.payment_info.map((info: any, index: any) => (
                                                        <div key={index}>
                                                            <b>{info?.payment_mode}:</b> {info?.amount_paid}
                                                        </div>
                                                    ))}
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td>{localBillInfo?.amount}</td>
                                                <td>{localBillInfo?.payment_info?.due_amount}</td>
                                                <td>{localBillInfo?.payment_info?.amount_paid}</td>
                                                <td>{localBillInfo?.payment_info?.payment_mode}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> </>
                    : <><div className="row mt-4 justify-content-around">
                        <div className="col-sm-5 col-5 col-md-5 col-lg-5 border border-dark">
                            <div className="row mt-1 justify-content-between">
                                <div className="col-lg-10 col-md-9 col-sm-11 align-self-center"><p>From</p></div>
                                <div className="col-lg-2 col-md-3 col-sm-1 pe-1 pt-0"><img
                                    src={`${SERVER}/${authUser?.saloon_logo}`}
                                    className="img-fluid"
                                    height="60px"
                                    width="80px"
                                /></div>
                            </div>

                            <h5 className="fontIcon">{authUser?.saloonName}</h5>
                            <p className="text-muted m-0">{billInfo[0]?.branch_info?.branch_address ?? localBillInfo[0]?.branch_info?.branch_address}</p>
                            {/* <p className="text-muted m-0">{`${billInfo[0]?.employee_info?.first_name ?? localBillInfo[0]?.employee_info?.first_name} ${billInfo[0]?.employee_info?.last_name ?? localBillInfo[0]?.employee_info?.last_name}`}</p> */}
                            <p className="text-muted mb-1">{billInfo[0]?.branch_info?.email ?? localBillInfo[0]?.branch_info?.email}</p>
                        </div>
                        <div className="col-sm-5 col-5 col-md-5 col-lg-5 border border-dark">
                            <div className="row mt-1 d-flex">
                                <div className="col-12 d-flex align-self-center"><p className="p-0">To</p></div>
                            </div>
                            <h5 className="fontIcon">{billInfo[0]?.customerinfo == null && localBillInfo[0]?.customerinfo == null ? "-" : (billInfo[0]?.customerinfo?.name ?? localBillInfo[0]?.customerinfo?.name)}</h5>
                            <p className="text-muted m-0">{billInfo[0]?.customerinfo == null && localBillInfo[0]?.customerinfo == null ? "-" : (billInfo[0]?.customerinfo?.mobile ?? localBillInfo[0]?.customerinfo?.mobile)}</p>
                            <p className="text-muted mb-1">{billInfo[0]?.customerinfo == null && localBillInfo[0]?.customerinfo == null ? "-" : (billInfo[0]?.customerinfo?.email ?? localBillInfo[0]?.customerinfo?.email ?? "-")}</p>
                        </div>
                    </div>
                        <div className="row mt-4 justify-content-around">
                            <div className="col-5"><p><span className="fw-semibold">Invoice No : </span>{billInfo[0]?.id ?? localBillInfo[0].id}</p></div>
                            {/* <div className="col-5"><p><span className="fw-semibold">Due Date : </span></p></div> */}
                            <div className="col-5"><p className=""><span className="fw-semibold ">Invoice Date : </span>{new Date(billInfo[0]?.transaction_date).toLocaleDateString('en-GB') ?? localBillInfo[0]?.transaction_date}</p></div>
                            {/* <div className="col-5"></div> */}
                        </div>
                        <div className="row me-lg-3 ms-lg-3">
                            {billInfo[0] != null ?
                                billInfo[0]?.transactionItems?.map((d: any) => d?.item_type).includes('Service') && billInfo[0]?.transactionItems?.map((d: any) => d?.item_type).includes('Product') ?
                                    <>
                                        <div className="col-12">
                                            <div className="table table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                            <th>Service</th>
                                                            <th>MRP({authUser.currency || '₹'})</th>
                                                            <th>Qty</th>
                                                            <th>Discount</th>
                                                            <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                            <th>Total({authUser.currency || '₹'})</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            billInfo[0]?.transactionItems?.filter(d => d.item_type == 'Service').map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                                <td>{data?.service_type}</td>
                                                                <td>{data?.mrp}</td>
                                                                <td >{data?.quantity}</td>
                                                                <td >{data?.discount_percent}%</td>
                                                                <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                                <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                            </tr>)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="table table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                            <th>Product</th>
                                                            <th>MRP({authUser.currency || '₹'})</th>
                                                            <th>Qty</th>
                                                            <th>Discount</th>
                                                            <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                            <th>Total({authUser.currency || '₹'})</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            billInfo[0]?.transactionItems?.filter(d => d.item_type == 'Product').map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                                <td>{data?.product_name}</td>
                                                                <td >{data?.mrp}</td>

                                                                <td >{data?.quantity}</td>
                                                                <td >{data?.discount_percent}%</td>
                                                                <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                                <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                            </tr>)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="col-12">
                                        <div className="table table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                        <th>{billInfo[0]?.transactionItems?.map(d => d.item_type).includes('Service') ? 'Service' : 'Product'}</th>
                                                        <th>MRP({authUser.currency || '₹'})</th>
                                                        <th>Qty</th>
                                                        <th>Discount</th>
                                                        <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                        <th>Total({authUser.currency || '₹'})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        billInfo[0]?.transactionItems?.map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                            <td>{data?.service_type ?? data?.product_name}</td>
                                                            <td >{data?.mrp}</td>
                                                            <td >{data?.quantity}</td>
                                                            <td >{data?.discount_percent}%</td>
                                                            <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                            <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                :
                                localBillInfo[0]?.transactionItems?.map(d => d.item_type).includes('Service') && localBillInfo[0]?.transactionItems?.map(d => d.item_type).includes('Product') ?
                                    <>
                                        <div className="col-12">
                                            <div className="table table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                            <th>Service</th>
                                                            <th>MRP({authUser.currency || '₹'})</th>
                                                            <th>Qty</th>
                                                            <th>Discount</th>
                                                            <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                            <th>Total({authUser.currency || '₹'})</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            localBillInfo[0]?.transactionItems?.filter(d => d.item_type == 'Service').map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                                <td>{data?.service_type}</td>
                                                                <td >{data?.mrp}</td>
                                                                <td >{data?.quantity}</td>
                                                                <td >{data?.discount_percent}%</td>
                                                                <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                                <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                            </tr>)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="table table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                            <th>Product</th>
                                                            <th>MRP({authUser.currency || '₹'})</th>
                                                            <th>Qty</th>
                                                            <th>Discount</th>
                                                            <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                            <th>Total({authUser.currency || '₹'})</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            localBillInfo[0]?.transactionItems?.filter(d => d.item_type == 'Product').map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                                <td>{data?.product_name}</td>
                                                                <td >{data?.mrp}</td>
                                                                <td >{data?.quantity}</td>
                                                                <td >{data?.discount_percent}%</td>
                                                                <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                                <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                            </tr>)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="col-12">
                                        <div className="table table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr style={{ borderBottom: "1pt solid black", borderTop: "1pt solid black" }}>
                                                        <th>{localBillInfo[0]?.transactionItems?.map(d => d.item_type).includes('Service') ? 'Service' : 'Product'}</th>
                                                        <th>MRP({authUser.currency || '₹'})</th>
                                                        <th>Qty</th>
                                                        <th>Discount</th>
                                                        <th>Discounted Amt.({authUser.currency || '₹'})</th>
                                                        <th>Total({authUser.currency || '₹'})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        localBillInfo[0]?.transactionItems?.map((data: any) => <tr style={{ borderBottom: "1pt solid black" }}>
                                                            <td>{data?.service_type ?? data?.product_name}</td>
                                                            <td >{data?.mrp}</td>
                                                            <td >{data?.quantity}</td>
                                                            <td >{data?.discount_percent}%</td>
                                                            <td>{calculateDiscount(data?.mrp, data?.discount_percent)}</td>
                                                            <td>{calculateRate(data?.mrp, data?.discount_percent) * data?.quantity}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                            }

                        </div>
                        <div className="row justify-content-end me-lg-3 ms-lg-3">
                            <div className="col-12 col-lg-12 col-md-12">
                                <div className="table table-responsive">
                                    <table className="table table-responsive" id="report-table">
                                        <thead>
                                            <tr>
                                                <th colSpan={4}>Invoice Summary</th>
                                            </tr>
                                            <tr>
                                                <th>Total({authUser.currency || '₹'})</th>
                                                <th>Due({authUser.currency || '₹'})</th>
                                                <th>Paid({authUser.currency || '₹'})</th>
                                                <th>Payment</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {billInfo[0]?.payment_info?.length > 0 ? (
                                                <tr>
                                                    <td>{billInfo[0]?.amount ?? localBillInfo[0]?.amount}</td>
                                                    <td>{billInfo[0]?.dues ?? localBillInfo[0]?.dues}</td>
                                                    <td>{billInfo[0]?.total_amount_paid ?? localBillInfo[0]?.total_amount_paid}</td>
                                                    <td colSpan="2">
                                                        {billInfo[0]?.payment_info.map((info: any, index: any) => (
                                                            <div key={index}>
                                                                <b>{info?.payment_mode}:</b> {info?.amount_paid}
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td>{localBillInfo[0]?.amount}</td>
                                                    <td>{localBillInfo[0]?.payment_info?.[0]?.due_amount}</td>
                                                    <td>{localBillInfo[0]?.payment_info?.[0]?.amount_paid}</td>
                                                    <td>{localBillInfo[0]?.payment_info?.[0]?.payment_mode}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div></>}
            </div>
            {/* </div> */}
        </>
    );
}

