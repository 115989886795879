import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const _ = require("lodash");
interface EmployeeState {
    value: Employee[];
    cateValue: EmpCate[];
    upData?: any; // Update this type based on your use case
}

interface Employee {

    // Add other properties as needed
}
interface EmpCate {

    // Add other properties as needed
}

const initialState: EmployeeState = {
    value: [],
    cateValue: [],
    upData: null,
};

const employeeSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {
       
        addEmployeeReducer: (state, action: PayloadAction<Employee>) => {
            state.value.push(action.payload);
        },
        listEmployeeReducer: (state, action: PayloadAction<Employee[]>) => {    
            // state.value = _.orderBy(action.payload,['first_name'],['asc']);
            state.value = action.payload;

        },
        updateEmployeeReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
            state.value = state.value.map((emp:any) => (emp.id === action.payload.id ? action.payload : emp));
            // console.log(state.value)
        },

        empCategoryReducer: (state, action: PayloadAction<EmpCate[]>) => {
            state.cateValue = action.payload;
        },

    },
});

export const { addEmployeeReducer, listEmployeeReducer, updateEmployeeReducer, empCategoryReducer } = employeeSlice.actions;
export default employeeSlice.reducer;




