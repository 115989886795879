import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IncentiveEmployeeState {
    value: IncentiveEmployee[];
    upData?: any;
}

interface IncentiveEmployee {
    id: number;
    employee_id: number;
    incentive_rule_id: number;
    isActive: boolean;
    created_by: number;
    updated_by: number;

}

const initialState: IncentiveEmployeeState = {
    value: [],
    upData: null,
};

const IncentiveEmployeeSlice = createSlice({
    name: "incentiveRule",
    initialState,
    reducers: {
        addIncentiveEmployeeReducer: (state, action: PayloadAction<IncentiveEmployee>) => {
            state.value.push(action.payload);
        },

        listIncentiveEmployeeReducer: (state, action: PayloadAction<IncentiveEmployee[]>) => {
            state.value = action.payload;
        },
        updateIncentiveEmployeeReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
            //state.value = state.value.map(inEmp => (inEmp.id === action.payload.id ? action.payload : inEmp))
// state.value = state.value.map(inEmp =>inEmp.employee_id === action.payload.employee_id ? action.payload : inEmp)
            // console.log(state.value)        
},

        updateIncentiveEmployee: (state, action: PayloadAction<IncentiveEmployee[]>) => {
            state.value = action.payload;
        },
    },
});

export const { listIncentiveEmployeeReducer, addIncentiveEmployeeReducer, updateIncentiveEmployeeReducer, updateIncentiveEmployee } = IncentiveEmployeeSlice.actions;
export default IncentiveEmployeeSlice.reducer;
