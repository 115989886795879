import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AdvancePaymentState {
    value: AdvancePayment[];
    upData?: any; // Update this type based on your use case
}

interface AdvancePayment {

    // Add other properties as needed
}

const initialState: AdvancePaymentState = {
    value: [],
    upData: null,
};

const advancePaymentSlice = createSlice({
    name: "advancepayment",
    initialState,
    reducers: {
        addAdvancePaymentReducer: (state, action: PayloadAction<AdvancePayment>) => {
            state.value.push(action.payload);
        },
        listAdvancePaymentReducer: (state, action: PayloadAction<AdvancePayment[]>) => {
            state.value = action.payload;
        },
        updateAdvancePaymentReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
        },
        deleteAdvancePaymentReducer:(state,action:PayloadAction<AdvancePayment[]>)=>{
            console.log("del",action.payload);
        }
    },
});

export const {listAdvancePaymentReducer,updateAdvancePaymentReducer,addAdvancePaymentReducer,deleteAdvancePaymentReducer} = advancePaymentSlice.actions;
export default advancePaymentSlice.reducer;
